

const initState = {
  // userLoading: false,
  // userInfo :null,
  // userInfoError : {},
  // noUser : false,
  // waitAuthCheck:true,
  // deleteUserError : null,
  // deleteUserSuccess: false,

  // adminUsersLoading: false,
  // adminUsers :null,
  // adminUsersError : null,
  
  // projects : null,
  // projectLoading: false,
  // projectError:'',

  // singleProject:null,
  // singleProjectLoading:false,
  // singleProjectError:'',
  // noSingleProject:false,

  // getAcceptedProjects:null,
  // getAcceptedProjectsLoading:false,
  // getAcceptedProjectsError:'',

  // getCompletedProjects:null,
  // getCompletedProjectsLoading:false,
  // getCompletedProjectsError:'',

  // getInProgressProjects:null,
  // getInProgressProjectsLoading:false,
  // getInProgressProjectsError:'',

  // getPendingProjects:null,
  // getPendingProjectsLoading:false,
  // getPendingProjectsError:'',

  // getRejectedProjects:null,
  // getRejectedProjectsLoading:false,
  // getRejectedProjectsError:'',

  // getAllProjects:null,
  // getAllProjectsLoading:false,
  // getAllProjectsError:'',

  // blogs : null,
  // blogLoading: false,
  // blogError:'',

  // notAllowed:false,
  
  // singleBlog:null,
  // singleBlogLoading:false,
  // singleBlogError:'',
  // noSingleBlog:false,

  // getAllBlogs:null,
  // getAllBlogsLoading:false,
  // getAllBlogsError:'',

  // fetchUserBlog:null,
  // fetchUserBlogLoading:false,
  // fetchUserBlogError:'',

  // commentedBlogs:null,
  // commentedBlogsLoading:false,
  // commentedBlogsError:'',

  // latestBlogsLoading:false,
  // latestBlogs:null,
  // latestBlogsError:'',



  
}

const reducer = (state= initState, action) =>{
  switch (action.type) {

    case 'ADD_TO_NEWSLETTER': 
    return {...state}


    // case "CREATE_PROJECT": 
    //   return {...state} ;
    // case  "CREATE_PROJECT_ERROR" :
    //   return {...state} ;
    // case  "UPDATE_PROJECT" :
    //   return {...state} ;
    // case  "UPDATE_PROJECT_ERROR" :
    //   return {...state} ;


    // case  "ADD_DONATION_PERK" :
    //   return {...state} ;
    // case  "ADD_DONATION_PERK_ERROR" :
    //   return {...state} ;
    // case  "ADD_DONATION" :
    //   return {...state} ;
    // case  "ADD_DONATION_ERROR" :
    //   return {...state} ;

    // case 'GET_USER_DATA':
    //   return { ...state, userLoading: true }
    // case 'GET_USER_DATA_SUCCESS':
    //   return { ...state, userLoading: false , userInfo: action.userInfo,waitAuthCheck:false }
    // case 'GET_USER_DATA_ERROR':
    //   return { ...state, userLoading: false , userInfo: null, userInfoError:action.error,waitAuthCheck:false }
    // case 'NO_USER':
    //   return { ...state, noUser:true,waitAuthCheck:false }
    // case 'LOGOUT':
    //   return { ...state, userInfo :null,waitAuthCheck:false }
    // case 'UPDATE_USER_DATA_SUCCESS':
    //   return { ...state }
    // case 'UPDATE_USER_DATA_ERROR':
    //   return { ...state }
    // case 'UPDATE_USER_EMAIL':
    //   return { ...state }
    // case 'UPDATE_USER_PASSWORD':
    //   return { ...state }

    // case 'DELETE_USER_FROM_FIREBASE_AUTH_AND_FIRESTORE':
    //   return { ...state, userInfo :null,waitAuthCheck:false, deleteUserSuccess:true }
    // case 'CANCEL_DELETE_USER_SUCCESS':
    //   return { ...state, deleteUserSuccess:false }

    // case 'DELETE_USER_ERROR':
    //   return { ...state, userInfo :null,waitAuthCheck:false,deleteUserError: action.email}
    // case 'CANCEL_DELETE_USER_ERROR':
    //   return { ...state, deleteUserError: null}

    //   case 'GET_ADMIN_USERS':
    //     return { ...state, adminUsersLoading: true }
    //   case 'GET_ADMIN_USERS_SUCCESS':
    //     return { ...state, adminUsersLoading: false , adminUsers: action.adminUsers }
    //   case 'GET_ADMIN_USERS_ERROR':
    //     return { ...state, adminUsersLoading: false , adminUsers: null, adminUsersError:action.error }

    //   case 'UPDATE_ADMIN_USERS':
    //     return { ...state }
    //   case 'UPDATE_ADMIN_USERS_ROLE':
    //     return { ...state }

    // case 'FETCH_PROJECTS':
    //   return { ...state, projectLoading:true }
    // case 'FETCH_PROJECTS_SUCCESS':
    //   return { ...state, projectLoading:false, projects: action.projects }
    // case 'FETCH_PROJECTS_ERROR':
    //   return { ...state, projectLoading:false,  projects: null,projectError:action.error }


    //   case 'FETCH_SINGLE_PROJECT':
    //     return { ...state, singleProjectLoading:true }
    //   case 'FETCH_SINGLE_PROJECT_SUCCESS':
    //     return { ...state,singleProjectLoading:false, singleProject: action.singleProject }
    //   case 'FETCH_SINGLE_PROJECT_ERROR':
    //     return { ...state,singleProjectLoading:false, singleProject:null,singleProjectError: action.error }
    //   case 'NO_SINGLE_PROJECT':
    //     return { ...state, noSingleProject:true }
    //   case 'CANCEL_PROJECT_INFO':
    //     return { ...state, singleProject:null }
    //   case 'CANCEL_NO_PROJECT_INFO':
    //     return { ...state, noSingleProject:false }

    //     case "UPDATE_BLOG": 
    //       return { ...state} ;
    //     case  "UPDATE_BLOG_ERROR" :
    //       return { ...state} ;

    //     case "DELETE_BLOG": 
    //       return { ...state} ;
    //     case  "DELETE_BLOG_ERROR" :
    //       return { ...state} ;

    //     case  "CREATE_BLOG_ERROR" :
    //       return { ...state} ;

    //     case 'FETCH_BLOGS':
    //       return { ...state, blogLoading:true }
    //     case 'FETCH_BLOGS_SUCCESS':
    //       return { ...state, blogLoading:false, blogs: action.blogs }
    //     case 'FETCH_BLOGS_ERROR':
    //       return { ...state, blogLoading:false,  blogs: null, blogError:action.error }


    //     case 'FETCH_SINGLE_BLOG':
    //       return { ...state, singleBlogLoading:true }
    //     case 'FETCH_SINGLE_BLOG_SUCCESS':
    //       return { ...state, singleBlogLoading:false, singleBlog: action.singleBlog }
    //     case 'FETCH_SINGLE_BLOG_ERROR':
    //       return { ...state, singleBlogLoading:false, singleBlog:null, singleBlogError: action.error }
    //     case 'NO_SINGLE_BLOG':
    //       return { ...state, noSingleBlog:true }
    //     case 'CANCEL_BLOG_INFO':
    //       return { ...state, singleBlog:null }
    //     case 'CANCEL_NO_BLOG_INFO':
    //       return { ...state, noSingleBlog:false }
        
    //     case 'ADD_COMMENT_TO_PENDINDCOMMENTS':
    //       return { ...state }
    //     case 'ADD_COMMENT_TO_ACCEPTEDCOMMENTS':
    //       return { ...state }
    //     case 'DELETE_COMMENT_TO_PENDINGCOMMENTS':
    //       return { ...state }

    //     case 'GET_LATEST_BLOGS':
    //       return { ...state, latestBlogsLoading:true }
    //     case 'GET_LATEST_BLOGS_SUCCESS':
    //       return { ...state, latestBlogsLoading:false, latestBlogs: action.latestBlogs }
    //     case 'GET_LATEST_BLOGS_ERROR':
    //       return { ...state, latestBlogsLoading:false, latestBlogs:null, latestBlogsError: action.error }


    //       case 'USER_NOT_ALLOWED_TO_SEE_THIS_PAGE':
    //         return { ...state, notAllowed:true }

    //       case 'GET_ALLBLOGS':
    //         return { ...state, getAllBlogsLoading:true }
    //       case 'GET_ALLBLOGS_SUCCESS':
    //         return { ...state, getAllBlogsLoading:false, getAllBlogs: action.allBlogs }
    //       case 'GET_ALLBLOGS_ERROR':
    //         return { ...state, getAllBlogsLoading:false, getAllBlogs: null,getAllBlogsError:action.error }

    //         case 'FETCH_USER_BLOGS':
    //           return { ...state, fetchUserBlogLoading: true }
    //         case 'FETCH_USER_BLOGS_SUCCESS':
    //           return { ...state, fetchUserBlogLoading: false , fetchUserBlog: action.blogs }
    //         case 'FETCH_USER_BLOGS_ERROR':
    //           return { ...state, fetchUserBlogLoading: false , fetchUserBlog: null, fetchUserBlogError:action.error }

    //           case 'GET_COMMENTED_BLOGS':
    //             return { ...state, commentedBlogsLoading:true }
    //           case 'GET_COMMENTED_BLOGS_SUCCESS':
    //             return { ...state, commentedBlogsLoading:false, commentedBlogs: action.commentedBlogs }
    //           case 'GET_COMMENTED_BLOGS_ERROR':
    //             return { ...state, commentedBlogsLoading:false, commentedBlogs: null,commentedBlogsError:action.error }

          
    //         case 'GET_ACCEPTED_PROJECTS':
    //           return { ...state, getAcceptedProjectsLoading:true }
    //         case 'GET_ACCEPTED_PROJECTS_SUCCESS':
    //           return { ...state, getAcceptedProjectsLoading:false, getAcceptedProjects: action.allProjects }
    //         case 'NO_ACCEPTED_PROJECTS':
    //           return { ...state, getAcceptedProjectsLoading:false, getAcceptedProjects: [] }
    //         case 'GET_ACCEPTED_PROJECTS_ERROR':
    //           return { ...state, getAcceptedProjectsLoading:false, getAcceptedProjects: null,getAcceptedProjectsError:action.error }

    //         case 'GET_COMPLETED_PROJECTS':
    //           return { ...state, getCompletedProjectsLoading:true }
    //         case 'GET_COMPLETED_PROJECTS_SUCCESS':
    //           return { ...state, getCompletedProjectsLoading:false, getCompletedProjects: action.allProjects }
    //         case 'NO_COMPLETED_PROJECTS':
    //           return { ...state, getCompletedProjectsLoading:false, getCompletedProjects: [] }
    //         case 'GET_COMPLETED_PROJECTS_ERROR':
    //           return { ...state, getCompletedProjectsLoading:false, getCompletedProjects: null,getCompletedProjectsError:action.error }

    //         case 'GET_IN_PROGRESS_PROJECTS':
    //           return { ...state, getInProgressProjectsLoading:true }
    //         case 'GET_IN_PROGRESS_PROJECTS_SUCCESS':
    //           return { ...state, getInProgressProjectsLoading:false, getInProgressProjects: action.allProjects }
    //         case 'NO_IN_PROGRESS_PROJECTS':
    //           return { ...state, getInProgressProjectsLoading:false, getInProgressProjects: [] }
    //         case 'GET_IN_PROGRESS_PROJECTS_ERROR':
    //           return { ...state, getInProgressProjectsLoading:false, getInProgressProjects: null,getInProgressProjectsError:action.error }

          
    //         case 'GET_PENDING_PROJECTS':
    //           return { ...state, getPendingProjectsLoading:true }
    //         case 'GET_PENDING_PROJECTS_SUCCESS':
    //           return { ...state, getPendingProjectsLoading:false, getPendingProjects: action.pendingProjects }
    //         case 'NO_PENDING_PROJECTS':
    //           return { ...state, getPendingProjectsLoading:false, getPendingProjects: [] }
    //         case 'GET_PENDING_PROJECTS_ERROR':
    //           return { ...state, getPendingProjectsLoading:false, getPendingProjects: null,getPendingProjectsError:action.error }

          
    //         case 'GET_REJECTED_PROJECTS':
    //           return { ...state, getRejectedProjectsLoading:true }
    //         case 'GET_REJECTED_PROJECTS_SUCCESS':
    //           return { ...state, getRejectedProjectsLoading:false, getRejectedProjects: action.allProjects }
    //         case 'NO_REJECTED_PROJECTS':
    //           return { ...state, getRejectedProjectsLoading:false, getRejectedProjects: [] }
    //         case 'GET_REJECTED_PROJECTS_ERROR':
    //           return { ...state, getRejectedProjectsLoading:false, getRejectedProjects: null,getRejectedProjectsError:action.error }


    //         case 'GET_ALLPROJECTS':
    //           return { ...state, getAllProjectsLoading:true }
    //         case 'GET_ALLPROJECTS_SUCCESS':
    //           return { ...state, getAllProjectsLoading:false, getAllProjects: action.allProjects }
    //         case 'GET_ALLPROJECTS_ERROR':
    //           return { ...state, getAllProjectsLoading:false, getAllProjects: null,getAllProjectsError:action.error }
    //         case 'UPDATE_PROJECT_CONFIRMATION_TO_TRUE':
    //           return { ...state }
    //         case 'UPDATE_PROJECT_CONFIRMATION_TO_FALSE':
    //           return { ...state }
    //         case 'UPDATE_PROJECT_FEATURED_TO_TRUE':
    //           return { ...state }
    //         case 'UPDATE_PROJECT_FEATURED_TO_FALSE':
    //           return { ...state }
    //         case 'UPDATE_BLOG_FEATURED_TO_TRUE':
    //           return { ...state }
    //         case 'UPDATE_BLOG_FEATURED_TO_FALSE':
    //           return { ...state }
    //         case 'ADD_TIME_TO_PROJECT':
    //           return { ...state }





    default : return state
  }
 
}

export default reducer
