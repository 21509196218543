import React from 'react'

import {useLocation} from "react-router-dom"

const Footer1 = () => {
    let year = new Date().getFullYear();
    const location = useLocation();

    const isCurrentURL = () => {
        const urls =[
                "/admin/dashboard",
                "/admin/user",
                "/admin/create-blog",
                "/admin/table",
                "/admin/typography",
                "/admin/icons",
                "/admin/maps",
                "/admin/notifications",
                "/admin/projects-validation",
                "/admin/accepted-projects",
                "/admin/rejected-projects",
                "/admin/featured-projects",
                "/admin/featured-blogs",
                "/admin/admin-users",
                "/admin/admin-coments",
                "/admin/edit-blog",
                "/admin/completed-projects",
                "/admin/in-progress-projects"

            ]

        // return location.pathname.toLowerCase() === url.toLowerCase();
        return urls.includes(location.pathname.toLowerCase())
    }



    return (
 <>
  { isCurrentURL() ?
    null :
    <footer className="footer-area heding-bg pos-rel pt-100 footer" style={{backgroundImage:"url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fbg%2F02.jpg?alt=media&token=a849d2fe-2340-47ae-a18c-4001f1a07fc4"+")"}} >
        <div className="container">
            <div className="footer-bottom-area">
                <div className="row mb-30">
                    <div className="col-xl-2 col-lg-3 col-md-6  wow fadeInUp2 animated" data-wow-delay='.1s'>
                        <div className="footer__widget mb-30">
                            <h5 className="semi-title mb-25">Liens Rapide</h5>
                            <ul className="fot-list">
                                <li><a href="#">à propos de nous</a></li>
                                <li><a href="#">Derniers evenements</a></li>
                                <li><a href="#">Comments ça marche</a></li>
                                <li><a href="#">News & articles</a></li>
                                <li><a href="#">Contactez Nous</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-6 wow fadeInUp2 animated" data-wow-delay='.3s'>
                        <div className="footer__widget mb-30 pl-40">
                            <h5 className="semi-title mb-25">Notre Projet</h5>
                            <ul className="fot-list">
                                <li><a href="#">Education</a></li>
                                <li><a href="#">Design</a></li>
                                <li><a href="#">Film & Video</a></li>
                                <li><a href="#">Technology</a></li>
                                <li><a href="#">Jeux</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6  wow fadeInUp2 animated" data-wow-delay='.5s'>
                        <div className="footer__widget mb-25 pl-85">
                            <h5 className="semi-title mb-25">Contact</h5>
                            <p className="mb-10"></p>
                            <ul className="fot-list address-list">
                                <li><a href="#"><i className="fas fa-map-marker-alt"></i>Niamey</a></li>
                                <li><a href="#"><i className="far fa-envelope"></i> contact@kamamini.net</a></li>
                                <li><a href="#"><i className="fas fa-phone-volume"></i> +227  96177658</a></li>
                            </ul>
                            
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-6 col-md-6  wow fadeInUp2 animated" data-wow-delay='.7s'>
                        <div className="footer__widget fot_abot mb-30 pl-85">
                            <h5 className="semi-title mb-25">Donation Us</h5>
                            <p className="mb-30">Sed ut perspi ciatis unde omnis iste 
                                natus error sit voluptatem accusantiu doloremque laudantiu totam</p>
                                <div className="subscribe-content foter-subscribe">
                                    <form className="subscribe-form" action="form.php">
                                        <input className="form-control" type="text" placeholder="Ton Email" />
                                        <button><i className="fas fa-arrow-right"></i></button>
                                    </form>
                                </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* <!--scroll-target-btn--> */}
            <a href="#top-menu" className="scroll-target"><i className="fas fa-arrow-up"></i></a>
            {/* <!--scroll-target-btn--> */}
            <div className="copy-right-area pt-30">
                <div className="row align-items-center">
                    <div className="col-xl-7 col-lg-7 col-md-6">
                        <div className="footer-log mb-30">
                            <a href="#" className="footer-logo mb-30"><img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fimages%2Flogo%2Fkamamini_2.png?alt=media&token=2fe5fbc8-cf7f-4bf0-9c2c-f706df5d5b57"
                               style={{width:'200px'}} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-6">
                        <div className="copyright mb-30 text-md-right">
                            <p>© {year} Kamamini. tous droits réservés</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer> 
}
        </>
    )
}

export default Footer1
