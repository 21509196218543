import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import PropTypes from "prop-types";
import { Grid, Container, CssBaseline, Box, CircularProgress,Avatar,Switch   } from '@material-ui/core'
import { makeStyles,styled  } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {fetchAdminUsers,updateUserAdmin,updateUserRole} from '../../../../redux/actions/authActions';
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import GridItem from "../../components/Grid/GridItem.js";



const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,

    },
    

  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,

  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
}));

const AdminUsers = () => {

    const dispatch= useDispatch();
    const classes = useStyles();
    const adminUsers = useSelector(state =>state.auth.adminUsers);
    
    useEffect(() => {
        window.scrollTo(0, 0)
       
      }, [])

    useEffect(() => {
        dispatch(fetchAdminUsers())

    }, [dispatch])

    const adminHandleChange = (event,id) => {
      dispatch(updateUserAdmin(event.target.checked, id))
    };

    const roleHandleChange = (event,id) => {
      dispatch(updateUserRole(event.target.value, id))
    };


    if(adminUsers){
    return (
        <>
        <CssBaseline/>
        <Container>
            <Box mb={5}></Box>
            <Grid container spacing={2}>
            <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Liste des administrateurs </h4>
              
            </CardHeader>
            <CardBody>
             
              <div className={classes.tableResponsive}>
                    <Table className={classes.table}>
                    
                        <TableHead className={classes[`${"warning"}  ${"TableHeader"}` ]}>
                          <TableRow className={classes.tableHeadRow}>
                            
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}> </TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>ID </TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Nom </TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Email </TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Admin </TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Role </TableCell>
                      
                          </TableRow>
                        </TableHead>
                      
                      <TableBody>
                        { adminUsers.map((user,key)=> {
                          return (

                            <TableRow key={key} className={classes.tableBodyRow}>
                            
                                <TableCell className={classes.tableCell} > <Avatar src={user.photoURL} /> </TableCell>
                                <TableCell className={classes.tableCell} > {user.id} </TableCell>
                                <TableCell className={classes.tableCell} > {user.displayName} </TableCell>
                                <TableCell className={classes.tableCell} > {user.email} </TableCell>
                                <TableCell className={classes.tableCell} > 
                                  <Android12Switch   checked={user.admin} onChange={(e) => adminHandleChange(e,user.id)} />
                                </TableCell>
                                <TableCell className={classes.tableCell} >
                              
                                <select className="custom-select" value={user.role} onChange={(e) => roleHandleChange(e,user.id)} >
                                  <option value='' >Aucune</option>
                                  <option value="Admin" >Admin</option>
                                  <option value="Staff">Staff</option>
                                  <option value="Editor">Editor</option>                               
                                </select>
          
                                </TableCell>
                            </TableRow>

                          );
                        })} 
                      </TableBody>
                    </Table>
                  </div>


            </CardBody>
          </Card>
        </GridItem>
             </Grid>


        </Container>

    </>
    )
}
else{
    return(
        <div id="preloader">
            <div className="preloader">
            <CircularProgress style={{color:'green'}} />
            </div>
        </div>
      )
}

}

export default AdminUsers

AdminUsers.defaultProps = {
  tableHeaderColor: "gray",
};

AdminUsers.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
