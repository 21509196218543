import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';


import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
// import Icon from 'src/@core/components/icon'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


import firebase from 'firebase/app';
// import 'firebase/firestore';




const CategorieFolder = () => {

        // initialisation erhiub
        const [categories, setCategories] = useState([]);
        const [newCategory, setNewCategory] = useState('');
        const [updatedCategory, setUpdatedCategory] = useState({ id: '', newName: '' })
      
        // Fonction pour récupérer les catégories depuis Firestore
        const fetchCategories = async () => {
          const categoriesCollection = await firebase.firestore().collection('categories').get();
          const categoriesData = categoriesCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setCategories(categoriesData);
        };
      
        useEffect(() => {
          fetchCategories();
        }, []);
      
        // Fonction pour ajouter une nouvelle catégorie
        const addCategory = async () => {
          await firebase.firestore().collection('categories').add({ name: newCategory });
          setNewCategory('');
          // Mettre à jour la liste des catégories après l'ajout
          fetchCategories();
        };
      
        // Fonction pour supprimer une catégorie
        const deleteCategory = async (categoryId) => {
          await firebase.firestore().collection('categories').doc(categoryId).delete();
          // Mettre à jour la liste des catégories après la suppression
          fetchCategories();}

          // Fonction pour mettre à jour une catégorie
          const updateCategory = async () => {
            await firebase.firestore().collection('categories').doc(updatedCategory.id).update({ name: updatedCategory.newName });
            // Mettre à jour la liste des catégories après la modification
            fetchCategories();
            // Réinitialiser l'état de mise à jour
            setUpdatedCategory({ id: '', newName: '' });
          };







    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
      


      //the dialog for adding
      const [open, setOpen] = React.useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };


      // dialog for update
      const [open2, setOpen2] = React.useState(false);
      const handleClickOpene = () => {
        setOpen2(true);
        
      };

      const handleClosee = () => {
        setOpen2(false);
      };
      
  return (
    <div>


        <Box style={{textAlign:'right'}} className='mb-3'>
            <React.Fragment>
              <Button variant="outlined" onClick={handleClickOpen}>
                Ajouter
              </Button>
              <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                  component: 'form',
                  onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const email = formJson.email;
                    console.log(email);
                    handleClose();
                  },
                }}
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              >
                <DialogTitle>Ajouter une categorie</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Ajouter la categorie que vous désirez
                  </DialogContentText>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="text"
                    label="Nom de la catégorie"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Annuler</Button>
                  <Button type="submit" onClick={addCategory}>Ajouter</Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
        </Box>


        {/* dialog for update */}
        <Box style={{textAlign:'right'}} className='mb-3'>
          {(updatedCategory.id &&
            <React.Fragment>
              <Dialog
                open={open2}
                onClose={handleClosee}
                PaperProps={{
                  component: 'form',
                  onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const email = formJson.email;
                    console.log(email);
                    handleClosee();
                  },
                }}
                value={updatedCategory.newName}
                onChange={(e) => setUpdatedCategory({ ...updatedCategory, newName: e.target.value })}
              >
                <DialogTitle>Modifier</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Modifier le nom de la catédorie
                  </DialogContentText>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="text"
                    label="Nom de la catégorie"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClosee}>Annuler</Button>
                  <Button type="submit" onClick={updateCategory} >Modifier</Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
            )}
        </Box>


    {/* the table */}
        <Box>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Categories</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map(category => (
            <StyledTableRow key={category.id}>
              <StyledTableCell component="th" scope="row">
              {category.name}{' '}
              </StyledTableCell>
              <StyledTableCell align="right">
              <Box>
          <IconButton >
          <EditIcon onClick={() => {handleClickOpene(); setUpdatedCategory({ id: category.id, newName: category.name })}}>Edit</EditIcon>
          </IconButton>
          <IconButton>
            <DeleteIcon onClick={() => deleteCategory(category.id)}>Delete</DeleteIcon>
          </IconButton>
            </Box>
              </StyledTableCell>
              {/* <StyledTableCell align="right"></StyledTableCell> */}
            </StyledTableRow>
           
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Box>



    </div>
  )
}
export default CategorieFolder












