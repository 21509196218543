import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CssBaseline, Box } from "@material-ui/core";
import { acceptedProjects } from "../redux/actions/projectActions";
import ProjectList from "../Components/ProjectList/AllProjectList";
import Seo from "../Components/Helmet/Seo";
import Loader from "../Components/Loader/Loader";
import Errorcase from "../Components/ErrorCase/ErrorCase";


const GetAllProjects = ({ setSticky }) => {
  const dispatch = useDispatch();

  const getAcceptedProjectsLoading = useSelector(state => state.projects.getAcceptedProjectsLoading)
  const getAcceptedProjects = useSelector((state) => state.projects.getAcceptedProjects);
  const getAcceptedProjectsError = useSelector((state) => state.projects.getAcceptedProjectsError);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Définis la taille de la page selon tes besoins

  // Gère la pagination localement
  // const startIndex = (currentPage - 1) * pageSize;
  // const endIndex = startIndex + pageSize;
  // const displayedProjects =getAcceptedProjects && getAcceptedProjects.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const paginationInfo = {
    count:
      getAcceptedProjects && Math.ceil(getAcceptedProjects.length / pageSize),
    page: currentPage,
    onChange: handlePageChange,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(acceptedProjects());
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setSticky(false);
    return () => {
      setSticky(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (getAcceptedProjectsLoading) {
    return (
      <Loader />
    )
  }


  if (getAcceptedProjectsError) {
    return (
      <Errorcase />
    )
  }

  if (getAcceptedProjects) {
    return (
      <>
        <Seo
          title="Projets de Kamamini"
          description="Explorez une variété de projets passionnants sur Kamamini. Trouvez des idées innovantes et soutenez les projets qui vous tiennent à cœur."
          name="Kamamini"
          type="website"
        />

        <CssBaseline />

        <section
          className="page-title-area"
          style={{
            backgroundImage:
              "url(" +
              "https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fshape%2Fproject.jpeg?alt=media&token=5b510057-4c4e-42f3-9cbe-9f17cffe8842" +
              ")",
          }}
        >
          <div className="right-border-shape">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fshape%2F02.png?alt=media&token=e3e85ab5-6bcd-4ca5-86b4-77ff51f0cb54"
              alt="kamamini shape"
            />
          </div>

          <div className="page-title-wrapper text-center ">
            <h1
              className="page-title wow fadeInUp2 animated"
              data-wow-delay=".1s"
            >
              Tous les projets
            </h1>
          </div>
        </section>
        <ProjectList
          projects={getAcceptedProjects}
          paginationInfo={paginationInfo}
        />
        <Box mb={5}></Box>
      </>
    );
  }
  else {
    return (
      <Loader />
    );
  }
};

export default GetAllProjects;
