import React from 'react';

import { Link } from "react-router-dom"

const AboutSection = () => {

    return (
        <div className='my-3'>

            <div className='container mx-auto'>

                <div className='row '>
                    <div className="col-xl-6 col-lg-5">
                        <div className="testimonial-img-box">
                            <div className="img-item wow d-none d-xl-inline-block fadeInUp2 animated" data-wow-delay='.3s'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fshape%2Fproject-people_1.jpg?alt=media&token=99b0f165-e5f6-428e-9940-61d4fe17db1b" alt="kamamini" />
                            </div>
                            <div className="img-item-two wow fadeInUp2 animated" data-wow-delay='.5s'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fbrand_1.jpg?alt=media&token=1fc4912c-9097-42bb-a1cc-9f9556b6749e" alt="kamamini"
                                    style={{ width: '350px' }}
                                />
                            </div>
                            <div className="img-item-three  wow fadeIn animated" data-wow-delay='.7s'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fshape%2F06.png?alt=media&token=cc1f3544-749c-4b7b-998b-263d762fa4ce" alt="kamamini" />
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-6 col-lg-7 px-3  mb-85'>
                        <h6 className='left-line left-linetest pl-75 pr-75 font-weight-bold '> KAMAMINI</h6>
                        Notre plateforme de financement participatif est un moyen innovant pour les gens de soutenir les projets qu'ils croient
                        importants. Les utilisateurs peuvent parcourir des projets en cours de financement et choisir de les soutenir.
                        Les projets peuvent être variés, allant de la création de produits à la réalisation de projets sociaux en passant par
                        les start-ups. Les utilisateurs peuvent également suivre l'avancement des projets qu'ils ont soutenus et voir comment leur
                        contribution a aidé à les rendre réalité. En utilisant notre plateforme, les utilisateurs peuvent non seulement soutenir
                        des projets qui leur tiennent à cœur, mais ils peuvent également devenir des acteurs actifs dans la création de projets
                        qui ont un impact réel sur leur communauté.


                        <p className='pt-60'>
                            <Link to='/about'>
                                <span className="theme_btn theme_btn_bg" data-animation="fadeInLeft" data-delay=".7s">
                                    Savoir plus
                                    <i className="fas fa-arrow-right"></i>
                                </span>
                            </Link>
                        </p>
                    </div>

                </div>
            </div>
            <hr />

        </div>

    );
}

export default AboutSection;

