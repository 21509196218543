import React,{useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { Grid, Container, CssBaseline, AppBar, Tabs, Tab, Box,CircularProgress  } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
// import GridContainer from "../../components/Grid/GridContainer.js";
// import Tasks from "../../components/Tasks/Tasks.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
import {inProgressProjects} from '../../../../redux/actions/projectActions'
import styles from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import SingleProjectCard5 from '../../../Cards/SingleProjectCard5/SingleProjectCard5' 


const useStyles = makeStyles(styles);

const MakeProjectsFeatured = () => {

    const dispatch= useDispatch();
    // const classes = useStyles();
    const getAllProjects = useSelector(state =>state.projects.getInProgressProjects);
    useEffect(() => {
        window.scrollTo(0, 0)
       
      }, [])

    useEffect(() => {
        dispatch(inProgressProjects())

    }, [dispatch])

    const pendingProjects = getAllProjects && getAllProjects.filter(project => project.featured === false )
    const pendingFeaturedProjects = getAllProjects && getAllProjects.filter(project => project.featured === true )



if(pendingProjects){
      
    return (
        <>
              
            <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="Projet"
            headerColor="success"
            tabs={[
              {
                tabName: "Tout les projets",
                // tabIcon: BugReport,
                tabContent: (
                  
                    <>
                    <CssBaseline/>
                    
                        <Box mb={5}></Box>
                        <Grid container spacing={2}>
                             {
                               pendingProjects.map(project =>{ 
                                     return(
                                         <Grid container item xs={12} sm={6} md={6} lg={4} key={project.id} >
                                             <SingleProjectCard5 project={project}  /> 
                                         </Grid>
                                     )
                                 })
                             }
                         </Grid>
                    
                </>
              
                ),
              },
              {
                tabName: "Mis en avant",
                // tabIcon: Code,
                tabContent: (
                    <>
                    <CssBaseline/>
                    <Container>
                        <Box mb={5}></Box>
                        <Grid container spacing={2}>
                             {
                               pendingFeaturedProjects.map(project =>{ 
                                     return(
                                         <Grid container item xs={12} sm={6} md={6} lg={4} key={project.id} >
                                             <SingleProjectCard5 project={project}  /> 
                                         </Grid>
                                     )
                                 })
                             }
                         </Grid>
                    </Container>
    
                </>
                ),
              },
              
            ]}
          />
        </GridItem>
        </>
    )
    }
    else{
        return(
            <div id="preloader">
                <div className="preloader">
                <CircularProgress style={{color:'green'}} />
                </div>
            </div>
          )
    }
}

export default MakeProjectsFeatured
