import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "../Admin/components/CustomButtons/Button.js";

const ActionDialog = ({title,image,contentText,open,handleClose,height,width,dangerContent,buttonAction,buttonActionParam}) => {

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
          {
            image && 
            <div className='text-center' >
              <img src={image} className='mt-3 img-fluid text-center' style={{height: height,width:width}} alt={`Kamamini ${title}`} />
            </div>
            
          }
            
            <DialogTitle  id="alert-dialog-title" className='d-flex justify-content-center'  >
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className='text-center' >
                    {contentText} 
                    <br/>
                    {
                        dangerContent &&  <strong className='text-danger' >{dangerContent}</strong>
                    }
                    <br/>
                    <br/>
                    <div  className="d-flex justify-content-around">
                        <Button color="success" onClick={()=> {buttonAction(buttonActionParam);handleClose()}} >Confirmer</Button>
                        <Button color="danger" onClick={handleClose} >Annuler</Button>
                    </div>
                </DialogContentText>
            </DialogContent>
    </Dialog>
  )
}

export default ActionDialog