import React from 'react'
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
const True = () => {
  return (
    <div>

            <div class="card bg-secondary " style={{width: "28rem", height:'18rem', cardBoxShadow:'2'}}>
            <div class="card-body d-flex align-items-center justify-content-center m-auto p-auto">
                <LinkedCameraIcon color='primary' sx={{ fontSize: 100 }} />
            </div>
            </div>

    </div>
  )
}

export default True