import React from 'react'
import {Link} from 'react-router-dom'
import '../assets/css/errorPage.css' 
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton'
import Seo from '../Components/Helmet/Seo'


const Error = () => {
    return (
      <>
      <Seo
        title="Page non trouvée"
        description="La page que vous cherchez n'a pas pu être trouvée."
        name="Kamamini"
        type="website"
      />
      <div className="py-5 pt-3">

      <div className="container ody">
      <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Ferror%2F404.svg?alt=media&token=103ec16c-43c5-4168-a578-14e721d393ac"
       alt="" className="img-responsive img-fluid"/>
      <div className="wrapperu">
          <h1>Page Non trouvé</h1>
         <p className="messageu">
          Oups! La page que vous rechercher est introuvable. il semble que le lien que vous avez suivi soit rompu
          ou que la page est été supprimée. Vous pouvez retourner a la page d'acceuil en cliquant sur le bouton ci-dessous.
          si le probleme persiste, veuillez nous contacter pour obtenir de l'aide.
         </p>
         <Link to='/' className="pb-5 ">
           <KamaminiButton text="Page d'accueil" />
         </Link>
         {/* <p className="copyRightsu">&copy; 2020 DeltatyCode</p> */}
      </div>
      </div>
      </div>
      </>
    )
}

export default Error
