import React from 'react'
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Grid, Box ,makeStyles,CircularProgress} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer } from 'react-toastify';
import { deepOrange } from '@material-ui/core/colors';
import moment from 'moment'
import ReactHtmlParser from "react-html-parser";
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton} from "react-share";
import {Link} from "react-router-dom"
import { getSingleBlog,addCommentsToPendingBlog,cancelBlogInfo,cancelNoBlogInfo,getRecentBlogs } from '../redux/actions/blogActions'
import { frenchSittings } from '../redux/actions/actions'
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton'
// import stop from '../assets/img/stopKamamini.svg';
import ConnectionError from '../Components/ConnectionError/ConnectionError'
import Seo from '../Components/Helmet/Seo'



const useStyles = makeStyles((theme) => (
    {
        
      root: {
        '& ul li': {
            listStyle:'inside'
          },
        '& ol li': {
            listStyle:'decimal'
          },
      
      },
      orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
      },
      large: {
        width: theme.spacing(11),
        height: theme.spacing(11),
      },

    }
    ));


const SingleBlog = (props) => {

     //corresponding to the searched url
//   const slug = props.match.params.slug;  

  const slug = window.location.href.split('blogs/')[1];


  const dispatch = useDispatch() 
  const classes = useStyles();

  const singleBlog = useSelector(state => state.blogs.singleBlog)
  const noSingleBlog = useSelector(state => state.blogs.noSingleBlog)
  const singleBlogError = useSelector(state => state.blogs.singleBlogError)
  let latestBlogs = useSelector(state => state.blogs.latestBlogs)
  // exclduing the current blog from the latest blogs if it is there
    if(latestBlogs && latestBlogs.length > 0){
        latestBlogs = latestBlogs.filter(blog => blog.slug !== slug)
    }
  const userInfo = useSelector(state => state.auth.userInfo)


  const [comments, setComments] = useState('')
  const [open, setOpen] = useState(false)

  const handleClickOpen =() =>{
    setOpen (true)
  }

  const handleClose = () => {
      setOpen(false)
  }
  
  moment.locale('fr',frenchSittings());

  useEffect (()=>{
    window.scrollTo(0, 0)
    dispatch(getSingleBlog(slug))
 
    return () => {
        dispatch(cancelBlogInfo())
        dispatch(cancelNoBlogInfo())
      }
    
  },[dispatch,slug]) 

  useEffect(() => {
    if(singleBlog)
    {
        dispatch(getRecentBlogs())
    }
  }, [dispatch,singleBlog])
  

  const handleCommentsChange = (e) => {
    setComments({
        text:e.target.value,
        userInfo,
        blogId:singleBlog.id,
        blogTitle:singleBlog.title,
        commentDate:moment().format("DD MMM YYYY")
    })
  }


  const writeComments = () => {
      dispatch(addCommentsToPendingBlog(comments,singleBlog.id,singleBlog.slug))
      setComments({
        text:'',
        userInfo,
        blogId:singleBlog.id,
        blogTitle:singleBlog.title,
    })
  }
 



   if(singleBlog && latestBlogs ){ 
       const {urlImage,title,blogText,authorDisplayName,authorImageUrl,category  } = singleBlog
    return (
    <>
    <Seo
    title={`${title}`}
    description={`Découvrez ${title} sur Kamamini`}
    name="Kamamini"
    type="article"
    />

    <ToastContainer />
                 

       {/* <!--blog-area start--> */}
       <section className="blog-details-area grey-bg pt-130 pb-100">
        <div className="container">
            <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-12 mb-40">
                    <div className="blogs__thumb--img white-bg pb-25">
                        <img src={urlImage} alt={title} />
                   </div>
                    <div className="blogs-details-content-area white-bg">
                        <div className="blogs blogs-03 white-bg wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <div className="blogs__thumb pos-rel mb-35">
                                <span className="blog-tag" >{category} </span>
                            </div>
                            <div className="blogs__content blogs-03__content">
                                <h3 className="mb-20">{title}</h3>
                                <div className="blogs__content--meta">
                                    <span><i className="far fa-user-circle"></i>{authorDisplayName}</span>
                                    <span><i className="far fa-calendar-alt"></i>{moment(singleBlog.timestamp.toDate()).format("DD MMMM  YYYY")}  </span>
                                    <span><i className="far fa-comment"></i>({singleBlog.acceptedComments.length}) Commentaires </span>
                                    {/* <span><i className="far fa-heart"></i> Like (1K)</span> */}
                                </div>

                                <div className={classes.root} >
                                    {ReactHtmlParser(blogText)} 

                                </div>
                                
                            </div>
                        </div>
                       
                        <div className="blog-post-tag wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <div className="row">
                                {/* <div className="col-xl-6 col-lg-6 col-md-7">
                                    <div className="post-tag-list mb-50">
                                        <span>Tags : </span>
                                        <a href="!#">Business Cart, </a>
                                        <a href="!#">Design & Branding</a>
                                    </div>
                                </div> */}
                                <div className="col-xl-6 col-lg-6 col-md-5">
                                    <div className="post-share-icon text-md-right mb-50">
                                        <span>Partager : </span>
                                        <FacebookShareButton    
                                            url ={`https://kamamini.net/blogs/${slug}`}
                                            quote={`Retrouvez le blogue ${singleBlog.title}  sur Kamamini`}
                                            hashtag={'Kamamini'}
                                        >
                                        <a href="!#"><i className="fab fa-facebook-f"></i></a>
                                        </FacebookShareButton>
                                        <TwitterShareButton 
                                        title={`Retrouvez le blogue ${singleBlog.title}  sur Kamamini`}
                                        via={'KamaminiCrowd'}
                                        hashtags={['Kamamini', 'Crowdfunding']}
                                        related={['@KamaminiCrowd']}
                                        url ={`https://kamamini.net/blogs/${slug}`}
                                        quote={`Retrouvez le blogue ${singleBlog.title}  sur Kamamini`}
                                        >
                                        <a href="!#"><i className="fab fa-twitter"></i></a>
                                        </TwitterShareButton>
                                        <LinkedinShareButton 
                                        title={`Retrouvez le blogue ${singleBlog.title}  sur Kamamini`}
                                        source ={`https://kamamini.net`}
                                        url ={`https://kamamini.net/blogs/${slug}`}
                                        summary= {singleBlog.title}
                                         >
                                        <a href="!#"><i className="fab fa-linkedin"></i></a>
                                        </LinkedinShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="author grey-bg mb-50 wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <div className="author__avatar">
                                <img className='rounded-circle' src={authorImageUrl} alt={authorDisplayName}/>
                            </div>
                            <div className="author__text">
                                <h4 className='py-3' >{authorDisplayName.toUpperCase()} </h4>
                                <div className="post-share-icon">
                                    <a rel="noopener noreferrer" target='_blank' href='#' ><i className="fab fa-facebook-f"></i></a>
                                    <a rel="noopener noreferrer" target='_blank' href='#' ><i className="fab fa-twitter"></i></a>
                                    <a rel="noopener noreferrer" target='_blank' href='#' ><i className="fab fa-linkedin"></i></a>
                                    <a rel="noopener noreferrer" target='_blank' href='#' ><i className="fab fa-github"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="blog-post-area mb-45  wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <div className="row">
                                <div className="col-xl-6 col-lg-12 col-md-12">
                                    <div className="post d-sm-flex align-items-center mb-50">
                                        <div className="post__img mr-20">
                                            <Link to={`${latestBlogs[0].slug}`}>
                                                 <img src={latestBlogs[0].urlImage} alt={latestBlogs[0].title} /> 
                                            </Link>
                                        </div>
                                        <div className="post__text">
                                            <h5><Link to={`${latestBlogs[0].slug}`}>{latestBlogs[0].title} </Link></h5>
                                            <div className="post__text--meta">
                                                <span><i className="fas fa-map-marker-alt pr-1"></i>{latestBlogs[0].category} </span>
                                                <span>
                                                    <i className="far fa-calendar-alt pr-1"></i>
                                                    {moment(latestBlogs[0].timestamp.toDate()).format("DD MMM  YYYY")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12">
                                    <div className="post d-sm-flex align-items-center mb-50">
                                        <div className="post__img mr-20">
                                            <Link to={`${latestBlogs[0].slug}`}>
                                                    <img src={latestBlogs[1].urlImage} alt={latestBlogs[0].title} /> 
                                            </Link>
                                        </div>
                                        <div className="post__text">
                                            <h5><Link to={`${latestBlogs[1].slug}`}>{latestBlogs[1].title} </Link></h5>
                                            <div className="post__text--meta">
                                                <span><i className="fas fa-map-marker-alt pr-1"></i>{latestBlogs[1].category} </span>
                                                <span>
                                                    <i className="far fa-calendar-alt pr-1"></i>
                                                    {moment(latestBlogs[1].timestamp.toDate()).format("DD MMM  YYYY")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="post-comments mb-50 wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <h4 className="comments-title mb-40">Commentaires du public</h4>
                            <ul className="latest-comments">
                                {
                                    singleBlog.acceptedComments.map((comment,index) => (
                                        <li key={index} className='mb-3' >
                                            <div className="comments-box">
                                                <div className="comments__avatar ">
                                                {
                                    comment.userInfo.photoURL? <Avatar  className={classes.large} alt={comment.userInfo.displayName} src={comment.userInfo.photoURL} />:<Avatar className={`${classes.orange} ${classes.large}`}>{comment.userInfo.initials} </Avatar>
                                  }
                                                </div>
                                                <div className="comments__content fix">
                                                    <h5 className='text-uppercase' >{comment.userInfo.displayName}</h5>
                                                {/* <span>IT Consultant</span> */}
                                                    <p>{comment.text}</p>
                                                    <div className="comments__meta">
                                                        <span>{comment.commentDate}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                                

                                
                                
                                
                            </ul>
                        </div>
                        <div className="post-comments-form pb-40 wow fadeInUp2 animated" data-wow-delay='.1s'>
                            {/* <h4 className="comments-title mb-40">Commenter</h4> */}
                            <div className="post-form-area">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="input-text input-msg mb-30">
                                            <textarea onChange={handleCommentsChange} value={comments.text} name="message" id="message" cols="30" rows="10" placeholder="Laisser un commentaire"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="comment-btn">
                                        {
                                            !userInfo ? 
                                            //Modals
                                            <div className="main-menu main-menu-02 d-none d-lg-block">
                                                <span onClick={handleClickOpen} className="theme_btn theme_btn_bg" >
                                                    Commenter 
                                                    <i className="far fa-arrow-right"></i>
                                                </span>
            
                                                <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description">

                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2FstopKamamini.svg?alt=media&token=a3a6ba1e-98f7-4277-a23b-dbf1820bfb0c" className='mt-3' style={{height: "60px"}} alt="Commentaire bloqué" />
                                                                                
                                                    <DialogTitle  id="alert-dialog-title" className='d-flex justify-content-center'  >
                                                    {"Vous n'avez pas accès à cette fonctionnalité"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            Vous devez être connecté. 
                                                            <br/>
                                                        </DialogContentText>
                                                        <div className="d-flex justify-content-center mt-3" >
                                                            <Link to='/login'>
                                                                <KamaminiButton onClick={handleClose} text="VOUS CONNECTER" />
                                                            </Link>
                                                        </div>
                                                    </DialogContent>
                                                </Dialog>

                                            </div>
                                            :
                                            
                                            <span onClick={() => writeComments()} className="theme_btn theme_btn_bg" >
                                                Commenter 
                                                <i className="far fa-arrow-right"></i>
                                            </span>

                                        }
                                            

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="col-xl-4 col-lg-4 col-md-12">
                    <div className="blog-standard-right">
                        <div className="widget mb-40 wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <ul className="widget-list">
                               <li>Web Development <span className="f-right"><i className="far fa-chevron-right"></i></span></li>
                               <li>IT Consulting <span className="f-right"><i className="far fa-chevron-right"></i></span></li>
                               <li>Product Engineering <span className="f-right"><i className="far fa-chevron-right"></i></span></li>
                               <li>Product Marketing <span className="f-right"><i className="far fa-chevron-right"></i></span></li>
                               <li>Education <span className="f-right"><i className="far fa-chevron-right"></i></span></li>
                               <li>Medical & Health <span className="f-right"><i className="far fa-chevron-right"></i></span></li>
                            </ul>
                        </div>
                        <div className="widget white-bg mb-40 pb-10 wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <h4 className="widget-title pt-40 mb-20 pl-35">
                                Articles Récents
                                </h4>
                            <ul className="post-list">
                                {
                                    latestBlogs.map((blog, index) => (
                                        <li key={index} >
                                        <div className="post d-sm-flex align-items-center mb-20">
                                            <div className="post__img mr-20">
                                            <Link to={`${blog.slug}`} >
                                                <img src={blog.urlImage} alt={blog.title} />
                                            </Link>
                                                
                                            </div>
                                            <div className="post__text">
                                                <h6  ><Link to={`${blog.slug}`} >{blog.title}</Link></h6>
                                                <span>
                                                    <i className="fas fa-chevron-right pr-2"></i>
                                                    {blog.category} 
                                                </span>
                                            </div>
                                        </div>
                                    </li> 
                                    ))
                                }
                                
                            </ul>
                        </div>
                        
                        <div className="widget white-bg mb-40 pb-10 wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <h4 className="widget-title pt-35 mb-20 pl-35">Popular Tags</h4>
                            <div className="tags">
                               <a href="!#">Design</a>
                               <a href="!#">Portfolio</a>
                               <a href="!#">Energy</a>
                               <a href="!#">Resume</a>
                               <a href="!#">Gallery</a>
                               <a href="!#">Renewable</a>
                            </div>
                        </div>
                        <div className="widget wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <div className="widget-donate-box pos-rel text-center" style={{backgroundImage: "url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fblog%2F15.jpg?alt=media&token=93cc4736-43c5-4972-a44e-0e0d1f428507"+")"}}
                            >
                                <h5>Donate Now</h5>
                                <h3>Want To Donate  
                                    Our Product</h3>
                                    <a className="theme_btn theme_btn_bg" href="contact.html"
                                    data-animation="fadeInLeft" data-delay=".5s">donate now <i
                                        className="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--blog-area end--> */}
        {/* <!--cta-area start--> */}
        {/* <section className="cta-area theme-bg pt-55 pb-25">
            <div className="container">
                <div className="row align-items-md-center">
                    <div className="col-xl-9 col-lg-9 col-md-8">
                        <div className="cta-wrapper wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <div className="section-title mb-30">
                                <h2>Become Our <b>Premium</b> Partner ?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="cta-wrapper wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <div className="cta-btn text-md-right">
                                <a className="theme_btn theme_btn_bg" href="contact.html">contact us <i
                                    className="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!--cta-area end--> */}
    
         
        </>
    )
   
}
else if (noSingleBlog){
    return(

    <>
    <Container >
        <Box mb={5} mt={5}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={11} >
                <h5>Il n' y a pas de blog correspondant à cet identifiant</h5> 
            </Grid>
            </Grid>
        </Box>
    </Container>
    </>)

}
else  if (singleBlogError){
    return(
      <ConnectionError />
    )
  }
else{
    return(
    <div id="preloader">
        <div className="preloader">
        <CircularProgress style={{color:'green'}} />
        </div>
    </div>    
    )
}
}


export default SingleBlog
