import { db, auth } from '../../Firebase/Firebase'
import firebase from 'firebase/app';
import moment from 'moment'
import axios from 'axios'
import { successToast, errorToast, getToken } from './actions'


// Fonction pour envoyer un e-mail à l'utilisateur
const sendUserEmail = async (userInfo, projectName) => {
  const token = await getToken();
  await axios.post('https://api.kamamini.net/UserProjectSendEmail', {
    email: userInfo.email,
    name: userInfo.displayName,
    projectName: projectName
  }, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
}

// Fonction pour envoyer un e-mail à l'administrateur
const sendAdminEmail = async (userInfo, projectName, projectId) => {
  const token = await getToken();
  await axios.post('https://api.kamamini.net/AdminProjectSendEmail', {
    email: userInfo.email,
    name: userInfo.displayName,
    projectName: projectName,
    UserId: userInfo.uid,
    ProjectId: projectId
  }, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
}

// create project and adding the user information to the project
export const createProject = (project, userInfo, handleClickOpen) => {
  return async (dispatch) => {

    // Ajout de la propriété de slug à l'objet "project"
    project.slug = encodeURIComponent(project.title);
    try {
      if (userInfo) {
        const docRef = await db.collection('projects').add({
          ...project,
          confirmation: '',
          featured: false,
          authorId: userInfo.uid,
          authorDisplayName: userInfo.displayName,
          authorImageUrl: userInfo.photoURL,
          authorInitials: userInfo.initials,
          authorEmail: userInfo.email,
          donor: [],
          donorsIds: [],
          donorsNumber: 0,
          comments: [],
          moneyRaised: 0,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });

        // Envoi d'e-mails
        await sendUserEmail(userInfo, project.title);
        await sendAdminEmail(userInfo, project.title, docRef.id);

        dispatch({
          type: "CREATE_PROJECT",
          project
        });

        handleClickOpen();
      } else {
        dispatch({
          type: "CREATE_PROJECT_ERROR",
          error: 'Veuillez vous connecter'
        });
      }
    } catch (firestoreError) {
      errorToast('Une erreur s\'est produite lors de la création du projet')
      dispatch({
        type: "CREATE_PROJECT_ERROR",
        error: 'Une erreur s\'est produite lors de la création du projet'
      });
    }
  }
}


export const updateAcceptedProject = (project) => {
  return async (dispatch) => {
    try {
      await db.collection('projects').doc(project.id).update({
        projectText: project.wysiwyg,
        images: project.images,
        youtubeVideos: project.youtubeUrl,
        faq: project.faq,
        perk: project.perkData,
        featuredImage: project.newImage,
        updateTime: firebase.firestore.FieldValue.serverTimestamp(),
      });

      dispatch({
        type: "UPDATE_PROJECT",
      });

      dispatch(getSingleProject(project.slug));

      successToast('Votre projet a été modifié avec succès');
    } catch (err) {
      dispatch({
        type: "UPDATE_PROJECT_ERROR",
        error: err,
      });

      errorToast('Une erreur s\'est produite lors de la modification du projet');
    }
  };
};

export const updateRejectedProject = (project) => {
  return async (dispatch) => {
    try {
      await db.collection('projects').doc(project.id).update({
        title: project.title,
        tagline: project.tagline,
        location: project.location,
        category: project.category,
        duration: project.duration,
        featuredImage: project.featuredImage,
        youtubeVideos: project.youtubeVideos,
        projectText: project.projectText,
        faq: project.faq,
        perk: project.perk,
        moneyRequest: project.moneyRequest,
        images: project.images,
        confirmation: '',
        updateTime: firebase.firestore.FieldValue.serverTimestamp(),
      });

      dispatch({
        type: "UPDATE_REJECTED_PROJECT",
      });

      successToast('Votre projet a été modifié avec succès');

      setTimeout(() => {
        dispatch(getSingleProject(project.slug));
      }, 3000);
    } catch (err) {
      errorToast('Une erreur est survenue lors de la modification du projet, veuillez réessayer');
      dispatch({
        type: "UPDATE_REJECTED_PROJECT_ERROR",
        error: err
      });
    }
  };
};


export const addDonation = (donation, newPerk) => {
  return async (dispatch) => {
    try {
      if (donation.perk) {
        await db.collection('projects').doc(donation.projectId).update({
          donor: firebase.firestore.FieldValue.arrayUnion(donation),
          donorsIds: firebase.firestore.FieldValue.arrayUnion(donation.donorId),
          donorsNumber: firebase.firestore.FieldValue.increment(1),
          perk: newPerk,
          moneyRaised: firebase.firestore.FieldValue.increment(donation.amount),
        });

        dispatch({ type: "ADD_DONATION_PERK" });
      } else {
        await db.collection('projects').doc(donation.projectId).update({
          donor: firebase.firestore.FieldValue.arrayUnion(donation),
          moneyRaised: firebase.firestore.FieldValue.increment(donation.amount),
          donorsIds: firebase.firestore.FieldValue.arrayUnion(donation.donorId),
          donorsNumber: firebase.firestore.FieldValue.increment(1),
        });

        dispatch({ type: "ADD_DONATION" });
      }
    } catch (err) {
      if (donation.perk) {
        dispatch({ type: "ADD_DONATION_PERK_ERROR" });
      } else {
        dispatch({ type: "ADD_DONATION_ERROR" });
      }
    }
  };
};



// this function get all projects if user is connected and return them in array 

export const fetchUserProject = () => {
  return async (dispatch) => {
    try {
      // loader
      dispatch({
        type: 'FETCH_USER_PROJECTS'
      });

      const user = await new Promise((resolve) => {
        auth.onAuthStateChanged((user) => {
          resolve(user);
        });
      });

      if (user) {
        // get all projects
        const querySnapshot = await db
          .collection('projects')
          .where('authorId', '==', user.uid)
          .get();

        const data = querySnapshot.docs.map((doc) => {
          const myData = doc.data();
          myData.id = doc.id;
          return myData;
        });

        dispatch({
          type: 'FETCH_USER_PROJECTS_SUCCESS',
          projects: data
        });
      } else {
        dispatch({
          type: 'NO_USER'
        });
      }
    } catch (error) {
      dispatch({
        type: 'FETCH_USER_PROJECTS_ERROR',
        error: error.message
      });
    }
  };
};


export const fetchProfileProjects = (id) => {
  return async (dispatch) => {
    try {
      // loader
      dispatch({
        type: 'FETCH_PROFILE_PROJECTS'
      });

      // get profile projects
      const querySnapshot = await db
        .collection('projects')
        .where('authorId', '==', id)
        .get();

      const data = [];

      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          const myData = doc.data();
          myData.id = doc.id;
          data.push(myData);
        }
      });

      dispatch({
        type: 'FETCH_PROFILE_PROJECTS_SUCCESS',
        projects: data
      });
    } catch (error) {
      dispatch({
        type: 'FETCH_PROFILE_PROJECTS_ERROR',
        error: error.message
      });
    }
  };
};

export const getSingleProject = (slug) => {
  return async (dispatch) => {
    try {
      // loader
      dispatch({
        type: 'FETCH_SINGLE_PROJECT'
      });

      // Tentez de récupérer les données de Firestore
      const querySnapshot = await db
        .collection('projects')
        .where('slug', '==', slug)
        .get();

      if (querySnapshot.docs.length > 0 && querySnapshot.docs[0].exists) {
        const doc = querySnapshot.docs[0];
        const myData = doc.data();
        myData.id = doc.id;

        dispatch({
          type: 'FETCH_SINGLE_PROJECT_SUCCESS',
          singleProject: myData
        });
      } else {
        dispatch({
          type: 'NO_SINGLE_PROJECT'
        });
      }
    } catch (error) {
      dispatch({
        type: 'FETCH_SINGLE_PROJECT_ERROR',
        error: error.message
      });
    }
  };
};


export const cancelProjectInfo = () => {
  return (dispatch) => {
    dispatch({
      type: 'CANCEL_PROJECT_INFO'
    })
  }
}
export const cancelNoProjectInfo = () => {
  return (dispatch) => {
    dispatch({
      type: 'CANCEL_NO_PROJECT_INFO'
    })
  }
}

// Function get All accepted projects and return them in array
export const acceptedProjects = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'GET_ACCEPTED_PROJECTS'
      });

      const querySnapshot = await db
        .collection('projects')
        .where('confirmation', '==', true)
        .orderBy('timestamp', 'desc')
        .get();

      const data = querySnapshot.docs.map((doc) => {
        const myData = doc.data();
        myData.id = doc.id;
        return myData;
      });

      if (data.length > 0) {
        dispatch({
          type: 'GET_ACCEPTED_PROJECTS_SUCCESS',
          allProjects: data
        });
      } else {
        dispatch({
          type: 'NO_ACCEPTED_PROJECTS'
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_ACCEPTED_PROJECTS_ERROR',
        error: error.message
      });
    }
  };
};


// Function get All accepted projects and return them in array
export const featuredProjects = () => {
  return async (dispatch) => {
    dispatch({
      type: 'GET_FEATURED_PROJECTS'
    });

    try {
      const snapshot = await db.collection('projects').where('featured', '==', true).get();
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (data.length > 0) {
        dispatch({
          type: 'GET_FEATURED_PROJECTS_SUCCESS',
          allProjects: data
        });
      } else {
        dispatch({
          type: 'NO_FEATURED_PROJECTS'
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_FEATURED_PROJECTS_ERROR',
        error: error.message
      });
    }
  };
};

// Function get All completed projects and return them in array


export const completedProjects = () => {
  return async (dispatch) => {
    dispatch({
      type: 'GET_COMPLETED_PROJECTS'
    });

    try {
      const date = new Date();
      const snapshot = await db.collection('projects').where('projectsEndDate', '<', date).get();
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (data.length > 0) {
        dispatch({
          type: 'GET_COMPLETED_PROJECTS_SUCCESS',
          allProjects: data
        });
      } else {
        dispatch({
          type: 'NO_COMPLETED_PROJECTS'
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_COMPLETED_PROJECTS_ERROR',
        error: error.message
      });
    }
  };
};


// Function get All in progress projects and return them in array
export const inProgressProjects = () => {
  return async (dispatch) => {
    dispatch({
      type: 'GET_IN_PROGRESS_PROJECTS'
    });

    try {
      const date = new Date();
      const snapshot = await db.collection('projects').where('projectsEndDate', '>', date).get();
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (data.length > 0) {
        dispatch({
          type: 'GET_IN_PROGRESS_PROJECTS_SUCCESS',
          allProjects: data
        });
      } else {
        dispatch({
          type: 'NO_IN_PROGRESS_PROJECTS'
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_IN_PROGRESS_PROJECTS_ERROR',
        error: error.message
      });
    }
  };
};


// Function that get all pending projects and return them in array
export const pendingProjects = () => {
  return async (dispatch) => {
    dispatch({
      type: 'GET_PENDING_PROJECTS'
    });

    try {
      const snapshot = await db.collection('projects').where('confirmation', '==', '').get();
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (data.length > 0) {
        dispatch({
          type: 'GET_PENDING_PROJECTS_SUCCESS',
          pendingProjects: data
        });
      } else {
        dispatch({
          type: 'NO_PENDING_PROJECTS'
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_PENDING_PROJECTS_ERROR',
        error: error.message
      });
    }
  };
};


export const rejectedProjects = () => {
  return async (dispatch) => {
    dispatch({
      type: 'GET_REJECTED_PROJECTS'
    });

    try {
      const snapshot = await db.collection('projects').where('confirmation', '==', false).get();
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (data.length > 0) {
        dispatch({
          type: 'GET_REJECTED_PROJECTS_SUCCESS',
          allProjects: data
        });
      } else {
        dispatch({
          type: 'NO_REJECTED_PROJECTS'
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_REJECTED_PROJECTS_ERROR',
        error: error.message
      });
    }
  };
};


// allow admin to accept the project
export const projectGoodConfirmation = (id, duration, emailData) => {
  return async (dispatch) => {
    try {
      let future = moment().add(duration, 'days');
      const token = await getToken();

      await db.collection('projects').doc(id).update({
        confirmation: true,
        projectsEndDate: future._d
      });

      successToast("Le projet a été accepté avec succès");

      await axios.post('https://api.kamamini.net/projectGoodConfirmation', emailData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      dispatch({
        type: 'UPDATE_PROJECT_CONFIRMATION_TO_TRUE'
      });

      dispatch(pendingProjects());
    } catch (error) {
      errorToast("Une erreur s'est produite");
      dispatch({
        type: 'UPDATE_PROJECT_CONFIRMATION_TO_TRUE_ERROR'
      });
    }
  };
};

// allow admin to add time to a project


export const addTimeToProject = (id, duration) => {
  return async (dispatch) => {
    try {
      let future = moment().add(duration, 'days');

      await db.collection('projects').doc(id).update({
        projectsEndDate: future._d
      });

      successToast("La durée du projet a été rallongée avec succès");

      dispatch({
        type: 'ADD_MORE_TIME_TO_PROJECT'
      });

      dispatch(completedProjects());
    } catch (error) {
      // Gérer l'erreur ici, par exemple, afficher un toast d'erreur
      errorToast("Une erreur s'est produite lors du rallongement de la durée du projet");
    }
  };
};

// allow admin to refuse the project
export const projectsBadConfirmation = (id, emailData) => {
  return async (dispatch) => {
    try {
      const token = await getToken();

      await db.collection('projects').doc(id).update({
        confirmation: false
      });

      successToast("Le projet a été rejeté avec succès");

      await axios.post('https://api.kamamini.net/projectBadConfirmation', emailData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      dispatch({
        type: 'UPDATE_PROJECT_CONFIRMATION_TO_FALSE'
      });

      dispatch(pendingProjects());
    } catch (error) {
      // Gérer l'erreur ici, par exemple, afficher un toast d'erreur
      console.error(error);
      errorToast("Une erreur s'est produite lors du rejet du projet");
    }
  };
};

// allow admin to cancel the confirmation of project
export const projectsCancelConfirmation = (id) => {
  return async (dispatch) => {
    try {
      await db.collection('projects').doc(id).update({
        confirmation: ''
      });

      successToast("L'approbation a été annulée avec succès");

      dispatch({
        type: 'CANCEL_PROJECT_CONFIRMATION'
      });

      dispatch(pendingProjects());
    } catch (error) {
      // Gérer l'erreur ici, par exemple, afficher un toast d'erreur
      console.error(error);
      errorToast("Une erreur s'est produite lors de l'annulation de l'approbation du projet");
    }
  };
};

// allow admin to make a project futured
export const featuredProject = (id) => {
  return async (dispatch) => {
    try {
      await db.collection('projects').doc(id).update({
        featured: true
      });

      successToast("Le projet a été marqué comme projet vedette avec succès");

      dispatch({
        type: 'UPDATE_PROJECT_FEATURED_TO_TRUE'
      });

      dispatch(acceptedProjects());
    } catch (error) {
      // Gérer l'erreur ici, par exemple, afficher un toast d'erreur
      console.error(error);
      errorToast("Une erreur s'est produite lors de la mise en vedette du projet");
    }
  };
};
// allow admin to camcel project futured
export const cancelFeaturedProject = (id) => {
  return async (dispatch) => {
    try {
      await db.collection('projects').doc(id).update({
        featured: false
      });

      successToast("Le projet vedette a été annulé avec succès");

      dispatch({
        type: 'UPDATE_PROJECT_FEATURED_TO_FALSE'
      });

      dispatch(acceptedProjects());
    } catch (error) {
      // Gérer l'erreur ici, par exemple, afficher un toast d'erreur
      console.error(error);
      errorToast("Une erreur s'est produite lors de l'annulation du projet vedette");
    }
  };
};



export const addCommentsToProject = (comment, id, slug) => {
  return async (dispatch) => {
    try {
      await db.collection('projects').doc(id).update({
        comments: firebase.firestore.FieldValue.arrayUnion(comment)
      });

      dispatch({
        type: 'ADD_COMMENT_TO_PROJECT'
      });

      successToast('Votre commentaire a été ajouté avec succès');
      dispatch(getSingleProject(slug))
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'ADD_COMMENT_TO_PROJECT_ERROR',
        error: error
      });
      errorToast("Une erreur s'est produite lors de l'ajout du commentaire");
    }
  };
};



export const getMyDonationProjects = (id) => {
  return async (dispatch) => {
    try {
      // Loader
      dispatch({
        type: 'FETCH_USER_DONATION_PROJECTS'
      });

      // Récupérer les projets où l'utilisateur a fait des dons
      const querySnapshot = await db.collection('projects').where("donorsIds", "array-contains", id).get();

      const data = querySnapshot.docs.map(doc => {
        const myData = doc.data();
        myData.id = doc.id;
        return myData;
      });

      dispatch({
        type: 'FETCH_USER_DONATION_PROJECTS_SUCCESS',
        userDonationProjects: data
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'FETCH_USER_DONATION_PROJECTS_ERROR',
        error: error.message
      });
    }
  };
};


export const getLengthOfProject = (projectAuthorID) => {
  return (dispatch) => {
    // Loader
    dispatch({
      type: 'GET_LENGTH_OF_PROJECT'
    });

    db.collection('projects').where('authorId', '==', projectAuthorID).get()
      .then(querySnapshot => {
        dispatch({
          type: 'GET_LENGTH_OF_PROJECT_SUCCESS',
          lengthOfProject: querySnapshot.size
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'GET_LENGTH_OF_PROJECT_ERROR',
          error: error.message
        });
      });
  };
};


export const cancelLengthOfProject = () => {
  return (dispatch) => {
    dispatch({
      type: 'CANCEL_LENGTH_OF_PROJECT'
    })
  }
}