import React,{useState,useContext,useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import { Formik,Field,Form,ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {verifyError} from '../redux/actions/authActions';
import {successToast,errorToast,getToken} from '../redux/actions/actions';
import {FirebaseContext} from '../Firebase/Firebase'
import { ToastContainer } from 'react-toastify';
import GoogleSignInButton from '../Components/Buttons/GoogleSignInButton/GoogleSignInButton'
import FacebookSignInButton from '../Components/Buttons/FacebookSignInButton/FacebookSignInButton'
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton'
import Seo from '../Components/Helmet/Seo';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

 const Login = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
   
  }, [])

      //Firebase context
    const {auth,firebaseApp,db} = useContext(FirebaseContext)

    // google and acebook authentiications variables
    let googleProvider = new firebaseApp.firebase_.auth.GoogleAuthProvider();
    let facebookProvider = new firebaseApp.firebase_.auth.FacebookAuthProvider();

    const data = {
        email: '',
        password: '',
      };
      const validationSchema = Yup.object().shape({ 
        email: Yup.string().email('Veuillez entrer une adresse email valide').required('L\'adresse email est requis'),  
        password: Yup.string().min(8,'Mot de passe trop court').required('Le mot de passe est requis'),
      })

      // authentification errors 
      const [error, setError] = useState('')
      const [googleError, setGoogleError] = useState('')
      const [facebookError, setFacebookError] = useState('')

       // google authentiication
       const google = async () =>{

        const token = await getToken();
        auth.signInWithPopup(googleProvider)
        .then((result) => {
        // The signed-in user info.
          if(result.additionalUserInfo.isNewUser){
            let userId = result.user.uid;
            db.collection('users').doc(userId).set({
                displayName: result.user.displayName,
                email : result.user.email,
                photoURL : result.user.photoURL,
                initials: result.user.displayName.match(/\b(\w)/g).join(''),
                authProvider: 'google',
            })
            const emailData = {
              email:result.user.email,
              name: result.user.displayName
            };
  
          fetch("https://api.kamamini.net/welcomeEmail", {
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(emailData),
            }) 
            successToast('Bienvenue sur Kamamini')
          }
          else{
            successToast('Heureux de vous revoir')
          }
            setTimeout(() =>{
              props.history.push('/')
            },3000)
        })
        .catch((error) => {
            // Handle Errors here.
            setGoogleError(error)
            const errorMsg = verifyError(error.code)
            if(errorMsg === null && error.message){
            errorToast(error.message)
            }
            else{
            errorToast(errorMsg)
            }
        });

      }

      //Facebook authentiication
      const facebook = async () => {
        const token = await getToken();

        auth.signInWithPopup(facebookProvider)
      .then((result) => {
        // The signed-in user info.
        if(result.additionalUserInfo.isNewUser){
          let userId = result.user.uid;
          db.collection('users').doc(userId).set({
              displayName: result.user.displayName,
              email : result.user.email,
              photoURL : result.user.photoURL,
              initials: result.user.displayName.match(/\b(\w)/g).join(''),
              authProvider: 'facebook',
          })
          const emailData = {
            email:result.user.email,
            name: result.user.displayName
          };

        fetch("https://api.kamamini.net/welcomeEmail", {
            method: 'POST',
            headers: { "Content-Type": "application/json"},
            body: JSON.stringify(emailData),
            
          }) 
          successToast('Bienvenue sur Kamamini')
        }
        else{
          successToast('Heureux de vous revoir')
        }
         setTimeout(() =>{
          props.history.push('/')
      },3000)
      })
      .catch((error) => {
        // Handle Errors here.
        setFacebookError(error)
        const errorMsg = verifyError(error.code)
        if(errorMsg === null && error.message){
          errorToast(error.message)
        }
        else{
          errorToast(errorMsg)
        }
      });
      }

      const handleSubmit = (values, SubmitProps) => {

        setTimeout(() =>{
          SubmitProps.resetForm()
          SubmitProps.setSubmitting(false)
        },2000)
        auth.signInWithEmailAndPassword(values.email,values.password)
        .then((res) =>(
          successToast('Heureux de vous revoir'),
          setTimeout(() => {
            props.history.push('/');
          }, 3000)
        ))
        .catch((error) => {
          // console.log(error);
          const errorMsg = verifyError(error.code)
          setError(error)
          if(errorMsg === null && error.message){
            errorToast(error.message)
          }
          else{
            errorToast(errorMsg)
          }
        });
       }

       // Material-ui css
    const classes = useStyles();

    // Show and hide password states and functions
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => {setShowPassword(!showPassword);};
    const handleMouseDownPassword = (event) => {event.preventDefault();};

  return (
    <>
        <Seo
          title="Connexion"
          description="Connectez-vous à votre compte Kamamini pour accéder à vos projets et vos contributions."
          name="Kamamini"
          type="website"
        />
        <div style={{backgroundImage:"url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fshape%2F09.png?alt=media&token=e0bf1212-2701-4148-b9ef-662219a204e3"+")"
    ,paddingBottom:'70px',paddingTop:'5px'}} >

    <Container component="main" maxWidth="sm">
        <CssBaseline />
          <ToastContainer />
      <div className={classes.paper}>
              <Box mb={5}>
                <Typography component="h1" variant="h6"  >
                  Connectez-vous à votre compte kamamini
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}  >
                  <GoogleSignInButton google={google} title="Continuer avec google" />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <FacebookSignInButton title="Continuer avec facebook" facebook={facebook}/>
                </Grid>
              </Grid>

        <Formik initialValues={data} validationSchema={validationSchema}   onSubmit={handleSubmit} >
         {(props) => (
           <Form className={classes.form}>
              <Field 
            as={TextField}
            variant="outlined"
            margin="normal"
            error={props.errors.email && props.touched.email}
            required
            fullWidth
            id="email"
            label="Addresse Email"
            name="email"
            autoComplete="email"
            autoFocus
            // value={email}
            // onChange={handleChange}
            helperText={<ErrorMessage name="email" />}
          />
          <FormControl className={clsx(classes.form)}
            variant="outlined" required
            autoComplete="current-password"
            fullWidth
            error={props.errors.password && props.touched.password}
            >
            <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
          <Field as={OutlinedInput} 
            id="password"
            name='password'
            // value={password}
            // onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={110}
          />
          <FormHelperText id="outlined-weight-helper-text">{<ErrorMessage name="password" />}</FormHelperText>
        </FormControl>

          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Se souvenir de moi"
          /> */}
         
          <KamaminiButton text={props.isSubmitting? 'Chargement':'Se connecter' } disabled={props.isSubmitting} fullWidth={true} type="submit"  CustomClasses={classes.submit} />
          <Grid container>
            <Grid item xs>
              <Link to="/forgot-password" variant="body2" className='link' >
                Mot de passe oublié ?
              </Link>
            </Grid>
            <Grid item>
            Vous n'avez pas de compte ?
              <Link to="/signup" variant="body2" className='link' >
                {" Inscrivez-vous"}
              </Link>
            </Grid>
          </Grid>
           </Form>
         )}
        </Formik>
      </div>
     
    </Container>
    </div>
    </>

  );
}

export default Login