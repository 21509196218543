import React,{useState} from 'react'
import {
    Container,
    Grid,
    Box,
    CssBaseline,
    LinearProgress,
    Button
  } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactPlayer from "react-player/youtube";
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import KamaminiButton from '../../../../Components/Buttons/KamaminiColorButton/KamaminiButton' 
import TextEditor from '../../../../Components/TextEditor/TextEditor'
import {updateBlog} from '../../../../redux/actions/blogActions'
import {errorToast,resizeFile} from '../../../../redux/actions/actions'

const Step2 = ({singleBlog,prevStep}) => {

    
    const dispatch = useDispatch()
    const [blogData, setBlogData] = useState(singleBlog);

    const [imageName, setImageName] = useState('Choisissez un fichier')
    
    const [progress, setProgress] = useState(0); //progress bar cloudinary
    const [textEditorInput, setTextEditorInput] = useState(blogData.blogText)
    const [youtubeUrl, setYoutubeUrl] = useState(blogData.youtubeUrl)
    const [saveInput, setSaveInput] = useState('')



    const handleChange = (e) => {   
        setBlogData({ ...blogData, [e.target.id]: e.target.value });
      };


    const handleSaveInput = (e) => {
        setSaveInput(e.target.value)
      }
    
      const addUrl =() =>{
       
      if(saveInput !== '' && youtubeUrl.indexOf(saveInput) === -1){
        setYoutubeUrl(prev => [...prev, saveInput])
        setSaveInput('')
      }
    
    }
    const deleteUrl = (url) =>{
      setYoutubeUrl((curr) => curr.filter((currUrl) => currUrl !== url ))
    }

    // Send file on Cloudinary(additionnal stockage space for the app)
    const uploadFile = (file,onProgress) =>{
        const key = "coyptbxs";
        const url = 'https://api.cloudinary.com/v1_1/kamamini/image/upload';
      
        return new Promise ((res, rej) => {
          const xhr = new XMLHttpRequest();
          xhr.open('POST', url);
      
          xhr.onload = () => {
            const resp = JSON.parse(xhr.responseText);
            res(resp.secure_url);

          };
          xhr.onerror = (evt) => rej(evt);
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentage = (event.loaded / event.total) * 100;
              onProgress(Math.round(percentage));
            }
          };
      
          const formData = new FormData();
          formData.append('file', file);
          formData.append('upload_preset', key);
      
          xhr.send(formData);
        });
      
      }
      const handleChangeImage = async(e)=>{
        const file = e.target.files[0];
        if(file && file['type'].split('/')[0] === 'image'){
          if (file.size <= (5000 * 1024)) {
            try {
              setImageName(file.name)
              const image = await resizeFile(file);
              const urlImage = await uploadFile(image,setProgress)
              setBlogData({ ...blogData, [e.target.id]: urlImage });
            } catch (error) {
              errorToast("Votre image n'a pas pu être sauvegardée, essayez de nouveau")
            }
          }
          else {
            errorToast("La taille de l'image ne doit pas être supérieur à 5 Mo")
          }
          
        }
      
      }


    const handleSubmit = (e) => {
        e.preventDefault()
        // setBlogData({ ...blogData, blogText: textEditorInput });
      dispatch(updateBlog(blogData.title,blogData.id,blogData.urlImage,blogData.category, textEditorInput,youtubeUrl))

    }


    return (
        <form onSubmit={handleSubmit} >

            <ToastContainer />
            <Container>
            <CssBaseline/>
            <KamaminiButton text='Précédent' IconLeft={ArrowLeftIcon} onClick={() => prevStep()} /> 
            <br/>
            <br/>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10} >
                    <label htmlFor="title">Titre du Blog<span className='star'>*</span> </label>
                    <div className="input-group ">
                        <input type="text"  required value={blogData.title} className="form-control" id="title" onChange={handleChange} aria-describedby="basic-addon3" />
                    </div>
                    <p className="note mb-3">Mettez le titre du Blog ici</p>
               </Grid>
               <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8} >
                    <h5 htmlFor="tagline">Image mise en avant <span className='star'>*</span> </h5>
                       {
                         
                         progress > 0 && progress < 100 && <LinearProgress variant="determinate" value={progress}/>
                       }
                        

                        <br></br>
                        <div className="input-group mb-3">
                            <div className="custom-file">
                                <input type="file" accept="image/x-png,image/gif,image/jpeg"  className="custom-file-input" id="urlImage" aria-describedby="inputGroupFileAddon01" onChange={(e)=>handleChangeImage(e)} lang="fr" />
                                <label className="custom-file-label " htmlFor="urlImage" data-browse="Parcourir">{imageName}</label>
                            </div>
                        </div>
                        <p className="note mb-3">Importer une image  qui représente votre blog.</p>
                        
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={4} > 
                    {
                        blogData.urlImage && 
                        <div className="projects projects-02 white-bg mb-30 wow fadeInUp2 animated" data-wow-delay='.1s'>
                        <div className="projects__thumb pos-rel">
                            <img className="img-thumbnail" src={blogData.urlImage} alt={imageName}/>
                        </div>
                        </div>
                    }
                    
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={10} lg={10} >
                    <label htmlFor="title">Categorie du Blog<span className='star'>*</span> </label>
                    <div className="input-group">
                        <input type="text"  required value={blogData.category} className="form-control" id="category" onChange={handleChange} aria-describedby="basic-addon3" />
                    </div>
                    <p className="note mb-3">indiquez la categorie de votre Blog</p>
                    </Grid>


                    
                </Grid>
               <Grid item xs={12} sm={12} md={10} lg={10} >
                    <TextEditor  data={textEditorInput} setData={setTextEditorInput}/>
               </Grid>
              </Grid>

              <br/>
              

              <Grid item xs={12} sm={12} md={10} lg={10} >
                <h5>VIDEO</h5>
                <label htmlFor="url">URL de la vidéo  </label>
                
                <p className="note mb-3">Saisissez une ou plusieurs URL YouTube qui apparaîtra dans la page du blog. </p>
                
                <div className="input-group ">
                    <input  type="url" placeholder='http://' value={saveInput} onChange={handleSaveInput }  className="form-control" id="url" aria-describedby="basic-addon3" />
                    <KamaminiButton onClick={addUrl} text='Ajouter la video'  />
                </div> 
                <br/>

                
                {
                 youtubeUrl.length > 0 &&
                 youtubeUrl.map((video,index) => {
                    return(
                      <Grid item xs={12} sm={12} md={10} lg={10} key={index} className='mb-5' >
                         <ReactPlayer  controls url={video}/>  
                         <Box mb={3} ></Box>
                         <Button className="bg-danger text-white" size="small" onClick={() => deleteUrl(video)}>
                          <DeleteIcon fontSize="small"  /> Supprimer
                        </Button>
                      </Grid>
                     
                    )
                    
                   })
                }
                </Grid>

                <Box mb={3} ></Box>
              <KamaminiButton text='Envoyer' type='submit' /> 
              <Box mb={5} ></Box>
            </Container>
            
        </form>
    )
}

export default Step2
