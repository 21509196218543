import React,{useEffect} from 'react'

const ProjectVideos = ({youtubeUrl,videos,setYoutubeUrl}) => {
    useEffect(() => {
        setYoutubeUrl(videos)
      }, [])
      
    return (
      <>
      {
        !youtubeUrl.length &&  <h4>Vous n'avez pas ajouté de vidéo </h4>
      }
      </>
    )
}

export default ProjectVideos