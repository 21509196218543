import { db } from '../../Firebase/Firebase'
import firebase from 'firebase/app';
import isOnline from 'is-online';
import { successToast, errorToast, getToken } from './actions'
import axios from 'axios'
//create blog and adding the user information
export const createBlog = (blog, userInfo) => {

  // adding slug property to the object "project"
  // blog.slug= blog.title.replace(/\s+/g, '-').toLowerCase()
  blog.slug = encodeURIComponent(blog.title)

  return (dispatch, getState) => {
    if (userInfo) {
      db.collection('blogs').add({
        ...blog,
        featured: false,
        authorId: userInfo.uid,
        authorDisplayName: userInfo.displayName,
        authorImageUrl: userInfo.photoURL,
        authorInitials: userInfo.initials,
        authorEmail: userInfo.email,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        pendingComments: [],
        acceptedComments: [],
      }).then(() => {
        dispatch({
          type: "CREATE_BLOG",
          blog
        })
        successToast('Votre blog a été envoyé avec succès')
      }).catch((err) => {
        dispatch({
          type: "CREATE_BLOG_ERROR",
          error: err
        })
      })

    } else {
      dispatch({
        type: "USER_NOT_SIGNED",
        error: "Veuillez vous connecter"
      })
    }

  }
}

export const updateBlog = (title, id, urlImage, category, blogText, youtubeUrl) => {

  // adding slug property to the object "project"
  let slug = encodeURIComponent(title)

  return (dispatch) => {
    db.collection('blogs').doc(id).update({
      title,
      slug,
      urlImage,
      category,
      blogText,
      youtubeUrl,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),

    })
      .then(() => {
        dispatch({ type: "UPDATE_BLOG" })
        successToast('Votre blog a été mis à jour avec suucès')
      }).catch((err) => {
        dispatch({
          type: "UPDATE_BLOG_ERROR",
          error: err
        })
      })
  }
}

export const deleteBlog = (blogId, userId) => {

  return (dispatch) => {
    db.collection('blogs').doc(blogId).delete()
      .then(() => {
        dispatch({ type: "DELETE_BLOG" })
        dispatch(fetchUserBlogs(userId))

      }).catch((error) => {
        dispatch({
          type: "DELETE_BLOG_ERROR",
          error: error
        })

      });
  }
}

// this function get all blogs if user is connected and return them in array 

export const fetchUserBlogs = (id) => {
  return (dispatch) => {
    // loader
    dispatch({
      type: 'FETCH_USER_BLOGS'
    })
    let data = [];
    // get single blog corresponding to the user id
    db.collection('blogs').where('authorId', '==', id).get()
      .then(doc => {
        if (doc.docs) {
          doc.docs.map(doc => {
            const myData = doc.data()
            myData.id = doc.id
            data.push(myData)
          })

          dispatch({
            type: 'FETCH_USER_BLOGS_SUCCESS',
            blogs: data
          })
        }
      })
      .catch(error => {
        dispatch({
          type: 'FETCH_USER_BLOGS_ERROR',
          error: error
        })
      })


  }
}



export const getSingleBlog = (slug) => {
  return (dispatch, getState) => {
    // loader
    dispatch({
      type: 'FETCH_SINGLE_BLOG'
    })
    let data = [];

    // Définissez un timeout de 10 secondes.
    const timeout = 10000;

    // Tentez de récupérer les données de Firestore jusqu'à ce que la connexion réussisse ou que le délai de timeout soit atteint.
    const getData = () => {
      return new Promise((resolve, reject) => {
        // Vérifiez si l'utilisateur est connecté à Internet.
        isOnline().then(online => {
          if (!online) {
            reject(new Error('Vous n\'êtes pas connecté à Internet.'));
          }
        });
        setTimeout(() => {
          reject(new Error('La connexion a pris trop de temps.'));
        }, timeout);

        db.collection('blogs').where('slug', '==', slug).get()
          .then(doc => {
            doc.docs.map(doc => {
              const myData = doc.data();
              myData.id = doc.id;
              data.push(myData);
            });

            if (data.length) {
              resolve(data);
            } else {
              reject(new Error('Aucun blog trouvé.'));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    // Utilisez la promesse pour afficher les données ou un message d'erreur.
    getData()
      .then(data => {
        data.map(blog => {
          dispatch({
            type: 'FETCH_SINGLE_BLOG_SUCCESS',
            singleBlog: blog
          });
        });
      })
      .catch((error) => {
        if (error.message === 'Aucun blog trouvé.') {
          dispatch({
            type: 'NO_SINGLE_BLOG'
          });
        } else {
          dispatch({
            type: 'FETCH_SINGLE_BLOG_ERROR',
            error: error.message
          });
        }
      });
  };
};


export const cancelBlogInfo = () => {
  return (dispatch) => {
    dispatch({
      type: 'CANCEL_BLOG_INFO'
    })
  }
}
export const cancelNoBlogInfo = () => {
  return (dispatch) => {
    dispatch({
      type: 'CANCEL_NO_BLOG_INFO'
    })
  }
}

// Function get AllBlog 

export const allBlogs = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_ALLBLOGS'
    })
    let data = []
    db.collection("blogs").get()
      .then(doc => {
        doc.docs.map(doc => {
          const myData = doc.data()
          myData.id = doc.id
          data.push(myData)


        })
        dispatch({
          type: 'GET_ALLBLOGS_SUCCESS',
          allBlogs: data
        })

      })
      .catch((error) => {
        dispatch({
          type: 'GET_ALLBLOGS_ERROR',
          error: error.message
        })
      })

  }
}

export const getCommentedBlogs = () => {
  return (dispatch) => {
    dispatch({
      type: 'GET_COMMENTED_BLOGS'
    })
    let data = []
    db.collection("blogs").where('pendingComments', '!=', []).get()
      .then(doc => {
        doc.docs.map(doc => {
          const myData = doc.data()
          myData.id = doc.id
          data.push(myData)
        })
        dispatch({
          type: 'GET_COMMENTED_BLOGS_SUCCESS',
          commentedBlogs: data
        })

      })
      .catch((error) => {
        dispatch({
          type: 'GET_COMMENTED_BLOGS_ERROR',
          error: error.message
        })
      })

  }
}

export const addCommentsToPendingBlog = (newComment, id, slug) => {
  return async (dispatch) => {
    const token = await getToken()

    db.collection('blogs').doc(id).update({
      pendingComments: firebase.firestore.FieldValue.arrayUnion(newComment)
    })

      .then(() => {

        axios.post('https://api.kamamini.net/newBlogComment', {
          blogTitle: newComment.blogTitle,
          blogId: id,
          commentDate: newComment.commentDate,
          senderName: newComment.userInfo.displayName,
          senderEmail: newComment.userInfo.email,
          senderId: newComment.userInfo.uid,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })

        dispatch({
          type: 'ADD_COMMENT_TO_PENDINDCOMMENTS'
        })
        successToast('Votre commentaire a été envoyé avec succès')
        dispatch(getSingleBlog(slug))

      })

      .catch(error => {
        dispatch({
          type: 'ADD_COMMENT_TO_PENDINDCOMMENTS_ERROR',
          error: error
        })
      })
  }
}

export const addCommentsToAcceptedBlog = (newComment, id, pendingComments) => {
  return (dispatch) => {
    db.collection('blogs').doc(id).update({
      acceptedComments: firebase.firestore.FieldValue.arrayUnion(newComment),
      pendingComments: pendingComments
    })
      .then(() => {
        dispatch({
          type: 'ADD_COMMENT_TO_ACCEPTEDCOMMENTS'
        })
        dispatch(getCommentedBlogs())

      })

      .catch(error => {
        dispatch({
          type: 'ADD_COMMENT_TO_PENDINDCOMMENTS_ERROR',
          error: error
        })
      })
  }
}
export const deleteCommentsToPendingBlog = (id, pendingComments) => {
  return (dispatch) => {
    db.collection('blogs').doc(id).update({
      pendingComments: pendingComments
    })
      .then(() => {
        dispatch({
          type: 'DELETE_COMMENT_TO_PENDINGCOMMENTS'
        })
        dispatch(getCommentedBlogs())

      })

      .catch(error => {
        dispatch({
          type: 'ADD_COMMENT_TO_PENDINDCOMMENTS_ERROR',
          error: error
        })
      })
  }
}

export const getFeaturedBlogs = () => {

  return (dispatch) => {
    dispatch({
      type: 'GET_FEATURED_BLOGS'
    })
    let data = []
    db.collection("blogs").where('featured', '==', true).get()
      .then(doc => {
        doc.docs.map(doc => {
          const myData = doc.data()
          myData.id = doc.id
          data.push(myData)
        })
        dispatch({
          type: 'GET_FEATURED_BLOGS_SUCCESS',
          featuredBlogs: data
        })

      })
      .catch((error) => {
        dispatch({
          type: 'GET_FEATURED_BLOGS_ERROR',
          error: error.message
        })
      })

  }
}

// allow admin to make blog futured
export const featuredBlog = (id) => {
  return (dispatch) => {
    db.collection('blogs').doc(id).update({
      featured: true
    })
      .then(() => {
        dispatch({
          type: 'UPDATE_BLOG_FEATURED_TO_TRUE'
        })
        dispatch(allBlogs())

      })
  }
}
// allow admin to cancel blog futured
export const cancelFeaturedBlog = (id) => {
  return (dispatch) => {
    db.collection('blogs').doc(id).update({
      featured: false
    })
      .then(() => {
        dispatch({
          type: 'UPDATE_BLOG_FEATURED_TO_FALSE'
        })
        dispatch(allBlogs())

      })
  }
}


// get the 5 most recent blogs except the one the user reads
export const getRecentBlogs = () => {
  return (dispatch) => {
    dispatch({
      type: 'GET_LATEST_BLOGS'
    })
    let data = []
    db.collection("blogs").orderBy("timestamp", "desc").limit(5).get()
      .then(doc => {
        doc.docs.map(doc => {
          const myData = doc.data()
          myData.id = doc.id
          data.push(myData)
        })
        dispatch({
          type: 'GET_LATEST_BLOGS_SUCCESS',
          latestBlogs: data
        })

      })
      .catch((error) => {
        dispatch({
          type: 'GET_LATEST_BLOGS_ERROR',
          error: error.message
        })
      })

  }
}
