import React from 'react'
import { deepOrange } from '@material-ui/core/colors';
import { makeStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import { displayProjectEndTime } from '../../../redux/actions/actions';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },

  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    //   maxWidth: 220,
    with: '100%',
    height: '100%',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const SingleProjectCard1 = ({ project }) => {

  let projectProgress = project && project.moneyRaised * 100 / parseInt(project.moneyRequest)

  // let Difference_In_Weeks = Difference_In_Time / (1000 * 3600 * 24 * 7);
  // let Difference_In_Months = Difference_In_Time / (1000 * 3600 * 24 * 30);
  // let Difference_In_Years = Difference_In_Time / (1000 * 3600 * 24 * 365);


  const classes = useStyles();


  if (project) {

    return (
      <div className="projects projects-02 white-bg mb-30 wow fadeInUp2 animated mx-1" data-wow-delay='.1s'>
        <div className="projects__thumb pos-rel">
          <Link to={'/projects/' + project.slug} >
            <img src={project.featuredImage} alt={project.title} />
          </Link>

          <span className="new-tag">{project.category}</span>

        </div>
        <div className="projects__content mt-2">
          <div className="new-tag hot_tag"><i className="fas fa-map-marker-alt"></i> {project.location}</div>
          <h4>
            <Link to={'/projects/' + project.slug} >
              {project.title}
            </Link>
          </h4>
          <div className="skill mb-30">
            <p>
              <span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(project.moneyRaised)} </span>
              {parseInt(project.moneyRaised) > 1 ? '  collectés' : '  collecté'}  </p>
            <div className="progress">
              <div className="progress-bar" role="progressbar" aria-valuenow={projectProgress} aria-valuemin="0" aria-valuemax="100" style={{ width: projectProgress + '%' }}>
                <h6>{parseInt(projectProgress)}%</h6>
              </div>
            </div>
          </div>
          <div className="projects__content--manager">
            <ul className="project-manager d-flex justify-content-between">
              <li className='d-flex justify-content-between  align-items-center' >

                {
                  project.authorImageUrl ? <Avatar alt={project.authorDisplayName} src={project.authorImageUrl} /> : <Avatar className={`${classes.orange} `}>{project.authorInitials} </Avatar>
                }
                <HtmlTooltip
                  placement="top"
                  title={
                    <div className="d-flex justify-content-around px-1 py-1" >
                      {
                        project.authorImageUrl ?
                          <Avatar alt={project.authorDisplayName} src={project.authorImageUrl} />
                          :
                          <Avatar className={`${classes.orange} `}>
                            {project.authorInitials}
                          </Avatar>
                      }
                      <div className="px-2" >
                        <Typography variant="body2" color="inherit">
                          {project.authorDisplayName}
                        </Typography>
                        <br />

                        <Link to={`/profile/${project.authorId}`} style={{ textDecoration: 'underline' }}>
                          <Typography variant="body1" color="inherit">Voir le profil</Typography>

                        </Link>
                      </div>
                    </div>
                  }>
                  <div className="text-underline-hover" >
                    <small className="px-1">{project.authorDisplayName}</small>
                  </div>
                </HtmlTooltip>
              </li>
              <li className="d-flex justify-content-end align-items-center">
                <p className="time no-margin">
                  <i className="far fa-clock px-1"></i>
                  {displayProjectEndTime(project.projectsEndDate)}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

    )

  }




}

export default SingleProjectCard1
