import React, { useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';

import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/fr';
import { Grid } from '@material-ui/core';
// import './style.css'
import './style.css'


// const editorConfiguration = {
//     toolbar: {
//         items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'indent', 'outdent', 'imageUpload', 'undo', 'redo'],
//     }
// };

function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader)
    }
}
class MyUploadAdapter {
    constructor(props) {
        // CKEditor 5's FileLoader instance.
        this.loader = props;
        // URL where to send files.
        this.url = 'https://api.cloudinary.com/v1_1/kamamini/image/upload';
    }

    // Starts the upload process.
    upload() {
        return new Promise((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject);
            this._sendRequest();
        });
    }

    // Aborts the upload process.
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    // Example implementation using XMLHttpRequest.
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();

        xhr.open('POST', this.url, true);
        // xhr.responseType = 'json';
        // xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
        // xhr.setRequestHeader('Authorization', getToken())
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve, reject) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = 'N\'arrive pas à importer ce fichier:' + ` ${loader.file.name}.`;

        xhr.addEventListener('error', () => reject(genericErrorText));
        xhr.addEventListener('abort', () => reject());
        xhr.addEventListener('load', () => {
            const response = xhr.response;
            if (!response || response.error) {
                return reject(response && response.error ? response.error.message : genericErrorText);
            }

            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            resolve({
                default: JSON.parse(response).url
            });

        });

        if (xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    // Prepares the data and sends the request.
    _sendRequest() {
        const data = new FormData();

        this.loader.file.then(result => {
            data.append('file', result);
            data.append('upload_preset', "wwm57ooi")
            this.xhr.send(data);
        }
        )
    }

}


const TextEditor = ({ data, setData }) => {
    useEffect(() => {
        setData(data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const editor = null;


    return (

        <Grid item xs={12} sm={12} md={12} lg={12} >

            <div className="kamamini_editor_container" >
                <CKEditor
                    required
                    onReady={editor => {
                        // console.log( 'Editor is ready to use!', editor );

                        // Insert the toolbar before the editable area.
                        if (editor) {
                            editor.ui.getEditableElement().parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            );
                        }


                        // eslint-disable-next-line no-self-assign
                        editor = editor;
                    }}
                    onError={({ willEditorRestart }) => {
                        // If the editor is restarted, the toolbar element will be created once again.
                        // The `onReady` callback will be called again and the new toolbar will be added.
                        // This is why you need to remove the older toolbar.
                        if (willEditorRestart) {
                            editor.ui.view.toolbar.element.remove();
                        }
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setData(data)
                        // console.log( { event, editor,data  } ) 
                    }}

                    editor={DecoupledEditor}
                    data={data}
                    config={{
                        language: 'fr',
                        extraPlugins: [MyCustomUploadAdapterPlugin],
                        link: {
                            addTargetToExternalLinks: true
                        }

                    }}

                />
            </div>

        </Grid>


    )
}

export default TextEditor
