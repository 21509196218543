import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SingleProjectCard1 from '../Cards/SingleProjectCard1/SingleProjectCard1';
import BigCard from '../Cards/BigCard/BigCard';
import { useEffect } from 'react';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ProjectByCategory({ projects }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const filterProjects = (category) => {
    // Filtrer les projets par catégorie et trier par financement décroissant
    const filtered = projects
      .filter((project) => project.category === category)
      .sort((a, b) => b.moneyRaised - a.moneyRaised)
      .slice(0, 4); // Prendre les 4 premiers

    setFilteredProjects(filtered);
  };


  const getUnique = (items, value) => {
    if (items) {
      return [...new Set(items.map(item => item[value]))];
    }
  };

  let categoryOption = getUnique(projects, "category");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedCategory = categoryOption[newValue];
    filterProjects(selectedCategory);
  };

  useEffect(() => {
    // Appeler filterProjects pour la première catégorie
    const initialCategory = categoryOption[0];
    filterProjects(initialCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <h4 className='py-2 normal-font text-center'>Découvrez nos projets par categories</h4>

      <div position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {
            categoryOption.map((category, index) => {
              return (
                <Tab key={index} label={category}  {...a11yProps(index)} />
              )
            })
          }

        </Tabs>
      </div>

      {categoryOption.map((category, index) => (
        <TabPanel key={index} value={value} index={index}>
          <>
            <div className='container'>
              <div className='row'>
                {/* Afficher le premier projet dans une carte distincte */}
                {filteredProjects.length > 0 && (
                  <div className='col-xs-12 col-md-4 mt-2'>
                    <SingleProjectCard1 project={filteredProjects[0]} />
                  </div>
                )}
                {filteredProjects.length > 1 && (
                  <div className='col-xs-12 col-md-8 ' >
                    <BigCard projects={filteredProjects.slice(1)} />
                  </div>
                )}

              </div>
            </div>
          </>
        </TabPanel>
      ))}

    </div>
  );
}
