const initState = {

  blogs: null,
  blogLoading: false,
  blogError: '',

  singleBlog: null,
  singleBlogLoading: false,
  singleBlogError: null,
  noSingleBlog: false,

  getAllBlogs: null,
  getAllBlogsLoading: false,
  getAllBlogsError: '',

  fetchUserBlog: null,
  fetchUserBlogLoading: false,
  fetchUserBlogError: '',

  commentedBlogs: null,
  commentedBlogsLoading: false,
  commentedBlogsError: '',

  latestBlogsLoading: false,
  latestBlogs: null,
  latestBlogsError: '',

  featuredBlogsLoading: false,
  featuredBlogs: null,
  featuredBlogsError: '',

}


const blogReducer = (state = initState, action) => {
  switch (action.type) {

    case "UPDATE_BLOG":
      return { ...state };
    case "UPDATE_BLOG_ERROR":
      return { ...state };
    case "DELETE_BLOG":
      return { ...state };
    case "DELETE_BLOG_ERROR":
      return { ...state };
    case "CREATE_BLOG_ERROR":
      return { ...state };

    case 'FETCH_BLOGS':
      return { ...state, blogLoading: true }
    case 'FETCH_BLOGS_SUCCESS':
      return { ...state, blogLoading: false, blogs: action.blogs }
    case 'FETCH_BLOGS_ERROR':
      return { ...state, blogLoading: false, blogs: null, blogError: action.error }


    case 'FETCH_SINGLE_BLOG':
      return { ...state, singleBlogLoading: true }
    case 'FETCH_SINGLE_BLOG_SUCCESS':
      return { ...state, singleBlogLoading: false, singleBlog: action.singleBlog }
    case 'FETCH_SINGLE_BLOG_ERROR':
      return { ...state, singleBlogLoading: false, singleBlog: null, singleBlogError: action.error }
    case 'NO_SINGLE_BLOG':
      return { ...state, noSingleBlog: true }
    case 'CANCEL_BLOG_INFO':
      return { ...state, singleBlog: null }
    case 'CANCEL_NO_BLOG_INFO':
      return { ...state, noSingleBlog: false }

    case 'ADD_COMMENT_TO_PENDINDCOMMENTS':
      return { ...state }
    case 'ADD_COMMENT_TO_ACCEPTEDCOMMENTS':
      return { ...state }
    case 'DELETE_COMMENT_TO_PENDINGCOMMENTS':
      return { ...state }

    case 'GET_LATEST_BLOGS':
      return { ...state, latestBlogsLoading: true }
    case 'GET_LATEST_BLOGS_SUCCESS':
      return { ...state, latestBlogsLoading: false, latestBlogs: action.latestBlogs }
    case 'GET_LATEST_BLOGS_ERROR':
      return { ...state, latestBlogsLoading: false, latestBlogs: null, latestBlogsError: action.error }

    case 'GET_FEATURED_BLOGS':
      return { ...state, featuredBlogsLoading: true }
    case 'GET_FEATURED_BLOGS_SUCCESS':
      return { ...state, featuredBlogsLoading: false, featuredBlogs: action.featuredBlogs }
    case 'GET_FEATURED_BLOGS_ERROR':
      return { ...state, featuredBlogsLoading: false, featuredBlogs: null, featuredBlogsError: action.error }

    case 'GET_ALLBLOGS':
      return { ...state, getAllBlogsLoading: true }
    case 'GET_ALLBLOGS_SUCCESS':
      return { ...state, getAllBlogsLoading: false, getAllBlogs: action.allBlogs }
    case 'GET_ALLBLOGS_ERROR':
      return { ...state, getAllBlogsLoading: false, getAllBlogs: null, getAllBlogsError: action.error }

    case 'FETCH_USER_BLOGS':
      return { ...state, fetchUserBlogLoading: true }
    case 'FETCH_USER_BLOGS_SUCCESS':
      return { ...state, fetchUserBlogLoading: false, fetchUserBlog: action.blogs }
    case 'FETCH_USER_BLOGS_ERROR':
      return { ...state, fetchUserBlogLoading: false, fetchUserBlog: null, fetchUserBlogError: action.error }

    case 'GET_COMMENTED_BLOGS':
      return { ...state, commentedBlogsLoading: true }
    case 'GET_COMMENTED_BLOGS_SUCCESS':
      return { ...state, commentedBlogsLoading: false, commentedBlogs: action.commentedBlogs }
    case 'GET_COMMENTED_BLOGS_ERROR':
      return { ...state, commentedBlogsLoading: false, commentedBlogs: null, commentedBlogsError: action.error }

    case 'UPDATE_BLOG_FEATURED_TO_TRUE':
      return { ...state }
    case 'UPDATE_BLOG_FEATURED_TO_FALSE':
      return { ...state }




    default: return state
  }

}

export default blogReducer
