import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {frenchSittings } from '../../../redux/actions/actions'

const BlogsCard = ({blog}) => {

    moment.locale('fr',frenchSittings());
    
    return (
        
        <div className="blogs mb-30">
            <div className="blogs__thumb pos-rel mb-45">
                <div className="blogs__thumb--img" >
                    <Link to={'/blogs/' + blog.slug} >
                        <img src={blog.urlImage} alt={blog.title} style={{height:'250px'}}/>
                    </Link>
                </div>
                <span className="blog-tag" >{blog.category}</span>
            </div>
        <div className="blogs__content">
            <div className="blogs__content--meta mb-15">
                <span><i className="far fa-calendar-alt"></i> {moment(blog.timestamp.toDate()).format("DD MMM  YYYY")} </span>
                <span><i className="far fa-comment"></i> ({blog.acceptedComments.length}) Commentaires</span>
            </div>
            <h4 className="mb-20"> <Link to={'/blogs/' + blog.slug} > {blog.title} </Link></h4>
            <ul className="project-manager">
                <li>
                    <Link to={'/blogs/'+ blog.slug}>
                        <img src={blog.authorImageUrl} style={{width:'35px',height:'35px'}} className="rounded-circle"  alt={blog.authorDisplayName} />
                        <span className='px-2' >{blog.authorInitials} </span>
                    </Link>
                </li>
                <li>
                    <Link to={'/blogs/'+ blog.slug} className="more_btn_02">  En savoir plus <i className="fas fa-arrow-right"></i></Link>
                </li>
            </ul>
        </div>
    </div>
    ) 
}

export default BlogsCard
