import React,{useState} from 'react'
import RewardDialog from '../../Dialog/RewardDialog'
import {errorToast,resizeFile} from '../../../redux/actions/actions'

const UserRewardCard = ({perk,setPerkData}) => {

    const [progress, setProgress] = useState(0);
    // const [projectData, setprojectData] = useState();
    const [imageName, setImageName] = useState('Importer votre image');

    const deletePerk = (perk)=> {
      setPerkData(prev => prev.filter((currPerk) => currPerk !== perk ))
    }


    const handleChange = (e,i) => {   
        let values = [...perk];
        values[i][e.target.id] =   e.target.value;
        setPerkData(values);
        // setprojectData({ ...projectData, [e.target.id]: e.target.value });
      };


      const uploadFile = async (file,onProgress) =>{
        const key = "ktru7nrq";
        const url = 'https://api.cloudinary.com/v1_1/kamamini/image/upload';
      
        return new Promise ((res, rej) => {
          const xhr = new XMLHttpRequest();
          xhr.open('POST', url);
      
          xhr.onload = () => {
            const resp = JSON.parse(xhr.responseText);
             res(resp.secure_url);
          };
          xhr.onerror = (evt) => rej(evt);
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentage = (event.loaded / event.total) * 100;
              onProgress(Math.round(percentage));
            }
          };
        
          const formData = new FormData();
          formData.append('file', file);
          formData.append('upload_preset', key);
      
          xhr.send(formData);
        })
        .then((data) => { return data })
        .catch((error) => {
          alert( 'Erreur veuillez réessayer');
        });
        
      
      }

      const handleChangeImg = async (e,index) => {
        const file = e.target.files[0];
        if(file && file['type'].split('/')[0] === 'image'){
          if (file.size <= (5000 * 1024)) {
            try {
              setImageName(file.name)
              const image = await resizeFile(file);
              const urlImage = await uploadFile(image,setProgress)
              let values = [...perk];
              values[index][e.target.id] =  urlImage;
              setPerkData(values);
              // setprojectData({ ...projectData, [e.target.id]: urlImage });
            } catch (error) {
              errorToast("Votre image n'a pas pu être sauvegardée, essayez de nouveau")
            }
          }
          else {
            errorToast("La taille de l'image ne doit pas être supérieur à 5 Mo")
          }
           
          }
        
      }


    return (
       
      perk && perk.map((perk, index) => (
            
          <div className='col-sm-12 col-md-6 col-lg-6 py-3 px-2' key={index}>
             <div className="reward__block " >
                {
                    perk.rewardImage &&  
                    <div className="reaward__thumb text-center mb-25">
                        <img src={perk.rewardImage}  alt={perk.title} />
                    </div>
                } 
        
                <div className="widget-rewardss">
                  <h5>titre: {perk.title}</h5>
                  <h5><span>prix : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(perk.price)} </span></h5>
                  <p className="mb-20">description : {perk.description} </p>

                    {
                        perk.quantity &&  
                        <ul className="rewards-list pt-15 mb-25">
                            <li>quantité disponible  {perk.quantity}  </li>
                        </ul>
                    }
            
                  {/* Modal */}
                  <RewardDialog 
                      perk={perk} 
                      handleChange={handleChange} 
                      handleChangeImg={handleChangeImg}
                      imageName={imageName}
                      index={index} 
                      deletePerk={deletePerk}
                      />
      
                </div>
              </div>
          </div>

        ))
                        
                      
    )
}

export default UserRewardCard
