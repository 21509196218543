import React, { useEffect, useState } from 'react'
import {
  Container,
  Grid,
  Box,
  CssBaseline,
  Button,
} from '@material-ui/core';
// import { Prompt } from 'react-router';
import ReactPlayer from "react-player/youtube";
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CurrencyFormat from 'react-currency-format';
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton'
import { Formik } from 'formik';
import { array, object, string } from 'yup';
import { useDispatch, } from 'react-redux'
import { errorToast } from '../redux/actions/actions';
import { updateRejectedProject } from '../redux/actions/projectActions';
import { OneFileUploadField } from '../Components/Upload/OneFileUploadField'
import { MultipleFileUploadField } from '../Components/Upload/MultipleFileUploadField'
import TextEditor from '../Components/TextEditor/TextEditor';
import AddFaq from '../Components/AddFaq/AddFaq';
import AddReward from '../Components/AddReward/AddReward'
import NoPerk from '../Components/StartCampaignForm/perk.svg'



const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    image: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }
));
const EditRejectedProject = ({ project }) => {

  const classes = useStyles();
  const dispatch = useDispatch()
  const [projectData, setProjectData] = useState({
    title: project.title,
    tagline: project.tagline,
    location: project.location,
    category: project.category,
    duration: project.duration,
  });

  const [imageUrl, setImageUrl] = useState([])
  const [imageDropZoneUrl, setImageDropZoneUrl] = useState(project.images)
  const [youtubeUrl, setYoutubeUrl] = useState(project.youtubeVideos)
  const [featuredImageUrl, setFeaturedImageUrl] = useState([project.featuredImage])
  const [dropzoneImages, setdropzoneImages] = useState([]);
  const [pitchContentRequired, setpitchContentRequired] = useState(true)
  const [saveInput, setSaveInput] = useState('')
  const [wysiwygData, setWysiwygData] = useState(project.projectText)
  const [faq, setFaq] = useState(project.faq)
  const [perk, setPerk] = useState(project.perk)
  const [moneyRequest, setMoneyRequest] = useState(project.moneyRequest);
  const [featuredImgRequired, setFeaturedImgRequired] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const PresentationImageUrl = dropzoneImages.filter((image) => image.url)

  // s'il ya imageUrl.url c'est lui qu on envoie sinon c'est featuredImageUrl

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  useEffect(() => {
    if (faq.length === 0) {
      setFaq([{ question: '', response: '', id: 0 }])
    }
  }, [faq])
  // transform featuredImgRequired to false if there is an image 
  useEffect(() => {
    if ((imageUrl.length > 0 && imageUrl[0].url) || (featuredImageUrl.length > 0 && featuredImageUrl[0])) {
      setFeaturedImgRequired(false)
    }
    else {
      setFeaturedImgRequired(true)
    }

  }, [imageUrl, featuredImageUrl])

  // transform pitchContentRequired to false if there is an image or video
  useEffect(() => {
    if (PresentationImageUrl.length > 0 || youtubeUrl.length > 0 || imageDropZoneUrl.length > 0) {
      setpitchContentRequired(false)
    } else {
      setpitchContentRequired(true)
    }
  }, [PresentationImageUrl, youtubeUrl, imageDropZoneUrl])


  const deleteVideoUrl = (url) => {
    if (youtubeUrl.length > 1) {
      setYoutubeUrl(prev => prev.filter((currUrl) => currUrl !== url))
    }
    if (youtubeUrl.length === 1) {
      setYoutubeUrl(prev => prev.filter((currUrl) => currUrl !== url))
      // setpitchContentRequired(true)
    }
  }

  const addYoutubeUrl = () => {
    if (saveInput !== '' && saveInput.includes("youtube.com") && youtubeUrl.indexOf(saveInput) === -1) {
      setYoutubeUrl(prev => [...prev, saveInput])
      setSaveInput('')
      setpitchContentRequired(false)
    }
  }

  const handleSaveInput = (e) => {
    setSaveInput(e.target.value)
  }

  const deleteFeaturedImageUrl = () => {
    setFeaturedImageUrl([])
  }

  const deleteImageDropZoneUrl = (url) => {
    setImageDropZoneUrl((curr) => curr.filter((currUrl) => currUrl !== url))
  }

  function deleteImage(file) {
    setdropzoneImages((curr) => curr.filter((fw) => fw !== file));
  }
  function deleteFeaturedImage(file) {
    setImageUrl([])
  }
  const addClick = () => {
    setPerk(prevState => ([...prevState, { price: 0, title: '', description: '', rewardImage: null, quantity: 0, id: 0 }]))
  }
  const checkFaqErrors = (faq) => {
    for (let i = 0; i < faq.length; i++) {
      if ((faq[i].question !== "" && faq[i].response === "") || (faq[i].question === "" && faq[i].response !== "")) {
        return true;
      }
    }
    return false;
  }
  // check if titles of perks are equals, return true if it's the case
  const checkIfTitlesAreEqual = (perks) => {
    // if perks are not greater than 1
    if (perks.length <= 1) {
      return false;
    }
    // check if perks titles are equal
    else {
      let i = -1;
      let j;
      while (++i < perks.length) {
        j = i;
        while (++j < perks.length) {
          // check if titles are not empty and if they are equal
          if (!perks[i].title.match(/^\s*$/) && perks[i].title === perks[j].title) {
            return true;
          }
        }
      }
      return false;
    }
  }
  // Check if titles is empty
  const checkIfTitlesIsEmpty = (perks) => {
    for (let i = 0; i < perks.length; i++) {
      if (perks[i].title.match(/^\s*$/)) {
        return true;
      }
    }
    return false;
  }
  // Check if descriptions is empty
  const checkIfDescriptionsIsEmpty = (perks) => {
    for (let i = 0; i < perks.length; i++) {
      if (perks[i].description.match(/^\s*$/)) {
        return true;
      }
    }
    return false;
  }

  // check if minimum price is less than 100
  const checkMinimumPrice = (perks) => {
    for (let i = 0; i < perks.length; i++) {
      if (perks[i].price.toString().match(/^\s*$/) || parseInt(perks[i].price) < 100) {
        return true;
      }
    }
    return false;
  }
  // check if minimum quantity is less than 0
  const checkMinimumQuantity = (perks) => {
    for (let i = 0; i < perks.length; i++) {
      if (perks[i].quantity.toString().match(/^\s*$/) || parseInt(perks[i].quantity) < 0) {
        return true;
      }
    }
    return false;
  }

  // transform if quantity is equal to 0 and transform it to illimited
  const transformIllimitedQuantity = (perks) => {
    for (let i = 0; i < perks.length; i++) {
      if (perks[i].quantity == 0) {
        perks[i].quantity = 'illimited'
      }
    }
    return perks;
  }

  // transform the perk quantity and the price in int
  const transformPerksQuantityAndPriceToInteger = (perks) => {

    for (let i = 0; i < perks.length; i++) {
      if ((typeof (perks[i].quantity) === 'string' && perks[i].quantity !== 'illimited') && (typeof (perks[i].price) === 'string')) {
        perks[i].quantity = parseInt(perks[i].quantity)
        perks[i].price = parseInt(perks[i].price)
      }
      else if ((typeof (perks[i].quantity) === 'string' && perks[i].quantity !== 'illimited')) {
        perks[i].quantity = parseInt(perks[i].quantity)
      }
      else if ((typeof (perks[i].price) === 'string')) {
        perks[i].price = parseInt(perks[i].price)
      }
    }
    return perks;
  }

  // check if minimum money request is less than 100
  const checkMinimummoneyRequest = (moneyRequest) => {
    if (moneyRequest.toString().match(/^\s*$/) || parseInt(moneyRequest) < 50000) {
      return true;
    }
    return false;
  }

  const getAllImages = (imageDropZoneUrl, PresentationImageUrl) => {
    let urls = [];
    if (PresentationImageUrl.length === 0) {
      return imageDropZoneUrl
    }
    else {
      for (let i = 0; i < PresentationImageUrl.length; i++) {
        urls.push(PresentationImageUrl[i].url)
      }
      return [...imageDropZoneUrl, ...urls];
    }

  }

  const handleChange = (e) => {
    setProjectData({ ...projectData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault()

    let values = [...perk];

    if (projectData.title.match(/^\s*$/)) {
      errorToast('Veuillez entrer le titre du projet')
    }
    else if (projectData.location.match(/^\s*$/)) {
      errorToast('Veuillez sélectionner le lieu du projet')
    }
    else if (featuredImgRequired) {
      errorToast('Veuillez choisir une image de présentation')
    }
    else if (projectData.category.match(/^\s*$/)) {
      errorToast('Veuillez sélectionner la catégorie du projet')
    }
    else if (projectData.duration.toString().match(/^\s*$/) || projectData.duration < 5 || projectData.duration > 90) {
      errorToast('La durée de la campagne doit être comprise entre 5 et 90 jours pour chaque projet')
    }
    else if (pitchContentRequired) {
      errorToast('Veuillez ajouter une vidéo ou une image à votre pitch')
    }
    else if (wysiwygData.match(/^\s*$/)) {
      errorToast('Veuillez ajouter une description à votre projet')
    }
    else if (checkFaqErrors(faq)) {
      errorToast('Veuillez remplir tous les champ des foires aux questions')
    }
    else if (checkIfTitlesAreEqual(values)) {
      errorToast('Vos récompenses ne peuvent pas avoir le même titre')
    }
    else if (checkIfTitlesIsEmpty(values)) {
      errorToast('Veuillez entrer le titre de la récompense')
    }
    else if (checkIfDescriptionsIsEmpty(values)) {
      errorToast('Veuillez entrer la description de la récompense')
    }
    else if (checkMinimumPrice(values)) {
      errorToast('Le prix minimum pour une récompense est de 100 FCFA')
    }
    else if (checkMinimumQuantity(values)) {
      errorToast('Veuillez entrer la quantité disponible pour cette récompense')
    }
    // else if (checkIllimitedQuantity(values))
    // {
    //   transformPerksQuantityAndPriceToInteger(values)
    //   setPerk(values)
    //   // console.log('illimited: ', values);
    // }
    else if (checkMinimummoneyRequest(moneyRequest)) {
      errorToast('La somme minimum de collecte est de 50 000 FCFA')
    }
    else {
      setDisabled(true)
      let newPerks = transformIllimitedQuantity(values)
      setPerk(values)
      let data = {
        id: project.id,
        slug: project.slug,
        title: projectData.title,
        tagline: projectData.tagline,
        location: projectData.location,
        category: projectData.category,
        duration: parseInt(projectData.duration),
        featuredImage: imageUrl[0] ? imageUrl[0].url : featuredImageUrl[0],
        youtubeVideos: youtubeUrl,
        projectText: wysiwygData,
        faq: faq,
        perk: transformPerksQuantityAndPriceToInteger(newPerks),
        moneyRequest,
        images: getAllImages(imageDropZoneUrl, PresentationImageUrl)
      }
      dispatch(updateRejectedProject(data))
    }

  }


  return (
    <>
      <Box mb={5}></Box>
      <Container>
        <CssBaseline />

        <form className={classes.form} onSubmit={handleSubmit} >
          <Grid container spacing={2}>
            {/* start of step1 */}
            <Grid item xs={12} sm={12} md={10} lg={10} >
              <div className="kamamini-input" >
                <h5 htmlFor="title">Titre du projet <span className='star'>*</span> </h5>
                <p className="note mb-2">Mettez le titre du projet ici</p>

                <div className="input-group input-group-lg">
                  <input type="text" value={projectData.title} onChange={handleChange} required className="form-control" id="title" aria-describedby="basic-addon3" />
                </div>
              </div>
              <p className=" mb-3"></p>
            </Grid>

            <Grid item xs={12} sm={12} md={10} lg={10} >
              <div className="kamamini-input" >
                <h5 htmlFor="tagline">Slogan du projet </h5>
                <p className="note mb-2">Fournissez une courte description qui décrit le mieux votre projet à votre public.</p>
                <div className="input-group ">
                  <textarea value={projectData.tagline} onChange={handleChange} className="form-control" id="tagline" aria-label="With textarea"></textarea>
                </div>
              </div>
              <p className=" mb-3"></p>

            </Grid>


            <Grid item xs={12} sm={12} md={10} lg={10} >

              <h5 >Lieux <span className='star'>*</span> </h5>
              <p className="note mb-2">Choisissez le lieu où vous lancez le projet. (Ce lieu sera visible sur la page de votre campagne pour que votre public puisse le voir.)</p>
              <div className="kamamini-input">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <label className="input-group-text k_green" htmlFor="location">Regions</label>
                  </div>
                  <select className="custom-select" value={projectData.location} onChange={handleChange} id="location" required={true}>
                    <option value='' >Aucune</option>
                    <option value="Agadez" >Agadez</option>
                    <option value="Diffa">Diffa</option>
                    <option value="Dosso">Dosso</option>
                    <option value="Maradi">Maradi</option>
                    <option value="Niamey">Niamey</option>
                    <option value="Tahoua">Tahoua</option>
                    <option value="Tillabery">Tillabery</option>
                    <option value="Zinder">Zinder</option>
                  </select>
                </div>
              </div>
              <p className=" mb-3"></p>
            </Grid>


            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={10} lg={10} >
                <h5 >Image principale <span className='star'>*</span> </h5>
                <p className="note mb-2">Importer une image  qui représente votre campagne.</p>
              </Grid>
            </Grid>

            <Formik
              initialValues={{ files: [] }}
              validationSchema={object({
                files: array(
                  object({
                    url: string().required(),
                  })
                ),
              })}
            >
              {() => (
                <>
                  <Grid className='pb-4 pt-2' container spacing={2} direction="column">
                    <OneFileUploadField
                      name="files"
                      files={imageUrl}
                      setFiles={setImageUrl}
                    />
                  </Grid>

                  <Grid container spacing={2}>

                    {
                      imageUrl.length > 0 && imageUrl[0].url ?

                        <Grid container item xs={12} sm={6} md={4} lg={3} className="mb-2" >
                          <Grid item>
                            <img className="img-fluid small rounded mx-auto d-block" src={imageUrl[0].url} alt="Kamamini project's" />
                          </Grid>

                          <Grid item className='px-3'>
                            <Button className="bg-danger text-white" size="small"
                              onClick={() => deleteFeaturedImage()}>
                              <DeleteIcon fontSize="small" />
                            </Button>
                          </Grid>
                        </Grid>


                        : null
                    }

                  </Grid>

                  <Grid container spacing={2}>

                    {
                      featuredImageUrl.length > 0 && imageUrl.length === 0 ?
                        <Grid container item xs={12} sm={6} md={4} lg={3}   >
                          <Grid item>
                            <img className="img-fluid small rounded mx-auto d-block" src={featuredImageUrl[0]} alt="Kamamini project's" />
                          </Grid>

                          <Grid item className='px-3'>
                            <Button className="bg-danger text-white" size="small"
                              onClick={() => deleteFeaturedImageUrl()}>
                              <DeleteIcon fontSize="small" />
                            </Button>
                          </Grid>
                        </Grid>


                        : null
                    }

                  </Grid>
                </>
              )}
            </Formik>


            <Grid item xs={12} sm={12} md={10} lg={10} >
              <h5 >Categorie <span className='star'>*</span> </h5>
              <p className="note mb-3">Pour aider les contributeurs à trouver votre campagne, sélectionnez une catégorie qui représente le mieux votre projet.</p>

              <div className="kamamini-input mb-3" >
                <div className="input-group">
                  <div className="input-group-prepend">
                    <label className="input-group-text k_green" htmlFor="category">Choisissez</label>
                  </div>
                  <select className="custom-select" value={projectData.category} onChange={handleChange} id="category" required={true}>
                    <option value='' >Aucune</option>
                    <option value="Agroalimentaire" >Agroalimentaire</option>
                    <option value="Education">Education</option>
                    <option value="Santé">Santé</option>
                    <option value="Entreprenariat">Entreprenariat</option>
                    <option value="Autres">Autres</option>
                  </select>
                </div>
              </div>
            </Grid>


            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <h5 >Durée de la campagne <span className='star'>*</span> </h5>
                <p className="note">Définissez le nombre de jours pendant lequel votre projet va recevoir un financement <br /> NB : La durée maximale est de 90 jours (3 mois)  </p>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} >
                <div className="kamamini-input" >

                  <div className="input-group input-group-lg">
                    <input type="number" value={projectData.duration} onChange={handleChange} min={5} max={90} required className="form-control" id="duration" aria-describedby="basic-addon3" />
                  </div>
                </div>
              </Grid>
            </Grid>

            {/* End of step1 */}


            {/* start of step2 */}

            <Grid item xs={12} sm={12} md={10} lg={10} >

              <h3>Présentation <span className='star'>*</span> </h3>
              <p className='note mb-3'>Vous pouvez ajouter une ou plusieurs vidéos et/ou une ou plusieurs images à votre projet pour le rendre encore plus impactant. Vous pouvez même combiner les deux si vous le souhaitez.
                <br />
                Elles apparaîtront en haut de la page de votre projet. <br />
                NB: Les projes avec vidéos recueillent beaucoup plus de fonds que les campagnes sans vidéos.</p>

            </Grid>

            <Grid item xs={12} sm={12} md={10} lg={10} >
              <h5>VIDEO</h5>
              <label htmlFor="url">URL de la vidéo  </label>

              <p className="note mb-3">Entrez l'URL YouTube puis cliquer sur le bouton &nbsp; 'AJOUTER LA VIDEO' &nbsp;  pour l'ajouter. Vous pouvez ajouter plusieurs vidéos<br />
                {/* NB: Vous devez ajouter la vidéo en cliquant sur le bouton &nbsp; 'AJOUTER LA VIDEO' &nbsp; pour l'enregistrer */}
              </p>
              <div className="kamamini-input" >
                <div className="input-group input-group-lg">
                  <input type="url" placeholder='http://' value={saveInput} onChange={handleSaveInput}
                    required={pitchContentRequired} className="form-control" id="url" aria-describedby="basic-addon3" />
                  <KamaminiButton onClick={addYoutubeUrl} text='Ajouter la video' className="input-group-text" />
                </div>
              </div>

              <br />

              {
                youtubeUrl.length > 0 &&
                youtubeUrl.map((video, index) => {
                  return (
                    <Grid item xs={8} sm={8} md={8} lg={8} key={index} className='mb-5' >
                      {/* <ReactPlayer   controls url={video}/>  */}
                      <div className="player-wrapper">
                        <ReactPlayer className="react-player" width='125%' controls url={video} />
                      </div>
                      <br />
                      <div className="d-flex justify-content-end">
                        <Button className="bg-danger text-white" size="small" onClick={() => deleteVideoUrl(video)}>
                          <DeleteIcon fontSize="small" /> Supprimer
                        </Button>
                      </div>

                    </Grid>
                  )

                })
              }
            </Grid>


            <Grid item xs={12} sm={12} md={10} lg={10} >
              <h5>IMAGE</h5>
              <p className='note mb-3'>Importer une ou plusieurs images. <br />
              </p>


              <Formik
                initialValues={{ files: [] }}
                validationSchema={object({
                  files: array(
                    object({
                      url: string().required(),
                    })
                  ),
                })}
              >
                {() => (
                  <>
                    <Grid className='pb-4 pt-2' container spacing={2} direction="column">
                      <MultipleFileUploadField
                        name="files"
                        files={dropzoneImages}
                        setFiles={setdropzoneImages}
                      />
                    </Grid>

                    <Grid container spacing={2}>

                      {
                        PresentationImageUrl.length ?
                          PresentationImageUrl.map((imageUrl, index) => {
                            return (

                              <Grid container item xs={12} sm={6} md={4} lg={3} key={index} className="mb-2" >
                                <Grid item>
                                  <img className="img-fluid small rounded mx-auto d-block" src={imageUrl.url} alt={imageUrl.file.name} />
                                </Grid>

                                <Grid item className='px-3'>
                                  <Button className="bg-danger text-white" size="small"
                                    onClick={() => deleteImage(imageUrl)}>
                                    <DeleteIcon fontSize="small" />
                                  </Button>
                                </Grid>
                              </Grid>

                            )
                          })
                          : null
                      }

                    </Grid>

                    <Grid container spacing={2}>

                      {
                        imageDropZoneUrl.length > 0 ?
                          imageDropZoneUrl.map((imageDropZoneUrl, index) => {
                            return (
                              <Grid container item xs={12} sm={6} md={5} lg={4} key={index}  >
                                <Grid item>
                                  <img className="img-fluid small rounded mx-auto d-block" src={imageDropZoneUrl} alt="Kamamini project's" />
                                </Grid>

                                <Grid item className='px-3'>
                                  <Button className="bg-danger text-white " size="small"
                                    onClick={() => deleteImageDropZoneUrl(imageDropZoneUrl)}>
                                    <DeleteIcon fontSize="small" />
                                  </Button>
                                </Grid>
                              </Grid>
                            )
                          })
                          : null
                      }

                    </Grid>

                  </>
                )}
              </Formik>


              <div className='mb-5' ></div>
            </Grid>



            <Grid item xs={12} sm={12} md={10} lg={10} >
              <h5 >Description <span className='star'>*</span> </h5>
              <p className='note mb-3'>Dites-en plus aux contributeurs potentiels de votre projet.
                <br></br>
                Fournissez des détails qui motiveront les gens à contribuer. Un bon pitch est convaincant, informatif et facile à comprendre   </p>

              <TextEditor data={wysiwygData} setData={setWysiwygData} />
              <div className='mb-3' ></div>
            </Grid>

            <Grid item xs={12} sm={12} md={10} lg={10} >
              <h5 >FAQ </h5>
              <p className='note mb-3'>La section FAQ (Foire Aux Questions) doit fournir les renseignements relatif au projet que les contributeurs souhaiteraient connaître avant de vous financer. </p>


              <AddFaq setFaq={setFaq} faq={faq} />
              <div className='mb-3' ></div>

            </Grid>


            {/* End of step2 */}

            {/* start of step3 */}

            {
              perk.length > 0 ?
                <Grid item xs={12} sm={12} md={12} lg={12} >

                  <h2 className='text-center mb-2' >Récompense ou Contrepartie</h2>
                  <p className='note mb-5 text-center'>Les Récompenses sont des incitations offertes aux contributeurs en échange de leurs soutiens. </p>
                  <AddReward perk={perk} setPerk={setPerk} />
                </Grid>
                :
                <Grid container direction="column" justifycontent="center" alignItems="center" >
                  <h3>Vous n'avez pas encore créé de récompense</h3>
                  <p className='note mb-5 text-center'>Les récompenses sont des incitations offertes aux contributeurs en échange de leurs soutiens. </p>
                  <img src={NoPerk} alt="kamamini gift" />
                  <br />
                  <KamaminiButton text='Créer une récompense' onClick={addClick} />
                </Grid>
            }


            {/* end of step3 */}

            {/* start of step4 */}

            <Grid item xs={8} sm={8} md={8} lg={8} className="mb-5">
              <div className="kamamini-input">

                <h5 htmlFor="duration">Objectif de financement <span className='star'>*</span> </h5>
                <p className="note mb-3">Combien d'argent souhaitez-vous collecter pour cette campagne ?  Veuillez à tenir compte de nos frais de commission.
                  <br /> NB : Le montant minimum de collecte est 50 000 F CFA </p>

                <div className="input-group input-group-lg">
                  {/* <input type="number" min={50000} value={projectData.moneyRequest} onChange={handleChange} required className="form-control" id="moneyRequest" aria-describedby="basic-addon3"  /> */}
                  <CurrencyFormat className="form-control" value={moneyRequest} displayType={'input'} thousandSeparator={'  '} suffix={'  F CFA'} allowNegative={false}
                    onValueChange={(values) => { setMoneyRequest(values.value) }}
                  />

                </div>
              </div>
            </Grid>




            <Grid item xs={12} sm={12} md={12} lg={12} className="mb-5">
              <div className="mt-1 text-center">
                <button type="submit" disabled={disabled} className="theme_btn theme_btn_bg edit_save" data-animation="fadeInLeft" data-delay=".7s"> Enregistrer</button>
              </div>
            </Grid>


















          </Grid>
        </form>

      </Container>

    </>
  )
}

export default EditRejectedProject