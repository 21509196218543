import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import moment from 'moment'
import PropTypes from 'prop-types';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import ReactHtmlParser from "react-html-parser";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import stop from '../assets/img/stopKamamini.svg';
import Lector from '../Components/Lector/Lector';
import RewardCard from '../Components/Cards/RewardCard/RewardCard'
import SmallRewardCard from '../Components/Cards/RewardCard/SmallRewardCard'
import AccordionComponent from '../Components/Accordion/Accordion'
import { getSingleProject, cancelProjectInfo, cancelNoProjectInfo, addCommentsToProject, getLengthOfProject, cancelLengthOfProject } from '../redux/actions/projectActions';
import { frenchSittings, displayProjectEndTime, errorToast, getTimeDifference } from '../redux/actions/actions';
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton'
import HorizontalScroll from '../Components/Scroll/HorizontalScroll'
import TextDialog from '../Components/Dialog/TextDialog'
// import Sand from '../assets/img/sand-clock.png'
import Seo from "../Components/Helmet/Seo";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import Errorcase from "../Components/ErrorCase/ErrorCase";
import Loader from "../Components/Loader/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    backgroundColor: 'black',
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  listStyle: {
    '& ul li': {
      listStyle: 'inside'
    },
    '& ol li': {
      listStyle: 'decimal'
    },

  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    // maxWidth: 220,
    with: '100%',
    height: '100%',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const SingleProject = (props) => {

  const dispatch = useDispatch()
  moment.locale('fr', frenchSittings());


  //import from store
  const userInfo = useSelector(state => state.auth.userInfo)
  const loading = useSelector(state => state.projects.singleProjectLoading)
  const project = useSelector(state => state.projects.singleProject)
  const noProject = useSelector(state => state.projects.noSingleProject)
  const singleProjectError = useSelector(state => state.projects.singleProjectError)
  const lengthOfProject = useSelector(state => state.projects.lengthOfProject)
  const lengthOfProjectLoading = useSelector(state => state.projects.lengthOfProjectLoading)
  const lengthOfProjectError = useSelector(state => state.projects.lengthOfProjectError)
  let history = useHistory();


  //corresponding to the searched url
  // const slug = props.match.params.slug;
  const slug = window.location.href.split('projects/')[1];

  // redirect to 404 if the project is not confirmed
  if (project && project.confirmation === false) {
    props.history.push('/404')
  }
  else if (project && project.confirmation === '') {
    props.history.push('/404')
  }

  let projectProgress = project && project.moneyRaised * 100 / parseInt(project.moneyRequest)
  let sortedPerks = project && project.perk.sort((a, b) => { return a.price - b.price })
  let sortedDonors = project && project.donor.sort((a, b) => { return b.donationDate - a.donationDate })
  let sortedComments = project && project.comments.sort((a, b) => { return b.commentDate - a.commentDate })

  const [endOpen, setEndOpen] = useState(false);
  const [open, setOpen] = useState(false);


  // get the numbers of donors per perk
  const getDonorsPerPerk = (perkTitle) => {
    let donors = project && project.donor.filter(donor => donor.perkTitle === perkTitle)
    return donors.length
  }

  // check if user is a the list of project contributors
  const isContributor = (UserId) => {
    let contributors = project && project.donor.filter(contributor => contributor.donorId === UserId)
    if (contributors && contributors.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  const handleClickEndOpen = () => {
    setEndOpen(true);
  };
  const handleEndClose = () => {
    setEndOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleClick = () => {
    if (userInfo) {

      if (displayProjectEndTime(project.projectsEndDate) === `Terminé`) {
        handleClickEndOpen()
      }
      else {
        history.push(`/${project.slug}/checkout?selection`);
      }
    } else {
      handleClickOpen()
    }
  }

  // console.log(project);

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [comments, setComments] = useState('')


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCommentsChange = (e) => {
    setComments({
      text: e.target.value,
      userInfo,
      projectId: project.id,
      projectTitle: project.title,
      commentDate: new Date()
    })
  }

  const writeComments = () => {
    // check if the input is not empty with regex
    if (comments.text.match(/^\s*$/)) {
      errorToast('Veuillez entrer un commentaire')
    }
    else {
      dispatch(addCommentsToProject(comments, project.id, project.slug))
      setComments({
        text: '',
        userInfo,
        projectId: project.id,
        projectTitle: project.title,
      })
    }
  }
  // console.log(project);

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getSingleProject(slug))
    return () => {
      dispatch(cancelProjectInfo())
      dispatch(cancelNoProjectInfo())
    }

  }, [])

  useEffect(() => {
    if (project && project.confirmation === true) {
      dispatch(getLengthOfProject(project.authorId))
    }
    return () => {
      dispatch(cancelLengthOfProject())
    }
  }, [project, dispatch])



  if (loading || lengthOfProjectLoading) {
    return (
      <Loader />
    )
  }


  if (singleProjectError || lengthOfProjectError) {
    return (
      <Errorcase />
    )
  }
  if (noProject) {
    return (
      <>
        <div className='container text-center py-4'>
          <ErrorIcon
            //color="success"
            style={{ color: '#02b663' }}
            sx={{ fontSize: 220 }}
          />
          <div className="wrapperu">
            <h1>Projet non trouvé</h1>
            <p className="messageu">
              Désolé, le projet que vous recherchez n'a pas été trouvé. Il se peut qu'il ait été supprimé ou que le lien soit incorrect. Veuillez vérifier les détails et réessayer
            </p>
            <Link to='/' className="pb-5 ">
              <KamaminiButton text="Page d'accueil" />
            </Link>
          </div>
        </div>

      </>
    )
  }

  if (project) {
    return (
      <>
        <Seo
          title={`${project.title}`}
          description={`Découvrez et soutenez le projet ${project.title} sur Kamamini`}
          name="Kamamini"
          type="website"
        />
        <CssBaseline />
        <ToastContainer />
        <Container >
          <Grid container spacing={2} className='pt-4 pb-3' >
            <Grid item xs={12} sm={12} md={6} lg={7} >
              <Lector videos={project.youtubeVideos} images={project.images} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5} >
              <div className="project-cart white-bg mb-20">
                <div className="projects__contentt">
                  <span className="new-tag">{project.category}</span>
                  <h3 className="no-margin" >{project.title}</h3>
                  {
                    project && project.tagline && <h5 className="py-3 normal-font" >{project.tagline}</h5>
                  }

                  <div className="projects__manager d-flex align-items-center mb-20">
                    <div className="manager-img mr-20">
                      {
                        project.authorImageUrl ?
                          <Avatar className={classes.large} alt={project.authorDisplayName} src={project.authorImageUrl} /> :
                          <Avatar className={`${classes.orange} ${classes.large}`}>{project.authorInitials}
                          </Avatar>
                      }
                    </div>

                    <div className="name">
                      <HtmlTooltip
                        placement="top"
                        title={
                          <div className="d-flex justify-content-around px-4 py-2" >
                            {
                              project.authorImageUrl ?
                                <Avatar className={classes.large} alt={project.authorDisplayName} src={project.authorImageUrl} /> :
                                <Avatar className={`${classes.orange} ${classes.large}`}>{project.authorInitials}
                                </Avatar>
                            }
                            <div className="px-3" >
                              <Typography variant="body2" color="inherit">{project.authorDisplayName}</Typography>
                              <br />

                              <Link to={`/profile/${project.authorId}`} style={{ textDecoration: 'underline' }}>
                                <Typography variant="body1" color="inherit">Voir le profil</Typography>

                              </Link>
                            </div>
                          </div>
                        }>
                        <div className="text-underline-hover" >
                          <h5 >{project.authorDisplayName}</h5>
                        </div>
                      </HtmlTooltip>
                      <address>
                        {
                          lengthOfProject !== null && lengthOfProject > 1 ?
                            <span> {lengthOfProject} campagnes</span>
                            : lengthOfProject === 1 ?
                              <span>{lengthOfProject} campagne </span>
                              : null
                        }
                      </address>
                    </div>
                  </div>
                  <div className="skill">
                    <p className="skill-para">

                      <span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(project.moneyRaised)} </span>
                      {parseInt(project.moneyRaised) > 1 ? '  collectés' : '  collecté'}
                    </p>
                    <div className="progress" style={{ height: '10px' }} >
                      <div className="progress-bar" role="progressbar" aria-valuenow={projectProgress}
                        aria-valuemin="0" aria-valuemax="100" style={{ width: projectProgress + '%' }} >
                        <h6>{parseInt(projectProgress)}%</h6>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="pt-2" >
                        <p>
                          {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(project.moneyRaised)}    {parseInt(project.moneyRaised) > 1 ? '  collectés' : '  collecté'} sur {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(project.moneyRequest)}
                        </p>
                      </div>

                      <div className="pt-2" >
                        <p className="time no-margin" >
                          <i className="far fa-clock px-1"></i>
                          {displayProjectEndTime(project.projectsEndDate)}
                        </p>

                      </div>
                    </div>
                  </div>

                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={8} md={8}  >
                      {/*                        
                  <ul className="cart-list d-sm-flex align-items-center">
                  <li>
                      <form className="cart-plus-minus">
                          <div className="plus-minus pos-rel">
                              <input type="text" />
                              <div className="updown plus"><i className="fas fa-chevron-left"></i></div>
                              <div className="updown minus"><i className="fas fa-chevron-right"></i></div>
                          </div>
                      </form>
                  </li>
                  </ul> */}
                      {
                        displayProjectEndTime(project.projectsEndDate) !== `Terminé` ?
                          <KamaminiButton fullWidth onClick={handleClick} text={"Contribuer au projet"} CustomClasses="py-3" /> :
                          <KamaminiButton fullWidth onClick={handleClick} text={"Projet terminé"} CustomClasses="py-3" />
                      }


                    </Grid>
                    <Grid item xs={12} sm={4} md={4}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      {/* <span>Partager : </span> */}
                      <FacebookShareButton className="px-2"
                        url={`https://kamamini.net/projects/${slug}`}
                        quote={`Découvrez le projet ${project.title}  sur Kamamini`}
                        hashtag={'Kamamini'}
                      >
                        <FacebookIcon size={32} className="" />
                      </FacebookShareButton>
                      <TwitterShareButton
                        className="px-2"
                        title={`Découvrez le projet ${project.title}  sur Kamamini`}
                        via={'KamaminiCrowd'}
                        hashtags={['Kamamini', 'Crowdfunding']}
                        related={['@KamaminiCrowd']}
                        url={`https://kamamini.net/projects/${slug}`}
                        quote={`Découvrez le projet ${project.title}  sur Kamamini`}
                      >
                        <TwitterIcon size={32} />
                      </TwitterShareButton>

                      <LinkedinShareButton
                        className=""
                        title={`Découvrez le projet ${project.title}  sur Kamamini`}
                        source={`https://kamamini.net`}
                        url={`https://kamamini.net/projects/${slug}`}
                        summary={project.title}
                      >
                        <LinkedInIcon size={32} className="" />

                      </LinkedinShareButton>
                    </Grid>
                  </Grid>


                </div>
              </div>
            </Grid>
          </Grid>
          {
            sortedPerks.length > 0 &&
            <>
              <div className="d-xl-none d-lg-none mb-2">
                <Typography variant={'h6'} >
                  <strong>
                    Choisissez une récompense
                  </strong>
                </Typography>
              </div>
              <div className="d-xl-none d-lg-none  d-flex align-items-center mb-5">

                <HorizontalScroll >
                  {
                    sortedPerks.map((perk) => {
                      return (
                        <SmallRewardCard
                          key={perk.id}
                          title={perk.title}
                          projectSlug={project.slug}
                          img={perk.rewardImage}
                          price={perk.price}
                          quantity={perk.quantity}
                          getDonorsPerPerk={getDonorsPerPerk}
                          endData={project.projectsEndDate}
                        />
                      )
                    })
                  }
                </HorizontalScroll>
              </div>
            </>
          }

          <Grid container spacing={2} className="border-top-kamamini " >
            <Grid item xs={12} sm={12} md={12} lg={12} >

              <div className={classes.root}>
                {/* <AppBar position="static" color="default"> */}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="on"
                  // indicatorColor="primary"
                  classes={{
                    indicator: classes.indicator,
                  }}
                  textColor="inherit"
                  aria-label="scrollable force tabs example"
                >
                  <Tab label="DESCRIPTION"  {...a11yProps(0)} />
                  <Tab label="FAQ"  {...a11yProps(1)} />
                  <Tab label="Contributeurs"  {...a11yProps(2)} />
                  <Tab label="COMMENTAIRES"  {...a11yProps(3)} />
                  {/* <Tab label="Item Five"  {...a11yProps(4)} />
          <Tab label="Item Six"  {...a11yProps(5)} />
          <Tab label="Item Seven"  {...a11yProps(6)} /> */}
                </Tabs>
                {/* </AppBar> */}



                <TabPanel value={value} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8} >
                      <Box mb={3}></Box>
                      <div className={`${classes.listStyle} left-content-box mb-45`} >
                        {ReactHtmlParser(project.projectText)}
                      </div>

                    </Grid>
                    <Grid
                      // container
                      // direction="column"
                      // justify="center"
                      // alignItems="center"
                      item md={4} lg={4}
                      className="d-none d-lg-block"
                    >

                      <Box p={3}>
                        <Typography variant={'h6'} >
                          <strong>
                            {
                              sortedPerks.length > 0 &&
                              'Choisissez une récompense'
                            }

                          </strong> </Typography>
                      </Box>

                      {
                        sortedPerks.map((perk) => {
                          return (
                            <RewardCard
                              key={perk.id}
                              title={perk.title}
                              projectSlug={project.slug}
                              img={perk.rewardImage}
                              price={perk.price}
                              description={perk.description}
                              quantity={perk.quantity}
                              getDonorsPerPerk={getDonorsPerPerk}
                              endData={project.projectsEndDate}
                            />
                          )
                        })
                      }


                    </Grid>


                  </Grid>
                </TabPanel>


                <TabPanel value={value} index={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8} >
                      <Box p={3}>
                        <Typography variant={'h5'} > <strong> Questions fréquentes</strong> </Typography>
                      </Box>

                      {/* <AccordionComponent />
          <Box p={1}></Box>
          <AccordionComponent /> */}
                      {
                        project.faq.map((faq) => {
                          return (
                            <AccordionComponent key={faq.id} question={faq.question} response={faq.response} />
                          )
                        })
                      }



                    </Grid>

                    <Grid
                      // container
                      // direction="column"
                      // justify="center"
                      // alignItems="center"
                      item md={4} lg={4}
                      className="d-none d-lg-block"
                    >

                      <Box p={3}>
                        <Typography variant={'h6'} >
                          <strong>
                            {
                              sortedPerks.length > 0 ?
                                'Choisissez une récompense' :
                                "Ce projet n'offre pas de récompense"
                            }

                          </strong> </Typography>
                      </Box>

                      {
                        sortedPerks.map((perk) => {
                          return (
                            <RewardCard
                              key={perk.id}
                              title={perk.title}
                              projectSlug={project.slug}
                              img={perk.rewardImage}
                              price={perk.price}
                              description={perk.description}
                              quantity={perk.quantity}
                              getDonorsPerPerk={getDonorsPerPerk}
                            />
                          )
                        })
                      }


                    </Grid>

                  </Grid>


                </TabPanel>
                <TabPanel value={value} index={3}>
                  <p className="mb-2">Seuls les contributeurs peuvent laisser des commentaires<span className='star'>*</span></p>

                  {/* <h4 className="comments-title mb-40">Commentaires des contributeurs</h4> */}

                  {
                    // list of comments

                    sortedComments.length > 0 ? sortedComments.map((comment, index) => (
                      <div key={index} className="post-comments mb-50 wow fadeInUp2 animated" data-wow-delay='.1s'>
                        <ul className="latest-comments">
                          <li className='mb-3' >
                            <div className="comments-box">
                              <div className="comments__avatar ">
                                {
                                  comment.userInfo.photoURL ? <Avatar className={classes.large} alt={comment.userInfo.displayName} src={comment.userInfo.photoURL} /> : <Avatar className={`${classes.orange} ${classes.large}`}>{comment.userInfo.initials} </Avatar>
                                }
                              </div>
                              <div className="comments__content fix">
                                <h5 className='text-uppercase' >{comment.userInfo.displayName}</h5>
                                <p>{comment.text}</p>
                                <div className="comments__meta">
                                  <span>
                                    {moment(comment.commentDate.toDate()).format("DD MMMM  YYYY")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                    ))
                      :
                      <div className="post-comments mb-50 wow fadeInUp2 animated py-3" data-wow-delay='.1s'>
                        <h5 className="comments-title mb-40">Aucun commentaire</h5>
                      </div>


                  }
                  {
                    // check if user is a the list of project contributors, if yes, he can add a comment
                    userInfo && isContributor(userInfo.uid) &&
                    <div className="post-comments-form pb-40 wow fadeInUp2 animated" data-wow-delay='.1s'>
                      {/* <h4 className="comments-title mb-40">Commenter</h4> */}
                      <div className="post-form-area">
                        <div className="row">
                          <div className="col-xl-12 review-form mb-25">
                            <div className="input-text input-message">
                              <textarea onChange={handleCommentsChange} value={comments.text} name="message" id="message" cols="30" rows="10" placeholder="Laisser un commentaire" />
                            </div>
                          </div>
                          <div className="col-xl-12">
                            <div className="comment-btn">
                              <div className="main-menu main-menu-02 ">
                                <span onClick={() => writeComments()} className="theme_btn theme_btn_bg" >
                                  Commenter
                                  <i className="far fa-arrow-right"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  }

                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8} >
                      <Box p={3}>
                        <Typography variant={'h5'} > <strong> Contributeurs</strong> </Typography>
                      </Box>
                      {
                        sortedDonors.map((contributor, index) => {
                          return (
                            <ul className="latest-comments" key={index} >
                              <li key={contributor.donorId} className='mb-3'>
                                {
                                  contributor.anonymous ?
                                    <div className="comments-box d-flex justify-content-between">
                                      <div>
                                        <div className="comments__avatar ">
                                          {
                                            <Avatar className={classes.large} />
                                          }
                                        </div>
                                        <div className="comments__content fix">
                                          <h5 className='text-uppercase' >Anonyme</h5>
                                          <span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(contributor.amount)} </span>
                                        </div>
                                      </div>
                                      {
                                        contributor.donationDate && <span className="date">Il y'a {getTimeDifference(contributor.donationDate)}</span>
                                      }

                                    </div>

                                    :
                                    <div className="comments-box d-flex justify-content-between">
                                      <div>
                                        <div className="comments__avatar ">
                                          {
                                            contributor.donorImargeUrl ? <Avatar className={classes.large} alt={contributor.displayName} src={contributor.donorImargeUrl} /> : <Avatar className={`${classes.orange} ${classes.large}`}>{contributor.initials} </Avatar>
                                          }
                                        </div>
                                        <div className="comments__content fix">
                                          <h5 className='text-uppercase' >{contributor.donorDisplayName}</h5>
                                          <span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(contributor.amount)}</span>
                                        </div>
                                      </div>

                                      {
                                        contributor.donationDate && <span className="date">Il y'a {getTimeDifference(contributor.donationDate)}</span>
                                      }
                                    </div>
                                }

                              </li>
                            </ul>
                          )
                        })
                      }
                    </Grid>
                    <Grid
                      // container
                      // direction="column"
                      // justify="center"
                      // alignItems="center"
                      item md={4} lg={4}
                      className="d-none d-lg-block"
                    >

                      <Box p={3}>
                        <Typography variant={'h6'} >
                          <strong>
                            {
                              sortedPerks.length > 0 ?
                                'Choisissez une récompense' :
                                "Ce projet n'offre pas de récompense"
                            }

                          </strong> </Typography>
                      </Box>

                      {
                        sortedPerks.map((perk) => {
                          return (
                            <RewardCard
                              key={perk.id}
                              title={perk.title}
                              projectSlug={project.slug}
                              img={perk.rewardImage}
                              price={perk.price}
                              description={perk.description}
                              quantity={perk.quantity}
                              getDonorsPerPerk={getDonorsPerPerk}
                            />
                          )
                        })
                      }


                    </Grid>
                  </Grid >


                </TabPanel>
                {/* <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel> */}
              </div>

            </Grid>
          </Grid>



        </Container>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          <img src={"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2FstopKamamini.svg?alt=media&token=a3a6ba1e-98f7-4277-a23b-dbf1820bfb0c"} className='mt-3' style={{ height: "60px" }} alt="Acces interdit à cete page" />
          <DialogTitle id="alert-dialog-title" className='d-flex justify-content-center'  >
            {"Vous n'avez pas accès a cette page"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vous devez être connecté pour accéder à cette page.
              <br />
            </DialogContentText>
            <div className="d-flex justify-content-center mt-3" >
              <Link to='/login'>
                <KamaminiButton onClick={handleClose} text="VOUS CONNECTER" />
              </Link>
            </div>
          </DialogContent>
        </Dialog>
        <TextDialog
          open={endOpen}
          handleClose={handleEndClose}
          contentText="Le délai de participation à ce projet est maintenant terminé. Nous remercions tous les contributeurs pour leur soutien et nous espérons que vous continuerez à soutenir d'autres projets sur Kamamini"
          image="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2FstopKamamini.svg?alt=media&token=a3a6ba1e-98f7-4277-a23b-dbf1820bfb0c"

        />
      </>
    );

  }

  return (
    <Loader />
  )

}



export default SingleProject