import React from 'react'

const AdminReward = ({title,img,price,description,quantity}) => {
    return (
               
               
                        
                        <div className="reward__block" >
                        {
                            img &&  
                            <div className="reaward__thumb text-center mb-25">
                                <img src={img} alt="" />
                            </div>
                        }
                       

                        <div className="widget-rewardss">
                        <h5>titre: {title}</h5>
                        <h5><span>prix : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(price) } </span></h5>
                        <p className="mb-20">description : {description} </p>
                       

                        {
                            quantity &&  
                            <ul className="rewards-list pt-15 mb-25">
                                <li>quantité disponible  {quantity}  </li>
                            </ul>
                        }
                       


                        
                   
                </div>
               
            </div>
    )
}

export default AdminReward
