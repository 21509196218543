import React, {Component} from 'react'
import {Grid,Button}  from '@material-ui/core';
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
export default class AddFaq extends Component {
    constructor(props) {
      super(props);
     
      // this.props.setFaq

      this.state = { values: [{question: '',response: ''}],
                      id:Date.now()
                      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    createUI(){
       return this.props.faq.map((el, i) => 
       <Grid key={i} container spacing={2}>
         <Grid  item xs={10} sm={10} md={10} lg={10} >
            
            <div className="kamamini-input" >
              <p htmlFor="question">Question  </p>
              <div className="input-group input-group-lg mb-4">
                  <input type="text" value={el.question} onChange={this.handleChange.bind(this, i)}  className="form-control" id="question" aria-describedby="basic-addon3" />
              </div>

              <p htmlFor="response">Réponse  </p>
              <div className="input-group  mb-5">
                  <textarea rows={5} type="text" value={el.response} onChange={this.handleChange.bind(this, i)}  className="form-control" id="response" aria-describedby="basic-addon3" />
              </div>
            </div>
          </Grid>
       {
          this.props.faq.length >1 &&
          <Grid  
          container
          direction="row"
          justifycontent="flex-end"
          alignItems="center" 
          item xs={2} sm={2} md={2} lg={2} >
            
            <Button className="bg-danger text-white" size="small" onClick={this.removeClick.bind(this, i)}>
              <CloseIcon fontSize="medium"  />
            </Button>
          </Grid>
        }
        
       </Grid>
       
      
       )
    }
  
    handleChange (i,event) {
      

       let values = [...this.props.faq];
       values[i][event.target.id] =   event.target.value;
      //  this.setState({ values });
       this.props.setFaq(values)
    }
    
    addClick(){
      this.setState((prev) => ({ id:Date.now() }))
      // this.setState(prevState => ({ values: [...prevState.values, {question: '',response: ''}]}))
      this.props.setFaq(prevState => ( [...prevState, {question: '',response: '', id:this.state.id  }]))
    }
     
    removeClick(i){
       let values = [...this.props.faq];
       values.splice(i,1);
      //  this.setState({ values });
       this.props.setFaq(values)
    }
  
    handleSubmit(event) {
      event.preventDefault();
    }
    render() {
      return (
       <>
            {this.createUI()}   
            <div  className="">
              <KamaminiButton onClick={this.addClick.bind(this)}  >
                <AddCircleIcon fontSize='medium' />
                  Ajouter une question 
              </KamaminiButton>
            </div>     

       </>
      );
    }
  }
  