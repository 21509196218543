import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = () => {
    return (
        <div id="preloader">
            <div className="preloader">
                <CircularProgress style={{ color: 'green' }} />
            </div>
        </div>
    )
}

export default Loader