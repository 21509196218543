import React from 'react'
const HorizontalScroll = ({children}) => {
  return (
    <div className="scrol-container">
      <div style={{ display: 'flex', width: '100%' }}>
        {children}
      </div>
    </div>
  )
}

export default HorizontalScroll