import React from 'react'

function Sticki() {
  return (
    <div className='sidebar'>
      <h2 className="privé">Conditions d'utilisation</h2><br />
      <ul className='container paragraph__color checkout-card py-3 li sidebar' style={{marginBottom:'200px'}} >
                <li classname="terms__li"><a href="#quesceque">1.	Qu'est-ce que Kamamini ?</a></li>
                <li classname="terms__li"><a href="#obligationUser">2.Obligations de l'utilisateur</a></li>
                <li classname="terms__li"><a href="#obligationProp">3.Obligations du propriétaire de la campagne</a></li>
                <li classname="terms__li"><a href="#soutenir">4.Soutenir une campagne</a></li>
                <li classname="terms__li"><a href="#role">5.Le rôle de kamamini</a></li>
                <li classname="terms__li"><a href="#propIntel">6.votre propriété intellectuelle</a></li>
                <li classname="terms__li"><a href="#VpropIntel">7.Notre propriété intellectuelle</a></li>
                <li classname="terms__li"><a href="#droit">8.Droits de kamamini concernant<br/> les comptes d'utilisateurs ;<br/> Résiliation</a></li>
                <li classname="terms__li"><a href="#panne">9.Pannes et maintenance du système</a></li>
                <li classname="terms__li"><a href="#clause">10.Clause de non-responsabilité</a></li>
                <li classname="terms__li"><a href="#suppression">11.Suppression de votre compte</a></li>
                <li classname="terms__li"><a href="#Indemnisation">12.Indemnisation</a></li>
                <li classname="terms__li"><a href="#frais">13.Nos frais</a></li>
            </ul>
    </div>
  )
}

export default Sticki
