import React,{useState} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { LinearProgress} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close';
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'
import Button from "../Admin/components/CustomButtons/Button.js";
import ActionDialog from "../Dialog/ActionDialog"

const RewardDialog = ({perk,handleChange,index,handleChangeImg,imageName,deletePerk}) => {
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    // const [projectData, setprojectData] = useState(perk);


    const defaultTitle = '';
    const defaultPrice = '';
    const defaultDescription = '';
    const defaultQuantity = '';
    

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
    setOpen(false);
    };

    const handleClickDeleteOpen = () => {
        setDeleteOpen(true);
      };
    
    const handleDeleteClose = () => {
    setDeleteOpen(false);
    };
 


  return (

    <>
        <KamaminiButton fullWidth text="Modifier" CustomClasses="mb-3" onClick={()=> handleClickOpen()} />
        <Button color="danger" fullWidth  onClick={()=> handleClickDeleteOpen()} >
            Supprimer
        </Button>

        <ActionDialog  
        open={deleteOpen}
        handleClose={handleDeleteClose}
        contentText="Êtes-vous sure de vouloir supprimer cette récompense ?"
        dangerContent="NB : cette action est définitive et irréversible"
        buttonAction={deletePerk}
        buttonActionParam={perk}
        />
    <Dialog open={open} onClose={handleClose}>
                <DialogTitle >
                    Modifier
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                    </IconButton>

                    
                </DialogTitle>
                <DialogContent>

                    <h5 htmlFor="title">Titre  <span className='star'>*</span> </h5>
                    <div className="kamamini-input" >
                        <div className="input-group input-group-lg mb-3">
                            <input type="text" onChange={(e)=> handleChange(e,index)} value={perk.title || defaultTitle}  required className="form-control" id="title" aria-describedby="basic-addon3" />
                        </div>
                    </div>
            
                    <h5 >Montant de la récompense <span className='star'>*</span> </h5>
                    <div className="kamamini-input" >
                        <div className="input-group input-group-lg mb-4">
                        <input type="number" onChange={(e)=> handleChange(e,index)} value={perk.price || defaultPrice} min="100" required  className="form-control" id="price" aria-describedby="basic-addon3" />
                        </div>
                    </div>


                    <h5 htmlFor="title">Description <span className='star'>*</span> </h5>
                    <div className="kamamini-input" >
                        <div className="input-group  mb-3">
                            <textarea type="text" onChange={(e)=> handleChange(e,index)} value={perk.description || defaultDescription}  required className="form-control" id="description" aria-describedby="basic-addon3" ></textarea>
                        </div>
                    </div>

                    <h5 htmlFor="title">Image de la récompense </h5>
                    {  
                        progress > 0 && progress < 100 && <LinearProgress variant="determinate"  value={progress}/>
                    }
                    <div className="input-group  mb-3">
                        <div className="custom-file">
                            <input type="file" accept="image/x-png,image/gif,image/jpeg"  className="custom-file-input" id="rewardImage"
                             onChange={(e)=> handleChangeImg(e,index)}
                             aria-describedby="inputGroupFileAddon01"  lang="fr" />
                            <label className="custom-file-label " htmlFor="inputGroupFile01" data-browse="Parcourir"> {imageName} </label>
                        </div>
                    </div> 

                    <h5 htmlFor="title">Quantité disponible  </h5>
                    <div className="kamamini-input" >
                        <div className="input-group input-group-lg mb-4">
                        <input type="number" onChange={(e)=> handleChange(e,index)} value={perk.quantity || defaultQuantity} min="0" required   className="form-control" id="quantity" aria-describedby="basic-addon3" />
                        </div>
                    </div>


                    <KamaminiButton fullWidth text="Enregistrer" onClick={()=> handleClose()} />
                

                </DialogContent>
            
            </Dialog>
    </>
  )
}

export default RewardDialog