import React,{useEffect} from 'react'
import { Prompt } from 'react-router'

import {
    Container,
    Grid,
    makeStyles,
    Box,
    CssBaseline
  } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import AddReward from '../AddReward/AddReward'
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'
import NoPerk from './perk.svg'
import {errorToast} from '../../redux/actions/actions';


  const useStyles = makeStyles((theme) => (
    {
      root: {
          width: '100%',
        },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
    }
  ));
  

const StepThree = (props) => {
    const classes = useStyles();

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
   
    const {
        nextStep,
        prevStep,
        perk,
        setPerk,
        CustumStepper
       } =props
   
    const Continue = (e) =>{
      e.preventDefault();
      nextStep()
    }
    const Back = (e) =>{
      e.preventDefault();
      prevStep()
    }
    const addClick =() =>{
     setPerk(prevState => ( [...prevState, {price: 0,title: '',description:'',rewardImage:null,quantity:0,id:0}]))
    }

  // check if titles of perks are equals, return true if it's the case
  const checkIfTitlesAreEqual = ( perks) => {
    // if perks are not greater than 1
    if (perks.length <= 1) {
      return false;
    }
    // check if perks titles are equal
    else {
      let i = -1;
      let j ;
      while (++i < perks.length) {
        j = i;
        while (++j < perks.length) {
          // check if titles are not empty and if they are equal
          if (!perks[i].title.match(/^\s*$/) && perks[i].title === perks[j].title) {
            return true;
          }
        }
      }
      return false;
    }
  }

  // Check if titles is empty
  const checkIfTitlesIsEmpty = (perks) => {
    for (let i = 0; i < perks.length; i++) {
      if(perks[i].title.match(/^\s*$/)){
        return true;
      }
    }
    return false;
  }

  // Check if descriptions is empty
  const checkIfDescriptionsIsEmpty = (perks) => {
    for (let i = 0; i < perks.length; i++) {
      if(perks[i].description.match(/^\s*$/)){
        return true;
      }
    }
    return false;
  }

  // check if minimum price is less than 100
  const checkMinimumPrice = (perks) => {
    for (let i = 0; i < perks.length; i++) {
      if(perks[i].price.toString().match(/^\s*$/) || parseInt(perks[i].price) < 100){
        return true;
      }
    }
    return false;
  }
  // check if minimum quantity is less than 0
  const checkMinimumQuantity = (perks) => {
    for (let i = 0; i < perks.length; i++) {
      if(perks[i].quantity.toString().match(/^\s*$/) || parseInt(perks[i].quantity) < 0 ){
        return true;
      }
    }
    return false;
  }

    // check if quantity is equal to 0 and transform it to illimited
    const checkIllimitedQuantity = (perks) => {
      for (let i = 0; i < perks.length; i++) {
        if(perks[i].quantity == 0){
          perks[i].quantity = 'illimited'
          return true;
        }
      }
      return false;
    }

    // transform the perk quantity and the price in int
const transformPerksQuantityAndPriceToInteger = (perks) => {
  for (let i = 0; i < perks.length; i++) {
    if((typeof(perks[i].quantity) === 'string' && perks[i].quantity !== 'illimited') && (typeof(perks[i].price) === 'string')){
      perks[i].quantity = parseInt(perks[i].quantity)
      perks[i].price = parseInt(perks[i].price)
    }
    else if((typeof(perks[i].quantity) === 'string' && perks[i].quantity !== 'illimited')) {
      perks[i].quantity = parseInt(perks[i].quantity)
    }
    else if ((typeof(perks[i].price) === 'string')) {
      perks[i].price = parseInt(perks[i].price)
    }
  }
}

    const handleSubmit = (e) => {
      e.preventDefault() 
      // console.log(perk);
      let values = [...perk];

      if (checkIfTitlesAreEqual(values)) {
        errorToast('Vos récompenses ne peuvent pas avoir le même titre')
      }
      else if (checkIfTitlesIsEmpty(values))
      {
        errorToast('Veuillez entrer le titre de la récompense')
      }
      else if (checkIfDescriptionsIsEmpty(values))
      {
        errorToast('Veuillez entrer la description de la récompense')
      }
      else if (checkMinimumPrice(values))
      {
        errorToast('Le prix minimum pour une récompense est de 100 FCFA')
      }
      else if (checkMinimumQuantity(values))
      {
        errorToast('Veuillez entrer la quantité disponible pour cette récompense')
      }
      else if (checkIllimitedQuantity(values))
      {
        transformPerksQuantityAndPriceToInteger(values)
        setPerk(values)
        nextStep()
        // console.log('illimited: ', values);
      }
      else 
      {
        transformPerksQuantityAndPriceToInteger(values)
        setPerk(values)
        nextStep()
        // console.log('Fineee: ', values);
      }
      

    }


    if(!perk.length){
      return (
        <>
      <Prompt
      // when={shouldBlockNavigation}
          message='Etes-vous sûr de vouloir quitter cette page ? Les données que vous avez entrées ne seront pas sauvegardées.'
        />
      <ToastContainer />

      <CustumStepper/>
      <Box mb={5}></Box>
      <Container>
            <CssBaseline/>

            <Grid container direction="column" justifycontent="center" alignItems="center" >

                <h3>Vous n'avez pas encore créé de récompense</h3>
                <p className='note mb-5 text-center'>Les récompenses sont des incitations offertes aux contributeurs en échange de leurs soutiens. </p>
                <img src={NoPerk} alt="kamamini gift" />
                <br/>
                <KamaminiButton  text='Créer une récompense' onClick={addClick} />
           
            </Grid>
                

                  <br/>
                  <br/>

                 <Grid  item xs={12} sm={12} md={12} lg={12} >
                    <div  className="d-flex justify-content-between">
                      <KamaminiButton text='Précédent' IconLeft={ArrowLeftIcon} onClick={Back} />
                      <KamaminiButton text='Suivant' IconRight={ArrowRightIcon} onClick={Continue} />
                    </div>
                 </Grid>
            <Box mb={5}></Box>
      </Container>
      </>
    )
    } else{
      return (
        <>
      <Prompt
      // when={shouldBlockNavigation}
          message='Etes-vous sûr de vouloir quitter cette page ? Les données que vous avez entrées ne seront pas sauvegardées.'
        />
            <CustumStepper/>
      <Box mb={5}></Box>
      <Container>
            <CssBaseline/>
            <form className={classes.form}  onSubmit={handleSubmit} >
              <Grid  item xs={12} sm={12} md={12} lg={12} >

              <h2 className='text-center mb-2' >Récompense ou Contrepartie</h2>
                <p className='note mb-5 text-center'>Les Récompenses sont des incitations offertes aux contributeurs en échange de leurs soutiens. </p>
                
               
                  <AddReward perk={perk}  setPerk={setPerk} />
            
                <br/>
                <br/>
                 <Grid  item xs={12} sm={12} md={12} lg={12} >
                    <div  className="d-flex justify-content-between">
                      <KamaminiButton text='Précédent' IconLeft={ArrowLeftIcon} onClick={Back} />
                      <KamaminiButton text={'Suivant'} IconRight={ArrowRightIcon} type='Submit' />
                    </div>
                 </Grid>
              </Grid>
            </form>

            <Box mb={5}></Box>
      </Container>
      </>
    )
    }
   
}

export default StepThree
