import {createStore,combineReducers,applyMiddleware,compose} from "redux"
import thunk from 'redux-thunk'
import reducer from './reducers/reducer'
import authReducer from './reducers/authReducer'
import blogReducer from './reducers/blogsReducer'
import projectReducer from './reducers/projectsReducer'


const rootReducer = combineReducers({
    reducer:reducer,
    auth : authReducer,
    blogs : blogReducer,
    projects : projectReducer
})
 const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer,
    composeEnhancers(
        applyMiddleware(thunk),

    ))

export default store