import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { Grid, Container, CssBaseline,  Box, CircularProgress  } from '@material-ui/core'
import {pendingProjects} from '../../../../redux/actions/projectActions'
import SingleProjectCard3 from '../../../Cards/SingleProjectCard3/SingleProjectCard3' 



const ProjetsValidation = ({setSingleProject,nextStep}) =>{

    const dispatch= useDispatch();
    const getPendingProjects = useSelector(state =>state.projects.getPendingProjects);
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    useEffect(() => {
        dispatch(pendingProjects())

    }, [dispatch])



    if(getPendingProjects){
        return (
            <>
                <CssBaseline/>
                <Container>
                    <Box mb={5}></Box>
                    <Grid container spacing={2}>
                         {
                           getPendingProjects.map(project =>{ 
                                 return(
                                     <Grid container item xs={12} sm={6} md={6} lg={4} key={project.id} >
                                         <SingleProjectCard3 project={project} setSingleProject={setSingleProject} nextStep={nextStep}  /> 
                                     </Grid>
                                 )
                             })
                         }
                     </Grid>


                </Container>

            </>

        )

    } 

    else if (getPendingProjects && getPendingProjects.length === 0){
        return (
            <>
                <CssBaseline/>
                <Container>
                    <Box mb={5}></Box>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <h2>
                                Aucun projet en attente de validation
                            </h2>
                        </Grid>
                    </Grid>
                </Container>

            </>
        )
    }
    
    else{
        return(
            <div id="preloader">
                <div className="preloader">
                <CircularProgress style={{color:'green'}} />
                </div>
            </div>
          )
    }

   
}
export default ProjetsValidation