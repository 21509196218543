import react from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'
import { Link } from 'react-router-dom'


const Errorcase = () => {
    return (
        <>
        
        <div className='container text-center py-4'>
            <ReportProblemIcon
            style={{color: '#02b663'}}
           // color="success"
            sx={{ fontSize: 220 }}
            />
            <div className="wrapperu">
                <h1>Quelque chose c'est mal passé</h1>
                <p className="messageu">
                Oops! Une erreur inattendue s'est produite. Veuillez réessayer plus tard ou vérifiez votre connexion internet. Si le problème persiste, n'hésitez pas à contacter le support technique.
                 </p>
         <Link to='/' className="pb-5 ">
           <KamaminiButton text="Page d'accueil" />
         </Link>
      </div>
        </div>
        
        </>
    )
}

export default  Errorcase