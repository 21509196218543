import React, { useState, useContext, useEffect } from 'react';
import firebase from 'firebase/app';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom'
import { Grid, Box, Typography, Container, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { verifyError } from '../redux/actions/authActions';
import { FirebaseContext } from '../Firebase/Firebase'
import { ToastContainer } from 'react-toastify';
import GoogleSignInButton from '../Components/Buttons/GoogleSignInButton/GoogleSignInButton'
import FacebookSignInButton from '../Components/Buttons/FacebookSignInButton/FacebookSignInButton'
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton'
import Seo from '../Components/Helmet/Seo';
import { successToast, errorToast, getToken } from '../redux/actions/actions';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUp = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  // Firebase contexct
  const { auth, db, firebaseApp } = useContext(FirebaseContext)

  // google and facebook authentiications variables
  let googleProvider = new firebaseApp.firebase_.auth.GoogleAuthProvider();
  let facebookProvider = new firebaseApp.firebase_.auth.FacebookAuthProvider();


  const data = {
    prenom: '',
    nom: '',
    email: '',
    password: '',
  };
  const validationSchema = Yup.object().shape({
    prenom: Yup.string().min(3, 'Prénom trop court').required('Le prénom est requis'),
    nom: Yup.string().min(3, 'Nom trop court').required('Le nom est requis'),
    email: Yup.string().email('Veuillez entrer une adresse email valide').required('L\'adresse email est requis'),
    password: Yup.string().min(8, 'Mot de passe trop court').required('Le mot de passe est requis'),
  })
  // the initial value of the state is the object'data'
  const [signUpData, setSignUpData] = useState(data);
  // authentification errors 
  const [error, setError] = useState('')
  const [googleError, setGoogleError] = useState('')
  const [facebookError, setFacebookError] = useState('')

  //destructuring
  const { prenom, nom, email, password } = signUpData

  const handleChange = (e) => {
    setSignUpData({ ...signUpData, [e.target.id]: e.target.value });
  };

  // google authentiication
  const google = async () => {
    const token = await getToken();
    auth.signInWithPopup(googleProvider)
      .then((result) => {
        // The signed-in user info.
        if (result.additionalUserInfo.isNewUser) {
          let userId = result.user.uid;
          db.collection('users').doc(userId).set({
            displayName: result.user.displayName,
            email: result.user.email,
            photoURL: result.user.photoURL,
            initials: result.user.displayName.match(/\b(\w)/g).join(''),
            authProvider: 'google',
            createDate: firebase.firestore.FieldValue.serverTimestamp(),
          })
          const emailData = {
            email: result.user.email,
            name: result.user.displayName
          };

          fetch("https://api.kamamini.net/welcomeEmail", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(emailData),
          })
          successToast('Bienvenue sur Kamamini')
        }
        else {
          successToast('Heureux de vous revoir')
        }

        setTimeout(() => {
          props.history.push('/')
        }, 3000)
      })
      .catch((error) => {
        // Handle Errors here.
        setGoogleError(error)
        const errorMsg = verifyError(error.code)
        if (errorMsg === null && error.message) {
          errorToast(error.message)
        }
        else {
          errorToast(errorMsg)
        }
      });

  }
  //Facebook authentiication
  const facebook = async () => {
    const token = await getToken();
    auth.signInWithPopup(facebookProvider)
      .then((result) => {
        // The signed-in user info.        
        if (result.additionalUserInfo.isNewUser) {
          let userId = result.user.uid;
          db.collection('users').doc(userId).set({
            displayName: result.user.displayName,
            email: result.user.email,
            photoURL: result.user.photoURL,
            initials: result.user.displayName.match(/\b(\w)/g).join(''),
            authProvider: 'facebook',
            createDate: firebase.firestore.FieldValue.serverTimestamp(),
          })
          const emailData = {
            email: result.user.email,
            name: result.user.displayName
          };

          fetch("https://api.kamamini.net/welcomeEmail", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(emailData),
          })
          successToast('Bienvenue sur Kamamini')
        }
        else {
          successToast('Heureux de vous revoir')
        }
        setTimeout(() => {
          props.history.push('/')
        }, 3000)
      })
      .catch((error) => {
        // Handle Errors here.
        setFacebookError(error)
        const errorMsg = verifyError(error.code)
        if (errorMsg === null && error.message) {
          errorToast(error.message)
        }
        else {
          errorToast(errorMsg)
        }
      });
  }


  const handleSubmit = async (values, SubmitProps) => {
    setTimeout(() => {
      SubmitProps.setSubmitting(false)
    }, 2000)
    auth.createUserWithEmailAndPassword(values.email, values.password)
      .then((userCredential) => {
        // Signed in 
        let userId = userCredential.user.uid;
        db.collection('users').doc(userId).set({
          displayName: values.prenom + " " + values.nom,
          email: values.email,
          photoURL: null,
          initials: values.prenom.charAt(0) + values.nom.charAt(0),
          authProvider: 'email',
          createDate: firebase.firestore.FieldValue.serverTimestamp(),
        })
        const emailData = {
          email: values.email,
          name: values.prenom + " " + values.nom
        };

        fetch("https://api.kamamini.net/welcomeEmail", {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(emailData),
        })
        successToast('Bienvenue sur Kamamini')
        SubmitProps.resetForm()
        // setSignUpData({...data})
        setTimeout(() => {
          props.history.push('/')
        }, 3000)
      })
      .catch((error) => {
        const errorMsg = verifyError(error.code)
        setError(error)
        if (errorMsg === null && error.message) {
          errorToast(error.message)
        }
        else {
          errorToast(errorMsg)
        }
        // setSignUpData({...data})
      });
  }

  // Material-ui css
  const classes = useStyles();

  // Show and hide password states and functions
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => { setShowPassword(!showPassword); };
  const handleMouseDownPassword = (event) => { event.preventDefault(); };

  return (
    <>
      <Seo
        title="Inscription"
        description="Rejoignez Kamamini et participez à des projets innovants. Inscrivez-vous dès maintenant et faites partie de notre communauté."
        name="Kamamini"
        type="website"
      />
      <div style={{
        backgroundImage: "url(" + "https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fshape%2F09.png?alt=media&token=e0bf1212-2701-4148-b9ef-662219a204e3" + ")"
        , paddingBottom: '70px', paddingTop: '5px'
      }} >


        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <ToastContainer />
          <div className={classes.paper}>
            <Box mb={5}>
              <Typography component="h1" variant="h5"  >
                Connectez-vous sur kamamini
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}  >
                <GoogleSignInButton google={google} title="S'inscrire avec google" />
              </Grid>
              <Grid item xs={12} sm={6} >
                <FacebookSignInButton title="S'inscrire avec facebook" facebook={facebook} />
              </Grid>
            </Grid>

            <Formik initialValues={data} validationSchema={validationSchema} onSubmit={handleSubmit}  >
              {(props) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        // autoComplete="fname"
                        error={props.errors.prenom && props.touched.prenom}
                        name="prenom"
                        variant="outlined"
                        required
                        fullWidth
                        id="prenom"
                        label="Prénom"
                        autoFocus
                        helperText={<ErrorMessage name="prenom" />}
                      // value={prenom}
                      // onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        error={props.errors.nom && props.touched.nom}
                        variant="outlined"
                        required
                        fullWidth
                        id="nom"
                        label="Nom"
                        name="nom"
                        helperText={<ErrorMessage name="nom" />}
                      // value={nom}
                      // onChange={handleChange}
                      // autoComplete="lname"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        error={props.errors.email && props.touched.email}
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Addresse Email"
                        name="email"
                        helperText={<ErrorMessage name="email" />}
                      // value={email}
                      // onChange={handleChange}
                      // autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={clsx(classes.form)}
                        variant="outlined"
                        required
                        error={props.errors.password && props.touched.password}
                        // autoComplete="current-password"
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                        <Field
                          as={OutlinedInput}
                          id="password"
                          name="password"
                          // value={password}
                          // onChange={handleChange}
                          type={showPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={110}
                        />
                        <FormHelperText id="outlined-weight-helper-text">{<ErrorMessage name="password" />}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox value="allowExtraEmails" color="primary"  />}
                        label="I want to receive inspiration, marketing promotions and updates via email."
                      />
                    </Grid> */}
                  </Grid>

                  <KamaminiButton text={props.isSubmitting ? 'Chargement' : "S'inscrire"} fullWidth={true} disabled={props.isSubmitting} type="submit" CustomClasses={classes.submit} />
                  <Grid container justify="flex-end">
                    <Grid item>
                      Vous avez déjà un compte ?
                      <Link to="/login" variant="body2" className='link'>
                        {' Se connecter'}
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              )}

            </Formik>
          </div>
        </Container>
      </div>
    </>
  );
}
export default SignUp