import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Box,Card   } from '@mui/material';
import Slider from '@mui/material/Slider';


const PrettoSlider = styled(Slider)({
    color: '#02b663',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#02b663',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

const ResponsiveFilteredBox = ({
    filter,
    handleCategoryChange,
    handleLocationChange,
    handleStatusChange,
    handleFundingChange,
    valuetext,
    categoryOption,
    locationOption
}) => {


  return (
        <Card  sx={{mb:8}} >
            <Typography sx={{textAlign:'center'}} variant="subtitle1"  gutterBottom>
             Options de filtres
        </Typography>
            <Box sx={{ display: 'flex' }}>
            <Box sx={{width:"100%"}} >
            {/* <Typography variant="subtitle2" sx={{color:'rgba(0, 0, 0, 0.6)'}} gutterBottom>
            Catégorie
            </Typography> */}

        <FormControl fullWidth className="my-2" >
        <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="category"
            value={filter.category}
            label="Catégorie"
            onChange={handleCategoryChange}
        >
            <MenuItem value="">Toutes les catégories</MenuItem>
            {
                categoryOption.map((category,index)=> {
                    return(
                    <MenuItem key={index} value={category}>{category}</MenuItem>
                    )
                })
            }
        </Select>
        </FormControl>

            </Box>
        

        
            <Box sx={{width:"100%",ml:5}} >
            {/* <Typography variant="subtitle2" sx={{color:'rgba(0, 0, 0, 0.6)'}} gutterBottom>
            Localisation
            </Typography> */}
        <FormControl fullWidth className="my-2">
        <InputLabel id="demo-simple-select-label">Localisation</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="location"
            value={filter.location}
            label="Localisation"
            onChange={handleLocationChange}
        >
            <MenuItem value="">Toutes les localisations</MenuItem>
            {
                locationOption.map((location,index)=> {
                    return(
                    <MenuItem key={index} value={location}>{location}</MenuItem>
                    )
                })
            }
        </Select>
        </FormControl>
            </Box>


        </Box>

        <Box sx={{ display: 'flex' }}>

        <Box sx={{width:"100%",ml:5}} >
        <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Statut</FormLabel>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            row
        >
            <FormControlLabel value="" onClick={handleStatusChange} control={<Radio />} label="Tous les projets" />
            <FormControlLabel onClick={handleStatusChange} value="En cours" control={<Radio />} label="En cours" />
            <FormControlLabel onClick={handleStatusChange} value="Terminé" control={<Radio />} label="Terminé" />
        </RadioGroup>
        </FormControl>
        </Box>

        <Box sx={{width:"100%",ml:5}} >
                    <Typography variant="subtitle2" sx={{color:'rgba(0, 0, 0, 0.6)'}} gutterBottom>
        Pourcentage de financement
        </Typography>
        <Box sx={{display:'flex'}} >
            <PrettoSlider
            valueLabelFormat={valuetext}
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        value={filter.funding}
        onChange={handleFundingChange}
        />
            <span className="py-2 px-3"  >{filter.funding}%</span>
        </Box>
        </Box>




            </Box>
        </Card>
    
  )
}

export default ResponsiveFilteredBox