import React from 'react'
import Radio from '@mui/material/Radio';
import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
import {Link} from 'react-router-dom'
import KamaminiButton from '../../Buttons/KamaminiColorButton/KamaminiButton'
import './perkCard.css'

const PerkCard = ({perk,getDonorsPerPerk,selectedRadioValue,handleChangeRadio,projectSlug}) => {

const {price,quantity,description,title} = perk;


const controlProps = (item) => (  
  {
  checked: selectedRadioValue === item,
  onChange: handleChangeRadio,
  value: item,
  name: 'size-radio-button-demo',
  inputProps: { 'aria-label': item },
});

// const greenChipStyle = {
//   backgroundColor: '#02b663',
//   color: '#fff',
//   padding: '5px',
//   fontSize: '0.8rem',
//   fontWeight: 500
// }
const yellowChipStyle = {
  backgroundColor: '#fed857',
  color: 'black',
  padding: '5px',
  fontSize: '0.8rem',
  fontWeight: 500
}

const selectedRadio = {...controlProps(title)}.checked === true ? {borderColor: '#02a056', borderWidth: '2px', borderStyle: 'solid',transform: "scale(1.05)"} : {};


  return (
    <div className="perk__card mb-4 " id='test' onClick={()=> handleChangeRadio(title) } style={selectedRadio}  >
        <div className="radio__container">
          <Radio  {...controlProps(title)} />
        </div>
        {
          price && description && quantity &&
          <div className="perk__content">
            <h3>Engagement de {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(price)  } </h3>
            <h2> {title} </h2>
            <p> {description} </p>
            <p> {quantity} </p>

              <div className='row'>
                <div className='col-sm-12 col-md-6 py-2'>
                  <Chip label={getDonorsPerPerk(title) > 1 ? `${getDonorsPerPerk(title)} Contributeurs` : `${getDonorsPerPerk(title)} Contributeur`}  size='medium' />
                </div>

                <div className='col-sm-12 col-md-6 py-2'>
                  <Chip label={parseInt(quantity) >0 ?` 
                    ${quantity} récompenses restantes `: 
                    parseInt(quantity) === 1 ? `  ${quantity} récompense restante `:
                    parseInt(quantity) === 0 ? ` Aucune récompense disponible `: 
                    quantity === 'illimited' && 'illimité'} style={yellowChipStyle} />
                </div>
              </div>
                

            {
              
                price && description && quantity ? {...controlProps(title)}.checked === true &&
                  <div className='d-flex justify-content-center mt-4 mn-2' >
                    <Link to={`/${projectSlug}/checkout?title=${encodeURIComponent(title)}`} >
                      <KamaminiButton  text='Continuer' />
                    </Link>
                  </div>
                  :
                  
                  
                  <div className='d-flex justify-content-center mt-4 mn-2' >
                    <Link to={`/${projectSlug}/checkout?`}  >
                      <KamaminiButton  text='Continuer' />
                    </Link>
                  </div>
            }

          </div>

        }
        {
          title && !price && !description && !quantity &&
          <div className="perk__content">
            <h3> {title} </h3>
            {
              {...controlProps(title)}.checked === true &&
              <div className='d-flex justify-content-center mt-4 mn-2' >
                <Link to={`/${projectSlug}/checkout?`}  >
                  <KamaminiButton fullWidth={true} text='Continuer' />
                </Link>
              </div>

            }
            
          </div>

        }
       
       
        
    </div>
  )
}
      


export default PerkCard