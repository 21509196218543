import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import Box from '@material-ui/core/Box';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },

  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    color: "black",
    marginLeft : theme.spacing(1),

  },
  heading2: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
    color: "black"
  },
  background: {
    backgroundColor: "rgb(233, 229, 229)"
  }
}));

const AccordionComponent = ({question,response}) => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
              <HelpOutlineIcon/>
              <Typography className={classes.heading}  >   {question} </Typography>
           
          </AccordionSummary>
          <AccordionDetails className={classes.background}>
            <Typography className={classes.heading2}  >
             {response}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Box p={1}></Box>

      
        
      </div>
    );
}

export default AccordionComponent
