import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik } from 'formik';
import { array, object, string } from 'yup';
import { Grid, Button, LinearProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactPlayer from "react-player/youtube";
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer } from 'react-toastify';
import TextEditor from '../Components/TextEditor/TextEditor'
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton'
import { getSingleProject, cancelProjectInfo, cancelNoProjectInfo, updateAcceptedProject } from '../redux/actions/projectActions';
import { MultipleFileUploadField } from '../Components/Upload/MultipleFileUploadField'
import UserRewardCard from '../Components/Cards/RewardCard/UserRewardCard'
import AddFaq from '../Components/AddFaq/AddFaq';
import ProjectImages from '../Components/EditProjectComponents/ProjectImages';
import ProjectVideos from '../Components/EditProjectComponents/ProjectVideos';
import ProjectFaq from '../Components/EditProjectComponents/ProjectFaq';
import { notAllowed, errorToast, resizeFile, displayProjectEndTime } from '../redux/actions/actions';
import EditRejectedProject from './EditRejectedProject'
// import Sand from '../assets/img/sand-clock.png'

const EditProject = (props) => {

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.auth.userInfo)
  const project = useSelector(state => state.projects.singleProject)
  const noProject = useSelector(state => state.projects.noSingleProject)

  const [selectedPerkState, setSelectedPerkState] = useState([])
  const [nonselectedPerkState, setNonSelectedPerkState] = useState([])
  const [wysiwyg, setWysiwyg] = useState(null);
  const [displayAddimages, setDisplayAddimages] = useState(false);
  const [displayVideoInput, setDisplayVideoInput] = useState(false);
  const [imageDropZoneUrl, setImageDropZoneUrl] = useState([]);
  const [dropzoneImages, setdropzoneImages] = useState([]);
  const [saveInput, setSaveInput] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState([]);
  const [faq, setFaq] = useState([]);
  const [uploadImageProgess, setuploadImageProgess] = useState(0);
  const [newFeaturedImageUrl, setnewFeaturedImageUrl] = useState('');
  const [pitchContentRequired, setpitchContentRequired] = useState(true)


  const deleteImageDropZoneUrl = (url) => {
    setImageDropZoneUrl((curr) => curr.filter((currUrl) => currUrl !== url))
  }

  function deleteImage(file) {
    setdropzoneImages((curr) => curr.filter((fw) => fw !== file));
  }


  const handleDisplayDropzone = () => {
    setDisplayAddimages(!displayAddimages)
    if (imageUrl.length) {
      for (let url = 0; url < imageUrl.length; url++) {
        setImageDropZoneUrl(prev => [...prev, imageUrl[url].url])
      }
    }
    setdropzoneImages([])
  }

  const handleSaveInput = (e) => {
    setSaveInput(e.target.value)
  }

  const addYoutubeUrl = () => {
    if (saveInput !== '' && saveInput.includes("youtube.com") && youtubeUrl.indexOf(saveInput) === -1) {
      setYoutubeUrl(prev => [...prev, saveInput])
      setSaveInput('')
    }
  }

  const deleteVideoUrl = (url) => {
    setYoutubeUrl((curr) => curr.filter((currUrl) => currUrl !== url))
  }

  const handleDisplayVideoInput = () => {
    setDisplayVideoInput(!displayVideoInput)
  }


  const uploadFile = async (file, onProgress) => {
    const key = "ktru7nrq";
    const url = 'https://api.cloudinary.com/v1_1/kamamini/image/upload';

    return new Promise((res, rej) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);

      xhr.onload = () => {
        const resp = JSON.parse(xhr.responseText);
        res(resp.secure_url);
      };
      xhr.onerror = (evt) => rej(evt);
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentage = (event.loaded / event.total) * 100;
          onProgress(Math.round(percentage));
        }
      };
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', key);

      xhr.send(formData);
    })
      .then((data) => { return data })
      .catch((error) => {
        alert('Erreur veuillez réessayer');
      });


  }

  const handleChangeImg = async (e) => {
    const file = e.target.files[0];
    if (file && file['type'].split('/')[0] === 'image') {
      if (file.size <= (5000 * 1024)) {
        try {
          const image = await resizeFile(file);
          const urlImage = await uploadFile(image, setuploadImageProgess)
          setnewFeaturedImageUrl(urlImage)
        } catch (error) {
          errorToast("Votre image n'a pas pu être sauvegardée, essayez de nouveau")
        }
      }
      else {
        errorToast("La taille de l'image ne doit pas être supérieur à 5 Mo")
      }
    }

  }



  // check if titles of perks are equals, return true if it's the case
  const checkIfTitlesAreEqual = (selectedPerks, nonselectedPerks, check) => {
    // if nonselectedPerks are not greater than 1
    if (nonselectedPerks.length <= 1 && check === 0) {
      return checkIfTitlesAreEqual(selectedPerks, nonselectedPerks, 1)
    }
    // check if nonselectedPerks titles are equal
    else if (check === 0) {

      let i = -1;
      let j;
      while (++i < nonselectedPerks.length) {
        j = i;
        while (++j < nonselectedPerks.length) {
          // check if titles are not empty and if they are equal
          if (nonselectedPerks[i].title === nonselectedPerks[j].title) {
            return true;
          }
        }
      }
      return checkIfTitlesAreEqual(selectedPerks, nonselectedPerks, 1)
    }
    // check if nonselectedPerks titles are equal to selectedPerks titles
    else if (check === 1) {
      for (let i = 0; i < selectedPerks.length; i++) {
        for (let j = 0; j < nonselectedPerks.length; j++) {
          if (nonselectedPerks[j].title === selectedPerks[i].title) {
            return true;
          }
        }
      }
    }
    return false;
  }

  const handleSave = (images) => {
    let newImage = newFeaturedImageUrl !== '' ? newFeaturedImageUrl : project.featuredImage;

    const data = {
      wysiwyg,
      newImage,
      images,
      youtubeUrl,
      faq,
      perkData: [...selectedPerkState, ...nonselectedPerkState],
      id: project.id,
      slug
    }
    let check = checkIfTitlesAreEqual(selectedPerkState, nonselectedPerkState, 0)
    // console.log(check);

    if (check) {
      errorToast('Vos récompenses ne peuvent pas avoir le même titre')
    }
    else if (pitchContentRequired) {
      errorToast('Veuillez ajouter une vidéo ou une image à votre pitch')
    }
    else if (wysiwyg.match(/^\s*$/)) {
      errorToast('Veuillez ajouter une description à votre projet')
    }
    else {
      dispatch(updateAcceptedProject(data))
    }

  }

  const handleConfirm = () => {
    let values = [...imageDropZoneUrl];
    if (imageUrl.length) {
      for (let url = 0; url < imageUrl.length; url++) {
        setImageDropZoneUrl(prev => [...prev, imageUrl[url].url])
        values.push(imageUrl[url].url)
      }
    }
    setdropzoneImages([])
    handleSave(values)
  }

  // get all unique values
  const getUnique = (items, value) => {
    if (items) {
      return [...new Set(items.map(item => item[value]))];
    }
  };

  const getNonSelectedPerk = (titles) => {
    let nonSelectedPerks = [];
    for (let i = 0; i < titles.length; i++) {
      for (let j = 0; j < project.perk.length; j++) {
        if (project && project.perk[j].title === titles[i]) {
          nonSelectedPerks.push(project.perk[j])
        }
      }
    }


    return nonSelectedPerks;
  }
  const getSelectedPerk = (titles) => {
    let selectedPerks = [];
    for (let i = 0; i < titles.length; i++) {
      for (let j = 0; j < project.perk.length; j++) {
        if (project && project.perk[j].title === titles[i]) {
          selectedPerks.push(project.perk[j])
        }
      }
    }
    return selectedPerks;

  }
  // get unique types
  let perkTitle = project && getUnique(project.perk, "title").filter(title => title !== undefined);
  let donationPerkTitle = project && getUnique(project.donor, "perkTitle").filter(title => title !== undefined);
  let nonselectedPerkTitles = project && perkTitle.filter(x => !donationPerkTitle.includes(x));
  let selectedPerkTitles = project && perkTitle.filter(x => donationPerkTitle.includes(x));
  let nonselectedPerk = project && getNonSelectedPerk(nonselectedPerkTitles)
  let selectedPerk = project && getSelectedPerk(selectedPerkTitles)

  // const slug = props.match.params.slug;
  const slug = window.location.href.split('/')[3];

  const imageUrl = dropzoneImages.filter((image) => image.url);

  // console.log(nonselectedPerkState);
  // console.log(selectedPerkState);
  // console.log(perkTitle);
  // console.log(donationPerkTitle);
  // console.log(nonselectedPerkTitles);
  // console.log(selectedPerkTitles);
  // console.log(nonselectedPerk);
  // console.log(selectedPerk);

  if (!userInfo) {
    props.history.push("/");
    dispatch(notAllowed())

  }
  if (project && userInfo && userInfo.uid !== project.authorId) {
    props.history.push("/");
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getSingleProject(slug))
    return () => {
      dispatch(cancelProjectInfo())
      dispatch(cancelNoProjectInfo())
    }
  }, [dispatch, slug])

  useEffect(() => {
    if (project) {
      setSelectedPerkState(selectedPerk)
      setNonSelectedPerkState(nonselectedPerk)
      setWysiwyg(project.projectText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project])
  // transform pitchContentRequired to false if there is an image or video
  useEffect(() => {
    if (imageUrl.length > 0 || youtubeUrl.length > 0 || imageDropZoneUrl.length > 0) {
      setpitchContentRequired(false)
    } else {
      setpitchContentRequired(true)
    }
  }, [imageUrl, youtubeUrl, imageDropZoneUrl])

  if (project && displayProjectEndTime(project.projectsEndDate) === `Terminé`) {
    return (
      <>
        <div className='container py-5'  >
          <div className="row py-5">
            <div className="col-md-12 text-center py-3">

              <h3 className='mb-3 text-center ' >Nous tenons à vous informer que votre projet <strong> {project.title}</strong> est maintenant considéré comme terminé</h3>
              <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fsand-clock.png?alt=media&token=1c748bef-8d5f-4a78-8754-4dfd23afb559" className='py-3 img-fluid text-center' alt={project.title} />

              <p style={{ 'fontSize': '18px', fontWeight: "300" }} className="text-center" >Par conséquent, vous ne pouvez plus effectuer de modifications sur celui-ci. Nous vous remercions pour votre engagement et votre détermination à mener à bien votre projet. Nous espérons que votre projet a été un succès et que vous avez réussi à atteindre vos objectifs. </p>
              <p style={{ 'fontSize': '16px', fontWeight: "300" }} className="text-center">
                S'il y a des informations que vous souhaiteriez ajouter ou des questions que vous souhaiteriez poser, n'hésitez pas à nous contacter. Nous sommes là pour vous aider à chaque étape de votre projet.
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }
  else if (project && project.confirmation) {

    return (
      <>
        <ToastContainer />
        <div className='container'>
          <div className='row py-5'>
            <div className='col-md-12'>
              <h2 className='text-center' >{project.title} </h2>
            </div>
          </div>

          <div className='row py-5'>
            <div className='col-md-12'>
              <h3 className='mb-4'> Description du projet </h3>
            </div>
            <div className='col-md-10' >
              {
                project.donor.length > 0 ?
                  <h4 style={{ 'fontSize': '18px', fontWeight: "400" }}>La description du projet ne peut plus être modifiée puisqu'un contributeur a déjà contribué </h4>
                  :
                  <TextEditor data={project.projectText} setData={setWysiwyg} />
              }

            </div>
          </div>

          <div className='row py-3'>
            <div className='col-md-12'>
              <h3 className='mb-4'>Image principale </h3>
            </div>

            {
              uploadImageProgess > 0 && uploadImageProgess < 100 && <LinearProgress variant="determinate" value={uploadImageProgess} />
            }

            <div className='col-md-5'>
              {
                newFeaturedImageUrl !== '' ?
                  <div className="projects projects-02 white-bg mb-30 wow fadeInUp2 animated" data-wow-delay='.1s'>
                    <div className="projects__thumb pos-rel">
                      <img className="img-thumbnail" src={newFeaturedImageUrl} alt={project.title} />
                    </div>
                  </div>
                  :
                  <div className="projects projects-02 white-bg mb-30 wow fadeInUp2 animated" data-wow-delay='.1s'>
                    <div className="projects__thumb pos-rel">
                      <img className="img-thumbnail" src={project.featuredImage} alt={project.title} />
                    </div>
                  </div>
              }

              <div className='row'>
                <div className='col-md-2'></div>
                <div className='col-sm-12  col-md-8'>
                  <input className="d-none" id="photoURL" onChange={handleChangeImg} type="file" accept="image/x-png,image/gif,image/jpeg" />
                  <label htmlFor="photoURL" className="py-2" >
                    <span className="theme_btn theme-border-btn2 editButton " data-animation="fadeInLeft" data-delay=".5s">
                      Modifier
                      <i className="fas fa-edit"></i>
                    </span>
                  </label>
                </div>
                <div className='col-md-2'></div>
              </div>

            </div>

          </div>

          <div className='row py-5'>
            <div className='col-md-12'>
              <h3 className='mb-4'> Images du projet </h3>
            </div>

            <div className='col-md-10'>
              <div className='row py-5'>
                <ProjectImages images={project.images} setImageDropZoneUrl={setImageDropZoneUrl} imageDropZoneUrl={imageDropZoneUrl} />

                <Grid container spacing={2}>

                  {
                    imageDropZoneUrl.length ?
                      imageDropZoneUrl.map((imageDropZoneUrl, index) => {
                        return (
                          <Grid container item xs={12} sm={6} md={5} lg={4} key={index}  >
                            <Grid item>
                              <img className="img-fluid small rounded mx-auto d-block" src={imageDropZoneUrl} alt="Kamamini project's" />
                            </Grid>

                            <Grid item className='px-3'>
                              <Button className="bg-danger text-white" size="small"
                                onClick={() => deleteImageDropZoneUrl(imageDropZoneUrl)}>
                                <DeleteIcon fontSize="small" />
                              </Button>
                            </Grid>
                          </Grid>
                        )
                      })
                      : null
                  }

                </Grid>

              </div>

              <div className="d-flex justify-content-end mb-3">
                <KamaminiButton onClick={() => handleDisplayDropzone()} text="Ajouter d'autres images " />
              </div>

              {
                displayAddimages &&
                <Formik
                  initialValues={{ files: [] }}
                  validationSchema={object({
                    files: array(
                      object({
                        url: string().required(),
                      })
                    ),
                  })}
                >
                  {() => (
                    <>
                      <Grid className='pb-4 pt-2' container spacing={2} direction="column">
                        <MultipleFileUploadField
                          name="files"
                          files={dropzoneImages}
                          setFiles={setdropzoneImages}
                        />
                      </Grid>

                      <Grid container spacing={2}>

                        {
                          imageUrl.length ?
                            imageUrl.map((imageUrl, index) => {
                              return (

                                <Grid container item xs={12} sm={6} md={5} lg={4} key={index}  >
                                  <Grid item>
                                    <img className="img-fluid small rounded mx-auto d-block" src={imageUrl.url} alt={imageUrl.file.name} />
                                  </Grid>

                                  <Grid item className='px-3'>
                                    <Button className="bg-danger text-white" size="small"
                                      onClick={() => deleteImage(imageUrl)}>
                                      <DeleteIcon fontSize="small" />
                                    </Button>
                                  </Grid>
                                </Grid>

                              )
                            })
                            : null
                        }

                      </Grid>

                    </>
                  )}
                </Formik>
              }
            </div>

          </div>


          <div className='row py-3'>
            <div className='col-md-12'>
              <h3 className='mb-4'> Vidéo(s) du projet </h3>
            </div>

            <div className='col-md-10 py-5'>
              <ProjectVideos setYoutubeUrl={setYoutubeUrl} videos={project.youtubeVideos} youtubeUrl={youtubeUrl} />
            </div>

            <div className='col-md-10 py-5'>
              {
                youtubeUrl.length > 0 &&
                youtubeUrl.map((video, index) => {
                  return (
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={10} sm={10} md={10} lg={10}  >
                        <ReactPlayer className="" controls url={video} />
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justifycontent="flex-end"
                        alignItems="center"
                        item xs={2} sm={2} md={2} lg={2} >

                        <Button className="bg-danger text-white" size="small" onClick={() => deleteVideoUrl(video)}>
                          <CloseIcon fontSize="medium" />
                        </Button>
                      </Grid>

                    </Grid>
                  )
                })
              }
            </div>

            <div className='col-md-10 ' >
              <div className="d-flex justify-content-end mb-3">
                <KamaminiButton onClick={() => handleDisplayVideoInput()} text="Ajouter d'autres vidéos " />
              </div>
            </div>

            {
              displayVideoInput &&
              <div className='col-md-10 py-3'>
                <div className="kamamini-input" >
                  <div className="input-group input-group-lg">
                    <input type="url" placeholder='http://' value={saveInput} onChange={handleSaveInput} className="form-control" id="url" aria-describedby="basic-addon3" />
                    <KamaminiButton onClick={addYoutubeUrl} text='Ajouter la video' className="input-group-text" />
                  </div>
                </div>
              </div>

            }

          </div>

          <div className='row py-3'>
            <div className='col-md-12'>
              <h3 className='mb-4'> Foires aux questions (FAQ) </h3>
            </div>

            <div className='col-md-10 py-1'>
              {
                <ProjectFaq faq={faq} faqs={project.faq} setFaq={setFaq} />
              }

            </div>
            <div className='col-md-10 py-3'>

              <AddFaq setFaq={setFaq} faq={faq} />

            </div>
          </div>


          <div className='row py-5'>

            <div className='col-md-12'>
              <h3 > Récompense(s) </h3>
              <p className="note mb-4">NB : Vous ne pouvez pas modifier les récompenses qui ont déjà reçu des contributions </p>
            </div>


            <div className='col-md-10 py-3'>

              {

                !project.perk ?
                  <h4>Ce projet n'a pas de récompense </h4>
                  :
                  nonselectedPerkState.length > 0 ?

                    <div className="row">
                      <UserRewardCard
                        perk={nonselectedPerkState}
                        setPerkData={setNonSelectedPerkState}
                      />
                    </div>
                    :
                    <div className="row">
                      <h4 style={{ 'fontSize': '18px', fontWeight: "400" }}>Vous ne pouvez plus modifier vos récompenses car elles ont déja été sélectionné par des contributeurs </h4>
                    </div>
              }

            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="mt-1 text-center">
                <span onClick={() => handleConfirm()} className="theme_btn theme_btn_bg edit_save" data-animation="fadeInLeft" data-delay=".7s"> Enregistrer</span>
              </div>
            </div>
          </div>

        </div>
      </>

    )
  }
  else if (project && project.confirmation === false) {
    return <EditRejectedProject project={project} />
  }
  else if (project && project.confirmation === '') {
    return <>
      <div className='container py-5'  >
        <div className="row py-5">
          <div className="col-md-12 text-center py-3">
            
           <h3 className='mb-3 text-center ' >Nous vous remercions pour avoir soumis votre projet sur Kamamini</h3>
           <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fsand-clock.png?alt=media&token=1c748bef-8d5f-4a78-8754-4dfd23afb559" className='py-3 img-fluid text-center' alt={project.title} />
            
           <p style={{'fontSize':'18px',fontWeight:"300"}} className="text-center" > Nous avons bien reçu votre projet et nous sommes en train de l'étudier </p> 
           <p style={{'fontSize':'16px',fontWeight:"300"}} className="text-center">
              Nous vous informons que vous ne pouvez pas actuellement modifier votre projet. Nous vous contacterons par email dès que nous aurons pris une décision concernant votre projet et vous fournirons des explications détaillées sur les raisons de notre décision.

              En attendant, nous vous invitons à consulter nos lignes directrices et à nous contacter si vous avez des questions ou des préoccupations
            </p>
          </div>
        </div>
      </div>
    </>
  }
  else if (noProject) {
    return (
      <div className='container py-5'  >
        <div className="row py-3">
          <div className="col-md-12">
            <h2 className='text-center' >Il n'ya pas de projet correspondant à cet identifiant</h2>
          </div>
        </div>
      </div>
    )
  }
  else {
    return (
      <div id="preloader">
        <div className="preloader">
          <CircularProgress style={{ color: 'green' }} />
        </div>
      </div>
    )
  }

}

export default EditProject