import React, {Component} from 'react'
import {Grid,Button}  from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'
import {errorToast,resizeFile} from '../../redux/actions/actions'

export default class AddReward extends Component {

    constructor(props) {
      super(props);
     
      // this.props.setPerk

      this.state = { values: [{price: 0,title: '',description:'',rewardImage:null,quantity:0}],
                              id:Date.now()
                    };
      this.handleSubmit = this.handleSubmit.bind(this);
      
    }

    createUI(){

       return this.props.perk.map((el, i) => 
       <React.Fragment key={i}>
       <ToastContainer />
       <Grid  container spacing={2}>
         <Grid  item xs={10} sm={10} md={10} lg={10} >


                                    {/* TITLE */}
                <h5 htmlFor="title">Titre  <span className='star'>*</span> </h5>
                <p className='note mb-3'>Le titre de votre récompense est celui qui apparaîtra sur la page de votre campagne et dans tout Kamamini. Choisissez un titre qui décrit au mieux le contenu de cette récompense.</p>

                <Grid  item xs={8} sm={8} md={8} lg={8} >
                  <div className="kamamini-input" >
                      <div className="input-group input-group-lg mb-3">
                          <input type="text"  required value={el.title} onChange={this.handleChange.bind(this, i)} className="form-control" id="title" aria-describedby="basic-addon3" />
                      </div>
                  </div>
                </Grid>
                                
                              {/* PRICE */}
                              <h5 >Montant de la récompense <span className='star'>*</span> </h5>
                <p className='note mb-3'>Définissez un montant que vous souhaitez collecter auprès des contributeurs qui réclament cette récompense.</p>
            
                <Grid  item xs={8} sm={8} md={8} lg={8} >
                  <div className="kamamini-input" >
                    <div className="input-group input-group-lg mb-4">
                      <CurrencyFormat className="form-control" value={el.price} displayType={'input'} thousandSeparator={'  '} suffix={'  F CFA'} allowNegative={false}  id="price"
                      onValueChange={(values) => { this.handlePriceChange(i,values.value) }} />
                    </div>
                  </div>
                </Grid>


                                        {/* DESCRIPTION */}
                <h5 htmlFor="title">Description <span className='star'>*</span> </h5>
                <p className='note mb-3'>Décrivez les détails de cette récompense. Soyez créatif, c'est l'occasion d'expliquer aux contributeurs ce qu'ils recevront en échange de leurs financements pour cette récompense spécifique.</p>
                <Grid  item xs={8} sm={8} md={8} lg={8} >
                  <div className="kamamini-input" >
                      <div className="input-group  mb-3">
                          <textarea type="text"  required value={el.description} onChange={this.handleChange.bind(this, i)} className="form-control" id="description" aria-describedby="basic-addon3" ></textarea>
                      </div>
                  </div>
                </Grid>

                                        {/* IMAGE */}
                <h5 htmlFor="title">Image de la récompense </h5>
                <p className='note mb-3'>sélectionnez l'image qui représente le mieux cette récompense.</p>
                        
                 <Grid container spacing={2}>
                   <Grid  item xs={8} sm={8} md={8} lg={8} >
                      <div className="input-group  mb-3">
                          <div className="custom-file">
                              <input type="file" accept="image/x-png,image/gif,image/jpeg"  className="custom-file-input" id="rewardImage" aria-describedby="inputGroupFileAddon01" onChange={this.handleChangeImg.bind(this, i) } lang="fr" />
                              <label className="custom-file-label " htmlFor="inputGroupFile01" data-browse="Parcourir"> Importer votre image</label>
                          </div>
                      </div> 
                   </Grid>
                   <Grid item xs={4} sm={4} md={4} lg={4} > 
                      {
                          el.rewardImage && 
                          <div className="projects projects-02 white-bg mb-30 wow fadeInUp2 animated" data-wow-delay='.1s'>
                          <div className="projects__thumb pos-rel">
                              <img className="img-thumbnail" src={el.rewardImage} alt='kamamini reward card'/>
                          </div>
                          </div>
                      }
                    
                    </Grid>



                                        {/* Quantity */}

                    <h5 htmlFor="title">Quantité disponible <span className='star'>*</span> </h5>
                    <p className='note mb-3'>Vous pouvez limiter la quantité disponible pour les contributeurs en fonction du volume de production. Si vous laissez ce champ à zéro, cela signifie qu'il n'y a pas de limite de quantité.</p>
                    <Grid  item xs={8} sm={8} md={8} lg={8} >
                      <div className="kamamini-input" >
                        <div className="input-group input-group-lg mb-4">
                          <input type="number" min="0" required  value={el.quantity} onChange={this.handleChange.bind(this, i)}  className="form-control" id="quantity" aria-describedby="basic-addon3" />
                        </div>
                      </div>
                   </Grid>

                 </Grid>     
            
          </Grid>
     
          <Grid  
          container
          direction="column"
          justifycontent="flex-end"
          alignItems="center" 
          item xs={2} sm={2} md={2} lg={2} >
            <Button className="bg-danger text-white" size="small" onClick={this.removeClick.bind(this, i)}>
            <DeleteForeverIcon fontSize="large"  /> 
            Supprimer la récompense
            </Button>
          </Grid>
      
        
       </Grid>
       <hr/>
       <br/>

       
      </React.Fragment>
       )
    }

     uploadFileReward = (file,onProgress) =>{
      const key = "wwm57ooi";
      const url = 'https://api.cloudinary.com/v1_1/kamamini/image/upload';
    
      return new Promise ((res, rej) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
    
        xhr.onload = () => {
          const resp = JSON.parse(xhr.responseText);
           res(resp.secure_url);
        };
        xhr.onerror = (evt) => rej(evt);
      
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', key);
    
        xhr.send(formData);
      })
      .then((data) => { return data })
      .catch((error) => {
        alert( 'Erreur veuillez réessayer');
      });
      
    }

  
    handleChange (i,event) {
        let values = [...this.props.perk];
        values[i][event.target.id] =   event.target.value;
       //  this.setState({ values }); 
        this.props.setPerk(values)
    }
    handlePriceChange (i,event) {
        let values = [...this.props.perk];
        values[i].price = event;
       //  this.setState({ values }); 
        this.props.setPerk(values)
    }
    
    async handleChangeImg (i,event) {
      const file = event.target.files[0];
     if (file && file['type'].split('/')[0] === 'image'  ) {
      if (file.size <= (5000 * 1024)) {
        try {     
          const image = await resizeFile(file);
          const urlImage = await this.uploadFileReward(image)
          let values = [...this.props.perk];
          values[i][event.target.id] =  urlImage;
          //  this.setState({ values }); 
          this.props.setPerk(values)
        }
        catch (error) {
          errorToast("Votre image n'a pas pu être sauvegardée, essayez de nouveau")
        }
      }
      else {
        errorToast("La taille de l'image ne doit pas être supérieur à 5 Mo")
      }

     }
    }
    
    addClick(){
      this.setState((prev) => ({ id:Date.now() }))
      // this.setState(prevState => ({ values: [...prevState.values, {price: 0,title: '',description:'',rewardImage:null,quantity:0}]}))
      this.props.setPerk(prevState => ( [...prevState, {price: 0,title: '',description:'',rewardImage:null,quantity:0,id:this.state.id}]))
    }
    
    removeClick(i){
       let values = [...this.props.perk];
       values.splice(i,1);
      //  this.setState({ values });
       this.props.setPerk(values)
    }
  
    handleSubmit(event) {
      event.preventDefault();
    }
    render() {
      return (
       <>
            {this.createUI()}   

            <br/>
            <br/>
            <div  className="d-flex justify-content-end">
              <KamaminiButton onClick={this.addClick.bind(this)} >
                <AddCircleIcon fontSize='medium' />
                Ajouter une nouvelle récompense
              </KamaminiButton> 
            </div>     
       </>
      );
    }
  }
  