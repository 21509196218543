import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import {Typography,makeStyles} from '@material-ui/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {logout} from '../../../redux/actions/authActions'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
   
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
	width:{
		minWidth: '2rem'
	},
	height:{
		minHeight: '2rem'
	},
	iconsBackground : {
		'&:hover svg ': {
            color: '#02a056',
          },
		'&:hover i ': {
            color: '#02a056',
          },
	}

  }));

function UserMenu(props) {
	const dispatch = useDispatch();
	const classes = useStyles();

	// const user = useSelector(({ auth }) => auth.user);

	const userInfo = useSelector(state => state.auth.userInfo)


	const [userMenu, setUserMenu] = useState(null);
	

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	return (
		<>
			<Button className={`${classes.height} ${classes.width}`} onClick={userMenuClick}>
				

				{userInfo.photoURL ? (
					<div className="d-flex">
					<Avatar className={classes.large}  src={userInfo.photoURL} />
					{
						userMenu ? <i className="px-2 d-flex align-items-center fas fa-chevron-up"></i> : <i className="px-2 d-flex align-items-center fas fa-chevron-down"></i>
					}
					
					</div>
				) : (
					// <Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
					<Avatar className={`${classes.orange}  ${classes.large} `}>{userInfo.initials.toUpperCase()} </Avatar>
				)}
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{
					<>
						<MenuItem component={Link} to="/user-profile" onClick={userMenuClose} role="button" className={classes.iconsBackground}>
							<ListItemIcon className={classes.width}>
								<AccountCircleIcon  />
							</ListItemIcon>
							<ListItemText primary="Mon profil" />
						</MenuItem>


						<MenuItem component={Link} to="/edit-profile" onClick={userMenuClose} role="button" className={classes.iconsBackground}>
							<ListItemIcon className={classes.width}>
								<i className="fas fa-user-edit ml-1"></i>
							</ListItemIcon>
							<ListItemText primary="Modifier mon profil" />
						</MenuItem>

						<MenuItem component={Link} to="/settings" onClick={userMenuClose} role="button" className={classes.iconsBackground}>
							<ListItemIcon className={classes.width}>
								<i className="fas fa-cog"></i>
							</ListItemIcon>
							<ListItemText primary="Paramètres" />
						</MenuItem>
						
						
						<MenuItem
							onClick={() => {
								dispatch(logout());
								userMenuClose();
							}}
							className={classes.iconsBackground}
						>
							<ListItemIcon className={classes.width}>
								<ExitToAppIcon/>
							</ListItemIcon>
							<ListItemText primary="Déconnexion" />
						</MenuItem>
					</>
				// )
				}
			</Popover>
		</>
	);
}

export default UserMenu;
