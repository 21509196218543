import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { addToNewsletter, errorToast } from '../../redux/actions/actions'


const Newsletter = () => {

    const dispatch = useDispatch();
    const [input, setInput] = useState("");

    const inputHandler = (e) => {
        setInput(e.target.value);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-useless-escape
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input)) {
            dispatch(addToNewsletter(input))
            setInput("");

        }
        else {
            errorToast('vous avez entré une adresse mail invalide')
        }
    }

    return (
        <div className='container-fluid bg-success p-5'>
            <div className="container">
                <div className=" pos-rel">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6">
                            <div className="subscribe-wrapper">
                                <div className="section-title text-left mb-30 wow fadeInLeft animated" data-wow-delay='.1s'>
                                    <h6 className="left-line pl-75 pr-75">Newsletters</h6>
                                    <h2>Souscrivez à Notre<br />
                                        <span>Newsletters</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="subscribe-content wow fadeInRight animated" data-wow-delay='.1s'>
                                <h6>Recevez chaque mise à jour pour rejoindre nos Newsletters</h6>
                                <form className="subscribe-form" onSubmit={submitHandler}>
                                    <input type="email" onChange={inputHandler} value={input} className="form-control" placeholder="Inserer votre Email" />
                                    <button type="submit">
                                        <i className="fas fa-arrow-right"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}


export default Newsletter;