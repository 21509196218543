import React from 'react';
import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';

const FirebaseContext = React.createContext(null);

const firebaseApp = Firebase.initializeApp({
    apiKey: "AIzaSyAGJy2PD6vzfIHP6TpcAaUTNLdkcjWgapo",
    authDomain: "kamamini.net",
    projectId: "kamamini-react",
    storageBucket: "kamamini-react.appspot.com",
    messagingSenderId: "931106011671",
    appId: "1:931106011671:web:f78adb15f8974ba35c7a52",
    measurementId: "G-DQRVQEPS5Z"
})


const auth = firebaseApp.auth();
const db = firebaseApp.firestore();
const storage = firebaseApp.storage();
const analytics = firebaseApp.analytics();

export {auth,db,storage,FirebaseContext,firebaseApp}

