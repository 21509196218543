const initState = {
  userLoading: false,
  userInfo: null,
  userInfoError: {},
  noUser: false,
  waitAuthCheck: true,
  deleteUserError: null,
  deleteUserSuccess: false,
  adminUsersLoading: false,
  adminUsers: null,
  adminUsersError: null,
  notAllowed: false,

  profileInfoLoading: false,
  profileInfo: null,
  profileInfoError: null,

  updateUserEmailError: null,
}


const authReducer = (state = initState, action) => {
  switch (action.type) {

    case 'GET_USER_DATA':
      return { ...state, userLoading: true }
    case 'GET_USER_DATA_SUCCESS':
      return { ...state, userLoading: false, userInfo: action.userInfo, waitAuthCheck: false }
    case 'GET_USER_DATA_ERROR':
      return { ...state, userLoading: false, userInfo: null, userInfoError: action.error, waitAuthCheck: false }
    case 'NO_USER':
      return { ...state, noUser: true, waitAuthCheck: false }
    case 'LOGOUT':
      return { ...state, userInfo: null, waitAuthCheck: false }
    case 'UPDATE_USER_DATA_SUCCESS':
      return { ...state }
    case 'UPDATE_USER_DATA_ERROR':
      return { ...state }
    case 'UPDATE_USER_EMAIL':
      return { ...state }
    case 'UPDATE_USER_EMAIL_ERROR':
      return { ...state, updateUserEmailError: action.error.message }
    case 'UPDATE_USER_PASSWORD':
      return { ...state }

    case 'DELETE_USER_FROM_FIREBASE_AUTH_AND_FIRESTORE':
      return { ...state, userInfo: null, waitAuthCheck: false, deleteUserSuccess: true }
    case 'CANCEL_DELETE_USER_SUCCESS':
      return { ...state, deleteUserSuccess: false }

    case 'DELETE_USER_ERROR':
      return { ...state, userInfo: null, waitAuthCheck: false, deleteUserError: action.email }
    case 'CANCEL_DELETE_USER_ERROR':
      return { ...state, deleteUserError: null }

    case 'GET_ADMIN_USERS':
      return { ...state, adminUsersLoading: true }
    case 'GET_ADMIN_USERS_SUCCESS':
      return { ...state, adminUsersLoading: false, adminUsers: action.adminUsers }
    case 'GET_ADMIN_USERS_ERROR':
      return { ...state, adminUsersLoading: false, adminUsers: null, adminUsersError: action.error }

    case 'UPDATE_ADMIN_USERS':
      return { ...state }
    case 'UPDATE_ADMIN_USERS_ROLE':
      return { ...state }

    case 'USER_NOT_ALLOWED_TO_SEE_THIS_PAGE':
      return { ...state, notAllowed: true }

    case 'GET_PROFILE_USER':
      return { ...state, profileInfoLoading: true }
    case 'GET_PROFILE_USER_SUCCESS':
      return { ...state, adminUsersLoading: false, profileInfo: action.profileInfo }
    case 'GET_PROFILE_USER_ERROR':
      return { ...state, profileInfoLoading: false, profileInfo: null, profileInfoError: action.error }
    case 'CANCEL_GET_PROFILE_USER':
      return { ...state, profileInfo: null }


    default: return state
  }

}

export default authReducer
