import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid, Container, CssBaseline, AppBar, Tabs, Tab, Box, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import FilterBlog from '../Components/Filter/FilterBlog'
import { allBlogs } from '../redux/actions/blogActions'
import BlogsCard from '../Components/Cards/BlogsCard/BlogsCard'
import Seo from '../Components/Helmet/Seo';







const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        border: '#2E3B55 solid 2px',
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40ch',
            '&:focus': {
                width: '60ch',
            },
        },
    },
}));


    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        indicator: {
            backgroundColor: 'black',
        },

        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },

    }));
    
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-force-tabpanel-${index}`}
                aria-labelledby={`scrollable-force-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography component={'span'}>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    const AllTheBlogs = () => {
        const [filter, setFilter] = useState({
            category: "",
            name: "",
        });
    
        const handleCategoryChange = (event) => {
            setFilter({ ...filter, category: event.target.value });
        };
    
        const handleNameChange = (event) => {
            setFilter({ ...filter, name: event.target.value });
        };
       
        const classes = useStyles();
        const dispatch = useDispatch()
        const getAllBlogs = useSelector(state => state.blogs.getAllBlogs)
        const [value, setValue] = useState(0);
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        

        useEffect(() => {
            window.scrollTo(0, 0)
            dispatch(allBlogs())

        }, [dispatch])

        const [blogs, setBlogs] = useState(getAllBlogs)
        console.log(blogs);
            const filteredBlogs = getAllBlogs && getAllBlogs.filter( blog => {
            return (
            (filter.name === "" || blog.title.toLowerCase().includes(filter.name.toLowerCase())) &&
            (filter.category === "" || blog.category === filter.category));
            });
            console.log(filteredBlogs)
        
    
        
        // get all unique values
        const getUnique = (items, value) => {
            if(items){
                return [...new Set(items.map(item => item[value]))];
            }};

       
        // get unique types

        let categoryOption = getUnique(getAllBlogs, "category");
        let title = getUnique(getAllBlogs, "title")


        //filter in tabs

        const handleFilterCategory = (name) => {
            const new_array = getAllBlogs.filter(blogs => blogs.category.includes(name))
            setBlogs(new_array)
        }

        //filter in seach bar
        const handleFilterTitle = (name) => {
            const new_array = getAllBlogs.filter(blogs => blogs.title.includes(name))
            setBlogs(new_array)
        }

        if (getAllBlogs) {


            return (
                <div>
                    <Seo
                        title='Tous les blogs'
                    />
                    <CssBaseline />
                    <section className="page-title-area" style={{ backgroundImage: "url(" + "https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fshape%2Fblog%20(1).jpg?alt=media&token=363379f5-e368-4f94-a142-455cd4d0dfc6" + ")" }}>
                        <div className="right-border-shape">
                            <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fshape%2F02.png?alt=media&token=e3e85ab5-6bcd-4ca5-86b4-77ff51f0cb54" alt="kamamini shape" />
                        </div>

                        <div className="page-title-wrapper text-center ">
                            <h1 className="page-title wow fadeInUp2 animated" data-wow-delay='.1s'>Tous les blogs</h1>
                        </div>
                    </section>
                    <Container>
                        <div className={classes.root}>
                            <AppBar position="static" color="default">
                             
                            </AppBar >
                            <FilterBlog spacing={3}
                            handleCategoryChange={handleCategoryChange}
                            filter={filter}
                            categoryOption={categoryOption}
                            />
                             <Box mb={4}></Box>
                    <Box sx={{ display:'flex',justifyContent:'center', pb:5 }}>
                        <Search >
                          <SearchIconWrapper>
                          <SearchIcon />
                          </SearchIconWrapper>
                          <StyledInputBase
                          placeholder="Rechercher des projets"
                          inputProps={{ 'aria-label': 'search' }}
                          id="name"
                          type="search"
                          value={filter.name}
                          onChange={handleNameChange}
                          />
                      </Search>
                </Box>
                                <Grid container spacing={2}>
                                {
                                    filteredBlogs.map(blog => {
                                        return(
                                            <Grid key={blog.id} container item xs={12} sm={6} md={4} lg={4}>
                                                < BlogsCard blog={blog} />
                                            </Grid>
                                        )
                                    })
                                }
                                </Grid>
                        </div>

                    </Container>
                </div>
            )
        }
        else {
            return (
                <div id="preloader">
                    <div className="preloader">
                        <CircularProgress style={{ color: 'green' }} />
                    </div>
                </div>
            )
        }
    }

    export default AllTheBlogs
