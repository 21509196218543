//  import React from 'react'

// import {NavLink,Link,useLocation} from "react-router-dom";
import {Link} from 'react-router-dom'
// const ImgCaroussel = () => {
//     return (
//         <>
//            <div className="slider-area pos-rel">
//             <div className="slider-active">
//                 <div className="single-slider slider-height pos-rel d-flex align-items-center"
//                      style={{backgroundImage : `url(${slider1}`}}>
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-xl-7">
//                                 <div className="slider__content text-left">
//                                     <span className="sub-title left-line pl-80 mb-35">Crowd Founding </span>
//                                     <h1 className="main-title mb-35" data-animation="fadeInUp2" data-delay=".2s">Change World
//                                         <span>Better Future.</span></h1>
//                                     <ul className="btn-list">
//                                     <Link to='/admin/create-blog' >
//                                          <li><span className="theme_btn theme_btn_bg"
//                                                 data-animation="fadeInLeft" data-delay=".7s">Créer un blog <i
//                                                     className="fas fa-arrow-right"></i></span>
//                                         </li>
//                                         </Link>
//                                         <Link to='/signup' >
//                                             <li><a className="theme_btn theme-border-btn" href="contact.html"
//                                                 data-animation="fadeInLeft" data-delay=".5s">S'inscrire <i
//                                                     className="fas fa-arrow-right"></i></a>
//                                             </li>
//                                         </Link>
//                                         <Link to='/allBlogs' >
//                                             <li><a className="theme_btn theme-border-btn" href="contact.html"
//                                                 data-animation="fadeInLeft" data-delay=".5s">Tous les blogs <i
//                                                     className="fas fa-arrow-right"></i></a>
//                                             </li>
//                                         </Link>
                                        
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="single-slider slider-height pos-rel d-flex align-items-center"
//                     style={{backgroundImage : `url(${slider2}`}}>
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-xl-7">
//                                 <div className="slider__content text-left">
//                                     <span className="sub-title left-line pl-80 mb-35">Crowd Founding </span>
//                                     <h1 className="main-title mb-35" data-animation="fadeInUp2" data-delay=".2s">Change World
//                                         <span>Better Future.</span></h1>
//                                     <ul className="btn-list">
//                                         <Link to='/admin/create-blog' >
//                                          <li><span className="theme_btn theme_btn_bg"
//                                                 data-animation="fadeInLeft" data-delay=".7s">Créer un blog <i
//                                                     className="fas fa-arrow-right"></i></span>
//                                         </li>
//                                         </Link>
                                       
//                                         <Link to='/signup' >
//                                             <li><a className="theme_btn theme-border-btn" href="contact.html"
//                                                 data-animation="fadeInLeft" data-delay=".5s">S'inscrire  <i
//                                                     className="fas fa-arrow-right"></i></a>
//                                             </li>
//                                         </Link>
//                                         <Link to='/allBlogs' >
//                                             <li><a className="theme_btn theme-border-btn" href="contact.html"
//                                                 data-animation="fadeInLeft" data-delay=".5s">Tous les blogs <i
//                                                     className="fas fa-arrow-right"></i></a>
//                                             </li>
//                                         </Link>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div> 
//         </>
//     )
// }

// export default ImgCaroussel



import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/imgcar.css"

export default class SimpleSlider extends Component {
  render() {


    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      autoplay: true,
      height: "180vh"
    };
   
    return (
      <div>

        <Slider {...settings}>
            <div>
            
          <div className="page-title-area" style={{backgroundImage : "url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fslider%2Fpexels1.jpg?alt=media&token=1a48e34a-27cc-4636-ba12-e271a2b01fd5"+")"
          , backgroundRepeat: "no-repeat", backgroundSize: 'cover', height:'70vh'}}>
            

            <div className="container"> 
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 py-5">
                    
                        <div class="slider__content slider__content__02 text-center py-4">
                                <span className="sub-title left-line pl-80 mb-35">Rejoignez la communauté Kamamini dès maintenant </span>
                                     <h1 className="main-title mb-35" data-animation="fadeInUp2" data-delay=".2s" style={{fontSize:'32px'}}>
                                        <span className='koh'>
                                            Soutenez les projets qui ont un impact réel sur notre société
                                        </span>
                                         </h1>

                                     <ul className="btn-list py-2">
                                        <li><Link to='/start-a-campaign' className="theme_btn theme_btn2 theme_btn_bg_02 text-white" 
                                                data-animation="fadeInLeft" data-delay=".7s">Lancez votre projet <i
                                                    className="fas fa-arrow-right"></i></Link>
                                        </li>
                                        <li><Link className="theme_btn theme_btn_bg" to="/about"
                                                data-animation="fadeInLeft" data-delay=".7s">En savoir plus<i
                                                    className="fas fa-arrow-right"></i></Link>
                                        </li>
                                    </ul>
                                   
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
                    
                                
                          

           
          </div>
          </div>

          <div className="">
            <div className="page-title-area" style={{backgroundImage : "url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fslider%2Fpexels3.png?alt=media&token=4015e86e-f5b1-4640-8e9d-312c85c5f714"+")"
            , backgroundRepeat: "no-repeat", backgroundSize: 'cover', height:'70vh'}}>
                        <div className="container" > 
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 py-5">
                    
                        <div class="slider__content slider__content__02 text-center py-4">
                                <span className="sub-title left-line pl-80 mb-35">Découvrez les projets les plus innovants sur Kamamini </span>
                                     <h1 className="main-title mb-35" data-animation="fadeInUp2" data-delay=".2s" style={{fontSize:'32px'}}>
                                        <span className='koh'>
                                             Vous avez un projet qui vous tient à cœur? Faites-le connaître à notre communauté 
                                        </span>
                                      </h1>

                                     <ul className="btn-list py-1">
                                        <li><Link to='/start-a-campaign' className="theme_btn theme_btn2 theme_btn_bg_02 text-white" 
                                                data-animation="fadeInLeft" data-delay=".7s">Lancez votre projet <i
                                                    className="fas fa-arrow-right"></i></Link>
                                        </li>
                                        <li><Link className="theme_btn theme_btn_bg" to="/about"
                                                data-animation="fadeInLeft" data-delay=".7s">En savoir plus<i
                                                    className="fas fa-arrow-right"></i></Link>
                                        </li>
                                    </ul>
                                   
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>

            </div>         
           </div>
        
        </Slider>
       
      </div>
    );
  }
}
