import React from 'react'
import BlogsCard from '../Cards/BlogsCard/BlogsCard';

const FeaturedBlogs = ({ featuredBlogs }) => {

    if (featuredBlogs) {
        return (
            <section className="blog-area pt-130 pb-100  grey-bg ">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2">
                            <div className="section-title text-center mb-70 wow fadeInUp2 animated" data-wow-delay='.1s'>
                                <h6 className="left-line pl-75 pr-75">Lecture Recommandée</h6>
                                <h2>Articles à Ne Pas Manquer<br />
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            featuredBlogs.map(blog => {
                                return (
                                    <div key={blog.id} className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp2 animated" data-wow-delay='.3s'>
                                        <BlogsCard blog={blog} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default FeaturedBlogs