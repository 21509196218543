import React,{useState,useEffect} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {  useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {CircularProgress } from '@material-ui/core'

// core components
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import styles from "../../../assets/jss/material-dashboard-react/layouts/adminStyle.js";


import  {adminRoutes,staffRoutes,editorRoutes}  from "../variables/routes.js";

let ps;

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {

  
  // styles
  const classes = useStyles();
  let history = useHistory();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [routes, setRoutes] = useState(null)
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const userInfo = useSelector(state => state.auth.userInfo)



  if(!userInfo || !userInfo.admin || userInfo.admin === false){
    history.push('/')
  }


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  const switchRoutes = (
    <Switch>
      {routes && routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  );
  useEffect(() => {
    if(userInfo && userInfo.role === "Admin"){
      setRoutes(adminRoutes)
    }
    else if(userInfo && userInfo.role === "Staff"){
      setRoutes(staffRoutes)
    }
    else if(userInfo && userInfo.role === "Editor"){
      setRoutes(editorRoutes)
    }
  }, [userInfo])
  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  
if(routes){
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"KAMAMINI"}
        logo={"/kamamini_1.png"}
        image={"url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fsidebar-2.jpg?alt=media&token=a5e2c788-d6d5-433b-ba2d-3f1425f87fdf"+")"}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"green"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}

      </div>
    </div>
  );
}
else{
  return(
      <div id="preloader">
          <div className="preloader" ref={mainPanel}>
          <CircularProgress style={{color:'green'}} />
          </div>
      </div>
    )
}
}
