import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { notAllowed, errorToast, successToast } from '../redux/actions/actions'
import { getFeaturedBlogs } from '../redux/actions/blogActions'
import { acceptedProjects, featuredProjects } from '../redux/actions/projectActions'
import { cancelDeleteUserError, cancelDeleteUserSuccess } from '../redux/actions/authActions'
import Newsletter from '../Components/Newsletter/Newsletter';
import CardCaroussel from '../Components/CardCarousselHome/CardCaroussel';
import Seo from '../Components/Helmet/Seo';
import AboutSection from '../Components/AboutSection/AboutSection';
import ProjectByCategory from '../Components/ProjectsByCategory/ProjectsByCategory';
import ImgCaroussel from '../Components/ImgCaroussel/ImgCaroussel';
import FeaturedBlogs from '../Components/FeaturedBlogs/FeaturedBlogs';
import Errorcase from '../Components/ErrorCase/ErrorCase';
import Loader from '../Components/Loader/Loader';



const Home = () => {

  const dispatch = useDispatch()
  const notAllowed = useSelector(state => state.auth.notAllowed)
  const deleteUserError = useSelector(state => state.auth.deleteUserError)
  const deleteUserSuccess = useSelector(state => state.auth.deleteUserSuccess)

  const getAcceptedProjectsLoading = useSelector(state => state.projects.getAcceptedProjectsLoading)
  const getAcceptedProjects = useSelector(state => state.projects.getAcceptedProjects)
  const getAcceptedProjectsError = useSelector(state => state.projects.getAcceptedProjectsError)
  const featuredBlogs = useSelector(state => state.blogs.featuredBlogs);

  const getFeaturedProjectsLoading = useSelector(state => state.projects.getFeaturedProjectsLoading)
  const getFeaturedProjects = useSelector(state => state.projects.getFeaturedProjects)
  const getFeaturedProjectsError = useSelector(state => state.projects.getFeaturedProjectsError)




  useEffect(() => {
    window.scrollTo(0, 0)

    if (deleteUserError !== null) {
      errorToast(`Vous devez vous connecter avec votre compte ${deleteUserError}, reconnectez vous et réessayez de supprimer ce compte`)
    }
    else if (deleteUserSuccess) {
      successToast('Votre compte a bien été supprimé')
    }
    return () => {
      dispatch(cancelDeleteUserError())
      dispatch(cancelDeleteUserSuccess())
    }

  }, [dispatch, deleteUserError, deleteUserSuccess])

  useEffect(() => {
    dispatch(acceptedProjects())
    dispatch(getFeaturedBlogs())
    dispatch(featuredProjects())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (getAcceptedProjectsLoading || getFeaturedProjectsLoading) {
    return (
      <Loader />
    )
  }


  if (getAcceptedProjectsError || getFeaturedProjectsError) {
    return (
      <Errorcase />
    )
  }


  if (getAcceptedProjects && featuredBlogs && getFeaturedProjects) {
    return (
      <>
        <Seo
          title="Accueil"
          description="Bienvenue sur Kamamini, la plateforme de financement participatif qui donne vie à vos projets."
          name="Kamamini"
          type="website"
        />
        <CssBaseline />

        <ToastContainer />

        <ImgCaroussel />

        <CardCaroussel getFeaturedProjects={getFeaturedProjects} />

        <AboutSection />

        <Container>
          <ProjectByCategory projects={getAcceptedProjects} />
        </Container>


        <Newsletter />
        <FeaturedBlogs featuredBlogs={featuredBlogs} />
      </>
    )
  }

  else {
    return (
      <div id="preloader">
        <div className="preloader">
          <CircularProgress style={{ color: 'green' }} />
        </div>
      </div>
    )
  }


}



export default Home
