import React from 'react'
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'
import {Link, NavLink} from 'react-router-dom'

 const Works2 = () => {
    return (
      <div className='container-fluid mb-0'>
        <div className='container d-flex justify-content-space-between pt-5 my-3' style={{height:'40vh',  width:'auto'}}>
            <div className='text-center'>

          <h5 className='font-weight-bold'>Rapide et sûr</h5>
          <p>
          Des millions de personnes font confiance à KAMAMINI en tant qu'expert n°1 de la collecte de fonds en ligne. 
          C'est pourquoi plus de personnes lancent  des collectes de fonds  sur KAMAMINI que sur toute autre plateforme.
          </p>
          <NavLink to="/blogs/pourquoi-choisir-kamamini">
            <KamaminiButton>Apprendre encore Plus</KamaminiButton>
          </NavLink>

          </div>
        </div>
      </div>
    )
}
export default Works2
