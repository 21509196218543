import React,{useEffect,useState} from 'react'
import { useWindowScroll } from "react-use";
import '../assets/css/TermsAndConditions.css'


const PrivacyPolicy = ({setSticky}) => {

  useEffect(() => {
    window.scrollTo(0, 0)
    setSticky(false)
    return () => {
      setSticky(true)
    }
  }, [])

const [ fix, setFix ] = useState(false)
const { y: pageYOffset } = useWindowScroll();


// function setFixedSidebar() {
//   console.log(pageYOffset);
// }
 
useEffect(() => {
  if(pageYOffset >= 600 && pageYOffset <= 4200) {
    setFix(true)
  }
  else{
    setFix(false)
  }
  
}, [pageYOffset,fix])

// window.addEventListener("scroll", setFixedSidebar)

  return (
    <>
      <div className="raw py-5 bg-light pt-3 mb-5">
        <div className="premier pt-5 col-md-6">
          <h4 >Les données que nous collectons, comment elles sont utilisées et avec qui nous les partageons</h4>
          <p className= "font-size-15px paragraph__color mt-3">Nous nous soucions de votre vie privée et souhaitons vous aider à comprendre comment nous recueillons, utilisons et partageons vos informations personnelles.</p>
        </div>
        <div className="col-md-4 d-none d-md-block">
          <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fproject%2Fstatistics.png?alt=media&token=033629c7-ad00-44be-9d2f-9e81e39aef95" className="photo" alt="kamamini statistic"/>
        </div>
      </div>

      <div className="raw">
      <div className="col-md-12 d-xl-none d-lg-none py-5">
          
          <h5 className="privé">Politique de confidentialité</h5><br/>
            <ul className='container paragraph__color py-3 li' >
                <li classname="terms__li"><a href="#intro">Introduction</a></li><br/>
                <li classname="terms__li"><a href="#identité">1.IDENTITE DU RESPONSABLE DU TRAITEMENT</a></li>
                <li classname="terms__li"><a href="#loi">2.APPLICATION DE LA LOI</a></li>
                <li classname="terms__li"><a href="#consentement">3.CONSENTEMENT</a></li>
                <li classname="terms__li"><a href="#methode">4.METHODE DE COLLECTE</a></li>
                <li classname="terms__li"><a href="#information">5.INFORMATION COLLECTER</a></li>
                <li classname="terms__li"><a href="#final">6. FINALITÉS</a></li>
                <li classname="terms__li"><a href="#destinataire">7. DESTINATAIRE DES DOSSIERS</a></li>
                <li classname="terms__li"><a href="#données">8. LES DONNÉES BANCAIRES</a></li>
                <li classname="terms__li"><a href="#droits">9. LES DROITS DE L'INTERNAUTE</a></li>
                <li classname="terms__li"><a href="#sécurité">10. SÉCURITÉ</a></li>
                <li classname="terms__li"><a href="#reclamation">11. RÉCLAMATION</a></li>
            </ul>
        </div>
      </div>

      <div className="raw g-0 bg-light">
        <div className="col-md-4 d-none d-lg-block py-5">
          
          <h5 className="privé">Politique de confidentialité</h5><br/>
          <div className={fix ? 'sidenav fixed' : 'sidenav'} >
            <ul className='container paragraph__color checkout-card py-3 li' >
                <li classname="terms__li"><a href="#intro">Introduction</a></li><br/>
                <li classname="terms__li"><a href="#identité">1.IDENTITE DU RESPONSABLE DU TRAITEMENT</a></li>
                <li classname="terms__li"><a href="#loi">2.APPLICATION DE LA LOI</a></li>
                <li classname="terms__li"><a href="#consentement">3.CONSENTEMENT</a></li>
                <li classname="terms__li"><a href="#methode">4.METHODE DE COLLECTE</a></li>
                <li classname="terms__li"><a href="#information">5.INFORMATION COLLECTER</a></li>
                <li classname="terms__li"><a href="#final">6. FINALITÉS</a></li>
                <li classname="terms__li"><a href="#destinataire">7. DESTINATAIRE DES DOSSIERS</a></li>
                <li classname="terms__li"><a href="#données">8. LES DONNÉES BANCAIRES</a></li>
                <li classname="terms__li"><a href="#droits">9. LES DROITS DE L'INTERNAUTE</a></li>
                <li classname="terms__li"><a href="#sécurité">10. SÉCURITÉ</a></li>
                <li classname="terms__li"><a href="#reclamation">11. RÉCLAMATION</a></li>
            </ul>
          </div>
        </div>
        <div className="border col-md-8">
            <h2 className='terms__h2' >Poitique de protection des données personnelles</h2>
            <p className="paragraph__color terms__p"><strong>Date de mise à jour :</strong> 12 Décembre 2022</p>

            <h5 id="intro">Introduction</h5>
              <div className="liste">
                <p className="paragraph__color terms__p" >La présente Charte des données personnelle (« la Charte ») est à jour au 12/12/2022.
                  KamamIni assure la sécurité et la confidentialité des informations qui lui sont confiées
                  Le site (ci-après « le Site ») est susceptible de collecter certaines données personnelles des Utilisateurs. Une donnée personnelle étant « Toute donnée relative à une personne physique et susceptible de contribuer à l’identification de cette dernière, de manière directe ou indirecte, que cette donnée soit confidentielle ou publique (« Données Personnelles »). »

                  La présente Charte a pour objet d’exposer les conditions dans lesquelles les données personnelles collectées sont traitées et utilisées, afin d’informer aux mieux les personnes concernées. 
                  La Charte fait partie intégrante des Conditions Générales d’Utilisation (« CGU »), KamaMini accorde une importance toute particulière à la protection des données personnelles de ses Utilisateurs.
                </p><br/><br/>
                  <h3 className="mt-0" id="identité">1.IDENTITE DU RESPONSABLE DU TRAITEMENT </h3><br/>
                  <p className="paragraph__color terms__p">
                  La société KamaMini, société à responsabilité limité (SARL, dont le siège est situé au quartier francophonie dans les locaux du CIPMEN, immatriculée au Registre du Commerce et du crédit mobilier, représentée par Monsieur Moctar Yonli, Gérant, dûment habilité aux fins des présentes,
                  (Ci-après, "l'Éditeur")
                </p><br/>
                  <h3 className="mt-0" id="loi">2.APPLICATION DE LA LOI</h3><br/>
                  <p className="paragraph__color terms__p">
                  L’Éditeur est responsable du traitement des données à caractère personnel et s’engage à respecter le contenu de la loi en matière de protection des données Personnel pour garantir la sécurité des utilisateurs. vos données personnelles seront traitées conformément à l’Acte additionnel A/SA.1/01/10 du 16 février 2010 de la CEDEAO relatif à la protection des données à caractère personnel.
                </p><br/>
                  <h3 className="mt-0" id="consentement">3.CONSENTEMENT</h3><br/>
                  <p className="paragraph__color terms__p">
                  En utilisant notre Site, l’Utilisateur accepte la politique de respect de la vie privée de l'Éditeur, et consent au fait que certaines données soient collectées selon les modalités et principes décrits ci-après.
                  La création d’un Compte Utilisateur et/ou Investisseur sur le Site est impossible si les informations obligatoires sollicitées dans le formulaire d’inscription sont manquantes.
                  Les newsletters transmises font apparaître un lien sur lequel il sera possible de cliquer de façon à ne plus recevoir d’emails
                  </p><br/>

                  <h3 className="mt-0" id="methode">4.METHODE DE COLLECTE</h3><br/>
                  <p className="paragraph__color terms__p">L’Éditeur peut recueillir des informations concernant l’Utilisateur à différents moments.
                    <ul><br/>
                      <li classname="terms__li">  ●	Lors de la saisie des informations personnelles pour la création d’un Compte ;</li>
                      <li classname="terms__li">  ●	Lors de la navigation sur le Site par le biais de Cookies ;</li>
                      <li classname="terms__li">  ●	Lors des échanges écrits ou oraux (emails, chat, témoignages, commentaires, commentaires, vote).</li>
                    </ul><br/>
                    La communication et le traitement des données est indispensable à la fourniture des Services et/ou au respect des exigences légales et règlementaires auxquelles le Site est soumis. En dehors de ces cas, aucune donnée n’est collectée sans l’accord préalable.
                  </p>
                  <h3 className="mt-0" id="information">5.INFORMATIONS COLLECTER</h3><br/>
                  <p className="paragraph__color terms__p">
                  Les informations collectées pour la création d’un Compte Utilisateur sont les suivantes :
                    <ul><br/>
                      <li classname="terms__li">  ●	Nom et prénom* ;</li>
                      <li classname="terms__li">  ●	Adresse email* ;</li>
                      <li classname="terms__li">  ●	Numéro de téléphone</li>
                    </ul><br/>
                      Les informations collectées pour la création d’un Compte porteur de projet sont les suivantes :
                      .	Informations obligatoires(*)
                      <ul><br/>
                        <li classname="terms__li">  ●	Nom et prénom* ;</li>
                        <li classname="terms__li">  ●	LAdresse email ;</li>
                        <li classname="terms__li">  ●	Numéro de téléphone;</li>
                        <li classname="terms__li">  ●	Biographie;</li>
                        <li classname="terms__li">  ●	Ville;</li>
                        <li classname="terms__li">  ●	Liens de réseaux sociaux;</li>
                      </ul><br/>
                      En sus des informations collectées, le Site demande la fourniture de pièces complémentaires et notamment :
                      <ul><br/>
                      <li classname="terms__li">  ●	Une pièce d’identité en cours de validité ;</li>
                      <li classname="terms__li">  ●	Une photo de profilAdresse email* ;</li>
                      <li classname="terms__li">  ●	Des pièces complémentaires pour certains cas et notamment si l’Utilisateur est une personne morale (Nif, Rccm, etc)</li>
                    </ul><br/>
                       Par ailleurs, des données sont collectées :Dans le cadre de la navigation sur le Site, concernant le comportement et les préférences de l’Internaute ;
                  </p>
                  <h3 className="mt-0" id="final">6. FINALITÉS</h3><br/>
                  <p className="paragraph__color terms__p">
                      L'Editeur ne collectera que les données adéquates, pertinentes et strictement nécessaires à la finalité du traitement dans le respect du principe de proportionnalité.
                      En fonction des situations, les finalités sont les suivantes :
                      <ul><br/>
                        <li classname="terms__li">●	la constitution d’un fichier d’Utilisateurs inscrits,</li>
                        <li classname="terms__li">●	la gestion du Compte,</li>
                        <li classname="terms__li">●	permettre d’utiliser les services du Site,</li>
                        <li classname="terms__li">●	respecter les obligations légales et réglementaires,</li>
                        <li classname="terms__li">●	lutter contre la fraude,</li>
                        <li classname="terms__li">●	sécuriser les transactions,</li>
                        <li classname="terms__li">●	établir des preuves de réalisation des Services,</li>
                        <li classname="terms__li">●	fournir des offres personnalisées</li>
                        <li classname="terms__li">●	établir des documents</li>
                        <li classname="terms__li">●	En cas d’abonnement à la newsletter du Site, l’adresse e-mail est utilisée pour l'envoi de nos mailings relatifs à l’actualité du Site et la constitution d’un fichier d’utilisateur inscrits à la Newsletter ;</li>
                      </ul><br/>
                          La technologie évoluant, l'Éditeur pourrait être amené à ajouter des finalités qui ne sont pas encore prévues dans cette Charte. Le cas échéant, les modifications des règles de confidentialité seront effectuées sur cette page.
                      </p>
                  <h3 className="mt-0" id="destinataire">7. DESTINATAIRE DES DOSSIERS</h3><br/>
                    <p className="paragraph__color terms__p">
                      Les données et informations collectées sur l’Utilisateur sont destinées à :
                      <ul><br/>
                        <li classname="terms__li">●	certains salariés de l’Éditeur, ainsi qu’à ses éventuels sous-traitants, ou partenaires dont l’intervention est nécessaire pour l’exécution des Services lesquels sont soumis au respect des mêmes règles sur la protection des données personnelles que l’Éditeur,</li>
                        <li classname="terms__li">●	aux autorités publiques lorsqu’elles en font la demande, ou dans le cadre des obligations réglementaires ou légales.</li>
                      </ul><br/>
                      Aucune vente ne sera faite de ces données collectées.
                      L’Éditeur se réserve le droit de transférer les données à caractère personnel dans le cas d’un changement de son contrôle ou de la cession de tout ou partie de ses actifs, notamment par acquisition ou fusion avec une autre société.
                      Néanmoins, les données peuvent être transmises aux autorités compétentes sur requête et notamment aux organismes publics, exclusivement pour répondre aux obligations légales, les auxiliaires de justice, les officiers ministériels et les organismes chargés d’effectuer le recouvrement de créances.
                    </p>
                  <h3 className="mt-0" id="données">8. LES DONNÉES BANCAIRES</h3>
                  <p className="paragraph__color terms__p">
                    Les transactions financières relatives au paiement via le Site, sont confiées à un prestataire de services de paiement qui en assure le bon déroulement et la sécurité.
                    Pour les besoins des services, ce prestataire de services de paiement peut être amené à être destinataire de vos données à caractère personnel (Nom, numéro de téléphone,  montant des transactions, ) , qu’il recueille et conserve en notre nom et pour notre compte.
                    L'Éditeur n’a pas accès à ces données.
                    Les données relatives aux cartes bancaires sont conservées pendant le temps de l’inscription sur le Site et à tout le moins, jusqu’à la résiliation du Compte.
                    En tout état de cause, les données relatives à celles-ci pourront être conservées, pour une finalité de preuve en cas d’éventuelle contestation de la transaction, en archives.
                  </p>
                  <h3 className="mt-0" id="droits">9. LES DROITS DE L'INTERNAUTE</h3>
                    <p className="paragraph__color terms__p">
                    L’Internaute dispose de plusieurs droits à valoir notamment :
                    <ul><br/>
                      <li classname="terms__li">●	droit d’accéder à ses données faisant l’objet d’un traitement ,</li>
                      <li classname="terms__li">●	droit de retirer son consentement à tout moment, sans remettre en cause le traitement mis en œuvre jusque-là et sous réserve que le retrait de consentement contreviennent à l’utilisation des Services en cours ,</li>
                      <li classname="terms__li">●	droit d’obtenir la rectification de données inexactes ou le complément de données incomplètes ,</li>
                      <li classname="terms__li">●	droit d’obtenir l’effacement de ses données après la suppression du Compte,</li>
                      <li classname="terms__li">● droit d’introduire une réclamation auprès de la Haute Autorité de Protection des Données Personnels ( HAPDP ).</li>
                    </ul><br/>
                    </p>
                  <h3 className="mt-0" id="sécurité">10. SÉCURITÉ</h3>
                  <p className="paragraph__color terms__p">
                    L’Editeur s’engage à faire ses meilleurs efforts pour protéger vos données à caractère personnel, afin notamment d’empêcher qu’elles soient déformées, endommagées ou communiquées à des tiers non autorisés.
                  </p>
                  <h3 className="mt-0" id="reclamation">11. RÉCLAMATION</h3>
                  <p className="paragraph__color terms__p">
                    Vous pouvez soumettre une déclaration si vous estimez que vos droits ne sont pas respectés à l’adresse e-mail de Kamamini :
                    support@kamamini.net
                  </p>
      </div>
    </div>
  </div>
</>
    
  )
}

export default PrivacyPolicy