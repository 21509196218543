import React from 'react';
import ConnectionIcon from './connection-icon.svg';

const ConnectionError = () => {
  return (
    <div style={{ backgroundColor: 'white', textAlign: 'center', padding: '20px' }} className='py-5' >
      {/* <img src={ConnectionIcon} alt="Icône de problème de connexion" style={{ width: '96px', height: '96px', fill: '#02b663' }} /> */}
      <h1 style={{ color: 'black' }}>Oops, il y a un problème de connexion !</h1>
      <p className='mb-5' style={{ color: 'black' }}>Vérifiez votre connexion internet et réessayez.</p>
    </div>
  );
};

export default ConnectionError;
