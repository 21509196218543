import React from 'react'
import {makeStyles,Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => (
    // console.log(theme)
    {
    
      kamaminiColor:{
        backgroundColor: '#02b663',
        fontFamily: `"Poppins",sans-serif`,
        fontWeight:700,
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#02a056',
          },
      },
    
    }
    ));
  
const KamaminiButton = ({text,onClick,disabled,IconRight,IconLeft,type,fullWidth,CustomClasses,children,variant}) => {
    const classes = useStyles();

    return (
        <Button
         type={type}
         onClick={onClick}
         fullWidth={fullWidth}
         disabled={disabled}
         variant={variant || "contained"}
         className={`${classes.kamaminiColor} ${CustomClasses}`}
         color="primary"
         >
        {children}
        {IconLeft && <IconLeft fontSize="large"/>}
        {text}
        {IconRight && <IconRight fontSize="large"/>}
       
            
        </Button>
      
    )
}

export default KamaminiButton
