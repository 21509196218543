import React from 'react'
import { Link } from 'react-router-dom'
import '../../../assets/css/bigCard.css'

const BigCard = ({ projects }) => {
    return (
        <div className='card text-align-center justify-content-center bort wow fadeInUp2 animated' style={{ border: ' 0.1px solid', width: 'auto', height: 'auto', borderRadius: '', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
            {
                projects.map((project) => (
                    <React.Fragment key={project.id}>
                        <div className='row d-flex align-items-center justify-content-space-between'>
                            <div className='col-xs-12 col-md-4 kor custom-project-card'>
                                <Link to={'/projects/' + project.slug} >
                                    <img
                                        src={project.featuredImage}
                                        alt={project.title}
                                        className='ml-3 mt-3   kor' />
                                </Link>
                            </div>
                            <div className='col-xs-12 col-md-8   letext'>
                                <div className='d-block mt-4'>
                                    <p className='text-muted'><i className="fas fa-map-marker-alt"></i> {project.location}</p>
                                    <Link to={'/projects/' + project.slug} >
                                        <h5 className="lead display-5 font-weight-bold ">{project.title} </h5>
                                    </Link>
                                    <div>
                                        <p className='mt-3 text-bold'>
                                            <span style={{ color: '#02b663' }}>
                                                {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(project.moneyRaised)}
                                            </span>
                                            {parseInt(project.moneyRaised) > 1 ? '  collectés' : '  collecté'}
                                        </p>
                                        <div className="progress letexte mt-4 " style={{ height: '7px', width: '90%' }} >
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: project.moneyRaised * 100 / parseInt(project.moneyRequest) + '%' }}
                                                aria-valuenow={project.moneyRaised * 100 / parseInt(project.moneyRequest)}
                                                aria-valuemin="0" aria-valuemax="100">
                                                <h6>{parseInt(project.moneyRaised * 100 / parseInt(project.moneyRequest))}%</h6>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />
                    </React.Fragment>
                ))
            }

            <div className='text-center pb-3'>
                <Link to='/all-projects' style={{ textDecoration: 'underline' }}> voir tous les projets</Link>
            </div>
        </div>


    )
}

export default BigCard