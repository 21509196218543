import React,{useEffect,useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'
import { Grid, Container, CssBaseline, Box, CircularProgress,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import {fetchUserBlogs,deleteBlog, allBlogs} from '../../../../redux/actions/blogActions'
import {frenchSittings} from '../../../../redux/actions/actions'
import Button from "../../components/CustomButtons/Button.js";


const Step1 = ({setSingleBlog,nextStep}) => {

    moment.locale('fr',frenchSittings());
    const dispatch= useDispatch();
    const userInfo = useSelector(state => state.auth.userInfo)
    const userBlogs = useSelector(state =>state.blogs.fetchUserBlog);
    const getAllBlogs = useSelector(state =>state.blogs.getAllBlogs)

       useEffect(() => {
        window.scrollTo(0, 0)
        // if user is logged in, fetch user blogs
        if((userInfo && userInfo.role === 'Admin' )|| ( userInfo && userInfo.role === 'Staff')  ){
            dispatch(allBlogs())
        }
        else if(userInfo && userInfo.role === 'Editor'){
            dispatch(fetchUserBlogs(userInfo.uid))
        }

    }, [dispatch,userInfo])

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleCloseDialog = () => {
        setOpen(false);
      };

    const handleEditBlog = (blog) => {
        setSingleBlog(blog)
        nextStep()
    }
    const  handleDeleteBlog = (id) =>{
        dispatch(deleteBlog(id,userInfo.uid))
        setOpen(false);
    }


    if(userBlogs){
        return (
            <>
                <CssBaseline/>
                <Container>
                    <Box mb={5}></Box>
                    <Grid container spacing={2}>
                         {
                           userBlogs.map(blog =>{ 
                                 return(
                                     <Grid container item xs={12} sm={6} md={6} lg={4} key={blog.id} >
                                         <div className="blogs mb-30">
                                            <div className="blogs__thumb pos-rel mb-45">
                                            <div className="blogs__thumb--img" > 
                                                <img src={blog.urlImage} alt={blog.title} />
                                            </div>
                                                <span className="blog-tag" >{blog.category}</span>
                                            </div>
                                            <div className="blogs__content">
                                                <div className="blogs__content--meta mb-15">
                                                    <span><i className="far fa-calendar-alt"></i> {moment(blog.timestamp.toDate()).format("DD MMM  YYYY")} </span>
                                                    <span><i className="far fa-comment"></i> ({blog.acceptedComments.length}) Commentaires</span>
                                                </div>
                                                <h4 className="mb-20">  {blog.title} </h4>
                                                <Button color="success" onClick={()=> handleEditBlog(blog)} >Modifier</Button>
                                                <Button color="danger" onClick={()=> handleClickOpen()} >Supprimer</Button>

                                                <Dialog
                                                    fullScreen={fullScreen}
                                                    open={open}
                                                    onClose={handleCloseDialog}
                                                    aria-labelledby="responsive-dialog-title"
                                                >
                                                    <DialogTitle id="responsive-dialog-title">{"Supprimer ce blog"}</DialogTitle>
                                                    <DialogContent>
                                                    <DialogContentText>
                                                    Vous voulez vraiment suprimer ce blog ? <br/>
                                                    Cette action est définitive et irréversible. Vous perderz toutes les informations relatives à ce blog
                                                    </DialogContentText>
                                                
                                           


                                                    </DialogContent>
                                                    <DialogActions>
                                                    <Button autoFocus onClick={()=> handleDeleteBlog(blog.id)} color="success">
                                                        Confirmer
                                                    </Button>
                                                    <Button onClick={handleCloseDialog} color="danger" autoFocus>
                                                        Annuler
                                                    </Button>
                                                    </DialogActions>
                                                </Dialog>

                                            </div>
                                        </div>

                                     </Grid>
                                 )
                             })
                         }
                     </Grid>


                </Container>

            </>

        )

    } 
    else if(getAllBlogs){
        return (
            <>
                <CssBaseline/>
                <Container>
                    <Box mb={5}></Box>
                    <Grid container spacing={2}>
                         {
                           getAllBlogs.map(blog =>{ 
                                 return(
                                     <Grid container item xs={12} sm={6} md={6} lg={4} key={blog.id} >
                                         <div className="blogs mb-30">
                                            <div className="blogs__thumb pos-rel mb-45">
                                            <div className="blogs__thumb--img" > 
                                                <img src={blog.urlImage} alt={blog.title} />
                                            </div>
                                                <span className="blog-tag" >{blog.category}</span>
                                            </div>
                                            <div className="blogs__content">
                                                <div className="blogs__content--meta mb-15">
                                                    <span><i className="far fa-calendar-alt"></i> {moment(blog.timestamp.toDate()).format("DD MMM  YYYY")} </span>
                                                    <span><i className="far fa-comment"></i> ({blog.acceptedComments.length}) Commentaires</span>
                                                </div>
                                                <h4 className="mb-20">  {blog.title} </h4>
                                                <Button color="success" onClick={()=> handleEditBlog(blog)} >Modifier</Button>
                                                <Button color="danger" onClick={()=> handleClickOpen()} >Supprimer</Button>

                                                <Dialog
                                                    fullScreen={fullScreen}
                                                    open={open}
                                                    onClose={handleCloseDialog}
                                                    aria-labelledby="responsive-dialog-title"
                                                >
                                                    <DialogTitle id="responsive-dialog-title">{"Supprimer ce blog"}</DialogTitle>
                                                    <DialogContent>
                                                    <DialogContentText>
                                                    Vous voulez vraiment suprimer ce blog ? <br/>
                                                    Cette action est définitive et irréversible. Vous perderz toutes les informations relatives à ce blog
                                                    </DialogContentText>
                                                
                                           


                                                    </DialogContent>
                                                    <DialogActions>
                                                    <Button autoFocus onClick={()=> handleDeleteBlog(blog.id)} color="success">
                                                        Confirmer
                                                    </Button>
                                                    <Button onClick={handleCloseDialog} color="danger" autoFocus>
                                                        Annuler
                                                    </Button>
                                                    </DialogActions>
                                                </Dialog>

                                            </div>
                                        </div>

                                     </Grid>
                                 )
                             })
                         }
                     </Grid>


                </Container>

            </>
        )
    } 
    else{
        return(
            <div id="preloader">
                <div className="preloader">
                <CircularProgress style={{color:'green'}} />
                </div>
            </div>
          )
    }
}

export default Step1
