import React from 'react'
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton';
import {Link, NavLink} from 'react-router-dom'
// import A1 from '../../assets/img/abc/01.png'
// import A2 from '../../assets/img/abc/02.png'
// import A4 from '../../assets/img/abc/04.png'
 const Works1 = () => {
    return (
        <div className='my-3 pt-5 justify-content-center align-items-center mb-0' style={{height:'60vh'}}>
          <div className='d-fex justify-content-center justify-content-space-between pt-3'>
          <div className='row justify-content-center'>
            <div className='col-md-3'><p style={{fontSize:'9rem'}}><img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fabc%2F01.png?alt=media&token=9c38e619-fba2-4865-a18c-26945ffbaa56" style={{width:'35px'}}  /></p>
            </div>
            <div className='col-md-3'>
            <h5 className='display-6'>
                  1. Commencez votre collecte de fonds</h5>
                <ul className='list-unstyled'>
                  <li>
                    <ul>
                <li>Définissez votre objectif de collecte de collecte de fonds</li>
                <li>Ajouter une photo ou une vidéo</li>
                <li>Raconter votre histoire</li>
                <li></li>
                   </ul>
                </li>
                </ul>
            </div>
          </div>


          <div className='row justify-content-center'>
            <div className='col-md-3'>
            <p style={{fontSize:'9rem'}}><img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fabc%2F02.png?alt=media&token=eaa39f6c-e8d1-4bd0-b9d6-ae6df8ef300e" style={{width:'35px'}}  /></p>
            </div>
            <div className='col-md-3'>
            <h6> 
                2. Partagez avec vos amis </h6>
                <ul>
                <li>Envoyer des emails</li>
                <li>Envoyer des messages text</li>
                <li>Partager sur les réseaux sociaux</li>
                <li></li>
                </ul>
            </div>
          </div>


          <div className='row justify-content-center'>
            <div className='col-md-3'>
            <p style={{fontSize:'9rem'}}><img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fabc%2F04.png?alt=media&token=551a0f45-1fe5-4ab6-9856-a447ebe4f245" style={{width:'35px'}}  /></p>
            </div>
            <div className='col-md-3'> <h6> 
                3. Gérer les dons</h6>
                <ul>
                <li>Accepter les dons</li>
                <li>Remercier les donateurs</li>
                <li>Retirer des fonds</li>
                <li></li>
                </ul></div>
          </div>





          {/* <div className='d-fex justify-content-center justify-content-space-between pt-3'> */}
            <div className='row justify-content-center pt-3'>.

            <div className='col-md-3'>
                <h5 className='display-6'> <p style={{fontSize:'9rem'}}><img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fabc%2F01.png?alt=media&token=9c38e619-fba2-4865-a18c-26945ffbaa56" style={{width:'35px'}}  /></p>
                  1. Commencez votre collecte de fonds</h5>
                <ul className='list-unstyled'>
                  <li>
                    <ul>
                <li>Définissez votre objectif de collecte de collecte de fonds</li>
                <li>Ajouter une photo ou une vidéo</li>
                <li>Raconter votre histoire</li>
                <li></li>
                   </ul>
                </li>
                </ul>
            </div>

            <div className='col-md-3'>
                <h6> <p style={{fontSize:'9rem'}}><img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fabc%2F02.png?alt=media&token=eaa39f6c-e8d1-4bd0-b9d6-ae6df8ef300e" style={{width:'35px'}}  /></p>
                2. Partagez avec vos amis </h6>
                <ul>
                <li>Envoyer des emails</li>
                <li>Envoyer des messages text</li>
                <li>Partager sur les réseaux sociaux</li>
                <li></li>
                </ul>
            </div>

            <div className='col-md-3'>
                <h6> <p style={{fontSize:'9rem'}}><img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fabc%2F04.png?alt=media&token=551a0f45-1fe5-4ab6-9856-a447ebe4f245" style={{width:'35px'}}  /></p>
                3. Gérer les dons</h6>
                <ul>
                <li>Accepter les dons</li>
                <li>Remercier les donateurs</li>
                <li>Retirer des fonds</li>
                <li></li>
                </ul>
            </div>

            </div>
          </div>

          <section className='d-flex justify-content-center'>
            <NavLink to="/start-a-campaign">
                <KamaminiButton>COMMENCER UNE CAMPAGNE</KamaminiButton>
            </NavLink>
          </section>





        </div>
    )
}
export default Works1;
