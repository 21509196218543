import React,{useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { Grid, Container, CssBaseline, Box,  CircularProgress  } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";

import {getCommentedBlogs,addCommentsToAcceptedBlog,deleteCommentsToPendingBlog,} from '../../../../redux/actions/blogActions'

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
    },
  };
  
  const useStyles = makeStyles(styles);



const Affich = ({comments,getAllBlogs}) =>{
    const classes = useStyles();
    const dispatch= useDispatch()

    const writeComments = (comment) => {
        const blog = getAllBlogs &&  getAllBlogs.filter(blog => blog.id === comment.blogId)
        let newPendingComments = blog[0].pendingComments.filter(blog => blog !== comment)

        dispatch(addCommentsToAcceptedBlog(comment,comment.blogId,newPendingComments))

    }
    const deleteComments = (comment) => {
        const blog = getAllBlogs &&  getAllBlogs.filter(blog => blog.id === comment.blogId)
        let newPendingComments = blog[0].pendingComments.filter(blog => blog !== comment)

        dispatch(deleteCommentsToPendingBlog(comment.blogId,newPendingComments))

    }

return (
<>
{
    comments.map((comment,id)=> {
    return(
            
    <Grid container spacing={2} key={id} >

        <Grid container item xs={12} sm={12} md={12} lg={12}  >

            <div className="blogs mb-30">
        
        <div className="blogs__content">
            <div className="blogs__content--meta mb-15">
                <span><i className="far fa-comment"></i>  ({comments.length}) Commentaires </span>
            </div>
            <p  >ID : {comment.blogId} </p>
            <h4 className="mb-20">{comment.blogTitle}</h4>
            <ul className="project-manager mb-3">
               
                <li>
                    <span className="more_btn_02" >Commentaires : &nbsp;  {comment.text} </span> <br/> <br/>
                    <span className="more_btn_02" >Date : &nbsp; {comment.commentDate}   </span>
                </li>
            </ul>
            <div  className="d-flex justify-content-around">
                <Button color="success" onClick={()=> writeComments(comment)}  >Accepter</Button>
                <Button color="danger" onClick={()=> deleteComments(comment)} >Supprimer</Button>
            </div> 
        </div>
    </div>

    <GridItem xs={4} sm={4} md={4} lg={4}>
        <Card profile>
            <CardAvatar profile>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {
                        comment.userInfo.photoURL? 
                        <img src={comment.userInfo.photoURL} alt={comment.userInfo.displayName} />
                        : <h3>{comment.userInfo.initials}</h3>
                    }
                    
                </a>
            </CardAvatar>
                <CardBody profile>
                <h4 className={classes.cardCategory}>{comment.userInfo.displayName} </h4>
                <h6 className={classes.cardTitle}>{comment.userInfo.email} </h6>

                <p className={classes.description}>
                    {comment.userInfo.uid}
                </p>
                <p className={classes.description}>
                    {comment.userInfo.initials}
                </p>
                
                </CardBody>
                </Card>
            </GridItem>
        </Grid>
    </Grid>
        )
    })
}
</>

    )
}

const PendingComments = () => {

    const dispatch= useDispatch()
    const getAllBlogs = useSelector(state =>state.blogs.commentedBlogs)

    useEffect(() => {
        dispatch(getCommentedBlogs())
    }, [dispatch])

    const pendingComments = getAllBlogs &&  getAllBlogs.map(blog => blog.pendingComments).filter(String);

     if(pendingComments){
        return (
            <>
                <CssBaseline/>
                <Container>
                    <Box mb={5}></Box>
                        {
                            pendingComments.map((comments,index) =>{
                                return <Affich comments={comments} getAllBlogs={getAllBlogs}  key={index} />
                                
                            })
                        }
                </Container>
            </>
        )
     }
     else{
        return(
            <div id="preloader">
                <div className="preloader">
                <CircularProgress style={{color:'green'}} />
                </div>
            </div>
          )
     }

    
}

export default PendingComments
