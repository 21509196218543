import React, { useEffect, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async';
// import routing component from react-router-dom
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
// import components and pages 
import NavBar2 from './Components/NavBars/NavBar2/NavBar2'
import Footer1 from './Components/Footers/Footer1/Footer1'
import Home from './Pages/Home'
import Login from './Pages/Login'
import SignUp from './Pages/SignUp'
// import AllCardsExemples from './Components/Cards/CardExemples/AllCardsExemples'
import SingleProject from './Pages/SingleProject'
import ForgotPassword from './Pages/ForgotPassword'
import Error from './Pages/Error'
import AllProjects from './Pages/AllProjects'
import AllBlogs from './Pages/AllBlogs'
import SingleBlog from './Pages/SingleBlog'
import Contact from './Pages/Contact'
import StartCampaign from './Pages/StartCampaign'
import EditDashboard from './Pages/EditDashboard'
import UserDashboard from './Pages/UserDashboard'
import Profile from './Pages/Profile'
import Settings from './Pages/Settings'
import Admin from "./Components/Admin/layouts/Admin.js";
// import Team from "./Pages/Team.js";
import About from "./Pages/About.js";
import Faq from "./Pages/Faq.js";
import Checkout from "./Pages/Checkout.js";
import EditProject from "./Pages/EditProject.js";
import PrivacyPolicy from "./Pages/PrivacyPolicy.js";
import TermsAndConditions from "./Pages/TermsAndConditions.js";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import { getUser } from './redux/actions/authActions'
import { useSelector, useDispatch } from "react-redux"
import SplashScreem from './Components/SplashScreem/SplashScreem';
import HowItworks from './Pages/HowItworks.js'
import NewsLetter from './Pages/NewsLetter.js'
import Trust from './Pages/Trust.js'
import Honest from './Pages/Honest.js'




const App = () => {
  const dispatch = useDispatch()
  const waitAuthCheck = useSelector(state => state.auth.waitAuthCheck)
  const [sticky, setSticky] = useState(true)


  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])



  if (waitAuthCheck) {
    return (<SplashScreem />)
  } else {
    return (
      <HelmetProvider>
        <Router>
          <NavBar2 sticky={sticky} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/contact" component={Contact} />
            {/* <Route exact path="/team" component={Team} /> */}
            <Route exact path="/about" component={About} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/howitworks" component={HowItworks} />
            <Route exact path="/trust" component={Trust} />
            <Route exact path="/honest" component={Honest} />
            <Route exact path="/newsletter" component={NewsLetter} />
            <Route exact path="/terms-conditions">
              <TermsAndConditions setSticky={setSticky} />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy setSticky={setSticky} />
            </Route>
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/all-projects"  >
              <AllProjects setSticky={setSticky} />
            </Route>
            <Route exact path="/all-blogs" component={AllBlogs} />
            <Route exact path="/user-profile" component={UserDashboard} />
            <Route exact path="/edit-profile" component={EditDashboard} />
            <Route exact path="/profile/:slug" component={Profile} />
            <Route exact path="/settings" component={Settings} />
            <Route path="/admin" component={Admin} />
            <Route exact path="/start-a-campaign" component={StartCampaign} />
            <Route exact path="/projects/:slug" component={SingleProject} />
            <Route exact path="/:slug/checkout" component={Checkout} />
            <Route exact path="/:slug/edit-project" component={EditProject} />
            <Route exact path="/blogs/:slug" component={SingleBlog} />
            <Route component={Error} />
          </Switch>
          <Footer1 />
          <ScrollToTop />
        </Router>
      </HelmetProvider>
    )
  }








}

export default App
