import React, { useEffect, useState } from 'react'
import {
  Container,
  Grid,
  // makeStyles,
  Box,
  CssBaseline
} from '@material-ui/core';
import { Prompt } from 'react-router'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ReactPlayer from "react-player/youtube";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import AccordionComponent from '../Accordion/Accordion'
import DisplayReward from '../Cards/RewardCard/DisplayReward'
import { createProject } from '../../redux/actions/projectActions'
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'
import TextDialog from '../Dialog/TextDialog'
// import verifie from '../../assets/img/verifie.png';




// const useStyles = makeStyles((theme) => (
//     {
//       root: {
//           width: '100%',
//         },
//     }
//   ));


const FinalStep = (props) => {
  // const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.auth.userInfo)
  const createProjectError = useSelector(state => state.projects.createProjectError)
  let history = useHistory();
  const { width, height } = useWindowSize()

  const {
    prevStep,
    projectData,
    imageUrl,
    youtubeUrl,
    imageDropZoneUrl,
    wysiwygData,
    faq,
    perk,
    moneyRequest
  } = props

  const [open, setOpen] = useState(false);
  const [run, setRun] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [sending, setSending] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setRun(true);
    setPrompt(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const project = {
    title: projectData.title,
    tagline: projectData.tagline,
    location: projectData.location,
    featuredImage: imageUrl[0],
    category: projectData.category,
    duration: parseInt(projectData.duration),
    moneyRequest: parseInt(moneyRequest),
    youtubeVideos: youtubeUrl,
    images: imageDropZoneUrl,
    projectText: wysiwygData,
    faq: faq,
    perk: perk,
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSending(true)
    window.scrollTo(0, 0)
    dispatch(createProject(project, userInfo, handleClickOpen))
    if (createProjectError) {
      setSending(false)
    } else {
      setTimeout(() => {
        history.push("/");
      }, 10000)
    }
  }



  const Back = (e) => {
    e.preventDefault();
    prevStep()
  }

  return (
    <>
      {
        !prompt &&
        <Prompt
          // when={shouldBlockNavigation}
          message='Etes-vous sûr de vouloir quitter cette page ? Les données que vous avez entrées ne seront pas sauvegardées.'
        />
      }
      <Box mb={5}></Box>
      <Container>
        <ToastContainer />
        <CssBaseline />
        <Confetti
          width={width}
          height={height}
          run={run}
        />
        <Grid container direction="column" justifycontent="center" alignItems="center" >
          <h2>Resumé de votre projet </h2>
          <br />
          <br />
        </Grid>

        <Grid item xs={7} sm={7} md={7} lg={7} >
          <h4>Titre du projet : &nbsp; {projectData.title}  </h4>
          <hr />
          <h4>Slogan du projet : &nbsp; {projectData.tagline ? projectData.tagline : "Vous n'avez pas ajouté de slogan"}  </h4>
          <hr />
          <h4>Lieu du projet : &nbsp; {projectData.location}  </h4>
          <hr />
          <h4>Image mise en avant   &nbsp;  </h4>
          <br />
          <img className="img-thumbnail" src={imageUrl[0]} alt={projectData.title} />
          <hr />
          <h4>Categorie du projet : &nbsp; {projectData.category}  </h4>
          <hr />
          <h4>Durée de la campagne : &nbsp; {projectData.duration} jours </h4>
          <hr />
          <h4>Objectif de financement : &nbsp; {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(moneyRequest)} </h4>
          <hr />
          <h4>Vidéo de Présentation du projet  </h4>
          <br />

          {
            youtubeUrl.length > 0 ?
              youtubeUrl.map((video, index) => {
                return (
                  <Grid item xs={8} sm={8} md={8} lg={8} key={index} className='mb-5' >
                    <ReactPlayer controls url={video} />
                    <br />
                  </Grid>

                )

              }) :
              <h5>Vous n'avez pas ajouté de video </h5>
          }
          <hr />

          <h4>Image de Présentation du projet  </h4>
          <br />

          <Grid container spacing={2}>

            {
              imageDropZoneUrl.length > 0 ?
                imageDropZoneUrl.map((imageUrl, index) => {
                  return (
                    <Grid container item xs={12} sm={6} md={4} lg={3} key={index}   >
                      <img className="img-fluid small rounded mx-auto d-block" src={imageUrl} alt="Kamamini project's" />
                    </Grid>
                  )
                })
                : <h5 className="px-3" >Vous n'avez pas ajouté d'image </h5>
            }

          </Grid>

          <hr />

          <h4>description du projet  </h4>
          <br />
          <div style={{ overflowY: 'scroll' }} className="kamamini_editor_container p-3" >
            {ReactHtmlParser(wysiwygData)}
          </div>
          <hr />

          <h4>Foires aux questions (FAQ) : </h4>
          <br />
          {
            faq.length > 0 ?
              faq.map((faq) => {
                return (
                  <AccordionComponent key={faq.id} question={faq.question} response={faq.response} />
                )
              })
              : <h5>Vous n'avez pas ajouté de foires aux questions </h5>
          }

          <hr />

          <h4>Récompense : </h4>
          <br />

          <Grid container spacing={2}>
            {
              perk.length > 0 ?
                perk.map((perk) => {
                  return (
                    <Grid container key={perk.id} item xs={12} sm={8} md={6} lg={6} >
                      <DisplayReward
                        title={perk.title}
                        img={perk.rewardImage}
                        price={perk.price}
                        description={perk.description}
                        quantity={perk.quantity}
                      />
                    </Grid>

                  )
                })
                : <h5 className='px-3' >Vous n'avez pas ajouté de récompense</h5>
            }
          </Grid>

        </Grid>

        <br />
        <br />
        <Grid item xs={12} sm={12} md={12} lg={12} >
          <div className="d-flex justify-content-between">
            <KamaminiButton text='Précédent' IconLeft={ArrowLeftIcon} onClick={Back} />
            <KamaminiButton text='Envoyer' disabled={sending} onClick={(e) => handleSubmit(e)} />
          </div>
        </Grid>
        <Box mb={5}></Box>

         <TextDialog 
          image={"url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fverifie.png?alt=media&token=1fba6d54-4667-44df-829d-175700f59322"+")"}
          title="Votre projet à été envoyé avec succès" 
          contentText={'Veuillez consulter votre adresse email' }  
          handleClose={handleClose}
          open={open}
          height="100px"
          width="100px" />
      </Container>
    </>
  )
}

export default FinalStep
