import React,{useEffect} from 'react'
import {
    Container,
    Grid,
    Box,
    CssBaseline,
    Button,
  } from '@material-ui/core';
  import { Prompt } from 'react-router';
  import { makeStyles } from '@material-ui/core/styles';
  import { ToastContainer } from 'react-toastify';
  import DeleteIcon from '@material-ui/icons/Delete';
  import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'
  import ArrowRightIcon from '@material-ui/icons/ArrowRight';
  import { Formik } from 'formik';
  import { array, object, string } from 'yup';
  import {errorToast} from '../../redux/actions/actions';
  import {OneFileUploadField} from '../Upload/OneFileUploadField'



  const useStyles = makeStyles((theme) => (
    {
      root: {
          width: '100%',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
        },
        image: {
          '& > *': {
            margin: theme.spacing(1),
          },
        },
        input: {
          display: 'none',
        },
    }
  ));

 const StepOne = (props) =>{
    // const { register, handleSubmit, watch, formState: { errors } } = useForm();
    // console.log(watch("example"));
    const classes = useStyles();

    useEffect(() => {
      window.scrollTo(0, 0)
     
    }, [])

    const {
      nextStep,
      imageUrl,
      CustumStepper,
      handleChange,
      projectData,
      featuredImgRequired,
      setImageUrl,
      setFeaturedImgRequired,
      featuredImageUrl,
      setFeaturedImageUrl
      
    } =props

    const handleSubmit = (e) => {
      e.preventDefault() 

      if(projectData.title.match(/^\s*$/)){
        errorToast('Veuillez entrer le titre du projet')
      }
      else if (projectData.location.match(/^\s*$/)){
        errorToast('Veuillez sélectionner le lieu du projet')
      }
      else if(featuredImgRequired ){
          errorToast('Veuillez choisir une image de présentation')
      }
      else if(projectData.category.match(/^\s*$/)){
        errorToast('Veuillez sélectionner la catégorie du projet')
      }
      else if(projectData.duration.toString().match(/^\s*$/) || projectData.duration <5 || projectData.duration > 90){
        errorToast('La durée de la campagne doit être comprise entre 5 et 90 jours pour chaque projet')
      }
      else{
        if (imageUrl.length > 0){
          for (let i = 0; i < imageUrl.length; i++) {
            setFeaturedImageUrl([imageUrl[i].url ])
        }}
        setImageUrl([])
        nextStep()
      }
    }

    const deleteImage = () => {
      setImageUrl([])
    }

    const deleteFeaturedImageUrl = () => {
      setFeaturedImageUrl([])
    }
    // transform featuredImgRequired to false if there is an image 
    useEffect(() => {
      if((imageUrl.length > 0 && imageUrl[0].url) || (featuredImageUrl.length > 0 && featuredImageUrl[0] )){
        setFeaturedImgRequired(false)
      }
      else{
        setFeaturedImgRequired(true)
      }

    }, [imageUrl,featuredImageUrl])

    return(
    <> 
            <Prompt
      // when={shouldBlockNavigation}
          message='Etes-vous sûr de vouloir quitter cette page ? Les données que vous avez entrées ne seront pas sauvegardées.'
        />
        <ToastContainer />
    
      <CustumStepper/>
      <Box mb={5}></Box>
      <Container>
            <CssBaseline/>

        <form className={classes.form}  onSubmit={handleSubmit} >

            <Grid container spacing={2}>
               <Grid item xs={12} sm={12} md={10} lg={10} >
                    <div className="kamamini-input" >
                      <h5 htmlFor="title">Titre du projet <span className='star'>*</span> </h5>
                      <p className="note mb-2">Mettez le titre du projet ici</p>

                      <div className="input-group input-group-lg">
                          <input type="text" value={projectData.title} onChange={handleChange}  required className="form-control" id="title" aria-describedby="basic-addon3" />
                      </div>
                    </div>
                    <p className=" mb-3"></p>

               </Grid>


               <Grid item xs={12} sm={12} md={10} lg={10} >
               <div className="kamamini-input" >
                    <h5 htmlFor="tagline">Slogan du projet </h5>
                    <p className="note mb-2">Fournissez une courte description qui décrit le mieux votre projet à votre public.</p>
                    <div className="input-group ">
                        <textarea  value={projectData.tagline} onChange={handleChange} className="form-control" id="tagline"  aria-label="With textarea"></textarea>
                    </div>
               </div>
               <p className=" mb-3"></p>     
               
               </Grid>


               <Grid item xs={12} sm={12} md={10} lg={10} >
              
                    <h5 >Lieux <span className='star'>*</span> </h5>
                    <p className="note mb-2">Choisissez le lieu où vous lancez le projet. (Ce lieu sera visible sur la page de votre campagne pour que votre public puisse le voir.)</p>
                  <div className="kamamini-input">
                    <div className="input-group">
                          <div className="input-group-prepend">
                              <label className="input-group-text k_green" htmlFor="location">Regions</label>
                          </div>
                              <select className="custom-select" value={projectData.location} onChange={handleChange} id="location" required={true}>
                              <option value='' >Aucune</option>
                              <option value="Agadez" >Agadez</option>
                              <option value="Diffa">Diffa</option>
                              <option value="Dosso">Dosso</option>                       
                              <option value="Maradi">Maradi</option>                  
                              <option value="Niamey">Niamey</option>
                              <option value="Tahoua">Tahoua</option>
                              <option value="Tillabery">Tillabery</option>
                              <option value="Zinder">Zinder</option>                  
                          </select>
                      </div>
                  </div>
                  <p className=" mb-3"></p>  

               
               </Grid>


                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={10} lg={10} >
                      <h5 >Image principale <span className='star'>*</span> </h5>
                      <p className="note mb-2">Importer une image  qui représente votre campagne.</p>
                    </Grid>
                </Grid>

                <Formik
                  initialValues={{ files: [] }}
                  validationSchema={object({
                    files: array(
                      object({
                        url: string().required(),
                      })
                    ),
                  })}
                >
              {() => (
                <>
                  <Grid className='pb-4 pt-2' container spacing={2} direction="column">
                      <OneFileUploadField 
                        name="files" 
                        files={imageUrl} 
                        setFiles={setImageUrl}
                      />
                  </Grid>

                  <Grid container spacing={2}>

                      {
                        imageUrl.length >0 && imageUrl[0].url ?


                            <Grid container item  xs={12} sm={6} md={4} lg={3}   >
                              <Grid item>
                                  <img className="img-fluid small rounded mx-auto d-block"  src={imageUrl[0].url} alt="Kamamini project's image" />
                              </Grid>
                               
                                  <Grid item className='px-3'>
                                      <Button className="bg-danger text-white"  size="small" 
                                        onClick={() => deleteImage()}>
                                        <DeleteIcon fontSize="small"  /> 
                                    </Button>
                                  </Grid>
                            </Grid>
                            
                          
                        :  null
                      }

                  </Grid>

                  <Grid container spacing={2}>

                      {
                        featuredImageUrl.length >0  ?


                            <Grid container item  xs={12} sm={6} md={4} lg={3}   >
                              <Grid item>
                                  <img className="img-fluid small rounded mx-auto d-block"  src={featuredImageUrl} alt="Kamamini project's image" />
                              </Grid>
                               
                                  <Grid item className='px-3'>
                                      <Button className="bg-danger text-white"  size="small" 
                                        onClick={() => deleteFeaturedImageUrl()}>
                                        <DeleteIcon fontSize="small"  /> 
                                    </Button>
                                  </Grid>
                            </Grid>
                            
                          
                        :  null
                      }

                  </Grid>
                </>
              )}
            </Formik>

                <Grid item xs={12} sm={12} md={10} lg={10} >
                      <h5 >Categorie <span className='star'>*</span> </h5>
                      <p className="note mb-3">Pour aider les contributeurs à trouver votre campagne, sélectionnez une catégorie qui représente le mieux votre projet.</p>

                    <div className="kamamini-input mb-3" >
                      <div className="input-group">
                          <div className="input-group-prepend">
                              <label className="input-group-text k_green" htmlFor="category">Choisissez</label>
                          </div>
                              <select className="custom-select" value={projectData.category} onChange={handleChange} id="category" required={true}>
                              <option value='' >Aucune</option>
                              <option value="Agroalimentaire" >Agroalimentaire</option>
                              <option value="Education">Education</option>                       
                              <option value="Santé">Santé</option>                  
                              <option value="Entreprenariat">Entreprenariat</option>
                              <option value="Autres">Autres</option>                  
                          </select>
                      </div>
                    </div>
                </Grid>
                <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                <h5 >Durée de la campagne <span className='star'>*</span> </h5>
                        <p className="note">Définissez le nombre de jours pendant lequel votre projet va recevoir un financement <br/> NB : La durée maximale est de 90 jours (3 mois)  </p>
                </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} >
                      <div className="kamamini-input" >
                       
                        <div className="input-group input-group-lg">
                            <input type="number" value={projectData.duration} onChange={handleChange} min={5} max={90}  required className="form-control" id="duration" aria-describedby="basic-addon3" />
                        </div>
                      </div>
                    </Grid>
                </Grid>
                

            </Grid>


              <br/>
              <br/>
         

            <Grid  item xs={12} sm={12} md={12} lg={12} >
              <div  className="d-flex justify-content-end">
                <KamaminiButton text={'Suivant'} IconRight={ArrowRightIcon} type='Submit' />
                {/* <KamaminiButton text={'Suivant'} IconRight={ArrowRightIcon} onClick={ () => nextStep()} /> */}
              </div>
            </Grid>


            </form>
            <Box mb={5}></Box>
        </Container>

           
              
            
            
    </>
    )
  }
  export default StepOne