import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer } from 'react-toastify';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { updateUserEmail, updateUserPassword, deleteUserFromFirebaseAuthAndFirestore, deleteFacebookAndGoogleUser, verifyError } from '../redux/actions/authActions';
import { errorToast } from '../redux/actions/actions';
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton';
import GoogleSignInButton from '../Components/Buttons/GoogleSignInButton/GoogleSignInButton'
import FacebookSignInButton from '../Components/Buttons/FacebookSignInButton/FacebookSignInButton'
import { FirebaseContext } from '../Firebase/Firebase'
import Seo from '../Components/Helmet/Seo';



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const Settings = () => {

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  const { auth, firebaseApp } = useContext(FirebaseContext)
  const classes = useStyles();

  // google and facebook authentiications variables
  let googleProvider = new firebaseApp.firebase_.auth.GoogleAuthProvider();
  let facebookProvider = new firebaseApp.firebase_.auth.FacebookAuthProvider();

  let history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.auth.userInfo);
  const updateUserEmailError = useSelector(state => state.auth.updateUserEmailError);

  if (!userInfo) {
    history.push("/");
  }


  const [newEmail, setNewEmail] = useState({
    password: "",
    newEmail: ""
  });
  const emailPasswordData = {
    password: '',
  };
  const emailPasswordValidationSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Mot de passe trop court').required('Le mot de passe est requis'),
  })

  const changePasswordData = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };
  const changePasswordValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().min(8, 'Mot de passe trop court').required('Le mot de passe est requis'),
    newPassword: Yup.string().min(8, 'Mot de passe trop court').required('Le mot de passe est requis'),
    confirmNewPassword: Yup.string().min(8, 'Mot de passe trop court').required('Le mot de passe est requis'),
  })

  const [deleteAccountPassword, setDeleteAccountPassword] = useState('');

  // Show and hide old password states and functions
  const [showEmailPassword, setShowEmailPassword] = useState(false)
  const handleClickShowEmailPassword = () => { setShowEmailPassword(!showEmailPassword); };
  const handleMouseDownEmailPassword = (event) => { event.preventDefault(); };
  // Show and hide old password states and functions
  const [showOldPassword, setShowOldPassword] = useState(false)
  const handleClickShowOldPassword = () => { setShowOldPassword(!showOldPassword); };
  const handleMouseDownOldPassword = (event) => { event.preventDefault(); };
  // Show and hide new password states and functions
  const [showNewPassword, setShowNewPassword] = useState(false)
  const handleClickShowNewPassword = () => { setShowNewPassword(!showNewPassword); };
  const handleMouseDownNewPassword = (event) => { event.preventDefault(); };
  // Show and hide confirmation password states and functions
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false)
  const handleClickShowConfirmationPassword = () => { setShowConfirmationPassword(!showConfirmationPassword); };
  const handleMouseDownConfirmationPassword = (event) => { event.preventDefault(); };
  // Show and hide delete user password states and functions
  const [showDeleteUserPassword, setShowDeleteUserPassword] = useState(false)
  const handleClickshowDeleteUserPassword = () => { setShowDeleteUserPassword(!showDeleteUserPassword); };
  const handleMouseDeleteUserPasswordPassword = (event) => { event.preventDefault(); };

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteUser = () => {
    dispatch(deleteUserFromFirebaseAuthAndFirestore(userInfo.email, deleteAccountPassword, userInfo.uid))
    setDeleteAccountPassword('')
    setOpen(false);

  };

  const handleChangeUpdateEmail = (e) => {
    setNewEmail({ ...newEmail, [e.target.id]: e.target.value });
  };

  const google = () => {
    // check if the user has chosen his current google account by first connecting him to his account and delete it if it is the case
    auth.signInWithPopup(googleProvider)
      .then((result) => {
        // The signed-in user info.
        //  verify if it is the current user
        if (result.user.email === userInfo.email) {
          dispatch(deleteFacebookAndGoogleUser(result.user.uid))
        }
        else {
          // if it is not the current user, then delete the new user account
          dispatch({
            type: 'DELETE_USER_ERROR',
            email: userInfo.email,
          })
          auth.currentUser.delete()
        }

      })
      .catch((error) => {
        // Handle Errors here.
        const errorMsg = verifyError(error.code)
        if (errorMsg === null && error.message) {
          errorToast(error.message)
        }
        else {
          errorToast(errorMsg)
        }
      });

  }
  const facebook = () => {
    auth.signInWithPopup(facebookProvider)
      .then((result) => {
        // The signed-in user info.
        //  verify if it is the current user
        if (result.user.email === userInfo.email) {
          dispatch(deleteFacebookAndGoogleUser(result.user.uid))
        }
        else {
          // if it is not the current user, then delete the new user account
          dispatch({
            type: 'DELETE_USER_ERROR',
            email: userInfo.email,
          })
          auth.currentUser.delete()
        }

      })
      .catch((error) => {
        // Handle Errors here.
        const errorMsg = verifyError(error.code)
        if (errorMsg === null && error.message) {
          errorToast(error.message)
        }
        else {
          errorToast(errorMsg)
        }
      });
  }
  const handleChangeEmail = (values, SubmitProps) => {
    setTimeout(() => {
      SubmitProps.setSubmitting(false)
    }, 2000)
    if (values.password.match(/^\s*$/)) {
      errorToast('Veuillez entrer le mot de passe')
    }
    else if (newEmail.newEmail.match(/^\s*$/)) {
      errorToast('Veuillez entrer la nouvelle adresse email')
    }
    else {
      dispatch(updateUserEmail(userInfo.uid, newEmail.newEmail, values.password, userInfo.email))
      if (!updateUserEmailError) {
        SubmitProps.resetForm()
        setNewEmail({
          password: "",
          newEmail: ""
        });
      }
    }

  }

  const handleChangePassword = (values, SubmitProps) => {
    setTimeout(() => {
      SubmitProps.setSubmitting(false)
    }, 2000)
    // console.log(values);
    if (values.oldPassword.match(/^\s*$/)) {
      errorToast("Veuillez entrer l'ancien mot de passe")
    }
    else if (values.newPassword.match(/^\s*$/)) {
      errorToast('Veuillez entrer le nouveau mot de passe')
    }
    else if (values.confirmNewPassword.match(/^\s*$/)) {
      errorToast('Veuillez confirmer le mot de passe')
    }
    else if (values.confirmNewPassword !== values.newPassword) {
      errorToast('Les mots de passe de passe ne correspondent pas')
    }
    else {
      SubmitProps.resetForm()
      dispatch(updateUserPassword(values.oldPassword, userInfo.email, values.newPassword))
    }

  }


  if (userInfo) {

    return (
      <>
        <Seo
          title="Paramètres"
          description="Personnalisez votre expérience sur Kamamini. Gérez vos informations de profil et préférences de compte."
          name="Kamamini"
          type="website"
        />

        <ToastContainer />
        <div className="container border my-5" >
          <div className="row ">
            <div className="col-md-12 d-flex py-3 text-white" style={{ backgroundColor: '#02b663' }} >
              <i className="fas fa-cog fa-3x"></i>
              <h2 className='px-3 text-white' >Paramètres</h2>
            </div>
            <div className="col-md-6">
              <div className="container rounded bg-white mt-5 mb-5 p-3">
                <h3>Changer votre adresse email</h3>

                <Formik initialValues={emailPasswordData} validationSchema={emailPasswordValidationSchema}
                  onSubmit={handleChangeEmail}
                >
                  {(props) => (
                    <Form className={classes.form}>
                      <label className="labels">Adresse e-mail actuelle </label>
                      <div className="input-group-lg mb-3">
                        <input type="email" disabled className="form-control" value={userInfo.email} />
                      </div>
                      <label className="labels no-margin">Mot de passe</label>
                      <FormControl className={clsx(classes.form, 'mb-3')}
                        variant="outlined" required
                        autoComplete="current-password"
                        fullWidth
                        error={props.errors.password && props.touched.password}
                      >
                        <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                        <Field as={OutlinedInput}
                          id="password"
                          name='password'
                          // value={password}
                          // onChange={handleChange}
                          type={showEmailPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowEmailPassword}
                                onMouseDown={handleMouseDownEmailPassword}
                                edge="end"
                              >
                                {showEmailPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={110}
                        />
                        <FormHelperText id="outlined-weight-helper-text">{<ErrorMessage name="password" />}</FormHelperText>
                      </FormControl>

                      <label className="labels">Nouvel e-mail </label>
                      <div className="input-group-lg mb-4">
                        <input type="email" id='newEmail' required onChange={(e) => { handleChangeUpdateEmail(e) }} value={newEmail.newEmail} className="form-control" />
                      </div>
                      <KamaminiButton text={props.isSubmitting ? 'Chargement' : 'Enregistrer'} disabled={props.isSubmitting} type="submit" fullWidth />

                    </Form>
                  )}
                </Formik>


              </div>
            </div>

            <div className="col-md-6">
              <div className="container rounded bg-white mt-5 mb-5 p-3">
                <h3>Changer votre mot de passe</h3>
                <Formik initialValues={changePasswordData} validationSchema={changePasswordValidationSchema} onSubmit={handleChangePassword} >
                  {(props) => (
                    <Form className={classes.form}>
                      <label className="labels no-margin">Ancien mot de passe</label>
                      <FormControl className={clsx(classes.form, 'mb-2')}
                        variant="outlined" required
                        autoComplete="current-password"
                        fullWidth
                        error={props.errors.oldPassword && props.touched.oldPassword}
                      >
                        <InputLabel htmlFor="outlined-adornment-password">Ancien mot de passe</InputLabel>
                        <Field as={OutlinedInput}
                          id="oldPassword"
                          name='oldPassword'
                          // value={password}
                          // onChange={handleChange}
                          type={showOldPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownOldPassword}
                                edge="end"
                              >
                                {showOldPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={170}
                        />
                        <FormHelperText id="outlined-weight-helper-text">{<ErrorMessage name="oldPassword" />}</FormHelperText>
                      </FormControl>


                      <label className="labels no-margin">Nouveau mot de passe</label>
                      <FormControl className={clsx(classes.form, 'mb-2')}
                        variant="outlined" required
                        autoComplete="current-password"
                        fullWidth
                        error={props.errors.newPassword && props.touched.newPassword}
                      >
                        <InputLabel htmlFor="outlined-adornment-password">Nouveau mot de passe</InputLabel>
                        <Field as={OutlinedInput}
                          id="newPassword"
                          name='newPassword'
                          // value={password}
                          // onChange={handleChange}
                          type={showNewPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownNewPassword}
                                edge="end"
                              >
                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={180}
                        />
                        <FormHelperText id="outlined-weight-helper-text">{<ErrorMessage name="newPassword" />}</FormHelperText>
                      </FormControl>


                      <label className="labels no-margin">Confirmer le mot de passe</label>
                      <FormControl className={clsx(classes.form, 'mb-2')}
                        variant="outlined" required
                        autoComplete="current-password"
                        fullWidth
                        error={props.errors.confirmNewPassword && props.touched.confirmNewPassword}
                      >
                        <InputLabel htmlFor="outlined-adornment-password">Confirmer le mot de passe</InputLabel>
                        <Field as={OutlinedInput}
                          id="confirmNewPassword"
                          name='confirmNewPassword'
                          // value={password}
                          // onChange={handleChange}
                          type={showConfirmationPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmationPassword}
                                onMouseDown={handleMouseDownConfirmationPassword}
                                edge="end"
                              >
                                {showConfirmationPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={210}
                        />
                        <FormHelperText id="outlined-weight-helper-text">{<ErrorMessage name="confirmNewPassword" />}</FormHelperText>
                      </FormControl>
                      <KamaminiButton text={props.isSubmitting ? 'Chargement' : 'Enregistrer'} disabled={props.isSubmitting} type="submit" fullWidth />

                    </Form>
                  )}
                </Formik>

              </div>

            </div>

            <div className="col-md-12 d-flex  justify-content-end py-3 text-white"
              style={{ backgroundColor: '#D3D3D3' }}
            >

              <Button variant="contained" color="secondary" onClick={handleClickOpen}>
                Supprimer mon compte
              </Button>
            </div>

          </div>
        </div>




        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Supprimer votre compte"}</DialogTitle>
          <DialogContent>
            <DialogContentText className='text-dark'>
              Vous voulez vraiment nous quitter ?
              <br />
              <strong className='text-danger' >Cette action est définitive et irréversible. Vous perdrez toutes vos informations</strong>
              <br />
              <strong>Veuillez d'abord confirmer votre identité </strong>
            </DialogContentText>
            {
              userInfo.authProvider === 'google' ?
                <Grid container spacing={2} className='py-3' >
                  <Grid item xs={12} sm={10}  >
                    <GoogleSignInButton google={google} title="Supprimer avec google" />
                  </Grid>
                </Grid>
                : userInfo.authProvider === 'facebook' ?

                  <Grid container spacing={2} className='py-3' >
                    <Grid item xs={12} sm={10} >
                      <FacebookSignInButton title="Supprimer avec facebook" facebook={facebook} />
                    </Grid>
                  </Grid>

                  : userInfo.authProvider === 'email' &&

                  <FormControl
                    variant="outlined" required
                    fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                    <OutlinedInput
                      value={deleteAccountPassword}
                      onChange={(e) => { setDeleteAccountPassword(e.target.value); }}
                      type={showDeleteUserPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickshowDeleteUserPassword}
                            onMouseDown={handleMouseDeleteUserPasswordPassword}
                            edge="end"
                          >
                            {deleteAccountPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={110}
                    />
                  </FormControl>

            }






          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDeleteUser} color="primary">
              Confirmer
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              Annuler
            </Button>
          </DialogActions>
        </Dialog>


      </>
    )

  }

  else {
    return (
      <div id="preloader">
        <div className="preloader">
          <CircularProgress style={{ color: 'green' }} />
        </div>
      </div>
    )
  }
}

export default Settings
