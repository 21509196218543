import React,{useEffect} from 'react'

const ProjectFaq = ({faq,faqs,setFaq}) => {

  useEffect(() => {
      setFaq(faqs)

  }, [])
  


  return (
    !faq.length &&  <h4>Vous n'avez pas ajouté de foires aux questions (FAQ) </h4>
  )
}

export default ProjectFaq