import React, { useEffect, useState } from 'react'
import { useWindowScroll } from "react-use";
import '../assets/css/TermsAndConditions.css'
import Sticky from 'react-stickynode'
import Sticki from '../Components/sticky/Sticki'


const TermsAndConditions = ({setSticky}) => {
  /*
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  
    return (
      <div>Conditions d'utilisations</div>
    )
  }
  
  
  
  
  const PrivacyPolicy = ({setSticky}) => {
  
    useEffect(() => {
      window.scrollTo(0, 0)
      setSticky(false)
      return () => {
        setSticky(true)
      }
    }, [])
  
  const [ fix, setFix ] = useState(false)
  const { y: pageYOffset } = useWindowScroll();
  
  
  // function setFixedSidebar() {
  //   console.log(pageYOffset);
  // }
   
  useEffect(() => {
    if(pageYOffset >= 600 && pageYOffset <= 4200) {
      setFix(true)
    }
    else{
      setFix(false)
    }
    
  }, [pageYOffset,fix])
  
  // window.addEventListener("scroll", setFixedSidebar)
  */
  useEffect(() => {
    window.scrollTo(0, 0)
    setSticky(false)
    return () => {
      setSticky(true)
    }
  }, [])
  return (
    <div style={{marginBottom:'60vh'}} >

      <div className="raw py-5 bg-light pt-3 mb-5">
        <div className="premier pt-5 col-md-6">
          <h4 >Bienvenue sur Kamamini! </h4>
          <p className="font-size-15px paragraph__color mt-3">En utilisant ce site et les services, en plus des politiques supplémentaires applicables à des fonctionnalités particulières, du contenu et des fonctionnalités des services (intégrés dans les conditions par référence), proposés par Kamaminni, les utilisateurs acceptent d'être liés par ces conditions.
            Kamamini peut modifier les Conditions à tout moment à sa seule discrétion en publiant une version révisée des Conditions. Sauf indication contraire, l'accès ou l'utilisation continue des Services après la date d'entrée en vigueur des Conditions révisées constitue votre acceptation des Conditions révisées. Si nous apportons des modifications importantes à ces conditions, nous en informerons les utilisateurs en publiant un avis sur notre site, via les services ou par tout autre moyen que nous jugerons approprié.
          </p>
        </div>
        <div className="col-md-4 d-none d-md-block">
          <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fproject%2Fstatistics.png?alt=media&token=033629c7-ad00-44be-9d2f-9e81e39aef95" className="photo" alt="kamamini statistic" />
        </div>
      </div>

      <div className="raw">
        <div className="col-md-12 d-xl-none d-lg-none py-5">

          <h5 className="privé">Conditions d'utilisation</h5><br />
          <ul className='container paragraph__color py-3 li' >
            <li classname="terms__li"><a href="#intro">1.	Qu'est-ce que Kamamini ?</a></li><br />
            <li classname="terms__li"><a href="#identité">2.Obligations de l'utilisateur</a></li>
            <li classname="terms__li"><a href="#loi">3.Obligations du propriétaire de la campagne</a></li>
            <li classname="terms__li"><a href="#consentement">4.Soutenir une campagne</a></li>
            <li classname="terms__li"><a href="#methode">5.Le rôle de kamamini</a></li>
            <li classname="terms__li"><a href="#information">6.Notre propriété intellectuelle</a></li>
            <li classname="terms__li"><a href="#final">7.Votre propriété intellectuelle</a></li>
            <li classname="terms__li"><a href="#destinataire">8.Droits de kamamini concernant les comptes d'utilisateurs ; Résiliation</a></li>
            <li classname="terms__li"><a href="#données">9.Pannes et maintenance du système</a></li>
            <li classname="terms__li"><a href="#droits">10.Clause de non-responsabilité</a></li>
            <li classname="terms__li"><a href="#sécurité">11.Suppression de votre compte</a></li>
            <li classname="terms__li"><a href="#reclamation">12.Indemnisation</a></li>
            <li classname="terms__li"><a href="#reclamation">13.Nos frais</a></li>
          </ul>
        </div>
      </div>

      <div className="raw g-0 bg-light">
        <div className="col-md-4 d-none d-lg-block py-5">

          
          <Sticky>
            <Sticki />
          </Sticky>
        </div>
        <div className="border col-md-8">
          <h2 className='terms__h2' >Conditions d'utilisations</h2>
          <p className="paragraph__color terms__p"><strong>Date de mise à jour :</strong> 12 Décembre 2022</p>
        <div className="liste">
          <h3 id="quesceque">1.Qu'est-ce que Kamamini ?</h3>
          
            <p className="paragraph__color terms__p" >
              Kamamini est une plate-forme de financement participatif en ligne qui rassemble les utilisateurs pour soutenir des campagnes de financement participatif proposant des produits innovants, un design créatif et des entreprises inspirées. Il permet aux utilisateurs de lancer et de chercher à collecter des fonds pour leurs propres campagnes et de contribuer aux campagnes des autres. Les propriétaires de campagne peuvent offrir des avantages aux contributeurs en remerciement du don de fonds des contributeurs.
            </p><br /><br />
            <h3 className="mt-0" id="obligationUser">2.Toutes les obligations de l'utilisateur</h3><br />
            <p className="paragraph__color terms__p">
              Nous vous accordons une licence limitée, non exclusive, non transférable et révocable pour utiliser nos services, sous réserve des présentes conditions et des restrictions suivantes en particulier:
              </p>
              <ul><br />
                <li classname="terms__li">
                  ●	Vous êtes responsable de votre compte. Pour utiliser de nombreux Services, vous devrez vous inscrire et créer un compte avec un nom d'utilisateur et un mot de passe. Vous devez nous fournir des informations exactes et complètes, et vous devez mettre à jour les informations de votre compte au besoin pour que les informations restent exactes et complètes. Vous ne pouvez pas usurper l'identité de quelqu'un d'autre, choisir un nom d'utilisateur susceptible d'offenser quelqu'un ou de violer les droits de quiconque. Vous êtes seul responsable du maintien de la confidentialité de votre compte et de toutes les activités associées ou se produisant sous votre compte. Si vous soupçonnez ou découvrez une utilisation non autorisée de votre compte, vous devez en informer immédiatement kamamini en nous contactant à l'adresse <a href='ne.kamamini@gmail.com'>ne.kamamini@gmail.com</a> .

                  Nous ne sommes pas responsables de toute perte ou dommage résultant de votre non-respect des exigences ci-dessus ou résultant de l'utilisation de votre compte avec ou sans votre connaissance.

                </li>
                <li classname="terms__li">
                  ● Résiliation de votre compte. Vous pouvez soumettre une demande de résiliation de votre compte en nous contactant sur la page de contact. Cela ne supprimera pas automatiquement le contenu de l'utilisateur. Nous pouvons avoir besoin de conserver certaines informations tel que requis par la loi ou si nécessaire à nos fins commerciales légitimes. Ces conditions, y compris nos droits sur le contenu utilisateur, survivent à la résiliation d'un compte.
                </li>
                <li classname="terms__li">
                  ● Vous devez avoir 18 ans Vous devez avoir 18 ans ou plus pour ouvrir un compte et utiliser Nos Services.
                </li>
                <li classname="terms__li">
                  ● Respect nos loi et nos politiques. Vous ne pouvez prendre aucune mesure qui enfreint ou viole les droits d'autrui, viole la loi ou viole tout contrat ou obligation légale que vous pourriez avoir envers une partie. Vous ne pouvez pas offrir d'avantages illégaux, enfreindre les politiques, règles ou directives de kamamini ou enfreindre toute loi, statut, ou réglementation applicable. Tous les utilisateurs doivent se conformer à nos directives communautaires.
                </li>
                <li classname="terms__li">
                  ● Soyez honnête. Ne publiez pas d'informations que vous savez fausses, trompeuses ou inexactes. Ne faites rien de trompeur ou de frauduleux.
                </li>
                <li classname="terms__li">
                  ● Respectez les droits des autres. Vous ne pouvez pas menacer, abuser, harceler, diffamer ou adopter un comportement diffamatoire, délictuel, obscène, profane ou envahissant la vie privée d'autrui.
                </li>
                <li classname="terms__li">
                  ● Respecter la propriété d'autrui. Ne distribuez pas de virus logiciels ou tout autre programme conçu pour interférer avec (1) le bon fonctionnement de tout logiciel, matériel ou équipement sur le site ou (2) l'utilisation du site par tout autre utilisateur.
                </li>
                <li classname="terms__li">
                  ● Ne vous engagez pas dans des activités qui affectent le fonctionnement du site. Vous ne pouvez pas contourner les mesures que nous avons mises en place pour sécuriser notre site ou nos services, prendre des mesures pour obtenir un accès non autorisé à un système, des données, des mots de passe ou d'autres informations ou services Kamamini ou utiliser tout type de logiciel pour « explorer » ou « araignée » n'importe quelle partie du Site.
                </li>
                <li classname="terms__li">
                  ● Frais. Il n'y a aucun frais pour la création d'un compte sur le Site. Les propriétaires de campagne sont facturés des frais de plate-forme en pourcentage des contributions qu'ils collectent.
                </li>
              </ul><br />
            <br />
            <h3 className="mt-0" id="obligationProp">3.	Obligations de l'équipe de campagne</h3><br />
            <p className="paragraph__color terms__p">
              Si vous enfreignez l'une des conditions d'utilisation, nous nous réservons le droit de résilier, de suspendre ou de prendre toute autre mesure liée à votre compte à notre seule discrétion. Vous ne pouvez pas accéder à notre site ou à nos services si nous vous en avons interdit l'accès
            </p><br />
            <ul><br />
              <li classname="terms__li">
                ● Lorsque vous créez une campagne sur le site et demandez des contributions, vous comprenez que vous concluez des accords juridiques distincts avec kamamini et avec vos contributeurs, et les règles suivantes s'appliquent (en plus de toutes les autres conditions et politiques supplémentaires).Faites des efforts de bonne foi pour réaliser les avantages associés à votre campagne dans le délai que vous communiquez aux contributeurs, et donnez la priorité à la réalisation de ces avantages.
              </li>
              <li classname="terms__li">
                ● Soyez réactif .Répondez rapidement et honnêtement à toutes les questions posées dans les commentaires, messages ou mises à jour, y compris toute question ou demande faite par kamamini pour vérifier sa capacité à répondre. Si vous ne répondez pas ou si un différend survient entre vous et vos contributeurs, nous pouvons fournir votre nom, votre adresse légale et votre adresse e-mail à vos contributeurs, ou dans les cas où la loi l'exige.
              </li>
              <li classname="terms__li">
                ● Mises à jour :Fournir des mises à jour substantielles et de qualité au moins une fois par mois aux contributeurs. Avertissez immédiatement les contributeurs en cas d'obstacles ou de retards.
              </li>
              <li classname="terms__li">
                ● Si vous avez reçu les contributions de votre campagne, mais que vous n'êtes pas en mesure d'offrir des avantages, remboursez les contributeurs.
              </li>
              <li classname="terms__li">
                ● Se conformer aux lois. Respectez toutes les lois et réglementations applicables dans les déclarations concernant votre campagne et vos avantages, l'utilisation des contributions et la livraison des avantages.
              </li>
              <li classname="terms__li">
                ● Soyez honnête et transparent. Les membres de l'équipe de campagne doivent être prêts, disposés et capables de justifier les allégations de votre campagne, y compris, mais sans s'y limiter, les fonctionnalités et capacités du produit, le stade de développement du produit et les délais de livraison avec des preuves vérifiables si nécessaire. Si vous n'êtes pas en mesure de faire droit aux réclamations, kamamini peut résilier votre compte, retenir des fonds, fournir votre nom, votre adresse et votre adresse e-mail, ou prendre d'autres mesures pour faire valoir ses droits en vertu du présent Accord et de la loi applicable.
              </li>
            </ul><br />

            <h3 className="mt-0" id="soutenir">4.Soutenir une campagne</h3><br />
            <p className="paragraph__color terms__p">
              En tant que contributeur, lorsque vous apportez une contribution à une campagne, vous comprenez que les règles et conditions suivantes s'appliquent (en plus de toutes les autres conditions, y compris les politiques supplémentaires).
            </p><br />
            <ul><br />
              <li classname="terms__li">
                ● 	Contributions volontaires. Toutes les contributions sont faites volontairement et à la seule discrétion et aux risques du contributeur.
              </li>
              <li classname="terms__li">
                ● Kamamini ne garantit pas que les campagnes réussiront ou que les avantages seront fournis ou jugés satisfaisants En contribuant à une campagne, les contributeurs soutiennent une idée, un projet ou une cause qui leur tient à cœur et qu'ils souhaitent aider à concrétiser. Comme toute personne qui se lance dans un projet à un stade précoce, les contributeurs acceptent le risque que la campagne connaisse des changements, des retards et des défis imprévus, ou qu'une campagne et ses avantages ne se concrétisent pas.kamamini ne garantit ni ne déclare que les contributions seront utilisées comme promis, que les propriétaires de campagne offriront des avantages ou que la campagne atteindra ses objectifs. Kamamini n'assume aucune obligation d'enquêter sur les réclamations faites par les propriétaires de campagne. Kamamini n'approuve, ne garantit, ne fait aucune représentation ou ne fournit aucune garantie concernant la qualité, la sécurité, la moralité ou la légalité de toute campagne, avantage ou contribution, ou la véracité ou l'exactitude de tout contenu utilisateur publié sur les services. La date de livraison d'un avantage est une estimation du propriétaire de la campagne (et non de kamamini), et il n'y a aucune garantie que le propriétaire de la campagne remplira et livrera l'avantage à cette date. Aucun transfert de propriété sur des biens meubles corporels n'a lieu via kamamini.
              </li>
              <li classname="terms__li">
                ● Communications entre les propriétaires de campagne et les contributeurs. Une fois que vous avez fait une contribution à une campagne, les propriétaires de la campagne peuvent avoir besoin de vous contacter pour obtenir des informations supplémentaires telles que l'adresse de livraison, les préférences spécifiques pour un avantage (par exemple, la couleur ou la taille d'un t-shirt), ou peuvent demander commentaires sur la campagne ou l'avantage. Dans certains cas, pour recevoir l'avantage, vous devrez peut-être fournir les informations demandées dans un délai spécifique pour recevoir l'avantage. Ne pas le faire peut entraîner la perte de l'avantage. Les Propriétaires de campagne ne doivent pas demander des informations qui ne sont pas nécessaires pour obtenir un avantage, y compris des informations personnelles telles que des numéros de sécurité sociale ou des informations de carte de crédit/bancaires. Veuillez nous contacter sur la page de contact si vous recevez une demande d'informations qui vous semble excessive.
              </li>
            </ul><br />
            <h3 className="mt-0" id="role">5.Le rôle de kamamini</h3><br />
            <ul><br />
              <li classname="terms__li">  ●	Nous n'approuvons aucun contenu utilisateur. kamamini fournit la plate-forme de création de campagnes et d'interaction des utilisateurs. Nous ne filtrons pas les campagnes et n'approuvons aucun contenu utilisateur sur notre site. De même, kamamini ne s'engage à aucune obligation d'enquêter ou de garantir la véracité de toute réclamation faite par les propriétaires de campagne. Vous devez évaluer les déclarations d'une campagne avant de choisir de soutenir la campagne.
              </li>
              <li classname="terms__li">  ●	Nous pouvons supprimer le Contenu Utilisateur ou annuler les Campagnes et rembourser les Contributions à Notre discrétion. kamamini peut supprimer le Contenu utilisateur qui enfreint nos Conditions d'utilisation à notre seule discrétion. La suppression du Contenu utilisateur, la fermeture d'un compte ou l'annulation des Campagnes et le remboursement des Contributions ne sont pas des actions que nous prenons à la légère, et nous pouvons prendre un certain temps pour enquêter et déterminer si de telles actions sont justifiées. Il est à notre seule discrétion de discuter des raisons de prendre des mesures contre tout contenu ou compte d'utilisateur.
              </li>
              <li classname="terms__li">  ●	Nous ne garantissons pas les avantages ou les remboursements. Les propriétaires de campagne sont seuls responsables de la livraison des avantages et de l'offre de tout remboursement en dehors de notre politique de remboursement.
              </li>
              <li classname="terms__li">  ● Nous ne fournissons pas de conseils fiscaux ou juridiques aux utilisateurs. Les utilisateurs sont seuls responsables de déterminer comment les lois applicables s'appliquent à l'exécution d'une campagne, à la contribution à une campagne ou à l'utilisation de l'un des services.
              </li>
              <li classname="terms__li">  ● 	Vous êtes responsable de vos campagnes, même si vous faites appel à Notre aide. Kamamini peut offrir des services supplémentaires, notamment l'aide à la rédaction du contenu de la campagne, l'aide à la conception des campagnes et la recommandation de fournisseurs et d'autres fournisseurs de services aux propriétaires de campagne. Tous ces services feront l'objet d'un accord distinct entre le propriétaire de la campagne et kamamini. Même lorsque Nous aidons avec votre Campagne, les Propriétaires de Campagne sont seuls responsables de la Campagne et doivent se conformer à toutes les obligations et conditions en tant que seule partie responsable de la campagne.
              </li>
            </ul><br />

            <h3 className="mt-0" id="propIntel">6.Votre propriété intellectuelle</h3><br />
            <p className="paragraph__color terms__p">
              Votre Contenu utilisateur reste votre propriété. Lorsque vous soumettez du Contenu utilisateur sur le Site ou via les Services, vous acceptez les conditions suivantes :
             </p>
              <ul><br />
                <li classname="terms__li">  ●
                  	Vous nous autorisez à utiliser votre Contenu utilisateur. Vous nous accordez un droit mondial, non exclusif, perpétuel, irrévocable, libre de redevance, pouvant faire l'objet d'une sous-licence et transférable d'utiliser, d'exercer, de commercialiser et d'exploiter les droits d'auteur, de publicité, de marque déposée et de base de données concernant votre Contenu utilisateur.
                </li>
                <li classname="terms__li">  ●
                  	Nous pouvons apporter des modifications ou supprimer votre contenu utilisateur. Vous nous accordez le droit d'apporter des changements, des éditions, des modifications, des traductions, du formatage ou de supprimer votre contenu utilisateur.
                </li>
                <li classname="terms__li">  ●
                  	Vous disposez de tous les droits légaux sur votre contenu utilisateur. Vous déclarez et garantissez que : (1) vous disposez de toutes les licences, droits, consentements et autorisations nécessaires pour accorder les droits énoncés dans les présentes Conditions à kamamini en ce qui concerne votre Contenu utilisateur ; (2) votre Contenu d'utilisateur n'enfreint pas et n'enfreindra pas les droits de propriété intellectuelle, les droits de propriété, les droits à la vie privée, la confidentialité, les droits de publicité d'un tiers ou autrement violera les présentes Conditions ou la loi applicable ; et (3) kamamini n'a pas besoin d'obtenir de licences, de droits, de consentements ou d'autorisations de, ou de faire des paiements à, un tiers pour toute utilisation de votre Contenu d'utilisateur, ou n'a aucune responsabilité envers un Utilisateur ou toute autre partie comme résultant de Notre utilisation ou exploitation de votre Contenu utilisateur.
                </li>
              </ul><br />
            
            <h3 className="mt-0" id="VpropIntel">7.Notre propriété intellectuelle</h3><br />

            <ul><br />
              <li classname="terms__li">●
              	Kamamini Propriété Intellectuelle. Les services, le contenu et les marques de kamamini sont protégés par la loi de plusieurs manières, notamment en vertu du droit d'auteur, des marques de commerce, des marques de service, des brevets, des secrets commerciaux et d'autres lois sur la propriété intellectuelle. Vous acceptez de respecter tous les droits d'auteur et autres mentions légales, informations et restrictions contenues dans tout Contenu, Services ou Marques Kamamini accessibles via le Site ou les Services. Vous acceptez de ne pas modifier, traduire ou créer de toute autre manière des œuvres dérivées des Services.
              </li>
              <li classname="terms__li">●
              	Droits d'utilisateur limités ; Licence pour le contenu Kamamini. Kamamini vous accorde une licence limitée (qui est temporaire, non exclusive, non sous-licenciable et non transférable) pour accéder et utiliser le Contenu Utilisateur et le Contenu Kamamini uniquement pour l'utilisation des Services conformément aux présentes Conditions. Vous ne pouvez pas reproduire, redistribuer, transmettre, céder, vendre, diffuser, louer, partager, prêter, modifier, adapter, éditer, créer des œuvres dérivées, concéder sous licence, ou autrement transférer ou utiliser tout Contenu utilisateur ou Contenu Kamamini à moins que Nous ne vous donnions expressément autorisation écrite de le faire. Nous nous réservons le droit de révoquer cette licence limitée d'accès et d'utilisation du Contenu utilisateur et du Contenu Kamamini à tout moment et à notre seule discrétion.
              </li>

            </ul><br />


            <h3 className="mt-0" id="droit">8.	Droits de kamamini concernant les comptes d'utilisateurs ; Résiliation.</h3><br />

            

            <ul><br />
              <li classname="terms__li">●
                	Nous nous réservons le droit de surveiller, de résilier, de suspendre ou de supprimer tout compte d'utilisateur à tout moment à notre seule discrétion. Nous n'avons aucune obligation de commenter les raisons de la surveillance, de la résiliation, de la suspension ou de la suppression du compte.
              </li>
              <li classname="terms__li">●
                Nous n'avons aucune responsabilité envers vous ou des tiers pour toute utilisation des Services associés à votre compte ou à votre Campagne. En cas de suspension ou de résiliation de votre compte, vous devez cesser toute utilisation des Services et du Contenu utilisateur. Vos déclarations et garanties survivront à la résiliation.
              </li>

            </ul><br />

            <h3 className="mt-0" id="panne">9.Pannes et maintenance du système.</h3><br />
            <p className="paragraph__color terms__p">
              Le Site ou les Services peuvent être indisponibles pour des raisons de maintenance programmée et pour d'autres raisons, y compris des pannes imprévues et d'autres dysfonctionnements. Nous ne sommes pas responsables si le site ou les services ne sont pas disponibles, ou si vous perdez des données, des informations ou du contenu utilisateur pour quelque raison que ce soit
            </p>
            <h3 className="mt-0" id='clause'>10.Clause de non-responsabilité</h3>
            <ul><br />
              <li classname="terms__li">  ●
                	Utilisez les Services à vos risques et périls. Nous ne faisons aucune déclaration concernant la qualité, la sécurité, la moralité ou la légalité de toute campagne, avantage ou contribution ou la véracité ou l'exactitude du contenu utilisateur publié sur les services. Nous ne garantissons pas la réalisation ou la performance des avantages, ou que les contributions seront utilisées comme décrit dans la campagne. Nous ne contrôlons ni n'approuvons le Contenu utilisateur publié sur le Site ou dans les Services et, par conséquent, ne garantissons en aucune manière la fiabilité, la validité, l'exactitude ou la véracité de ce Contenu utilisateur. Kamamini n'est pas responsable des dommages ou pertes liés à votre utilisation des Services. Nous ne nous impliquons pas dans les litiges entre les Utilisateurs ou entre les Utilisateurs et tout tiers concernant l'utilisation des Services.
              </li>
              <li classname="terms__li">  ●
                	Vous nous libérez de toute réclamation. Lorsque vous utilisez les Services, vous dégagez Kamamini des réclamations, dommages et demandes de toute nature - connus et inconnus, suspectés ou insoupçonnés, divulgués ou non divulgués - découlant de ou liés aux Services. Tout le Contenu auquel vous accédez ou utilisez via les Services est entièrement à vos risques et périls et vous êtes seul responsable de tout dommage ou perte résultant pour toute partie.
              </li>
            </ul><br />

            <h3 className="mt-0" id="suppression">11.	Suppression de votre compte</h3>
            <p className="paragraph__color terms__p">
            Vous pouvez résilier votre compte à tout moment depuis vos paramètres de compte. Nous pourrions conserver certains types d'informations comme l'exige la loi ou à des fins commerciales légitimes. Toutes les dispositions du présent contrat restent en vigueur après la résiliation d'un compte, y compris nos droits relatifs au contenu quel qu'il soit que vous avez déjà publié sur le Site. (Par exemple, si vous avez lancé un projet, le fait de supprimer votre compte n'entraînera pas la suppression automatique de votre projet sur le Site.) Vous pouvez nous contacter à l'adresse ne.kamamini@gmail.com pour toute demande de renseignements ou pour demander la suppression d'une page de projet (chose qui pourrait ne pas être possible, en fonction des circonstances).
            </p>

            <h3 className="mt-0" id="Indemnisation">12.	Indemnisation  </h3>
            <p className="paragraph__color terms__p">
            Si une action de votre part entraîne des poursuites à notre encontre ou viole l'une des dispositions du présent contrat, vous vous engagez à nous défendre, nous indemniser et nous dégager de toute responsabilité, notamment à l'égard des plaintes et des frais (y compris les frais d'avocat raisonnables et autres coûts juridiques) découlant de ou liés à votre utilisation ou votre utilisation abusive de Kamamini. Nous nous réservons le droit d'assurer la défense et le contrôle exclusifs de toute question relative à la présente clause d'indemnisation, auquel cas vous acceptez de coopérer et de nous aider à faire valoir toutes les défenses disponibles.
            </p>

            <h3 className="mt-0" id="frais">13.Nos frais </h3>
            <p className="paragraph__color terms__p">
              La création d'un compte sur Kamamini est gratuite. Si vous créez un projet qui est intégralement financé, nous et nos partenaires de traitement des paiements prélevons 8 %. Nous ne prélèverons aucuns frais sans vous avoir préalablement laissé la possibilité d'en prendre connaissance et de les accepter. Si nous sommes amenés à modifier nos frais, nous l'annoncerons sur notre Site.
            </p>

          </div>
        </div>
      </div>
    </div>

  )
}

export default TermsAndConditions