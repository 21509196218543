import { Grid, makeStyles } from '@material-ui/core';
import { useField } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { SingleFileUploadWithProgress } from './SingleFileUploadWithProgress';
import { UploadError } from './UploadError';
import Upload from './upload.svg'
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'

let currentId = 0;
function getNewId() {
    // we could use a fancier solution instead of a sequential ID 🙂
    return ++currentId;
}
const useStyles = makeStyles((theme) => ({
    dropzone: {
        border: `2px dashed ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        background: theme.palette.background.default,
        height: theme.spacing(20),
        outline: 'none',
    },
}));
export function MultipleFileUploadField({ name,files,setFiles }) {
    const [_, __, helpers] = useField(name);
    const classes = useStyles();
    // console.log(files);
    const onDrop = useCallback((accFiles, rejFiles) => {
        const mappedAcc = accFiles.map((file) => ({ file, errors: [], id: getNewId() }));
        const mappedRej = rejFiles.map((r) => (Object.assign(Object.assign({}, r), { id: getNewId() })));
        setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
    }, []);
    useEffect(() => {
        helpers.setValue(files);
        // helpers.setTouched(true);
    }, [files]);
    function onUpload(file, url) {
        setFiles((curr) => curr.map((fw) => {
            if (fw.file === file) {
                return Object.assign(Object.assign({}, fw), { url });
            }
            return fw;
        }));
    }
    function onDelete(file) {
        setFiles((curr) => curr.filter((fw) => fw.file !== file));
    }
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ['image/*'],
        maxSize: 5000 * 1024, // 3000KB
    });
    // console.log(files);
  
  return (
    <React.Fragment>
      <Grid item>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <div  className='text-center py-2'>
            <img src={Upload} alt="" />
          </div>
          
        <div className='text-center'>
          <span >Glissez et déposez des fichiers ici</span>
          <br />
          <span > ou</span> <br />
          <KamaminiButton text="cliquer pour importer"  />
        </div>
          
        </div>
      </Grid>

      {files.map((fileWrapper) => { 
        
        let fileUrl = fileWrapper.url ? fileWrapper.url : '' ;

        return(
        <Grid item key={fileWrapper.id}>
          
                 {/* {console.log(fileWrapper.url)} */}
       
          {fileWrapper.errors.length ? (
            <UploadError
              file={fileWrapper.file}
              errors={fileWrapper.errors}
              onDelete={onDelete}
            />
          ) : (
            <SingleFileUploadWithProgress
              onDelete={onDelete}
              onUpload={onUpload}
              file={fileWrapper.file}
              fileUrl={fileUrl}
            />
          )}
        </Grid>)
      })}
    </React.Fragment>
  );
}
