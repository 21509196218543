import React, {useState} from 'react'
import Step1 from './Step1';
import Step2 from './Step2';


const InProgressProjects = () =>{

 const [singleProject, setSingleProject] = useState(null);
 const [step,setStep] = useState(0)
 

 const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };



function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Step1 
                setSingleProject={setSingleProject}
                nextStep={nextStep}
            
        />;
      case 1:
        return <Step2 
                singleProject={singleProject}
                prevStep={prevStep}

        />;
        default:
          return 'Unknown stepIndex';
    }
}


return (
    <>
    {getStepContent(step)}
    </>
)


}


export default InProgressProjects