import React, {useState} from 'react'
import {useDispatch} from "react-redux";
import { ToastContainer } from 'react-toastify';
import {addToNewsletter,errorToast} from '../redux/actions/actions'


 const NewsLetter = () => {

    const dispatch = useDispatch();


    const [input, setInput] = useState ("");
    const [message, setMessage] = useState ("");
    const  inputHandler = (e) => {
        setInput(e.target.value);
    };
    const submitHandler = (e) => {
        e.preventDefault();


 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input))
  {
    dispatch(addToNewsletter(input))
    setInput("");
    setMessage("Merci pour la souscription")
    setTimeout(()=> {
        setMessage("");
    }, 2000)
  }
  else {
    errorToast('vous avez entré une adresse mail invalide')
  }



    }
    return (
        <div>
            <ToastContainer />
            
                <form onSubmit={submitHandler}>
                    <h2>Subscribe to our Newsletter</h2>
                    <input type="email" onChange={inputHandler} value={input}/>
                    <button type="submit">submit</button>
                </form>
                <alert>{message}</alert>

        </div>
    )
}

export default NewsLetter
