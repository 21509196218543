import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

import { errorToast, successToast } from '../redux/actions/actions';
import Seo from '../Components/Helmet/Seo';
const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const defaultData = {
        name: '',
        email: '',
        number: '',
        subject: '',
        message: '',
    };
    // the initial value of the state is the object'data'
    const [contactData, setContactData] = useState(defaultData);
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const recaptchaRef = React.createRef();

    //   const [emailStatus, setEmailStatus] = useState('');

    // destructuring
    const { name, email, number, subject, message } = contactData

    const handleChange = (e) => {
        setContactData({ ...contactData, [e.target.id]: e.target.value });
    };

    function onChangeReCAPTCHA(value) {
        setRecaptchaToken(value);
    }
    function onExpiredRecaptcha() {
        setRecaptchaToken('')
        recaptchaRef.current.reset()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (recaptchaToken && recaptchaToken !== '') {

            if (name.match(/^\s*$/)) {
                errorToast("Veuillez inscrire votre nom")
            }
            else if (email.match(/^\s*$/)) {
                errorToast('Veuillez remplir votre adresse email')
            }
            else if (subject.match(/^\s*$/)) {
                errorToast("Veuillez indiquer l'objet du contact")
            }
            else if (message.match(/^\s*$/)) {
                errorToast("Veuillez compléter votre message")
            }

            else {
                const data = {
                    email,
                    subject,
                    text: message,
                    name,
                    number,
                    recaptchaToken: recaptchaToken
                };

                fetch("https://api.kamamini.net/contactEmail", {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(data),
                })
                    .then((data) => {
                        if (data.status === 200) {
                            successToast('Votre message a été envoyé avec succès')
                            setContactData(defaultData)
                        }
                        else {
                            errorToast('Votre message n\'a pas pu être envoyé, Veuillez réessayer')
                        }

                    })
                //   .catch( 
                //     errorToast('Erreur lors de l\'envoi de l\'email catch')
                //   )
            }
        } else {
            errorToast("Veuillez cocher la case reCAPTCHA pour prouver que vous n'êtes pas un robot")
        }

    }

    return (
        <>
            <Seo
                title="Contactez Kamamini"
                description="Besoin de nous contacter ? Utilisez ce formulaire."
                name="Kamamini"
                type="website"
            />

            <ToastContainer />

            <section className="contact-box-area pt-125">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <div className="section-title text-center mb-85 wow fadeInUp2 animated" data-wow-delay='.1s'>
                                <h6 className="left-line pl-75 pr-75">Fonctionnalité du Projet</h6>
                                <h2>Nous avons pour mission de <br />
                                    <span>de vous aider à prendre un bon départ</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div className="contact-box d-lg-flex mb-50 wow fadeInUp2 animated text-center" data-wow-delay='.1s'>
                                <div className="contact-box__icon">
                                    <i className="fas fa-map-marker-alt"></i>
                                </div>
                                <div className="contact-box__content">
                                    <h4>Localisation</h4>
                                    <h5>Niamey, Niger</h5>
                                </div>
                            </div>
                        </div>
                        <div className="ol-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div className="contact-box d-lg-flex mb-50 wow fadeInUp2 animated text-center" data-wow-delay='.1s'>
                                <div className="contact-box__icon">
                                    <i className="fal fa-envelope"></i>
                                </div>
                                <div className="contact-box__content">
                                    <h4>Envoyez nous un email</h4>
                                    <h5>contact@kamamini.net</h5>
                                </div>
                            </div>
                        </div>
                        <div className="ol-xl-4 col-lg-4 col-md-4 col-sm-6">
                            <div className="contact-box d-lg-flex mb-50 wow fadeInUp2 animated text-center" data-wow-delay='.5s'>
                                <div className="contact-box__icon">
                                    <i className="fal fa-phone"></i>
                                </div>
                                <div className="contact-box__content">
                                    <h4>Contactez Nous</h4>
                                    <h5>+227 96 17 76 58</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-details-area pb-120 wow fadeInUp2 animated" data-wow-delay='.3s'>
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="post-form-area contact-form pt-125">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="input-box">
                                                <h5>Nom <span className='star'>*</span></h5>
                                                <div className="input-text mb-35">
                                                    <input type="text" onChange={handleChange} id="name" required value={name} className="form-control" placeholder="Nom au complet" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="input-box mb-35">
                                                <h5>Email  <span className='star'>*</span> </h5>
                                                <div className="input-text input-mail">
                                                    <input type="email" onChange={handleChange} id="email" required value={email} className="form-control" placeholder="Adresse email" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="input-box mb-35">
                                                <h5>Téléphone</h5>
                                                <div className="input-text input-phone">
                                                    <input type="number" onChange={handleChange} id="number" value={number} className="form-control" placeholder="Numero de téléphone" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="input-box mb-35">
                                                <h5>Objet  <span className='star'>*</span> </h5>
                                                <div className="input-text input-sub">
                                                    <input type="text" onChange={handleChange} id="subject" required value={subject} className="form-control" placeholder="Je voudrais.." />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                            <div className="input-box mb-35">
                                                <h5>Message  <span className='star'>*</span> </h5>
                                                <div className="input-text input-message">
                                                    <textarea name="message" onChange={handleChange} id="message" required value={message} cols="30" rows="10" placeholder="Message"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 d-flex justify-content-center">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey="6LfxoukoAAAAAJlngjv6UavRoDm6LAUTc6VK8NDJ"
                                                onChange={onChangeReCAPTCHA}
                                                onExpired={onExpiredRecaptcha}
                                            />
                                        </div>

                                        <div className="col-xl-12 col-lg-12 col-md-12 py-4 d-flex justify-content-center">
                                            <div className="msg-btn ">
                                                <button type="submit" style={{ border: 'none' }} className="theme_btn theme_btn_bg " >
                                                    Envoyer<i className="fas fa-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form >
            </section>

            {/* <section className="contact-map-area">
        <div className="container-fluid pl-0 pr-0">
            <div className="row no-gutters">
                <div className="col-xl-12">
                    <div className="map-area map-02  wow fadeInUp2 animated" data-wow-delay='.1s'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d29168.42973837642!2d90.37845363955077!3d23.958540550000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1606918909925!5m2!1sen!2sbd"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

        </>
    )
}

export default Contact