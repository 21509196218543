import React from 'react'
import './google.scss'
import GoogleImg  from './Google__G__Logo.svg'
const GoogleSignInButton = ({title,google}) => {
    return (
        <div className="google-btn" onClick={google}>
            <div className="google-icon-wrapper">
                <img className="google-icon" src={GoogleImg} alt='google'/>
            </div>
            <p className="btn-text d-flex align-item-center"><b> {title}</b></p>
        </div>
    )
}

export default GoogleSignInButton
