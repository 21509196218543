import React,{useEffect} from 'react'
import { Prompt } from 'react-router'
import {
    Container,
    Grid,
    Button,
    Box,
    CssBaseline,
  } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Formik } from 'formik';
import { array, object, string } from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactPlayer from "react-player/youtube";
import { ToastContainer } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'
import TextEditor from '../TextEditor/TextEditor';
import AddFaq from '../AddFaq/AddFaq';
import {MultipleFileUploadField} from '../Upload/MultipleFileUploadField'
import {errorToast} from '../../redux/actions/actions';


  const useStyles = makeStyles((theme) => (
      {
        root: {
            width: '100%',
          },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
        },
      }
    ));


const StepTwo = (props) =>{
    


    const classes = useStyles();

    const {
           nextStep,
           prevStep,
           saveInput,
           handleSaveInput,
           addYoutubeUrl,
           youtubeUrl,
           deleteVideoUrl,
           wysiwygData,
           setWysiwygData,
           setFaq,
           faq,
           CustumStepper,
           pitchContentRequired,
           setpitchContentRequired,
           checkFaqErrors,
           deleteEmptyFaq,
           dropzoneImages,setdropzoneImages,imageDropZoneUrl,setImageDropZoneUrl
          
          } =props

          const imageUrl = dropzoneImages.filter((image) => image.url )
          // console.log(pitchContentRequired);

          useEffect(() => {
            window.scrollTo(0, 0)

          }, [])

         // transform pitchContentRequired to false if there is an image or video
          useEffect(() => {

            if (imageUrl.length >0 || youtubeUrl.length >0 || imageDropZoneUrl.length >0){
              setpitchContentRequired(false)
            } else {
              setpitchContentRequired(true)
            }
          }, [imageUrl,youtubeUrl])

          useEffect(() => {
            if(faq.length === 0)
            {
              setFaq([{question: '',response: '', id:0 }])
            }
          }, [faq])
            
    const handleSubmit = (e) => {
      e.preventDefault() 

      if (pitchContentRequired){
        errorToast('Veuillez ajouter une vidéo ou une image à votre pitch')
      }
      else if (wysiwygData.match(/^\s*$/)){
        errorToast('Veuillez ajouter une description à votre projet')
      }
      else if (checkFaqErrors(faq))
      {
        errorToast('Veuillez remplir tous les champ des foires aux questions')
      }
      else{
        if (imageUrl.length > 0){
          for (let url = 0; url < imageUrl.length; url++) {
          setImageDropZoneUrl(prev => [...prev, imageUrl[url].url ])
        }}
        deleteEmptyFaq(faq)
        setdropzoneImages([])
        nextStep()
      }
    }

    const prev = () =>{
      if (imageUrl.length){
        for (let url = 0; url < imageUrl.length; url++) {
        setImageDropZoneUrl(prev => [...prev, imageUrl[url].url ])
      }}
      setdropzoneImages([])
      prevStep()
    }

    const deleteImageDropZoneUrl = (url) =>{
      setImageDropZoneUrl((curr) => curr.filter((currUrl) => currUrl !== url ))
    }

    function deleteImage(file) {
      setdropzoneImages((curr) => curr.filter((fw) => fw !== file));
    }
  
    return(
      <>
    <Prompt
    // when={shouldBlockNavigation}
        message='Etes-vous sûr de vouloir quitter cette page ? Les données que vous avez entrées ne seront pas sauvegardées.'
      />
      <ToastContainer />

      <CustumStepper/>
      <Box mb={5}></Box>
      <Container>
            <CssBaseline/>
          <form className={classes.form}  onSubmit={handleSubmit} >
            <Grid container spacing={2}>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10} >
                  
                  <h3>Présentation <span className='star'>*</span> </h3>
                  <p className='note mb-3'>Vous pouvez ajouter une ou plusieurs vidéos et/ou une ou plusieurs images à votre projet pour le rendre encore plus impactant. Vous pouvez même combiner les deux si vous le souhaitez.
                  <br/>
                  Elles apparaîtront en haut de la page de votre projet. <br/>
                  NB: Les projes avec vidéos recueillent beaucoup plus de fonds que les campagnes sans vidéos.</p>

                </Grid>
               

                <Grid item xs={12} sm={12} md={10} lg={10} >
                <h5>VIDEO</h5>
                <label htmlFor="url">URL de la vidéo  </label>
                
                <p className="note mb-3">Entrez l'URL YouTube puis cliquer sur le bouton &nbsp; 'AJOUTER LA VIDEO' &nbsp;  pour l'ajouter. Vous pouvez ajouter plusieurs vidéos<br/>
                  {/* NB: Vous devez ajouter la vidéo en cliquant sur le bouton &nbsp; 'AJOUTER LA VIDEO' &nbsp; pour l'enregistrer */}
                 </p>
                <div className="kamamini-input" >
                  <div className="input-group input-group-lg">
                      <input  type="url" placeholder='http://' value={saveInput} onChange={handleSaveInput } 
                      required={pitchContentRequired} className="form-control" id="url" aria-describedby="basic-addon3" />
                      <KamaminiButton onClick={addYoutubeUrl} text='Ajouter la video' className="input-group-text" />
                  </div>
                </div> 
                 
                <br/>
                
                {
                  youtubeUrl.length > 0 &&
                  youtubeUrl.map((video,index) => {
                    return(
                      <Grid item xs={8} sm={8} md={8} lg={8} key={index} className='mb-5' >
                         <ReactPlayer   controls url={video}/> 
                         <br/>
                         <div  className="d-flex justify-content-end">
                            <Button className="bg-danger text-white" size="small" onClick={() => deleteVideoUrl(video)}>
                              <DeleteIcon fontSize="small"  /> Supprimer
                            </Button>
                         </div>
                        
                      </Grid>
                    )
                    
                   })
                }
                </Grid>


                <Grid item xs={12} sm={12} md={10} lg={10} >
                  <h5>IMAGE</h5>
                  <p className='note mb-3'>Importer une ou plusieurs images. <br/>
                  </p>


                  <Formik
                  initialValues={{ files: [] }}
                  validationSchema={object({
                    files: array(
                      object({
                        url: string().required(),
                      })
                    ),
                  })}
                >
              {() => (
                <>
                  <Grid className='pb-4 pt-2' container spacing={2} direction="column">
                      <MultipleFileUploadField 
                        name="files" 
                        files={dropzoneImages} 
                        setFiles={setdropzoneImages}
                      />
                  </Grid>

                  <Grid container spacing={2}>

                      {
                        imageUrl.length ?
                        imageUrl.map((imageUrl,index) => {
                          return (

                            <Grid container item  xs={12} sm={6} md={4} lg={3} key={index}  >
                              <Grid item>
                                  <img className="img-fluid small rounded mx-auto d-block"  src={imageUrl.url} alt={imageUrl.file.name} />
                              </Grid>
                               
                                  <Grid item className='px-3'>
                                      <Button className="bg-danger text-white"  size="small" 
                                        onClick={() => deleteImage(imageUrl)}>
                                        <DeleteIcon fontSize="small"  /> 
                                    </Button>
                                  </Grid>
                            </Grid>
                            
                          )
                        })
                        :  null
                      }

                  </Grid>

                  <Grid container spacing={2}>

                      {
                        imageDropZoneUrl.length > 0 ?
                        imageDropZoneUrl.map((imageDropZoneUrl,index) => {
                          return (
                            <Grid container item  xs={12} sm={6} md={5} lg={4} key={index}  >
                              <Grid item>
                                  <img className="img-fluid small rounded mx-auto d-block" src={imageDropZoneUrl} alt="Kamamini project's image" />
                              </Grid>
                               
                                  <Grid item className='px-3'>
                                      <Button className="bg-danger text-white "  size="small" 
                                        onClick={() => deleteImageDropZoneUrl(imageDropZoneUrl)}>
                                        <DeleteIcon fontSize="small"  /> 
                                    </Button>
                                  </Grid>
                            </Grid>
                          )
                        })
                        :  null
                      }

                  </Grid>
                  
                </>
              )}
            </Formik>


                  <div className='mb-5' ></div>
                </Grid>

              </Grid>

                <Grid item xs={12} sm={12} md={10} lg={10} >
                <h5 >Description <span className='star'>*</span> </h5>
                <p className='note mb-3'>Dites-en plus aux contributeurs potentiels de votre projet.
                <br></br>
                Fournissez des détails qui motiveront les gens à contribuer. Un bon pitch est convaincant, informatif et facile à comprendre   </p>

                <TextEditor data={wysiwygData} setData={setWysiwygData} />
                <div className='mb-3' ></div>
                </Grid>

                <Grid item xs={12} sm={12} md={10} lg={10} >
                <h5 >FAQ </h5>
                <p className='note mb-3'>La section FAQ (Foire Aux Questions) doit fournir les renseignements relatif au projet que les contributeurs souhaiteraient connaître avant de vous financer. </p>
                
                
              <AddFaq setFaq={setFaq} faq={faq}/>
                
              
               </Grid>
            </Grid>


                <Box mb={5}></Box>

                 <Grid  item xs={12} sm={12} md={12} lg={12} >
                    <div  className="d-flex justify-content-between">
                      <KamaminiButton text='Précédent' IconLeft={ArrowLeftIcon} onClick={() => prev() } />
                      {/* <KamaminiButton text={'Suivant'} IconRight={ArrowRightIcon} onClick={() => nextStep() } /> */}
                      <KamaminiButton text={'Suivant'} IconRight={ArrowRightIcon} type='Submit'/>
                    </div>
                 </Grid>

                 </form>
      
                <Box mb={5}></Box>
      </Container>

     

      
      </>
    )
  }

  export default StepTwo
