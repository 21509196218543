import { db, auth } from '../../Firebase/Firebase'
import firebase from 'firebase/app';
import { successToast, errorToast } from './actions'

// this function verify if user has an account or not 
// if it's true it search the user information to the database through his ID 

// this function verify if there has an error that ocurred during the authentiication
export const verifyError = (errorCode) => {
  switch (errorCode) {
    case 'auth/email-already-in-use':
      return 'L\'adresse e-mail est déjà utilisée par un autre compte'
    case "auth/invalid-email":
      return 'L\'adresse e-mail est invalide'
    case "auth/weak-password":
      return 'Le mot de passe doit comporter au moins 6 caractères'
    case "auth/network-request-failed":
      return 'Le délai d’attente est dépassé'
    case "auth/timeout":
      return 'L\'opération a expiré.'
    case "auth/cancelled-popup-request":
      return 'Cette opération a été annulée en raison de l\'ouverture d\'une autre fenêtre pop-up conflictuelle.'
    case "auth/popup-blocked":
      return 'Impossible d\'établir une connexion avec la popup. Elle a peut-être été bloquée par le navigateur.'
    case "auth/popup-closed-by-user":
      // return 'Le popup a été fermée par l\'utilisateur avant de finaliser l\'opération.'
      return;
    case "auth/user-not-found":
      return 'Il n\'y a pas d\'enregistrement d\'utilisateur correspondant à cet identifiant.'
    case "auth/wrong-password":
      return 'Le mot de passe n\'est pas valide.'
    case "auth/account-exists-with-different-credential":
      return 'Un compte existe déjà avec la même adresse e-mail mais des informations d\'identification différentes. Connectez-vous à l\'aide d\'un fournisseur associé à cette adresse e-mail.'
    case "auth/requires-recent-login":
      return 'Connectez-vous à nouveau avant de réessayer cette requête'
    case "auth/too-many-requests":
      return "L'accès à ce compte a été temporairement désactivé en raison de nombreuses tentatives de connexion infructueuses. Vous pouvez le rétablir immédiatement en réinitialisant votre mot de passe ou en réessayant plus tard"

    default: return null
  }
}


export const getUser = () => {
  return (dispatch) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch({
          type: 'GET_USER_DATA'
        });

        db.collection('users').doc(user.uid).get()
          .then(doc => {
            if (doc.exists) {
              const myData = doc.data();
              myData.uid = user.uid;

              dispatch({
                type: 'GET_USER_DATA_SUCCESS',
                userInfo: myData
              });
            } else {
              // Si le document n'existe pas
              dispatch({
                type: 'GET_USER_DATA_ERROR',
                error: 'Le document n\'existe pas'
              });
            }
          })
          .catch(error => {
            dispatch({
              type: 'GET_USER_DATA_ERROR',
              error: error
            });
          });
      } else {
        dispatch({
          type: 'NO_USER'
        });
      }
    });
  };
};


export const getProfileUser = (uid) => {
  return (dispatch) => {
    // loader
    dispatch({
      type: 'GET_PROFILE_USER'
    })
    // get user information through the user id 
    db.collection('users').doc(uid).get()
      .then(doc => {
        console.log(doc);
        if (doc && doc.exists) {
          const myData = doc.data();
          myData.uid = doc.id
          dispatch({
            type: 'GET_PROFILE_USER_SUCCESS',
            profileInfo: myData
          })
        }
        else if (doc.exists === false) {
          dispatch({
            type: 'GET_PROFILE_USER_ERROR',
            error: "Le profil n'existe pas"
          })
        }
      })
      .catch(error => {
        dispatch({
          type: 'GET_PROFILE_USER_ERROR',
          error: error
        })
      })
  }
}

export const cancelGetProfileUser = () => {
  return (dispatch) => {
    dispatch({
      type: 'CANCEL_GET_PROFILE_USER'
    })
  }
}

export const updateUserData = user => {
  return async (dispatch) => {
    try {
      // Vérifier si Firebase est initialisé
      if (firebase.app === undefined) {
        throw new Error("Firebase n'est pas initialisé.");
      }

      // Mettre à jour les données utilisateur dans Firestore
      await db.collection('users').doc(user.uid).set(user);

      // Attendre la confirmation de la mise à jour
      // Avant de récupérer les données mises à jour
      await dispatch(getUser());

      // Dispatch de l'action de succès
      dispatch({
        type: "UPDATE_USER_DATA_SUCCESS",
      });

      // Affichage d'un message de succès
      successToast('Votre profil a été modifié avec succès');
    } catch (err) {
      // Dispatch de l'action d'erreur
      dispatch({
        type: "UPDATE_USER_DATA_ERROR",
        error: err.message,
      });
      // Affichage d'un message d'erreur à l'utilisateur
      errorToast('Une erreur s\'est produite lors de la mise à jour de votre profil');
    }
  }
};

export const updateUserEmail = (id, newEmail, password, oldEmail) => {
  return async (dispatch) => {
    try {
      // Sign in with old email and password
      await auth.signInWithEmailAndPassword(oldEmail, password);

      // Update the email in Firebase Authentication
      await auth.currentUser.updateEmail(newEmail);

      // Update the email in Firestore
      await db.collection('users').doc(id).update({ email: newEmail });

      // Dispatch success actions
      dispatch({ type: "UPDATE_USER_EMAIL" });
      dispatch(getUser());

      // Show success message
      successToast('E-mail modifié avec succès');
    } catch (error) {
      // Handle specific errors
      dispatch({ type: "UPDATE_USER_EMAIL_ERROR", error });
      const errorMsg = verifyError(error.code);

      // Dispatch error actions
      if (errorMsg === null && error.message) {
        errorToast(error.message);
      } else {
        errorToast(errorMsg);
      }
    }
  };
};


export const updateUserPassword = (oldPassword, email, newPassword) => {
  return async (dispatch) => {
    try {
      // Sign in with the current email and password
      await auth.signInWithEmailAndPassword(email, oldPassword);

      // Update the password in Firebase Authentication
      await auth.currentUser.updatePassword(newPassword);

      // Dispatch success actions
      dispatch({ type: "UPDATE_USER_PASSWORD" });
      dispatch(getUser());

      // Show success message
      successToast('Mot de passe modifié avec succès');
    } catch (error) {
      // Handle specific errors
      const errorMsg = verifyError(error.code);

      // Dispatch error actions
      if (errorMsg === null && error.message) {
        errorToast(error.message);
      } else {
        errorToast(errorMsg);
      }
    }
  };
};


export const deleteUserFromFirebaseAuthAndFirestore = (email, password, id) => {
  return async (dispatch) => {
    try {
      // Sign in with the current email and password
      await auth.signInWithEmailAndPassword(email, password);

      // Delete the user document from Firestore
      await db.collection('users').doc(id).delete();

      // Delete the user from Firebase Authentication
      await auth.currentUser.delete();

      // Dispatch success action
      dispatch({ type: 'DELETE_USER_FROM_FIREBASE_AUTH_AND_FIRESTORE' });
    } catch (error) {
      // Handle specific errors
      const errorMsg = verifyError(error.code);

      // Dispatch error actions
      if (errorMsg === null && error.message) {
        errorToast(error.message);
      } else {
        errorToast(errorMsg);
      }
    }
  };
};

export const deleteFacebookAndGoogleUser = (id) => {
  return async (dispatch) => {
    try {
      // Delete the user document from Firestore
      await db.collection('users').doc(id).delete();

      // Delete the user from Firebase Authentication
      await auth.currentUser.delete();

      // Dispatch success action
      dispatch({ type: 'DELETE_USER_FROM_FIREBASE_AUTH_AND_FIRESTORE' });
    } catch (error) {
      // Handle specific errors
      const errorMsg = verifyError(error.code);

      // Dispatch error actions
      if (errorMsg === null && error.message) {
        errorToast(error.message);
      } else {
        errorToast(errorMsg);
      }
    }
  };
};


export const fetchAdminUsers = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'GET_ADMIN_USERS'
      });

      // get all admin users with the admin is true 
      const querySnapshot = await db.collection('users').where('admin', '==', true).get();

      const data = querySnapshot.docs.map(doc => {
        const myData = doc.data();
        myData.id = doc.id;
        return myData;
      });

      dispatch({
        type: 'GET_ADMIN_USERS_SUCCESS',
        adminUsers: data
      });
    } catch (error) {
      dispatch({
        type: 'GET_ADMIN_USERS_ERROR',
        error: error.message
      });
      // Optionally, rethrow the error if needed
      throw error;
    }
  };
};


// allow administrator to update the admin field 
export const updateUserAdmin = (value, id) => {
  return async (dispatch) => {
    try {
      await db.collection('users').doc(id).update({
        admin: value
      });

      dispatch({
        type: 'UPDATE_ADMIN_USERS'
      });

      // Mettez à jour la liste des utilisateurs administrateurs après la modification
      dispatch(fetchAdminUsers());
    } catch (error) {
      // Gérez l'erreur ici, vous pouvez la journaliser ou la propager selon vos besoins
      errorToast('Erreur lors de la mise à jour du statut admin :', error);

      // Facultatif : rejet de l'erreur pour permettre la gestion ailleurs si nécessaire
      throw error;
    }
  };
};


// allow administrator to update the admin field 
export const updateUserRole = (value, id) => {
  return async (dispatch) => {
    try {
      await db.collection('users').doc(id).update({
        role: value
      });

      dispatch({
        type: 'UPDATE_ADMIN_USERS_ROLE'
      });

      // Mettez à jour la liste des utilisateurs administrateurs après la modification
      dispatch(fetchAdminUsers());
    } catch (error) {
      // Gérez l'erreur ici, vous pouvez la journaliser ou la propager selon vos besoins
      errorToast('Erreur lors de la mise à jour du rôle utilisateur :', error);

      // Facultatif : rejet de l'erreur pour permettre la gestion ailleurs si nécessaire
      throw error;
    }
  };
};


export const logout = () => {
  return async (dispatch) => {
    try {
      await auth.signOut();

      dispatch({
        type: 'LOGOUT',
      });
    } catch (error) {
      // Gérez l'erreur ici, vous pouvez la journaliser ou la propager selon vos besoins
      dispatch({
        type: 'LOGOUT_ERROR',
        error: error
      })
      errorToast('Erreur lors de la déconnexion :', error);
      // Facultatif : rejet de l'erreur pour permettre la gestion ailleurs si nécessaire
      throw error;
    }
  };
};


export const cancelDeleteUserError = () => {
  return (dispatch) => {
    dispatch({
      type: 'CANCEL_DELETE_USER_ERROR'
    })
  }
}
export const cancelDeleteUserSuccess = () => {
  return (dispatch) => {
    dispatch({
      type: 'CANCEL_DELETE_USER_SUCCESS'
    })
  }
}
