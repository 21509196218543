import React from 'react'
import './facebook.scss'
import FacebookIcon from '@material-ui/icons/Facebook';

const FacebookSignInButton = ({title,facebook}) => {
    return (
        
        <div className="facebook-btn" onClick={facebook} >
        <div className="facebook-icon-wrapper">
            {/* <img className="facebook-icon"  /> */}
            <FacebookIcon  className="facebook-icon" />
        </div>
        <p className="btn-text text-center"><b> {title}</b></p>
    </div>
    )
}

export default FacebookSignInButton
