import React from 'react'
// import Image10 from '../../../assets/img/blog/10.jpg'
// import User from '../../../assets/img/blog/04.png'
import {useDispatch} from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {frenchSittings } from '../../../redux/actions/actions'
import {featuredBlog,cancelFeaturedBlog} from '../../../redux/actions/blogActions'
import Button from "../../Admin/components/CustomButtons/Button.js";
import KamaminiButton from '../../Buttons/KamaminiColorButton/KamaminiButton'


const BlogsCard2 = ({blog}) => {

    moment.locale('fr',frenchSittings());
    const dispatch= useDispatch();


    const handleAdd = (id) => {
      dispatch(featuredBlog(id))
    }
    const handleCancel = (id) => {
      dispatch(cancelFeaturedBlog(id))
    }

if (blog) { 
    
        
    return (
        
        <div className="blogs mb-30">
        <div className="blogs__thumb pos-rel mb-45">
           <div className="blogs__thumb--img" > 
            <Link to={'/blogs/' + blog.slug} >
                <img src={blog.urlImage} alt={blog.title} />
            </Link>
           </div>
            <span className="blog-tag" >{blog.category}</span>
        </div>
        <div className="blogs__content">
            <div className="blogs__content--meta mb-15">
                <span><i className="far fa-calendar-alt"></i> {moment(blog.timestamp.toDate()).format("DD MMM  YYYY")} </span>
                <span><i className="far fa-comment"></i> Comment (03)</span>
            </div>
            <h4 className="mb-20">  {blog.title} </h4>
                {
                    blog.featured ? 
                    <Button color="danger" onClick={() =>handleCancel(blog.id)} >Annuler</Button>
                    :
                    <KamaminiButton text='Mettre en avant' onClick={() =>handleAdd(blog.id)} />
                }
                
            <ul className="project-manager">
               <br/>
                <li>
                    <span className="more_btn_02">  {blog.id} </span>
                </li>
            </ul>
        </div>
    </div>
    ) }
    else {
        return ( 
        <div className="blogs mb-30">
        <div className="blogs__thumb pos-rel mb-45">
           <div className="blogs__thumb--img">
                <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fblog%2F10.jpg?alt=media&token=20bff672-92f4-4b9e-bd0e-b95ace872491" alt="" />
           </div>
            <a className="blog-tag" href="blog-details.html">Technology</a>
        </div>
        <div className="blogs__content">
            <div className="blogs__content--meta mb-15">
                <span><i className="far fa-calendar-alt"></i> 05 Nov 2020</span>
                <span><i className="far fa-comment"></i> Comment (03)</span>
            </div>
            <h4 className="mb-20"><a href="blog-details.html">Build A Simple Crypto Curr See Nockchcin Node</a></h4>
            <ul className="project-manager">
                <li><a href="!#"><img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fblog%2F04.jpg?alt=media&token=45b08411-99b6-4b7c-977f-d910040e8a39" alt="" />
                        <span>David Zon</span></a></li>
                <li>
                    <a className="more_btn_02" href="blog-details.html">Read more <i className="far fa-arrow-right"></i></a>
                </li>
            </ul>
        </div>
    </div>
        )
    }
}

export default BlogsCard2
