import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import '../assets/css/avatar.css'
import Seo from '../Components/Helmet/Seo'



const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
       
      }, [])


    return (
        <div>
            <Seo
            title="À Propos de Kamamini"
            description="Découvrez l'histoire et la mission de Kamamini, la plateforme de financement participatif qui soutient les projets innovants et créatifs."
            name="Kamamini"
            type="website"
            />
          
    {/* <!-- header-area end --> */}

    {/* <!-- slide-bar start --> */}
    <aside class="slide-bar">

        {/* <!-- side-mobile-menu end --> */}
    </aside>
    <div class="body-overlay"></div>
    {/* <!-- slide-bar end --> */}

    <main>
        
        {/* <!--page-title-area start--> */}
        <section class="page-title-area" style={{ backgroundImage: "url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fevents%2F01.jpg?alt=media&token=a2a75543-883d-419e-ac9a-93eaf999918a"+")"
        , }}>
            {/* <div class="right-border-shape">
                <img src="assets/img/shape/02.png" alt="" />
            </div> */}
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3">
                        <div class="page-title-wrapper text-center">
                            <h1 class="page-title wow fadeInUp2 animated mb-5" data-wow-delay='.1s'>A propos</h1>
                            {/* <div class="breadcrumb">
                                <ul class="breadcrumb-list wow fadeInUp2 animated" data-wow-delay='.3s'>
                                    <li><a href="index.html">Home <i class="far fa-chevron-right"></i></a></li>
                                    <li><a class="active" href="#">About Us</a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--page-title-area area--> */}
        {/* <!--about-us-area start--> */}
        <section class="about-us-area-03 pt-130 pb-85">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-lg-6 col-md-12">
                        <div class="about-img mb-50">
                            <div class="about-img__thumb wow fadeInUp2 animated" data-wow-delay='.1s'>
                                {/* <img src="assets/img/about/03.jpg" alt="" /> */}
                            </div>
                            <div class="about-img__style-text wow fadeInUp2 animated" data-wow-delay='.3s'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fabout%2Fkamateam.jpg?alt=media&token=c7b3a3b8-cc2d-495e-b2a1-1a7316a4da94"
                                  className="img-fluid d-none d-lg-block" alt="" />
                            </div>
                            <div class="about-img__style-shape wow fadeInUp2 animated" data-wow-delay='.4s'>
                                <img src="assets/img/shape/07.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-6 col-md-12">
                        <div class="about__wrapper about__wrap__03 pl-65 wow fadeInUp2 animated" data-wow-delay='.3s'>
                            <div class="section-title text-left mb-35">
                                <h6 class="left-line pl-75">La start-up</h6>
                                <h2>une Plateforme Web<br />
                                    <span>Crée par des Nigériens</span></h2>
                            </div>
                            {/* <div class="partner-list d-sm-flex align-items-center mb-30">
                                <div class="total-client">
                                    <h4><span>36k </span> Our Global Partner</h4>
                                </div>
                                <div class="joint-btn ml-15">
                                    <a href="contact.html" class="theme_btn theme_btn2 ">join with us <i
                                            class="far fa-arrow-right"></i></a>
                                </div>
                            </div> */}
                            <p>Fort d'une équipe expérimenté dans le domaine de la programmation et convaincu que l'entreprenariat est l'une des meilleurs solutions aux difficultés d'insertion des jeunes, <b>KamaMini</b> est une plate-forme intuitive et accéssible à l'ensemble des nigériens: </p>
                                <p>-Vous êtes intéressée par l'entreprenariat? </p> 
                                <p>-Vous souhaiter faire parler de vos projects ?</p>   
                                <p>-Vous voulez contribuez à la création d'un tissu entreprenarial solide au Niger? </p> 
                                <br></br>
                                <h6 className='font-weight-light text-center'>KamaMini est votre solution</h6>  
                            {/* <a href="contact.html" class="theme_btn theme_btn_bg mt-25 center">En savoir plus <i
                                class="far fa-arrow-right"></i></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--about-us-area end--> */}
        {/* <!--feature-project-area start--> */}
        <section class="about-feature-project pb-25">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12">
                        <div class="about__wrapper feature__wrapper mb-30 wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <div class="section-title text-left mb-25">
                                <h6 class="left-line pl-75">Nos prestations</h6>
                                <h2>Des services<br />
                                <span>pour la réussite de vos projets</span></h2>
                            </div>
                            <p>Kamamini vous accompagne dans la réussite de vos projects grâce à :</p>
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 wow fadeInUp2 animated" data-wow-delay='.2s'>
                                    <div class="single-feature mb-30">
                                        <div class="single-feature__icon mr-20">
                                            <i class="flaticon-dish"></i>
                                        </div>
                                        <div class="single-feature__content">
                                            <h5><a href="project-video.html">Une page web</a></h5>
                                            <p>pour la visitbilité de votre projet (description, photo, vidéo, etc.) </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 wow fadeInUp2 animated" data-wow-delay='.3s'>
                                    <div class="single-feature mb-30">
                                        <div class="single-feature__icon mr-20">
                                            <i class="flaticon-computer"></i>
                                        </div>
                                        <div class="single-feature__content">
                                            <h5><a href="project-video.html">Des rencontres</a></h5>
                                            <p>la mise en relation avec des contributeurs & investisseurs </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 wow fadeInUp2 animated" data-wow-delay='.4s'>
                                    <div class="single-feature mb-30">
                                        <div class="single-feature__icon mr-20">
                                            <i class="flaticon-stethoscope"></i>
                                        </div>
                                        <div class="single-feature__content">
                                            <h5><a href="project-video.html">Financement</a></h5>
                                            <p>une source de financement complémentaire pour vos projects </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 wow fadeInUp2 animated" data-wow-delay='.8s'>
                                    <div class="single-feature mb-30">
                                        <div class="single-feature__icon mr-20">
                                            <i class="flaticon-project-management"></i>
                                        </div>
                                        <div class="single-feature__content">
                                            <h5><a href="project-video.html">Autres services</a></h5>
                                            <p>Des services numérique à vos attente </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 mt-5">
                        <div class="about-img feature__img__box pl-65 mb-50 wow fadeInUp2 animated" data-wow-delay='.3s'>
                            <div class="about-img__thumb">
                                <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fabout%2Ffood-g5f6923ebb_1920.jpg?alt=media&token=29520176-fd04-49fd-b382-58660dff9b79' style={{height:'60vh'}} alt="" />
                            </div>
                            <div class="about-img__style-text d-none d-md-block wow fadeInUp2 animated" data-wow-delay='.5s'>
                                <img src="assets/img/project/02.png" alt="" />
                            </div>
                            <div class="about-img__style-shape">
                                <img src="assets/img/shape/07.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--feature-project-area end--> */}
         {/* <!--counter-area start--> */}
         {/* <section class="counter-area heding-bg pt-130 pb-95" style={{backgroundImage: "url(assets/img/bg/03.png)"}}>
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                        <div class="counetrs mb-30 wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <div class="counetrs__icon mb-30"><i class="far fa-gem"></i></div>
                            <h1><span class="counter">402</span>+</h1>
                            <p>Project Complate</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                        <div class="counetrs mb-30 wow fadeInUp2 animated" data-wow-delay='.2s'>
                            <div class="counetrs__icon mb-30"><i class="fal fa-gift"></i></div>
                            <h1><span class="counter">365</span>+</h1>
                            <p>Global Partner</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                        <div class="counetrs mb-30 wow fadeInUp2 animated" data-wow-delay='.3s'>
                            <div class="counetrs__icon mb-30"><i class="far fa-trophy-alt"></i></div>
                            <h1><span class="counter">753</span>+</h1>
                            <p>Awards Winning</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                        <div class="counetrs mb-30 wow fadeInUp2 animated" data-wow-delay='.4s'>
                            <div class="counetrs__icon mb-30"><i class="far fa-users"></i></div>
                            <h1><span class="counter">862</span>+</h1>
                            <p>Active Volunteer</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!--counter-area end--> */}
        {/* <!--team-area start--> */}
        <section class="team-area pt-125 pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 offset-xl-1">
                        <div class="section-title text-center mb-70 wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <h6 class="left-line pl-75 pr-75">Notre équipe</h6>
                            <h2>Nos "Super"<br />
                                <span>Developpeur</span></h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInUp2 animated" data-wow-delay='.3s'>
                        <div class="team text-center mb-30">
                            <div class="team__thumb mb-25 pos-rel">
                                <div class="team-avatar cat">
                                    
                                    <img  src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fteam%2Fimran.jpg?alt=media&token=b473d23f-988d-4075-a1f0-9d9c70716bd4"
                                     alt="" class="mig" />
                                    
                                </div>
                                <a class="msg-me" href="mailto:imran.kabo@kamamini.net"><i class="far fa-envelope-open"></i></a>
                            </div>
                            <div class="team__content">
                                <p>Developpeur</p>
                                <h5><a href="#">Imran Kabo</a></h5>
                                <a class="more_btn" href="#"><i class="fas fa-plus"></i></a>
                                <ul class="team__social mt-10">
                                    <li>
                                        <a href="https://www.facebook.com/imran.ibrah" target='_blank' rel="noopener noreferrer">
                                            <i class="fab fa-facebook-f"></i>
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="#">
                                            <i class="fab fa-twitter"></i>
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                    </li> */}
                                    {/* <li>
                                        <a href="#">
                                            <i class="fab fa-youtube"></i>
                                            <i class="fab fa-youtube"></i>
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="https://www.linkedin.com/in/imran-kabo/" target='_blank' rel="noopener noreferrer">
                                            <i class="fab fa-linkedin"></i>
                                            <i class="fab fa-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInUp2 animated" data-wow-delay='.5s'>
                        <div class="team text-center mb-30">
                            <div class="team__thumb mb-25 pos-rel">
                                <div class="team-avatar cat">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fteam%2Fibou1.jpg?alt=media&token=0c98e7b1-d414-4863-8931-e79bef8eeeb1"
                                     alt="" class="mig" />
                                </div>
                                <a class="msg-me" href="mailto:ibrahim.namagare@kamamini.net">

                                    <i class="far fa-envelope-open"></i></a>
                            </div>
                            <div class="team__content">
                                <p>Developpeur</p>
                                <h5><a href="#">Ibrahim Namagaré</a></h5>
                                <a class="more_btn" href="#"><i class="fas fa-plus"></i></a>
                                <ul class="team__social mt-10">
                                    <li>
                                        <a href="https://www.facebook.com/ibrahim.namagare" target='_blank' rel="noopener noreferrer">
                                            <i class="fab fa-facebook-f"></i>
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/ibrahim_namass" target='_blank' rel="noopener noreferrer">
                                            <i class="fab fa-twitter"></i>
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="#">
                                            <i class="fab fa-youtube"></i>
                                            <i class="fab fa-youtube"></i>
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="https://www.linkedin.com/in/ibrahim-namagare-4339b81b2/" target='_blank' rel="noopener noreferrer">
                                            <i class="fab fa-linkedin"></i>
                                            <i class="fab fa-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInUp2 animated" data-wow-delay='.7s'>
                        <div class="team text-center mb-30">
                            <div class="team__thumb mb-25 pos-rel">
                                <div class="team-avatar cat">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fteam%2Frayna.jpg?alt=media&token=6ed88702-12f3-4d66-a1d1-a7845dcd4c33"
                                     alt="" class="mig" />
                                </div>
                                <a class="msg-me" href="#"><i class="far fa-envelope-open"></i></a>
                            </div>
                            <div class="team__content">
                                <p>Developpeur</p>
                                <h5><a href="#">Raynatou hamani</a></h5>
                                <a class="more_btn" href="#"><i class="fas fa-plus"></i></a>
                                <ul class="team__social mt-10">
                                    <li>
                                        <a href="https://web.facebook.com/rainatou.amadou.33" target='_blank' rel="noopener noreferrer">
                                            <i class="fab fa-facebook-f"></i>
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/AmadouRainatou" target='_blank' rel="noopener noreferrer">
                                            <i class="fab fa-twitter"></i>
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="#">
                                            <i class="fab fa-youtube"></i>
                                            <i class="fab fa-youtube"></i>
                                        </a>
                                    </li> */}
                                    {/* <li>
                                        <a href="#">
                                            <i class="fab fa-behance"></i>
                                            <i class="fab fa-behance"></i>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInUp2 animated" data-wow-delay='.9s'>
                        <div class="team text-center mb-30">
                            <div class="team__thumb mb-25 pos-rel">
                                <div class="team-avatar cat">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fteam%2Fmocy.jpg?alt=media&token=3673e98f-409c-4084-bd33-5e96a3b4b811"
                                     alt="" class="mig" />
                                </div>
                                <a class="msg-me" href="mailto:moctar.yonli@gmail.com"><i class="far fa-envelope-open"></i></a>
                            </div>
                            <div class="team__content">
                                <p>Developpeur FullStack</p>
                                <h5><a href="#">Moctar Yonli</a></h5>
                                <a class="more_btn" href="#"><i class="fas fa-plus"></i></a>
                                <ul class="team__social mt-10">
                                    <li>
                                        <a href="https://www.facebook.com/moctar.yonli.5" target='_blank' rel="noopener noreferrer">
                                            <i class="fab fa-facebook-f"></i>
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/moctar_yonli" target='_blank' rel="noopener noreferrer">
                                            <i class="fab fa-twitter"></i>
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="#">
                                            <i class="fab fa-youtube"></i>
                                            <i class="fab fa-youtube"></i>
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="https://www.linkedin.com/in/moctar-yonli-b49" target='_blank'  rel="noopener noreferrer" >
                                            <i class="fab fa-linkedin"></i>
                                            <i class="fab fa-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--team-area end--> */}
        {/* <!--donation-area start--> */}
        {/* <section class="donation-area pt-125 pb-100" style={{backgroundImage: "url(assets/img/events/01.jpg)"}}>
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 offset-xl-2">
                        <div class="donation-wrapper">
                            <div class="section-title white-title text-center mb-40 wow fadeInUp2 animated"
                                data-wow-delay='.2s'>
                                <h6 class="left-line pl-75 pr-75">Donate Now</h6>
                                <h2>Raise Your Hand To The<br />
                                    <span>Right Place</span></h2>
                            </div>
                            <ul class="btn-list text-center mb-30 wow fadeInUp2 animated" data-wow-delay='.3s'>
                                <li><a class="theme_btn theme_btn_bg" href="blog-details.html"
                                        data-animation="fadeInLeft" data-delay=".7s">explore more <i
                                            class="far fa-arrow-right"></i></a>
                                </li>
                                <li><a class="theme_btn theme-border-btn" href="contact.html"
                                        data-animation="fadeInLeft" data-delay=".7s">donate now <i
                                            class="far fa-arrow-right"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!--donation-area end--> */}
        {/* <!--testimonial-area start--> */}
        {/* <section class="testimonial-area theme-bg2 pt-125 pb-130" style={{backgroundImage: "url(assets/img/bg/04.png)"}}>
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 offset-xl-1">
                        <div class="section-title white-title text-center mb-70 wow fadeInUp2 animated" data-wow-delay='.2s'>
                            <h6 class="left-line pl-75 pr-75">Clients Feedback</h6>
                            <h2>356754+ Peoples Say<br />
                                <span>About Given </span></h2>
                        </div>
                    </div>
                </div>
                <div class="testimonial-wrapper">
                    <div class="row testimonial-active">
                        <div class="col-xl-6">
                            <div class="testimonial-item wow fadeInUp2 animated" data-wow-delay='.3s'>
                                <div class="author-img fix pb-20">
                                    <div class="author-avatar f-right">
                                        <img src="assets/img/testimonial/01.png" alt="" />
                                    </div>
                                </div>
                                <div class="author-content mb-15">
                                    <h5 class="left-line pl-40">David Michel,<span class="desig">CEO & Founder</span></h5>
                                </div>
                                <p class="semi-title mb-15">But I must explain to you how all this mistake denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of ter-builder</p>
                                <div class="review-icon">
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="testimonial-item wow fadeInUp2 animated" data-wow-delay='.5s'>
                                <div class="author-img fix pb-20">
                                    <div class="author-avatar f-right">
                                        <img src="assets/img/testimonial/02.png" alt="" />
                                    </div>
                                </div>
                                <div class="author-content mb-15">
                                    <h5 class="left-line pl-40">Somalia Silva,<span class="desig">CEO & Founder</span></h5>
                                </div>
                                <p class="semi-title mb-15">But I must explain to you how all this mistake denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of ter-builder</p>
                                <div class="review-icon">
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="testimonial-item wow fadeInUp2 animated" data-wow-delay='.3s'>
                                <div class="author-img fix pb-20">
                                    <div class="author-avatar f-right">
                                        <img src="assets/img/testimonial/01.png" alt="" />
                                    </div>
                                </div>
                                <div class="author-content mb-15">
                                    <h5 class="left-line pl-40">David Michel,<span class="desig">CEO & Founder</span></h5>
                                </div>
                                <p class="semi-title mb-15">But I must explain to you how all this mistake denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of ter-builder</p>
                                <div class="review-icon">
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="testimonial-item wow fadeInUp2 animated" data-wow-delay='.5s'>
                                <div class="author-img fix pb-20">
                                    <div class="author-avatar f-right">
                                        <img src="assets/img/testimonial/02.png" alt="" />
                                    </div>
                                </div>
                                <div class="author-content mb-15">
                                    <h5 class="left-line pl-40">Somalia Silva,<span class="desig">CEO & Founder</span></h5>
                                </div>
                                <p class="semi-title mb-15">But I must explain to you how all this mistake denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of ter-builder</p>
                                <div class="review-icon">
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                    <a href="#"><i class="fas fa-star"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!--testimonial-area end--> */}
        {/* <!--brand-area start--> */}
        {/* <section class="brand-area grey-bg2 pt-130">
            <div class="container custom-container-02">
                <div class="row brand-active pb-125">
                    <div class="col-xl-2 wow fadeInUp2 animated" data-wow-delay='.1s'>
                        <div class="brand-slide text-center">
                            <div class="brand-img">
                                <a href="#"><img src="assets/img/brand/01.png" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 wow fadeInUp2 animated" data-wow-delay='.3s'>
                        <div class="brand-slide text-center">
                            <div class="brand-img">
                                <a href="#"><img src="assets/img/brand/02.png" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 wow fadeInUp2 animated" data-wow-delay='.5s'>
                        <div class="brand-slide text-center">
                            <div class="brand-img">
                                <a href="#"><img src="assets/img/brand/03.png" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 wow fadeInUp2 animated" data-wow-delay='.7s'>
                        <div class="brand-slide text-center">
                            <div class="brand-img">
                                <a href="#"><img src="assets/img/brand/04.png" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 wow fadeInUp2 animated" data-wow-delay='.9s'>
                        <div class="brand-slide text-center">
                            <div class="brand-img">
                                <a href="#"><img src="assets/img/brand/05.png" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 wow fadeInUp2 animated" data-wow-delay='.11s'>
                        <div class="brand-slide text-center">
                            <div class="brand-img">
                                <a href="#"><img src="assets/img/brand/01.png" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 wow fadeInUp2 animated" data-wow-delay='.1s'>
                        <div class="brand-slide">
                            <div class="brand-img">
                                <a href="#"><img src="assets/img/brand/02.png" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 wow fadeInUp2 animated" data-wow-delay='.3s'>
                        <div class="brand-slide">
                            <div class="brand-img">
                                <a href="#"><img src="assets/img/brand/03.png" alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!--brand-area end--> */}
        {/* <!--blog-area start--> */}
        {/* <section class="blog-area pt-130 pb-100">
            <div class="container">
                <div class="row no-gutters">
                    <div class="col-xl-4 col-lg-8 col-md-12">
                        <div class="blog-wrapper mb-30">
                            <div class="section-title text-left mb-70 wow fadeInUp2 animated" data-wow-delay='.1s'>
                                <h6 class="left-line pl-75 pr-75">Latest News</h6>
                                <h2>Every Update<br />
                                    <span>News & Blog</span></h2>
                                <p>Sedut perspiciatis unde omnis iste natus error 
                                    sit voluptatem accse doloremque laudantium 
                                    totam rem aperiam eaque ipsa quae abillo</p>
                                <a href="blog-details.html" class="theme_btn theme_btn2 theme_btn_bg_02">view all news <i
                                        class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp2 animated" data-wow-delay='.3s'>
                        <div class="blogs mb-30 mr-15">
                            <div class="blogs__thumb pos-rel mb-45">
                               <div class="blogs__thumb--img">
                                    <img src="assets/img/blog/01.jpg" alt="" />
                               </div>
                                <a class="blog-tag" href="blog-details.html">Education</a>
                            </div>
                            <div class="blogs__content">
                                <div class="blogs__content--meta mb-15">
                                    <span><i class="far fa-calendar-alt"></i> 05 Nov 2020</span>
                                    <span><i class="far fa-comment"></i> Comment (03)</span>
                                </div>
                                <h4 class="mb-20"><a href="blog-details.html">How Make Cross-Browser Testing More Lambda.</a></h4>
                                <ul class="project-manager">
                                    <li><a href="blog-details.html"><img src="assets/img/blog/03.png" alt="" />
                                            <span>David Zon</span></a></li>
                                    <li>
                                        <a class="more_btn_02" href="blog-details.html">Read more <i class="far fa-arrow-right"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp2 animated" data-wow-delay='.5s'>
                        <div class="blogs mb-30 ml-15">
                            <div class="blogs__thumb pos-rel mb-45">
                               <div class="blogs__thumb--img">
                                    <img src="assets/img/blog/02.jpg" alt="" />
                               </div>
                                <a class="blog-tag" href="blog-details.html">Education</a>
                            </div>
                            <div class="blogs__content">
                                <div class="blogs__content--meta mb-15">
                                    <span><i class="far fa-calendar-alt"></i> 05 Nov 2020</span>
                                    <span><i class="far fa-comment"></i> Comment (03)</span>
                                </div>
                                <h4 class="mb-20"><a href="blog-details.html">How Make Cross-Browser Testing More Lambda.</a></h4>
                                <ul class="project-manager">
                                    <li><a href="blog-details.html"><img src="assets/img/blog/04.png" alt="" />
                                            <span>David Zon</span></a></li>
                                    <li>
                                        <a class="more_btn_02" href="blog-details.html">Read more <i class="far fa-arrow-right"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!--blog-area end--> */}
        {/* <!--cta-area start--> */}
        <section class="cta-area theme-bg pt-55 pb-25">
            <div class="container">
                <div class="row align-items-md-center">
                    <div class="col-xl-9 col-lg-9 col-md-8">
                        <div class="cta-wrapper wow fadeInUp2 animated" data-wow-delay='.1s'>
                            <div class="section-title mb-30">
                                <h2>Envie de devenir un de nos <b>Partnernaire</b>  ?</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4">
                        <div class="cta-wrapper">
                            <div class="cta-btn text-left text-md-right wow fadeInUp2 animated" data-wow-delay='.1s'>
                                <Link class="theme_btn theme_btn_bg" to="/contact">contactez nous <i
                                    class="far fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--cta-area end--> */}
    </main>
    {/* <!--footer-area start--> */}
    {/* <footer class="footer-area heding-bg pos-rel pt-100" style="background-image:url(assets/img/bg/02.png)">
        <div class="container">
            <div class="footer-bottom-area">
                <div class="row mb-30">
                    <div class="col-xl-2 col-lg-3 col-md-6  wow fadeInUp2 animated" data-wow-delay='.1s'>
                        <div class="footer__widget mb-30">
                            <h5 class="semi-title mb-25">Quick Links</h5>
                            <ul class="fot-list">
                                <li><a href="#">About us</a></li>
                                <li><a href="#">Latest events</a></li>
                                <li><a href="#">How It Works</a></li>
                                <li><a href="#">News & articles</a></li>
                                <li><a href="#">Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-6 wow fadeInUp2 animated" data-wow-delay='.3s'>
                        <div class="footer__widget mb-30 pl-40">
                            <h5 class="semi-title mb-25">Our Project</h5>
                            <ul class="fot-list">
                                <li><a href="#">Education</a></li>
                                <li><a href="#">Design</a></li>
                                <li><a href="#">Film & Video</a></li>
                                <li><a href="#">Technology</a></li>
                                <li><a href="#">Games</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6  wow fadeInUp2 animated" data-wow-delay='.5s'>
                        <div class="footer__widget mb-25 pl-85">
                            <h5 class="semi-title mb-25">Our Project</h5>
                            <p class="mb-10">Perspi ciatis unde omnis iste nat
                                error sit voluptatem accusan</p>
                            <ul class="fot-list address-list">
                                <li><a href="#"><i class="far fa-map-marker-alt"></i> 250 Main Street, 2nd Floor, USA</a></li>
                                <li><a href="#"><i class="far fa-envelope"></i> support@gmail.com</a></li>
                                <li><a href="#"><i class="far fa-phone-volume"></i> 888 (0123) 456 79</a></li>
                            </ul>
                            
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6  wow fadeInUp2 animated" data-wow-delay='.7s'>
                        <div class="footer__widget fot_abot mb-30 pl-85">
                            <h5 class="semi-title mb-25">Donation Us</h5>
                            <p class="mb-30">Sed ut perspi ciatis unde omnis iste 
                                natus error sit voluptatem accusantiu doloremque laudantiu totam</p>
                                <div class="subscribe-content foter-subscribe">
                                    <form class="subscribe-form" action="form.php">
                                        <input class="form-control" type="text" placeholder="Enter Your Email" />
                                        <button><i class="far fa-arrow-right"></i></button>
                                    </form>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--scroll-target-btn-->
            <a href="#top-menu" class="scroll-target"><i class="far fa-arrow-up"></i></a>
            <!--scroll-target-btn-->
            <div class="copy-right-area pt-30">
                <div class="row align-items-center">
                    <div class="col-xl-7 col-lg-7 col-md-6">
                        <div class="footer-log mb-30">
                            <a href="index.html" class="footer-logo mb-30"><img src="assets/img/logo/footer-logo1.png"
                                alt=""></a>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-6">
                        <div class="copyright mb-30 text-left text-md-right">
                            <p>© 2020 Given. All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer> */}
    {/* <!--footer-area end--> */}




        </div>
    )
}

export default About
