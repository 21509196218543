// import KamaminiButton from '../../Buttons/KamaminiColorButton/KamaminiButton'
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';

import { Link } from 'react-router-dom';
import { displayProjectEndTime } from '../../../redux/actions/actions'

const useStyles = makeStyles({
    card: {
        width: 300,
        maxWidth: 450
    },
    media: {
        height: 250,
    }
});


const SingleProjectCard2 = ({ project }) => {
    const classes = useStyles();
    let projectProgress =    project && project.moneyRaised * 100/parseInt(project.moneyRequest)

    return (
        <div className='col-md-6 col-sm-12 mt-3'>
            <Card className={classes.card} >
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={project.featuredImage}
                        title={project.title}
                    />
                    <CardContent>
                        <div className='text-center'>
                            <h4 className='py-1' >
                                 {project.title}  
                            </h4>
                                    <Link to={project.slug + '/edit-project'}  className="theme_btn theme_btn_bg edit_save edit_padding" data-animation="fadeInLeft" data-delay=".7s"> 
                                    Modifier
                                    <i className="fas fa-edit"></i>
                                    </Link>

                                    <Link to={project.slug + '/edit-project'} className="theme_btn theme-border-btn2 editButton mx-3 edit_padding" data-animation="fadeInLeft" data-delay=".5s">
                                            Voir
                                    <i className="fas fa-eye"></i>
                                    </Link>
                                
                                
                                
                                
                         
                            

                        </div>
                        <p className='d-flex py-2'>
                            <h5 style={{color:'#02B663'}} className='mx-2'> {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(project.moneyRaised)}
                            </h5>
                            <span variant="subtitle1" gutterBottom>
                            {project.moneyRaised > 0 ? "collectés" : "collecté"}
                            </span>
                        </p>
                        <Divider/>
                        {
                            project.confirmation ?
                                <Typography variant="subtitle1" gutterBottom>
                                Status : <span style={{color:'#02B663'}} >{displayProjectEndTime(project.projectsEndDate)} </span>
                                </Typography>
                            : project.confirmation === false ?
                            <Typography variant="subtitle1" gutterBottom>
                                Status : <span style={{color:'#02B663'}} >
                                     Rejeté
                                    </span>
                            </Typography>
                            :
                            <Typography variant="subtitle1" gutterBottom>
                                Status : <span style={{color:'#02B663'}} > En attente</span>
                                
                            </Typography>
                        }
                        <Typography variant="subtitle1" gutterBottom>
                            financé à <span style={{color:'#02B663'}} >{parseInt(projectProgress)}%</span>
                        </Typography>
                        
                    </CardContent>

                </CardActionArea>
            </Card>
        </div>

    );
}



export default SingleProjectCard2
