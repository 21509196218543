import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Prompt } from 'react-router';
import { LinearProgress } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer } from 'react-toastify';
import { updateUserData } from '../redux/actions/authActions';
import { notAllowed, errorToast, resizeFile } from '../redux/actions/actions';
import '../assets/css/editProfile.css'
// import User from "../images/Dashboard/user.png"

const EditDashboard = (props) => {


    const userInfo = useSelector(state => state.auth.userInfo);

    const dispatch = useDispatch()
    let history = useHistory();
    if (!userInfo) {
        history.push("/");
        dispatch(notAllowed())

    }

    const [userData, setUserData] = useState(userInfo);
    const [progress, setProgress] = useState(0);
    const [prompt, setPrompt] = useState(false);

    const defaultNumber = ''
    const defaultBio = ''
    const defaultCity = ''
    const defaultWebsiteLink = ''
    const defaultFacebookLink = ''
    const defaultTwitterLink = ''
    const defaultLinkedinLink = ''
    const defaultInstagramLink = ''



    const uploadFile = async (file, onProgress) => {
        const key = "ktru7nrq";
        const url = 'https://api.cloudinary.com/v1_1/kamamini/image/upload';

        return new Promise((res, rej) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url);

            xhr.onload = () => {
                const resp = JSON.parse(xhr.responseText);
                res(resp.secure_url);
            };
            xhr.onerror = (evt) => rej(evt);
            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentage = (event.loaded / event.total) * 100;
                    onProgress(Math.round(percentage));
                }
            };

            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', key);

            xhr.send(formData);
        })
            .then((data) => { return data })
            .catch((error) => {
                alert('Erreur veuillez réessayer');
            });


    }

    const handleChangeImg = async (e) => {
        const file = e.target.files[0];
        if (file && file['type'].split('/')[0] === 'image') {
            if (file.size <= (5000 * 1024)) {
                try {
                    const image = await resizeFile(file);
                    const urlImage = await uploadFile(image, setProgress)
                    setUserData({ ...userData, [e.target.id]: urlImage });
                    setPrompt(true)
                } catch (error) {
                    errorToast("Votre image n'a pas pu être sauvegardée, essayez de nouveau")
                }
            }
            else {
                errorToast("La taille de l'image ne doit pas être supérieur à 5 Mo")
            }

        }
    }


    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.id]: e.target.value });
        setPrompt(true)
    };

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])

    const checkPrompt = (e) => {
        if (prompt) {
            if (e) {
                e.returnValue = ''
            }
            return '';
        }
    }

    window.onbeforeunload = (event) => {
        // Show prompt based on state
        const e = event || window.event;
        e.preventDefault();
        checkPrompt(e)
    };

    if (userData) {
        return (
            <>

                <ToastContainer />
                {prompt &&
                    <Prompt
                        when={prompt}
                        message='Etes-vous sûr de vouloir quitter cette page ? Les données que vous avez entrées ne seront pas sauvegardées.'
                    />
                }

                <div className="container rounded bg-white mt-5 mb-5">
                    <div className="row">
                        <div className="col-md-4 border-right">
                            {
                                progress > 0 && progress < 100 && <LinearProgress variant="determinate" value={progress} />
                            }
                            <div className="d-flex flex-column align-items-center text-center p-3 py-5">

                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <h4 className="text-right">Photo de profil</h4>

                                </div>

                                {
                                    userData.photoURL ?
                                        <img className="rounded-circle mt-5" width="150px" src={userData.photoURL} alt={userData.displayName} />
                                        :
                                        <img className="rounded-circle mt-5" width="150px" src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fimages%2FDashboard%2Fuser.png?alt=media&token=affe75a3-f2c6-4d7c-80fd-c92236d630dd"
                                            alt="Kamamini default user" />
                                }


                                <input className="d-none" id="photoURL" onChange={handleChangeImg} type="file" accept="image/x-png,image/gif,image/jpeg" />
                                <label htmlFor="photoURL" className="py-3" >
                                    <span className="theme_btn theme-border-btn2 editButton" data-animation="fadeInLeft" data-delay=".5s">
                                        Modifier
                                        <i className="fas fa-edit"></i>
                                    </span>
                                </label>

                            </div>
                        </div>
                        <div className="col-md-4 border-right text-dark">
                            <div className="p-3 py-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="text-right">À propos de moi</h4>
                                </div>
                                <div className="row mt-2">

                                    <div className="col-md-12">
                                        <label className="labels">Nom</label>
                                        <div className="input-group-lg">
                                            <input type="text" onChange={handleChange} id="displayName" className="form-control" placeholder="Nom" value={userData.displayName} />
                                        </div>
                                    </div>


                                </div>
                                <div className="row mt-3">

                                    {/* <div className="col-md-12 mb-3">
                        <label className="labels">Email </label>
                        <div className="input-group-lg">
                            <input type="email" onChange={handleChange} className="form-control" id="email"  placeholder="Entrez votre email" value={userData.email}/>
                        </div>
                    </div> */}

                                    <div className="col-md-12 mb-3">
                                        <label className="labels">Mobile Number</label>
                                        <div className="input-group input-group-lg">
                                            <input type="number" onChange={handleChange} id="number" className="form-control input-group-lg" placeholder="Numéro de téléphone" value={userData.number || defaultNumber} />
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="labels">Biographie </label>
                                        <div className="input-group">
                                            <textarea type="text" onChange={handleChange} id="bio" className="form-control" placeholder="Biographie" value={userData.bio || defaultBio} ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="labels">Ville </label>

                                        <div className="kamamini-input">
                                            <div className="input-group">

                                                <select className="custom-select" value={userData.city || defaultCity} onChange={handleChange} id="city" required={true}>
                                                    <option value='' >Aucune</option>
                                                    <option value="Agadez" >Agadez</option>
                                                    <option value="Diffa">Diffa</option>
                                                    <option value="Dosso">Dosso</option>
                                                    <option value="Maradi">Maradi</option>
                                                    <option value="Niamey">Niamey</option>
                                                    <option value="Tahoua">Tahoua</option>
                                                    <option value="Tillabery">Tillabery</option>
                                                    <option value="Zinder">Zinder</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                </div>




                            </div>
                        </div>
                        <div className="col-md-4 text-dark">
                            <div className="p-3 py-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="text-right">Réseaux sociaux</h4>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="labels">Site internet <i className="px-1 fas fa-globe"></i> </label>
                                    <div className="input-group input-group-lg">
                                        <input type="url" onChange={handleChange} id="websiteLink" className="form-control" placeholder="http://" value={userData.websiteLink || defaultWebsiteLink} />
                                    </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="labels">Facebook <i className="px-1 fab fa-facebook"></i> </label>
                                    <div className="input-group input-group-lg">
                                        <input type="url" onChange={handleChange} id="facebookLink" className="form-control" placeholder="http://" value={userData.facebookLink || defaultFacebookLink} />
                                    </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="labels">Twitter <i className="px-1 fab fa-twitter"></i> </label>
                                    <div className="input-group input-group-lg">
                                        <input type="url" onChange={handleChange} id="twitterLink" className="form-control" placeholder="http://" value={userData.twitterLink || defaultTwitterLink} />
                                    </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="labels">Linkedin <i className="px-1 fab fa-linkedin"></i> </label>
                                    <div className="input-group input-group-lg">
                                        <input type="url" onChange={handleChange} id="linkedinLink" className="form-control" placeholder="http://" value={userData.linkedinLink || defaultLinkedinLink} />
                                    </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="labels">Instagram <i className="px-1 fab fa-instagram"></i> </label>
                                    <div className="input-group input-group-lg">
                                        <input type="url" onChange={handleChange} id="instagramLink" className="form-control" placeholder="http://" value={userData.instagramLink || defaultInstagramLink} />
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="mt-1 text-center">
                                <span onClick={() => dispatch(updateUserData(userData))} className="theme_btn theme_btn_bg edit_save" data-animation="fadeInLeft" data-delay=".7s"> Enregistrer</span>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        )
    }
    else {
        return (
            <div id="preloader">
                <div className="preloader">
                    <CircularProgress style={{ color: 'green' }} />
                </div>
            </div>
        )
    }
}


export default EditDashboard
