import React,{useState,useEffect} from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Box,Card   } from '@mui/material';
import Slider from '@mui/material/Slider';
import { useWindowScroll } from "react-use";


const PrettoSlider = styled(Slider)({
    color: '#02b663',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#02b663',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });
const FilterBlog =  ({
    filter,
    categoryOption,
    handleCategoryChange
}) => {
   

     return (
    <>
      <Card
       style={{ backgroundColor: 'white', padding: '20px' }} 
       sx={{mt:12}}
      >
        <Typography variant="h5"  gutterBottom>
             Options de filtres
        </Typography>

        <Typography variant="h6" sx={{color:'rgba(0, 0, 0, 0.6)'}} gutterBottom>
            Catégorie
        </Typography>
        <FormControl fullWidth className="my-2" >
        <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="category"
            value={filter.category}
            label="Catégorie"
            onChange={handleCategoryChange}
        >
            
            <MenuItem value="">Toutes les catégories</MenuItem>
            {
                categoryOption.map((category,index)=> {
                    return(
                    <MenuItem key={index} value={category}>{category}</MenuItem>
                    )
                })
            }
        </Select>
        </FormControl>
      </Card>
    </>
  )
}


export default FilterBlog
