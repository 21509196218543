import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { Grid, Container, CssBaseline, Box, CircularProgress  } from '@material-ui/core'
import {rejectedProjects} from '../../../../redux/actions/projectActions'
import SingleProjectCard4 from '../../../Cards/SingleProjectCard4/SingleProjectCard4' 


const Step1 = ({setSingleProject,nextStep}) =>{

    const dispatch= useDispatch();
    const pendingProjects = useSelector(state =>state.projects.getRejectedProjects);
    useEffect(() => {
        window.scrollTo(0, 0)
       
      }, [])

    useEffect(() => {
        dispatch(rejectedProjects())

    }, [dispatch])



    if(pendingProjects){
        return (
            <>
                <CssBaseline/>
                <Container>
                    <Box mb={5}></Box>
                    <Grid container spacing={2}>
                         {
                           pendingProjects.map(project =>{ 
                                 return(
                                     <Grid container item xs={12} sm={6} md={6} lg={4} key={project.id} >
                                         <SingleProjectCard4 project={project} setSingleProject={setSingleProject} nextStep={nextStep}  /> 
                                     </Grid>
                                 )
                             })
                         }
                     </Grid>


                </Container>

            </>

        )

    } 
    else if (pendingProjects && pendingProjects.length === 0){
        return (
            <>
                <CssBaseline/>
                <Container>
                    <Box mb={5}></Box>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} sm={6} md={4} lg={4}>
                            <Box mb={5}></Box>
                            <h2>Aucun projet rejeté </h2>
                        </Grid>
                    </Grid>
                </Container>
            </>
        )
    }
    
    else{
        return(
            <div id="preloader">
                <div className="preloader">
                <CircularProgress style={{color:'green'}} />
                </div>
            </div>
          )
    }

   
}
export default Step1