import React,{useEffect} from 'react'

const ProjectImages = ({images,setImageDropZoneUrl,imageDropZoneUrl}) => {

    useEffect(() => {
      setImageDropZoneUrl(images)
    }, [])
    
  return (
    <>
    {
      !imageDropZoneUrl.length &&  <h4>Vous n'avez pas ajouté d'image </h4>
    }
    </>
  )
}

export default ProjectImages