import { toast } from 'react-toastify';
import firebase from 'firebase/app';
import moment from 'moment'
import Resizer from "react-image-file-resizer";
import { db, auth } from '../../Firebase/Firebase'

export const successToast = (text, time) => toast.success((text), {
  position: "top-right",
  autoClose: time ? time : 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});;
export const errorToast = (text, time) => toast.error((text), {
  position: "top-right",
  autoClose: time ? time : 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});;

export const warningToast = (text, time) => toast.warning((text), {
  position: "top-right",
  autoClose: time !== null ? time : time === false ? undefined : 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});;



export const userNotAllowToast = () => toast.error('Vous n\'êtes pas authorisé à accéder à cette page', {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});;



export const addToNewsletter = (input) => {
  return (dispatch) => {
    // Vérifiez d'abord si l'e-mail existe déjà dans la collection
    db.collection("emails")
      .where("email", "==", input)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // L'e-mail n'existe pas, alors ajoutez-le
          db.collection("emails")
            .add({
              email: input,
              timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() =>
              dispatch({
                type: "ADD_TO_NEWSLETTER",
              }),
              successToast("Merci pour votre inscription à notre newsletter ! Restez à l'affût pour les dernières actualités 🚀")
            )
            .catch((error) => console.error('Error adding email to newsletter:', error));
        } else {
          // L'e-mail existe déjà, vous pouvez gérer cela comme nécessaire
          warningToast('Vous êtes déjà abonné à notre newsletter,merci de votre intérêt pour Kamamini !')
        }
      })
      .catch((error) => console.error('Error checking email existence:', error));
  };
};


//function that regulate access to createblog page
export const notAllowed = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'USER_NOT_ALLOWED_TO_SEE_THIS_PAGE'
    })

  }
}
export const notAuthorized = () => {
  return (dispatch) => {
    userNotAllowToast()
  }
}

export const frenchSittings = () => {
  return {
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm'
    },
    calendar: {
      sameDay: '[Aujourd’hui à] LT',
      nextDay: '[Demain à] LT',
      nextWeek: 'dddd [à] LT',
      lastDay: '[Hier à] LT',
      lastWeek: 'dddd [dernier à] LT',
      sameElse: 'L'
    },
    relativeTime: {
      future: 'dans %s',
      past: 'il y a %s',
      s: 'quelques secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
      return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
      return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
      return hours < 12 ? 'PD' : 'MD';
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4  // Used to determine first week of the year.
    }
  }
}

export const verifyUploadError = (errorCode) => {
  switch (errorCode) {
    case 'file-invalid-type':
      return 'Le type de fichier doit être une image'
    case "file-too-large":
      return "La taille de l'image ne doit pas être supérieur à 5 Mo"
    // case "auth/weak-password":
    //   return 'Le mot de passe doit comporter au moins 6 caractères'
    // case "auth/network-request-failed":
    //   return 'Le délai d’attente est dépassé'
    // case "auth/timeout":



    default: return null
  }
}

export const displayProjectEndTime = (date) => {

  let today = new moment();
  let endDate = new moment(date.toDate());
  let duration = moment.duration(today.diff(endDate));
  let days = parseInt(duration.asDays().toFixed(0)) * (-1);
  let hours = parseInt(duration.asHours().toFixed(0)) * (-1);
  let minutes = parseInt(duration.asMinutes().toFixed(0)) * (-1);
  let seconds = parseInt(duration.asSeconds().toFixed(0)) * (-1);
  let milliseconds = parseInt(duration.asMilliseconds().toFixed(0)) * (-1);

  if (hours === 24) {
    return `${days} jour restant`
  }
  else if (hours > 23) {
    return `${days} jours restants`
  }
  else if (minutes === 60) {
    return `${hours} heure restante`
  }
  else if (minutes > 59) {
    return `${hours} heures restantes`
  }
  else if (seconds === 60) {
    return `${minutes} minute restante`
  }
  else if (seconds > 59) {
    return `${minutes} minutes restantes`
  }
  else if (milliseconds === 60) {
    return `${seconds} seconde restante`
  }
  else if (milliseconds > 60) {
    return `${seconds} secondes restantes`
  }
  else {
    return `Terminé`
  }

}
export const getTimeDifference = (date) => {
  let today = moment();
  let endDate = moment(date.toDate());
  let duration = moment.duration(today.diff(endDate));

  let years = Math.floor(duration.asYears());
  let months = duration.months();
  let days = duration.days();
  let hours = duration.hours();
  let minutes = duration.minutes();
  let seconds = duration.seconds();

  if (years > 0) {
    return `${years} an${years > 1 ? 's' : ''}`;
  } else if (months > 0) {
    return `${months} mois`;
  } else if (days > 0) {
    return `${days} jour${days > 1 ? 's' : ''}`;
  } else if (hours > 0) {
    return `${hours} heure${hours > 1 ? 's' : ''}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    return `${seconds} seconde${seconds > 1 ? 's' : ''}`;
  }
}
export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      1200,
      "JPEG",
      150,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const getToken = async () => {
  try {
    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
    // Stocke le token dans les cookies ou utilise-le directement dans les requêtes
    return token;
  } catch (error) {
    // Gérer les erreurs de connexion
  }
}