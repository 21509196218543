import React, { useState, useEffect, useMemo } from 'react'
import axios from "axios"
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { createTheme, ThemeProvider, } from '@mui/material/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TextField from '@mui/material/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom'
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton'
import { getSingleProject, cancelProjectInfo, cancelNoProjectInfo, addDonation } from '../redux/actions/projectActions';
import { successToast, errorToast, warningToast, displayProjectEndTime, getToken } from '../redux/actions/actions'
import PerkCard from '../Components/Cards/PerkCard/PerkCard'
// import { title } from '../assets/jss/material-dashboard-react';
import TextDialog from '../Components/Dialog/TextDialog'
// import verifie from '../assets/img/verifie.png';


const theme = createTheme({
    palette: {
        neutral: {
            main: '#02b663',
            contrastText: '#fff',
        },
    },
});



function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const Checkout = (props) => {

    const dispatch = useDispatch()
    let query = useQuery();
    // const slug = props.match.params.slug;
    const slug = window.location.href.split('/')[3];

    const project = useSelector(state => state.projects.singleProject)
    const noProject = useSelector(state => state.projects.noSingleProject)
    const userInfo = useSelector(state => state.auth.userInfo);

    const selectedPerk = project && project.perk.filter(perk => perk.title === query.get("title"))
    const selection = query.get("selection")

    const nonSelectedPerk = project && project.perk.filter(perk => perk.title !== query.get("title"))
    let sortedPerks = project && project.perk.sort((a, b) => { return a.price - b.price })
    // get the perk that the quatity are superior than 0 or illimited
    const disponibleSortedPerks = sortedPerks && sortedPerks.filter(perk => perk.quantity > 0 || perk.quantity === "illimited")


    nonSelectedPerk && selectedPerk.length > 0 && nonSelectedPerk.push({
        title: selectedPerk[0].title,
        description: selectedPerk[0].description,
        price: selectedPerk[0].price,
        id: selectedPerk[0].id,
        quantity: selectedPerk[0].quantity === 'illimited' ? selectedPerk[0].quantity : selectedPerk[0].quantity - 1,
        rewardImage: selectedPerk[0].rewardImage ? selectedPerk[0].rewardImage : null,
    })
    const [alignment, setAlignment] = useState('mobile-money');
    const [selectedPayment, setSelectedPayment] = useState(true);
    const [bankCardSubmitting, setBankCardSubmitting] = useState(false)
    const [displayWarningCount, setDisplayWarningCount] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);


    const date = new Date()
    let Difference_In_Time = project && project.projectsEndDate.toDate().getTime() - date.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (!userInfo) {
        props.history.push('/login')
    }
    else if (Difference_In_Days < 0) {

        props.history.push(`/projects/${project.slug}`)
        dispatch(cancelProjectInfo())
        dispatch(cancelNoProjectInfo())

    } else if (selectedPerk && selectedPerk.length > 0 && selectedPerk[0].quantity <= 0) {
        props.history.push(`/projects/${project.slug}`)
        dispatch(cancelProjectInfo())
        dispatch(cancelNoProjectInfo())
    }

    // get the numbers of donors per perk
    const getDonorsPerPerk = (perkTitle) => {
        let donors = project && project.donor.filter(donor => donor.perkTitle === perkTitle)
        return donors.length
    }
    const [selectedRadioValue, setSelectedRadioValue] = useState('');

    const handleChangeRadio = (event) => {
        if (typeof (event) === 'object') {
            setSelectedRadioValue(event.target.value);
        }
        else if (typeof (event) === 'string') {
            setSelectedRadioValue(event);

        }
    };

    const handleClickOpen = () => {
        setDialogOpen(true);
    }

    const handleClose = () => {
        setDialogOpen(false);
    };


    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getSingleProject(slug))
        return () => {

            dispatch(cancelProjectInfo())
            dispatch(cancelNoProjectInfo())
        }

    }, [dispatch, slug])

    useMemo(() => {
        if (displayWarningCount) {
            warningToast('Paiement en attente', 5000)
        }
    }, [displayWarningCount])

    const ERROR_MESSAGES = {
        emptyCardNumber: "Le numéro de la carte n'est pas valide",
        invalidCardNumber: "Le numéro de la carte n'est pas valide",
        emptyExpiryDate: "La date d'expiration n'est pas valide",
        monthOutOfRange: "Le mois d'expiration doit être compris entre 01 et 12.",
        yearOutOfRange: "L'année d'expiration ne peut pas être dans le passé.",
        dateOutOfRange: "La date d'expiration ne peut pas être dans le passé.",
        invalidExpiryDate: "La date d'expiration n'est pas valide",
        emptyCVC: "Le code de sécurité n'est pas valide",
        invalidCVC: "Le code de sécurité n'est pas valide"
    }

    const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } = usePaymentInputs({ errorMessages: ERROR_MESSAGES });


    // formik validation if user has not selected a perk
    const formik = useFormik({
        initialValues: {
            formA: {
                amount: '',
                anonymous: false,
            },
            formB: { firstName: '', lastName: '', number: '' },
            formC: { cardName: '', cardNumber: '', expiryDate: '', cvc: '' }
        },
        validationSchema: Yup.object({
            formA: Yup.object({
                amount: Yup.number().typeError('le montant doit être de type numérique').required('Le motant est requis').positive().integer().min(100, 'le montant doit être supérieur ou égal à 100'),
            }),
            formB: Yup.object({
                firstName: Yup.string().required('Le nom est requis'),
                lastName: Yup.string().required('Le prénom est requis'),
                number: Yup.number().typeError('le numéro doit être de type numérique').required('Le numéro est requis').positive().integer(),
            }),
            formC: Yup.object({
                // cardName: Yup.string().required('Le nom du titulaire de la carte est requis'),

            })
        }),
        onSubmit: async (values, SubmitProps) => {
            const token = await getToken();

            setBankCardSubmitting(true);

            const paymentData = {
                amount: values.formA.amount,
                displayName: values.formB.firstName + " " + values.formB.lastName,
                number: `${values.formB.number}`,
                projectName: project.title,
            };

            const emailData = {
                userEmail: userInfo.email,
                name: values.formB.firstName + " " + values.formB.lastName,
                projectName: project.title,
                amount: values.formA.amount
            };

            try {
                const paymentResponse = await axios.post('https://api.kamamini.net/sendMoney', paymentData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                const paymentReference = paymentResponse.data.reference;

                if (paymentResponse.data.status === 'failed' || !paymentResponse.data.status) {
                    errorToast('Échec du paiement');
                    SubmitProps.setSubmitting(false);
                    setBankCardSubmitting(false);
                } else {
                    // Function to get payment status
                    const handlePaymentStatus = async () => {
                        try {
                            const statusResponse = await axios.post('https://api.kamamini.net/getSatus', { reference: paymentReference }, {
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json'
                                }
                            });

                            if (statusResponse.data.status === 'succeeded') {
                                const paymentInfo = {
                                    amount: values.formA.amount,
                                    anonymous: values.formA.anonymous,
                                    donorDisplayName: values.formB.firstName + " " + values.formB.lastName,
                                    donorNumber: values.formB.number,
                                    donorImargeUrl: userInfo.photoURL ? userInfo.photoURL : null,
                                    donorId: userInfo.uid,
                                    projectTitle: project.title,
                                    paymentMethodName: selectedPayment === true ? 'Mobile Money' : 'Carte bancaire',
                                    projectSlug: slug,
                                    projectId: project.id,
                                    projectImage: project.featuredImage,
                                    perk: false,
                                    donationDate: new Date(),
                                };
                                dispatch(addDonation(paymentInfo, nonSelectedPerk));

                                successToast('Paiement réussi');
                                SubmitProps.setSubmitting(false);
                                SubmitProps.resetForm();
                                setBankCardSubmitting(false);
                                handleClickOpen();

                                // Send email for donations without perks
                                await axios.post('https://api.kamamini.net/noPerkPayment', emailData, {
                                    headers: {
                                        'Authorization': `Bearer ${token}`,
                                        'Content-Type': 'application/json'
                                    }
                                });
                            } else if (statusResponse.data.status === 'failed' || !statusResponse.data.status) {
                                errorToast('Échec du paiement');
                                SubmitProps.setSubmitting(false);
                                setBankCardSubmitting(false);
                            } else {
                                setDisplayWarningCount(true);
                                setTimeout(() => {
                                    handlePaymentStatus();
                                }, 5000);
                            }
                        } catch (statusError) {
                            errorToast("Une erreur s'est produite lors de la vérification du statut du paiement");
                        }
                    };

                    handlePaymentStatus();
                }
            } catch (error) {
                console.log(error);
                errorToast('Votre demande comporte une erreur. Vérifiez vos informations avant de réessayer.');
                SubmitProps.setSubmitting(false);
                setBankCardSubmitting(false);
            }
        },


    });


    // formik validation if user has selected a perk
    const formikPerk = useFormik({
        initialValues: {
            formA: { anonymous: false },
            formB: { firstName: '', lastName: '', number: '' },
            formC: { cardName: '', cardNumber: '', expiryDate: '', cvc: '' }
        },
        validationSchema: Yup.object({
            formB: Yup.object({
                firstName: Yup.string().required('Le nom est requis'),
                lastName: Yup.string().required('Le prénom est requis'),
                number: Yup.number().typeError('le numéro doit être de type numérique').required('Le numéro est requis').positive().integer(),
            }),

        }),
        onSubmit: async (values, SubmitProps) => {
            const token = await getToken();
            setBankCardSubmitting(true);

            const data = {
                amount: selectedPerk[0].price,
                displayName: values.formB.firstName + " " + values.formB.lastName,
                number: `${values.formB.number}`,
                projectName: project.title,
            };

            const emailData = {
                userEmail: userInfo.email,
                name: values.formB.firstName + " " + values.formB.lastName,
                projectName: project.title,
                perkName: selectedPerk[0].title,
                perkPrice: selectedPerk[0].price
            };

            try {
                const response = await axios.post('https://api.kamamini.net/sendMoney', data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                const paymentReference = response.data.reference;

                if (response.data.status === 'failed' || !response.data.status) {
                    errorToast('Échec du paiement');
                    SubmitProps.setSubmitting(false);
                    setBankCardSubmitting(false);
                } else {
                    // warningToast('Paiement en cours', 3000);

                    // Function to get payment status
                    const handlePaymentStatus = async () => {
                        try {
                            const statusResponse = await axios.post('https://api.kamamini.net/getSatus', { reference: paymentReference }, {
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json'
                                }
                            });

                            if (statusResponse.data.status === 'succeeded') {
                                const paymentInfo = {
                                    amount: parseInt(selectedPerk[0].price),
                                    anonymous: values.formA.anonymous,
                                    donorDisplayName: values.formB.firstName + " " + values.formB.lastName,
                                    donorNumber: values.formB.number,
                                    donorImargeUrl: userInfo.photoURL ? userInfo.photoURL : null,
                                    donorId: userInfo.uid,
                                    projectTitle: project.title,
                                    paymentMethodName: selectedPayment === true ? 'Mobile Money' : 'Carte bancaire',
                                    projectSlug: slug,
                                    projectId: project.id,
                                    projectImage: project.featuredImage,
                                    perk: true,
                                    perkImage: selectedPerk[0].rewardImage ? selectedPerk[0].rewardImage : null,
                                    perkTitle: selectedPerk[0].title,
                                    donationDate: new Date(),
                                };

                                dispatch(addDonation(paymentInfo, nonSelectedPerk));

                                await axios.post('https://api.kamamini.net/perkPayment', emailData, {
                                    headers: {
                                        'Authorization': `Bearer ${token}`,
                                        'Content-Type': 'application/json'
                                    }
                                });

                                successToast('Paiement réussi');
                                SubmitProps.setSubmitting(false);
                                SubmitProps.resetForm();
                                setBankCardSubmitting(false);
                                handleClickOpen();
                            } else if (statusResponse.data.status === 'failed' || !statusResponse.data.status) {
                                errorToast('Échec du paiement');
                                SubmitProps.setSubmitting(false);
                                setBankCardSubmitting(false);
                            } else {
                                setDisplayWarningCount(true);
                                setTimeout(() => {
                                    handlePaymentStatus();
                                }, 5000);
                            }
                        } catch (statusError) {
                            errorToast("Une erreur s'est produite lors de la vérification du statut du paiement");
                        }
                    };

                    handlePaymentStatus();
                }
            } catch (error) {
                console.log(error);
                errorToast('Votre demande comporte une erreur. Vérifiez vos informations avant de réessayer.');
                SubmitProps.setSubmitting(false);
                setBankCardSubmitting(false);
            }
        },

    });


    const handleToggleButton = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
            setSelectedPayment(!selectedPayment)
        }
    };



    // bank card form if user has not selected a perk
    const bankCardSubmit = (e) => {
        e.preventDefault();
        setBankCardSubmitting(true)
        setTimeout(() => {
            setBankCardSubmitting(false)
        }, 2000)

        if (formik.values.formA.amount === "" || formik.values.formA.amount < 100) {
            errorToast("Le montant du don doit être supérieur ou égal à 100 francs")
        }
        else if (meta.error) {
            errorToast(meta.error)
        }
        else if (meta.cardType.displayName !== 'Visa' && meta.cardType.displayName !== 'Mastercard') {
            errorToast('La carte doit être une carte Visa ou Mastercard')
        }
        else {
            const data = {
                amount: formik.values.formA.amount,
                cardName: formik.values.formC.cardName,
                cardNumber: parseInt(formik.values.formC.cardNumber.replace(/ /g, '')),
                expiryDate: formik.values.formC.expiryDate,
                cvc: formik.values.formC.cvc,
            }

            // console.log(data);
        }
    }

    // bank card form if user has selected a perk
    const bankCardWithPerkSubmit = (e) => {
        e.preventDefault();
        setBankCardSubmitting(true)
        setTimeout(() => {
            setBankCardSubmitting(false)
        }, 2000)

        if (meta.error) {
            errorToast(meta.error)
        }
        else if (meta.cardType.displayName !== 'Visa' && meta.cardType.displayName !== 'Mastercard') {
            errorToast('La carte doit être une carte Visa ou Mastercard')
        }
        else {
            const data = {
                amount: selectedPerk[0].price,
                cardName: formikPerk.values.formC.cardName,
                cardNumber: parseInt(formikPerk.values.formC.cardNumber.replace(/ /g, '')),
                expiryDate: formikPerk.values.formC.expiryDate,
                cvc: formikPerk.values.formC.cvc,
            }

            // console.log(data);

        }
    }


    const border1 = alignment === 'mobile-money' ? { outlineColor: '#02a056', outlineWidth: '1px', outlineStyle: 'solid', margin: '2px' } : {};
    const border2 = alignment === 'bank-card' ? { outlineColor: '02a056', outlineWidth: '1px', outlineStyle: 'solid', margin: '2px' } : {};

    // if there is no project
    if (noProject && project === null) {
        return (
            <>

                <div className='container py-5'  >
                    <div className="row py-3">
                        <div className="col-md-12">
                            <h2 className='text-center' >Il n'ya pas de project correspondant à cet identifiant. </h2>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    // loader
    else if (project === null) {
        return (
            <div id="preloader">
                <div className="preloader">
                    <CircularProgress style={{ color: 'green' }} />
                </div>
            </div>
        )
    }


    // if user haven't selected a perk and there is a project
    else if (project && selection !== null && selectedPerk.length === 0) {

        return (
            <>
                <ToastContainer />
                <div className='container py-5'  >

                    <div className='col-md-12 mb-4'>
                        <Link to={`/projects/${project.slug}`} >
                            <span className="theme_btn theme-border-btn2 editButton" data-animation="fadeInLeft" data-delay=".5s"  >
                                <ArrowBackIosIcon fontSize='small' />
                                Revenir à la campagne
                            </span>
                        </Link>
                    </div>

                    <div className="row py-3">
                        <div className="col-md-9">
                            <h3 style={{ 'fontSize': '28px', fontWeight: "500" }} >Veuillez sélectionner une récompense</h3>
                            <h6 className='mb-4' style={{ 'fontSize': '20px', fontWeight: "400" }} >sélectionnez une option ci-dessous.</h6>

                            <PerkCard perk={{ title: 'Engagement sans récompense' }}
                                selectedRadioValue={selectedRadioValue}
                                handleChangeRadio={handleChangeRadio}
                                projectSlug={project.slug}
                            />

                            {
                                disponibleSortedPerks && disponibleSortedPerks.map((perk, index) => {
                                    return (
                                        <PerkCard key={index} perk={perk} getDonorsPerPerk={getDonorsPerPerk} selectedRadioValue={selectedRadioValue}
                                            handleChangeRadio={handleChangeRadio}
                                            projectSlug={project.slug}
                                        />
                                    )
                                })
                            }

                        </div>

                        <div className="col-md-3">
                            <p className='perk__content'>
                                Votre contribution représente votre engagement à soutenir un créateur ambitieux qui n'a pas encore réalisé son projet. Il existe un risque que malgré tous les efforts de Kamamini, vous ne receviez pas votre récompense. Réfléchissez-y avant de vous engager. Kamamini ne peut être tenue pour responsable des promesses du créateur ni de la livraison des récompenses.
                            </p>
                        </div>

                    </div>

                </div>

            </>


        )
    }

    // if there is a project and a perk
    else if (selectedPerk && selectedPerk.length > 0) {


        return (
            <>
                <ToastContainer />

          <TextDialog 
          image={"url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fverifie.png?alt=media&token=1fba6d54-4667-44df-829d-175700f59322"+")"}
          title="Votre paiement à été effectué avec succès" 
          handleClose={handleClose}
          open={dialogOpen}
          height="100px" 
          width="100px" />

                <div className='container'>
                    <div className='row py-5'>
                        {/* left side */}
                        <div className='col-md-7'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 mb-4'>
                                        <Link to={`/projects/${project.slug}`} >
                                            <span className="theme_btn theme-border-btn2 editButton" data-animation="fadeInLeft" data-delay=".5s"  >
                                                <ArrowBackIosIcon fontSize='small' />
                                                Revenir à la campagne
                                            </span>
                                        </Link>
                                        <p className='mb-5 mt-3 text-dark' style={{ 'fontSize': '22px' }} >Vous soutenez la campagne <strong>{project.title}</strong>  </p>
                                        <h5>Résumé de l'engagement</h5>
                                    </div>
                                    <div className='row'>
                                        {
                                            selectedPerk[0].rewardImage &&
                                            <div className='col-md-4'>
                                                <img src={selectedPerk[0].rewardImage} alt={selectedPerk[0].title} className='img-fluid' />
                                            </div>
                                        }

                                        <div className='col-md-4 py-2'>
                                            <h5 className='px-2' >{selectedPerk[0].title}</h5>
                                            <p className='text-dark'>{selectedPerk[0].description}</p>
                                        </div>
                                        <div className='col-md-4'>
                                            <h5 className='px-2'>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(selectedPerk[0].price)}</h5>
                                        </div>
                                        <div className='col-md-12'>
                                            <FormControlLabel
                                                className='mt-3 text-dark'
                                                control={<Checkbox value="anonymous" color="primary"
                                                    checked={formikPerk.values.formA.anonymous}
                                                    onChange={formikPerk.handleChange}
                                                    name='formA.anonymous'
                                                    sx={{
                                                        // color: '#02b663',
                                                        '&.Mui-checked': {
                                                            color: '#02a056',
                                                        },
                                                    }}
                                                />}
                                                label="Ne pas afficher mon nom sur la campagne (anonyme)"
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-12 py-5'>
                                        <h4 className='mb-4' >Votre engagement</h4>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className='text-dark px-2'>Récompense</p>
                                                    </div>
                                                    <div>
                                                        <p className='text-dark px-2'>{selectedPerk[0].title}</p>
                                                    </div>
                                                    <div>
                                                        <p className='text-dark px-2'>
                                                            {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(selectedPerk[0].price)}
                                                        </p>
                                                    </div>

                                                </div>

                                                <hr className='no-margin' />

                                                <div className="d-flex justify-content-between py-2">
                                                    <div>
                                                        <h6 className='text-dark'>Montant total</h6>
                                                    </div>
                                                    <div>
                                                        <p className='text-dark' >
                                                            <span style={{ color: '#02b663' }}> <strong>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(selectedPerk[0].price)}</strong></span></p>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* end of left side */}

                        <div className='col-md-5'>
                            <div className='card checkout-card py-3'   >
                                <div className='card-header text-center text-dark'>
                                    <h4>Paiement</h4>
                                    <p>Selectionnez un moyen de paiement</p>
                                </div>
                                <div className='card-body'>
                                    <ThemeProvider theme={theme}>
                                        <ToggleButtonGroup
                                            orientation="vertical"
                                            color="neutral"
                                            value={alignment}
                                            exclusive
                                            onChange={handleToggleButton}
                                            className='mb-3'
                                            fullWidth
                                        >

                                            <ToggleButton size="large" value="mobile-money" style={border1}  >
                                                <li className='px-3'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fairtel.jpg?alt=media&token=82a77f49-5fc6-4edf-9de0-6b5e664025f2"
                                                        className='checkout-image' alt='airtel' />
                                                    <p className='no-margin'>Airtel</p>
                                                </li>
                                                <li className='px-3'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fzamani.png?alt=media&token=0c88845f-8f24-4657-b9f8-5c44602f8b0a"
                                                        className='checkout-image' alt="zamani" />
                                                    <p className='no-margin'> Zamani</p>
                                                </li>
                                                <li className='px-3'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fmoov-africa.jpg?alt=media&token=7f1408db-ab16-4fd3-a91a-87856a356680"
                                                        className='checkout-image' alt='moov' />
                                                    <p className='no-margin'>Moov</p>
                                                </li>
                                            </ToggleButton>

                                        </ToggleButtonGroup>
                                    </ThemeProvider>

                                    <ThemeProvider theme={theme}>
                                        <ToggleButtonGroup
                                            orientation="vertical"
                                            color="neutral"
                                            fullWidth
                                            value={alignment}
                                            exclusive
                                            onChange={handleToggleButton}
                                        >
                                            <ToggleButton size="large" value="bank-card" style={border2}  >
                                                <li className='px-4'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fvisa.png?alt=media&token=64787b6f-9018-45fc-a13b-e3f423405614"
                                                        className='checkout-image' alt='visa' />
                                                    <p className='no-margin'>Visa</p>
                                                </li>
                                                <li className='px-4'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fmastercard.png?alt=media&token=22af7101-38e8-4997-bc50-44c8f6fd7a43" className='checkout-image' alt='mastercard' />
                                                    <p className='no-margin'>Mastercard</p>
                                                </li>


                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </ThemeProvider >

                                    {
                                        (alignment === 'mobile-money') ?
                                            (
                                                <>
                                                    <form onSubmit={formikPerk.handleSubmit} className='mb-3 mt-3' >

                                                        <div className='form-group text-dark'  >
                                                            <div className='row mb-3'  >
                                                                <div className='col-md-6 mb-3'>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                        error={formikPerk.errors.formB && formikPerk.touched.formB && formikPerk.errors.formB.firstName ? true : false}
                                                                        label="Nom"
                                                                        name='formB.firstName'
                                                                        value={formikPerk.values.formB.firstName}
                                                                        onBlur={formikPerk.handleBlur}
                                                                        onChange={formikPerk.handleChange}
                                                                        type='text'
                                                                        helperText={formikPerk.errors.formB && formikPerk.touched.formB && formikPerk.errors.formB.firstName}
                                                                    />

                                                                </div>

                                                                <div className='col-md-6'>

                                                                    <TextField
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                        error={formikPerk.errors.formB && formikPerk.touched.formB && formikPerk.errors.formB.lastName ? true : false}
                                                                        label="Prénom"
                                                                        name='formB.lastName'
                                                                        value={formikPerk.values.formB.lastName}
                                                                        onBlur={formikPerk.handleBlur}
                                                                        onChange={formikPerk.handleChange}
                                                                        type='text'
                                                                        helperText={formikPerk.errors.formB && formikPerk.touched.formB && formikPerk.errors.formB.lastName}
                                                                    />

                                                                </div>
                                                            </div>

                                                            <div className='row mb-4'>
                                                                <div className='col-md-12'>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                        error={formikPerk.errors.formB && formikPerk.touched.formB && formikPerk.errors.formB.number ? true : false}
                                                                        label="Numéro de téléphone"
                                                                        name='formB.number'
                                                                        value={formikPerk.values.formB.number}
                                                                        onBlur={formikPerk.handleBlur}
                                                                        onChange={formikPerk.handleChange}
                                                                        type='number'
                                                                        helperText={formikPerk.errors.formB && formikPerk.touched.formB && formikPerk.errors.formB.number}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <KamaminiButton type='submit' text={formikPerk.isSubmitting ? 'Traitement' : 'Payer'} disabled={formikPerk.isSubmitting} fullWidth />

                                                    </form>

                                                    <small className=' text-dark' >En validant votre engagement, vous acceptez  <Link to='/terms-conditions' className='link' >les Conditions d'utilisation</Link>   et <Link to='/privacy-policy' className='link'>la Politique de confidentialité</Link>  de Kamamini et vous nous autorisez à débiter votre moyen de paiement.</small>

                                                </>
                                            )
                                            :

                                            (
                                                <>
                                                    <form onSubmit={bankCardWithPerkSubmit} className='mb-3 mt-3'>

                                                        <div className='form-group text-dark'>

                                                            <div className="row">
                                                                <div className="col-12 mb-2">
                                                                    <TextField
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                        error={formikPerk.errors.formC && formikPerk.touched.formC && formikPerk.errors.formC.cardName ? true : false}
                                                                        label="Nom sur la carte"
                                                                        name='formC.cardName'
                                                                        value={formikPerk.values.formC.cardName}
                                                                        onBlur={formikPerk.handleBlur}
                                                                        onChange={formikPerk.handleChange}
                                                                        type='cardName'
                                                                        helperText={formikPerk.errors.formC && formikPerk.touched.formC && formikPerk.errors.formC.cardName}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-12 mb-2">
                                                                    <label className="text mb-1">Numéro de la carte</label>
                                                                    <div className="input-group input-group-lg">
                                                                        <span className="input-group-text bg-light" id="basic-addon1">
                                                                            <svg {...getCardImageProps({ images })} />
                                                                        </span>

                                                                        <input className="form-control" required  {...getCardNumberProps({ onChange: formikPerk.handleChange })} placeholder="1234 5678 435678" name='formC.cardNumber'
                                                                            value={formikPerk.values.formC.cardNumber}
                                                                        />
                                                                    </div>
                                                                    <div style={{ color: "red" }}>
                                                                        {meta.isTouched && meta.erroredInputs.cardNumber && <small>{meta.erroredInputs.cardNumber} </small>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-4">
                                                                <div className="col-6">
                                                                    <label >Date d'expiration</label>
                                                                    <div className="input-group input-group-lg">
                                                                        <input className="form-control" required
                                                                            {...getExpiryDateProps({ onChange: formikPerk.handleChange })}
                                                                            name='formC.expiryDate'
                                                                            value={formikPerk.values.formC.expiryDate}
                                                                        />
                                                                    </div>
                                                                    <div style={{ color: "red" }}>
                                                                        {meta.isTouched && meta.erroredInputs.expiryDate && <small>{meta.erroredInputs.expiryDate} </small>}
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <label >Code CVC</label>
                                                                    <div className="input-group input-group-lg">
                                                                        <input className="form-control" required  {...getCVCProps({ onChange: formikPerk.handleChange })} name='formC.cvc' value={formikPerk.values.formC.cvc} />
                                                                    </div>
                                                                    <div style={{ color: "red" }}>
                                                                        {meta.isTouched && meta.erroredInputs.cvc && <small>{meta.erroredInputs.cvc} </small>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <KamaminiButton type='submit' text={bankCardSubmitting ? 'Traitement' : 'Payer'} disabled={bankCardSubmitting} fullWidth />

                                                    </form>

                                                    <small className=' text-dark' >En validant votre engagement, vous acceptez  <Link to='/terms-conditions' className='link' >les Conditions d'utilisation</Link>   et <Link to='/privacy-policy' className='link'>la Politique de confidentialité</Link>  de Kamamini et vous nous autorisez à débiter votre moyen de paiement.</small>
                                                </>

                                            )
                                    }


                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </>
        )
    }

    // if there are no perk for the project, we display donation page
    else if (selectedPerk && selectedPerk.length === 0) {
        return (
            <>
                <ToastContainer />

          <TextDialog 
          image={"url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fverifie.png?alt=media&token=1fba6d54-4667-44df-829d-175700f59322"+")"}
          title="Votre paiement à été effectué avec succès" 
          handleClose={handleClose}
          open={dialogOpen}
          height="100px" 
          width="100px" />
        
        <div className='container'>
            <div className='row py-5'>
                {/* left side */}
                <div className='col-md-7'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 mb-4'>
                                    <Link to={`/projects/${project.slug}`} >
                                        <span className="theme_btn theme-border-btn2 editButton"  data-animation="fadeInLeft" data-delay=".5s"  >
                                            <ArrowBackIosIcon fontSize='small' />
                                            Revenir à la campagne  
                                        </span>
                                    </Link>
                                    <p className='mb-5 mt-3 text-dark' style={{'fontSize':'22px'}} >Vous soutenez la campagne <strong>{project.title}</strong>  </p>
                                    <h5>Résumé de l'engagement</h5>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-4'>
                                    <img src={project.featuredImage} alt={project.title} className='img-fluid' />
                                </div>
                                <div className='col-md-4 py-2'>
                                    <Link to={`/projects/${project.slug}`}>
                                    <h5 className='px-2' >{project.title}</h5>
                                    </Link>
                                    
                                    <p className='text-dark px-2' >Votre don aidera le projet <strong>{project.title}</strong> </p>
                                </div>
                            </div>

                                    <div className='col-md-12 py-2'>
                                        <h4 className='mb-3'> Entrez le montant de votre don</h4>

                                        <form onSubmit={formik.handleSubmit} >
                                            <FormControl
                                                variant="outlined" required
                                                error={formik.errors.formA && formik.touched.formA ? true : false}
                                                fullWidth
                                            >
                                                <InputLabel htmlFor="outlined-adornment-password">Montant</InputLabel>
                                                <OutlinedInput
                                                    autoFocus
                                                    value={formik.values.formA.amount}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    name='formA.amount'
                                                    type='number'
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                FCFA
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    labelWidth={80}
                                                />
                                                {formik.errors.formA && formik.touched.formA && (
                                                    <FormHelperText id="outlined-weight-helper-text">{formik.errors.formA.amount}</FormHelperText>
                                                )}
                                            </FormControl>
                                            <FormControlLabel
                                                className='mt-3 text-dark'
                                                control={<Checkbox value="anonymous" color="primary"
                                                    checked={formik.values.formA.anonymous}
                                                    onChange={formik.handleChange}
                                                    name='formA.anonymous'
                                                    sx={{
                                                        // color: '#02b663',
                                                        '&.Mui-checked': {
                                                            color: '#02a056',
                                                        },
                                                    }}
                                                />}
                                                label="Ne pas afficher mon nom sur la campagne (anonyme)"
                                            />
                                        </form>

                                        <div className='col-md-12 py-5'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className='text-dark px-2'>Récompense</p>
                                                        </div>
                                                        <div>
                                                            <span className=' px-2'>Non merci, je souhaite simplement soutenir le projet</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <div>
                                                            <p className='text-dark px-2'> {formik.values.formA.amount > 0 && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(formik.values.formA.amount)} </p>
                                                        </div>
                                                    </div>

                                                    <hr className='no-margin' />

                                                    <div className="d-flex justify-content-between py-2">
                                                        <div>
                                                            <h6 className='text-dark'>Montant total</h6>
                                                        </div>
                                                        <div>
                                                            <p className='text-dark' >
                                                                <span style={{ color: '#02b663' }}> <strong> {formik.values.formA.amount > 0 && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(formik.values.formA.amount)}</strong></span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* end of left side */}

                        {/* right side */}
                        <div className='col-md-5'>
                            <div className='card checkout-card py-3'   >
                                <div className='card-header text-center text-dark'>
                                    <h4>Paiement</h4>
                                    <p>Selectionnez un moyen de paiement</p>
                                </div>
                                <div className='card-body'>
                                    <ThemeProvider theme={theme}>
                                        <ToggleButtonGroup
                                            orientation="vertical"
                                            color="neutral"
                                            value={alignment}
                                            exclusive
                                            onChange={handleToggleButton}
                                            className='mb-3'
                                            fullWidth
                                        >

                                            <ToggleButton size="large" value="mobile-money" style={border1}  >
                                                <li className='px-3'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fairtel.jpg?alt=media&token=82a77f49-5fc6-4edf-9de0-6b5e664025f2"
                                                        className='checkout-image' alt='airtel' />
                                                    <p className='no-margin'>Airtel</p>
                                                </li>
                                                <li className='px-3'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fzamani.png?alt=media&token=0c88845f-8f24-4657-b9f8-5c44602f8b0a"
                                                        className='checkout-image' alt='zamani' />
                                                    <p className='no-margin'> Zamani</p>
                                                </li>
                                                <li className='px-3'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fmoov-africa.jpg?alt=media&token=7f1408db-ab16-4fd3-a91a-87856a356680"
                                                        className='checkout-image' alt='moov' />
                                                    <p className='no-margin'>Moov</p>
                                                </li>
                                            </ToggleButton>

                                        </ToggleButtonGroup>
                                    </ThemeProvider>

                                    <ThemeProvider theme={theme}>
                                        <ToggleButtonGroup
                                            orientation="vertical"
                                            color="neutral"
                                            fullWidth
                                            value={alignment}
                                            exclusive
                                            onChange={handleToggleButton}
                                        >
                                            <ToggleButton size="large" value="bank-card" style={border2}  >
                                                <li className='px-4'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fvisa.png?alt=media&token=64787b6f-9018-45fc-a13b-e3f423405614"
                                                        className='checkout-image' alt='visa' />
                                                    <p className='no-margin'>Visa</p>
                                                </li>
                                                <li className='px-4'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fmastercard.png?alt=media&token=22af7101-38e8-4997-bc50-44c8f6fd7a43"
                                                        className='checkout-image' alt='mastercard' />
                                                    <p className='no-margin'>Mastercard</p>
                                                </li>


                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </ThemeProvider >

                                    {
                                        (alignment === 'mobile-money') ?
                                            (
                                                <>
                                                    <form onSubmit={formik.handleSubmit} className='mb-3 mt-3' >

                                                        <div className='form-group text-dark'  >
                                                            <div className='row mb-3'  >
                                                                <div className='col-md-6'>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                        error={formik.errors.formB && formik.touched.formB && formik.errors.formB.firstName ? true : false}
                                                                        label="Nom"
                                                                        name='formB.firstName'
                                                                        value={formik.values.formB.firstName}
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        type='text'
                                                                        helperText={formik.errors.formB && formik.touched.formB && formik.errors.formB.firstName}
                                                                    />

                                                                </div>

                                                                <div className='col-md-6'>

                                                                    <TextField
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                        error={formik.errors.formB && formik.touched.formB && formik.errors.formB.lastName ? true : false}
                                                                        label="Prénom"
                                                                        name='formB.lastName'
                                                                        value={formik.values.formB.lastName}
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        type='text'
                                                                        helperText={formik.errors.formB && formik.touched.formB && formik.errors.formB.lastName}
                                                                    />

                                                                </div>
                                                            </div>

                                                            <div className='row mb-4'>
                                                                <div className='col-md-12'>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                        error={formik.errors.formB && formik.touched.formB && formik.errors.formB.number ? true : false}
                                                                        label="Numéro de téléphone"
                                                                        name='formB.number'
                                                                        value={formik.values.formB.number}
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        type='number'
                                                                        helperText={formik.errors.formB && formik.touched.formB && formik.errors.formB.number}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <KamaminiButton type='submit' text={formik.isSubmitting ? 'Traitement' : 'Payer'} disabled={formik.isSubmitting} fullWidth />

                                                    </form>

                                                    <small className=' text-dark' >En validant votre engagement, vous acceptez  <Link to='/terms-conditions' className='link' >les Conditions d'utilisation</Link>   et <Link to='/privacy-policy' className='link'>la Politique de confidentialité</Link>  de Kamamini et vous nous autorisez à débiter votre moyen de paiement.</small>

                                                </>
                                            )
                                            :

                                            (
                                                <>
                                                    <form onSubmit={bankCardSubmit} className='mb-3 mt-3'>

                                                        <div className='form-group text-dark'>

                                                            <div className="row">
                                                                <div className="col-12 mb-2">
                                                                    <TextField
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                        error={formik.errors.formC && formik.touched.formC && formik.errors.formC.cardName ? true : false}
                                                                        label="Nom sur la carte"
                                                                        name='formC.cardName'
                                                                        value={formik.values.formC.cardName}
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        type='cardName'
                                                                        helperText={formik.errors.formC && formik.touched.formC && formik.errors.formC.cardName}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-12 mb-2">
                                                                    <label className="text mb-1">Numéro de la carte</label>
                                                                    <div className="input-group input-group-lg">
                                                                        <span className="input-group-text bg-light" id="basic-addon1">
                                                                            <svg {...getCardImageProps({ images })} />
                                                                        </span>

                                                                        <input className="form-control" required  {...getCardNumberProps({ onChange: formik.handleChange })} placeholder="1234 5678 435678" name='formC.cardNumber'
                                                                            value={formik.values.formC.cardNumber}
                                                                        />
                                                                    </div>
                                                                    <div style={{ color: "red" }}>
                                                                        {meta.isTouched && meta.erroredInputs.cardNumber && <small>{meta.erroredInputs.cardNumber} </small>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-4">
                                                                <div className="col-6">
                                                                    <label >Date d'expiration</label>
                                                                    <div className="input-group input-group-lg">
                                                                        <input className="form-control" required
                                                                            {...getExpiryDateProps({ onChange: formik.handleChange })}
                                                                            name='formC.expiryDate'
                                                                            value={formik.values.formC.expiryDate}
                                                                        />
                                                                    </div>
                                                                    <div style={{ color: "red" }}>
                                                                        {meta.isTouched && meta.erroredInputs.expiryDate && <small>{meta.erroredInputs.expiryDate} </small>}
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <label >Code CVC</label>
                                                                    <div className="input-group input-group-lg">
                                                                        <input className="form-control" required  {...getCVCProps({ onChange: formik.handleChange })} name='formC.cvc' value={formik.values.formC.cvc} />
                                                                    </div>
                                                                    <div style={{ color: "red" }}>
                                                                        {meta.isTouched && meta.erroredInputs.cvc && <small>{meta.erroredInputs.cvc} </small>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <KamaminiButton type='submit' text={bankCardSubmitting ? 'Traitement' : 'Payer'} disabled={bankCardSubmitting} fullWidth />

                                                    </form>

                                                    <small className=' text-dark' >En validant votre engagement, vous acceptez  <Link to='/terms-conditions' className='link' >les Conditions d'utilisation</Link>   et <Link to='/privacy-policy' className='link'>la Politique de confidentialité</Link>  de Kamamini et vous nous autorisez à débiter votre moyen de paiement.</small>
                                                </>

                                            )
                                    }


                                </div>
                            </div>

                        </div>

                        {/* end of right side */}


                    </div>
                </div>
            </>
        )
    }

}

export default Checkout
