import React, { useEffect, useState } from 'react';
import Resizer from "react-image-file-resizer";
import { Grid, LinearProgress } from '@material-ui/core';
import { FileHeader } from './FileHeader';
import {errorToast} from '../../redux/actions/actions'

export function SingleFileUploadWithProgress({
  file,
  onDelete,
  onUpload,
  fileUrl
}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    async function upload() {
      try {
        const image = await resizeFile(file);
        const url =  await uploadFile(image, setProgress);
        // const url = await  uploadToLocalStorage(file,setProgress);
        onUpload(file, url);
    } catch (error) {
      errorToast("Votre image n'a pas pu être sauvegardée, essayez de nouveau.")
    }
    }

    upload();
  }, []);
  
  // console.log(fileUrl);
  
  return (
    <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
        <FileHeader file={file} onDelete={onDelete} />
        <br/>
        <LinearProgress variant="determinate" value={progress} />
      </Grid>
    </Grid>
   
  );
}

function uploadFile(file, onProgress) {
  const keys = "wwm57ooi";
  const urls = 'https://api.cloudinary.com/v1_1/kamamini/image/upload';
  

  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', urls);

    xhr.onload = () => {
      const resp = JSON.parse(xhr.responseText);
      res(resp.secure_url);
    };
    xhr.onerror = (evt) => rej(evt);
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentage));
      }
    };

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', keys);

    xhr.send(formData);
  });
}

 function uploadToLocalStorage  (file, onProgress) {
  return new Promise((res, rej) => {  
      const reader = new FileReader();
      reader.addEventListener('load', ()=>{
        localStorage.setItem('featured-image',reader.result)
        res(reader.result)
    })

    reader.onerror = (evt) => rej(evt);
    reader.onprogress = (event) => {
      console.log(event)
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentage));
      }
    };

    reader.readAsDataURL(file);
  });
  }


  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      1200,
      "JPEG",
      150,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });