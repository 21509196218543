import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Seo from '../Components/Helmet/Seo';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <Seo
        title="FAQ"
        description="Trouvez des réponses à vos questions fréquemment posées à propos de Kamamini."
        name="Kamamini"
        type="website"
      />
      <main>

        {/* <!--page-title-area start--> */}
        <section className="page-title-area h-4" style={{
          backgroundImage: "url(" + "https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fbg%2Fpexels0.jpg?alt=media&token=36693263-d3f1-40c4-a59e-bf93cf603806" + ")"
          ,
        }}>
          <div className="right-border-shape">
            {/* <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fevents%2F15.jpg?alt=media&token=799a5ce3-9e35-46a3-a221-91b659b095ac"
                  alt="" /> */}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-10 offset-xl-1">
                <div className="page-title-wrapper text-center">
                  <h1 className="page-title wow fadeInUp2 animated" >Faq</h1>
                  <div className="breadcrumb">
                    <ul className="breadcrumb-list wow fadeInUp2 animated" >
                      {/* <li><a href="index.html">Home <i className="far fa-chevron-right"></i></a></li>
                                      <li><a className="active" href="#">Faq</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--page-title-area area--> */}
        <section className="faq-area-02 grey-bg pt-130 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8">
                <div className="faq-content mb-30">

                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                      <Typography>  Qu’est-ce que le financement participatif ou crowdfunding ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Le financement par la foule, c’est un levier qui permet au grand public de soutenir financièrement et
                        collectivement une idée ou un projet qui le séduit. Le financement participatif (en anglais crowdfunding) est une expression
                        décrivant tous les outils et méthodes de transactions financières qui font appel à
                        un grand nombre de personnes pour financer un projet.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                      <Typography> Kamamini ? C’est quoi ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        La start-up kamamini propose une plateforme de financement participative aidant à la réalisation de projets de toutes personnes ou entités dévouée ayant un project suceptible de contribuer à l'économie et au bonheur des populations . Le crowdfunding fait appel à la bienveillance des personnes et leur générosité envers les porteurs de projets. vous pouvez donner à titre gratuit ou acceder aux recompenses proposés pas nos porteurs de projects

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  {/* <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography> 3) La somme collectée me revient-elle intégralement ?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Les projets doivent atteindre 90% de l’objectif de collecte fixé par le créateur de projet pour recevoir les contributions 
            des donateurs. Le cas échéant, les donateurs sont remboursés automatiquement à 90% de leur contribution.
                                              
            </Typography>
          </AccordionDetails>
        </Accordion> */}
                  {/* <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography>4) Que se passe-t-il quand la somme voulue n’est pas collectée dans le temps imparti ?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Dans ce cas aucun don n’est prélevé. D’où l’importance de ne pas surévaluer le besoin ou de fractionner l’investissement en étapes. 
            Dans un souci de réussite totale de votre projet, l’équipe KAMAMINI vous apportera son expertise et ses conseils afin de fixer 
            un objectif réalisable.                                         
            </Typography>
          </AccordionDetails>
        </Accordion> */}
                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <Typography> Quelle limite m’est fixée quand je demande du financement ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Aucun plafond n’est imposé. L’idée est que le public s’investisse complètement, que ca soit en partageant le projet ou en faisant
                        une contribution, ou encore en donnant de son temps. La requête du porteur de projet doit cependant rester raisonnable,
                        et coller à l’initiative souhaitée
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                      <Typography>  Qu’y gagnent les contributeurs?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Les donateurs agissent de leur propre initiative, le bon vouloir est la seule condition pour financer un projet.
                        La contribution n’est pas un investissement en soi puisque la propriété intellectuelle reste celle des créateurs de projet.
                        Ces derniers peuvent, s’ils le souhaitent affecter des contreparties personnalisées à chaque contribution, en fonction de la somme donnée. Elles sont délivrées une fois la collecte de fonds réussie. Ces contreparties sont proposées par les porteurs de projet mais doivent rester conformes à l’état d’esprit requis
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                      <Typography>  Quel est l’apport pour KAMAMINI?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        KAMAMINI perçoit une commission de 5% sur les collectes de fonds réussies. Par contre elle ne perçoit évidemment rien sur
                        les collectes de fonds qui échouent.
                        Aide aux contributeurs
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                      <Typography> L’inscription sur Kamamini est elle payante ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Non, l’accès et l’utilisation du site ne sont soumis à aucun frais.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                      <Typography>  Puis je contacter un porteur de projet ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Cela est possible, encore faut il être contributeur du projet en question. Pour ce faire, rendez vous sur la page du projet,
                        au dessus de l’image, cliquez sur “Envoyer un message”. Vous pouvez également rédiger un commentaire en bas de la page projet.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                      <Typography>  Suis je autorisé à supporter plusieurs projets en même temps ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Bien sûr ! Vous avez la possibilité de composer votre “portefeuille” de projets.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                      <Typography>  Ai-je la possibilité de contribuer sans donner mon identité ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Tout à fait. Il est possible de contribuer de maniere anonyme, il est au prealable obligatoire de crée un compte .
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  {/* <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
          <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
            <Typography>  12) Je peux cacher le montant de ma contribution ?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Oui. Vous pouvez décider de ne pas rendre public le montant de votre contribution. Il suffit de cocher lors du paiement la case 
            “Je souhaite cacher le montant de ma contribution” en dessous du montant que vous soumettrez.                                            
            </Typography>
          </AccordionDetails>
        </Accordion> */}
                  <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
                      <Typography> Comment savoir que le projet que j’ai soutenu a réussi ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        KAMAMINI se charge d’envoyer un e-mail à tous les contributeurs concernés par le projet,
                        et propage de même l’information sur ses réseaux sociaux (facebook, twitter, etc…)
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                    <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
                      <Typography> Les conditions générales d’utilisation auxquelles j’adhère sur KAMAMINI sont elles un contrat entre le porteur de projet et moi ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Elles ne le sont en aucun cas. Il n’existe pas de lien contractuel entre vous et le porteur de projet. Le seul lien contractuel qui
                        existe relie le porteur de projet à KAMAMINI sur la base d’un contrat signé pour chaque projet soumis et validé.
                        Le déroulement des collectes
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                    <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
                      <Typography> Les porteurs de projet connaissent-ils mon identité une fois que j’ai soutenu leur idée ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Les seules informations qui lui sont communiquées sont votre adresse e-mail et votre numéro de téléphone, pour qu’il puisse
                        éventuellement vous faire parvenir sa contrepartie. Toute autre information telle que vos coordonnées bancaires est à juste
                        titre confidentielle et vous restez le seul capable d’y accéder.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                    <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
                      <Typography> Sur quelles bases avoir confiance en des porteurs de projets ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Nous contrôlons leur fiabilité avec toutes nos ressources à disposition et à cet effet avant de valider leurs projets. De plus,
                        nous avons affaire à des associations, des hôpitaux, des particuliers ... S’ils abusaient du système, vos remarques et
                        commentaires nous obligeraient à les éliminer de kamamini. Leur réputation serait largement endommagée. Nous partons du principe que
                        la majorité des créateurs de projets sauront respecter leurs engagements. N’oubliez pas que les collectes se font d’abord au sein de
                        la communauté des créateurs. C’est la meilleure garantie contre les abus en tout genre.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                    <AccordionSummary aria-controls="panel14d-content" id="panel14d-header">
                      <Typography>Les contenus mis à ma disposition sur kamamini sont-ils fiables ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Tout à fait. Ils nous sont fournis par les créateurs de projets eux-mêmes. C’est leur responsabilité de faire en sorte que tout le contenu soit libre
                        de droit.
                        Le Paiement
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="faq-right">
                  {/* <div className="widget mb-30 wow fadeInUp2 animated" >
                                  <div className="widget-donate-box pos-rel text-center" style={{backgroundImage:`url(${BG4})`}}>
                                      <h5>Donate Now</h5>
                                      <h3>Want To Donate  
                                          Our Product</h3>
                                          <a className="theme_btn theme_btn_bg" href="contact.html"
                                          data-animation="fadeInLeft" data-delay=".5s">donate now <i
                                              className="far fa-arrow-right"></i></a>
                                  </div>
                              </div> */}
                  <div className="widget white-bg mb-40 wow fadeInUp2 animated" >
                    <div className="widget-contact-box pos-rel">
                      <h4>Contactez Nous</h4>
                      {/* <p>Sed perspicia unde omnis iste natus error sit voluptatem accuese</p> */}
                      <ul className="fot-list address-list mb-20">
                        <li><a href="#"><i className="fas fa-map-marker-alt"></i> Niamey</a></li>
                        <li><a href="#"><i className="fas fa-envelope"></i> contact@kamamini.net</a></li>
                        <li><a href="#"><i className="fas fa-phone-volume"></i>+227 97507172</a></li>
                      </ul>
                      <ul className="team__social media-social mt-10">
                        <li>
                          <a href="#">

                            <i className="fab fa-facebook-f"></i>
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-youtube"></i>
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-behance"></i>
                            <i className="fab fa-behance"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--feature-project-area end--> */}
        {/* <!--cta-area start--> */}
        <section className="cta-area theme-bg pt-55 pb-25">
          <div className="container">
            <div className="row align-items-md-center">
              <div className="col-xl-9 col-lg-9 col-md-8">
                <div className="cta-wrapper wow fadeInUp2 animated" >
                  <div className="section-title mb-30">
                    <h2>Devenez notre partenaire <b>Premium</b> ?</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4">
                <div className="cta-wrapper wow fadeInUp2 animated" >
                  <div className="cta-btn text-md-right">
                    <a className="theme_btn theme_btn_bg" href="contact.html">contactez nous <i
                      className="far fa-arrow-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--cta-area end--> */}
      </main>

    </>
  )
}



