import React, { useState, useEffect } from 'react'
import {
  CssBaseline,
} from '@material-ui/core';
import { Pagination, PaginationItem } from '@mui/material';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import { fetchUserProject, getMyDonationProjects } from '../redux/actions/projectActions';
import { notAllowed } from '../redux/actions/actions';
import '../assets/css/profile.css';
import SingleProjectCard2 from '../Components/Cards/SingleProjectCard2/SingleProjectCard2'
import { Tabs, Tab, Paper } from '@mui/material';
import Seo from '../Components/Helmet/Seo';
import SingleProjectCard1 from '../Components/Cards/SingleProjectCard1/SingleProjectCard1'
import Errorcase from '../Components/ErrorCase/ErrorCase';
import Loader from '../Components/Loader/Loader';




const UserDashboard = (props) => {
  const userInfo = useSelector(state => state.auth.userInfo)
  const dispatch = useDispatch()
  const userProjects = useSelector(state => state.projects.userProjects)
  const donationProjects = useSelector(state => state.projects.userDonationProjects)
  const loading = useSelector(state => state.projects.userProjectsLoading)
  const userProjectsError = useSelector(state => state.projects.userProjectsError)
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Définis la taille de la page selon tes besoins

  // Gère la pagination localement
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedProjects = userProjects && userProjects.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  let history = useHistory();

  if (!userInfo) {
    history.push("/");
    dispatch(notAllowed())
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(fetchUserProject())
    dispatch(getMyDonationProjects(userInfo.uid))
  }, [dispatch, userInfo])

  if (loading) {
    return (
      <Loader />
    )
  }

  if (userProjectsError) {
    return (
      <Errorcase />
    )
  }

  // if (userInfo && userProjects) {
  return (
    <>
      <Seo
        title="Mon Profil"
        description="Consultez et gérez votre profil sur Kamamini. Suivez vos projets, modifiez vos paramètres et bien plus encore."
        name="Kamamini"
        type="website"
      />

      <CssBaseline />

      <div className="container-fluid rounded" style={{ background: '#fff', padding: "0", margin: '0' }}>
        <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fproject%2Fagadez-cover.jpg?alt=media&token=370cad75-1673-4f2e-980b-1c14f2c1c861' className='w-100  img-fluid' style={{ height: '300px' }} alt='new' />
        <div style={{ borderBottom: '0.5rem solid #1A1E2D' }} ></div>
        <p className='mb-5 py-2'></p>
        <div className='container '>
          <div className='row py-5 '>
            <div className='col-md-4 col-sm-12'>
              <div className="card   border-info-custom" style={{ borderRadius: '50 px 50px' }}>
                <div className='card w-50 mx-5 h-100 '>
                  {
                    userInfo.photoURL &&
                    <img src={userInfo.photoURL} className="card-img-top w-100 mx-5 rounded-3" style={{ marginTop: '-50px' }} alt={userInfo.displayName} />
                  }
                </div>
                <h4 className='text-center mx-5 py-3'>{userInfo.displayName}</h4>
                <div className="card-body">
                  {
                    userInfo.bio && <>
                      <h5 style={{ color: '#02b663' }} >Biographie: </h5> <h5 className='text-dark'>{userInfo.bio}</h5>
                    </>
                  }

                  <h5 style={{ color: '#02b663' }} className='py-2'>Email: <span className='text-dark'>{userInfo?.email}</span></h5>
                  <h5 style={{ color: '#02b663' }} className='py-2'>Nombre de projet: <span className='text-dark'>{userProjects?.length}</span></h5>
                  {
                    userInfo?.number &&
                    <h5 style={{ color: '#02b663' }} className='py-2'>Phone: <span className='text-dark'>{userInfo?.number && userInfo?.number}</span></h5>
                  }

                  {
                    userInfo?.city &&
                    <h5 style={{ color: '#02b663' }} className='py-2'>Ville: <span className='text-dark'>{userInfo?.city && userInfo?.city}</span></h5>
                  }

                </div>
                <div className='card-footer'>
                  <div className="profile-work text-center">
                    <h3 style={{ color: '#1A1E2D' }} className='py-2'>Réseaux sociaux</h3>
                    <ul className="fot-list link-list d-flex justify-content-between mx-5">
                      {
                        userInfo.websiteLink &&
                        <>
                          <li><a href={userInfo.websiteLink} rel="noopener noreferrer" target='_blank' > <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/800px-Google_Chrome_icon_%28February_2022%29.svg.png' alt='internet' className='img-fluid rounded-circle ' style={{ width: '1.5rem' }} /></a></li>
                        </>
                      }
                      {
                        userInfo.facebookLink &&
                        <>
                          <li><a href={userInfo.facebookLink} rel="noopener noreferrer" target='_blank' >
                            <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/fichier%20pour%20emails%2Fimag.png?alt=media&token=0d6d2032-0daf-4433-92bd-5fa285b6ae0a' className='img-fluid rounded-circle ' style={{ width: '1.5rem' }} alt='facebook' />
                          </a></li>
                        </>
                      }

                      {
                        userInfo.twitterLink &&
                        <>
                          <li><a href={userInfo.twitterLink} rel="noopener noreferrer" target='_blank' >
                            <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/fichier%20pour%20emails%2Ftw.png?alt=media&token=7ceb45af-e0ac-49cd-94f3-ed594ac6cc3a' className='img-fluid rounded-circle ' style={{ width: '1.5rem' }} alt='twitter' />
                          </a></li>
                        </>
                      }
                      <br />

                      {
                        userInfo.linkedinLink &&
                        <>
                          <li><a href={userInfo.linkedinLink} rel="noopener noreferrer" target='_blank' >
                            <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/fichier%20pour%20emails%2Flinkedin%20(1).png?alt=media&token=18c94a56-1f20-4c93-b266-19202739c0db' className='img-fluid rounded-circle ' style={{ width: '1.5rem' }} alt='linkedin' />
                          </a></li>
                        </>
                      }
                      <br />


                      {
                        userInfo.instagramLink &&
                        <>
                          <li><a href={userInfo.instagramLink} rel="noopener noreferrer" target='_blank' >
                            <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/fichier%20pour%20emails%2Finsta1.jpg?alt=media&token=2e5d9d93-2715-46ae-b218-20fc1c117be2' className='img-fluid rounded-circle ' style={{ width: '1.5rem' }} alt='instagram' />
                          </a></li>
                        </>
                      }
                      <br />

                      {
                        !userInfo.websiteLink && !userInfo.facebookLink && !userInfo.twitterLink && !userInfo.linkedinLink && !userInfo.instagramLink &&
                        <>
                          <p className='no_social_media' >Vous n'avez ajouté aucun liens vers vos résaux sociaaux </p>
                          <div className="d-flex justify-content-between align-items-center experience">
                            <Link to="/edit-profile" className="border px-3 p-1 add-experience"><i className="fa fa-plus"></i>&nbsp;Ajouter</Link>
                          </div> <br />
                        </>
                      }
                    </ul>
                    <p></p>
                    <Link to="/edit-profile" className="theme_btn theme-border-btn2 editButton mx-3 " data-animation="fadeInLeft" data-delay=".5s">
                      Modifier mon profil
                      <i className="fas fa-edit"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-8 col-sm-12'>
              <div className='row w-100 justify-content-around'>
                <Paper elevation={3} style={{ width: '100%', marginBottom: '20px' }}>
                  <Tabs

                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: { backgroundColor: 'black' }
                    }}
                    textColor='inherit'
                    centered
                  >
                    <Tab style={{ width: '50%' }} label="Mes Projects" />
                    <Tab style={{ width: '50%' }} label="Mes contributions" />
                  </Tabs>
                </Paper>

                {/* Contenu des onglets */}
                {value === 0 && <div className='row'>
                  <div className='row border-info-custom justify-content-around mx-3'>
                    <div className='row d-flex justify-content-around  h-100 py-2 mx-5'>
                      {
                        displayedProjects && displayedProjects?.length ? (
                          displayedProjects.map(project => {
                            return (
                              <div className='' key={project.id}>
                                <SingleProjectCard2 project={project} />
                              </div>
                            )
                          })

                        ) : (
                          <div className='col-12'>
                            <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fproject%2FNoPoject.png?alt=media&token=9c2015bb-03f6-4ea8-b58f-ee4163b91b86' alt='no project' className='img-fluid' />
                          </div>
                        )
                      }
                      {

                        userProjects?.length > 0 && (
                          <div className='row d-flex justify-content-center mt-4 text-center pt-5'>
                            <Pagination
                              count={Math.ceil(userProjects.length / pageSize)}
                              page={currentPage}
                              classes={{
                                "& .MuiPaginationItem-root": {
                                  color: "#fff",
                                  backgroundColor: '#02a056'
                                }
                              }}
                              // color="primary"

                              size="large"
                              onChange={handlePageChange}
                              renderItem={(item) => (
                                <PaginationItem
                                  sx={
                                    item.page === currentPage
                                      ? {
                                        backgroundColor: '#1A1E2D !important',
                                        color: '#fff'
                                      }
                                      : {}
                                  }
                                  component="button"
                                  {...item}
                                />
                              )}
                            />

                          </div>
                        )
                      }

                    </div>
                  </div>
                </div>

                }
                {value === 1 && <div className='row'>
                  <div className='row border-info-custom justify-content-around'>
                    <div className='row d-flex justify-content-around   py-2 mx-2'>
                      {
                        donationProjects && donationProjects?.length ? (
                          donationProjects.map(project => {
                            return (
                              <div className='' key={project.id}>
                                <SingleProjectCard1 project={project} />
                              </div>
                            )
                          })

                        ) : (
                          <div className='col-12'>
                            <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fproject%2FNoPoject.png?alt=media&token=9c2015bb-03f6-4ea8-b58f-ee4163b91b86' alt='no project' className='img-fluid' />
                          </div>
                        )
                      }
                    </div>
                  </div>

                </div>}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )

  // }
  // else {
  //   return (
  //     <div id="preloader">
  //       <div className="preloader">
  //         <CircularProgress style={{ color: 'green' }} />
  //       </div>
  //     </div>
  //   )
  // }

}

export default UserDashboard
