import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { CircularProgress } from '@material-ui/core'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '../../assets/css/carroussel.css'
import SingleProjectCard1 from '../Cards/SingleProjectCard1/SingleProjectCard1';


function CardCaroussel({ getFeaturedProjects }) {


  const splideOptions = {
    rewind: true,
    type: "loop",
    focus: "center",
    gap: "1rem",
    pagination: false,
    perPage: 3,

    breakpoints: {
      1200: {
        perPage: 2,
        gap: "0.8rem",
      },
      768: {
        perPage: 1,
      },
    },
  };

  if (getFeaturedProjects) {

    return (
      <section className="feature-area grey-bg pos-rel pt-50 pb-100">
        <div className='container' >
          <div class="row">
            <div className="col-xl-10 offset-xl-1 wow fadeInUp2 animated" data-wow-delay='.1s'>
              <div className="section-title text-center mb-85">
                <h6 className="left-line pl-75 pr-75">Projets en tendance</h6>
                <h3>Explorez les projets les plus populaires du moment</h3>
              </div>
            </div>
          </div>
          <div className="justify-content-lg-between">
            <Splide options={splideOptions}>
              {
                getFeaturedProjects.map((project) => (
                  <SplideSlide key={project.id}>
                    <SingleProjectCard1 project={project} />
                  </SplideSlide>
                ))
              }
            </Splide>
          </div>
        </div>

      </section>
    )
  }
  else {
    return (
      <div id="preloader">
        <div className="preloader">
          <CircularProgress style={{ color: 'green' }} />
        </div>
      </div>
    )
  }

}

export default CardCaroussel
