import React from 'react'
import Container from '@material-ui/core/Container';
import Works1 from '../Components/HowItworks/Works1';
import Works2 from '../Components/HowItworks/Works2';
import Seo from '../Components/Helmet/Seo';

const HowItworks = () => {
    return (
        <>
        <Seo
        title="Comment ça marche"
        description="Découvrez comment Kamamini vous permet de concrétiser vos projets grâce au financement participatif."
        name="Kamamini"
        type="website"
        />
            
            <div className=' '>
           <h4 className='text-center mx-5 pt-2'> HOW KAMAMINI WORKS</h4>
           </div>
            <Container>
                <Works1></Works1>
            </Container>
            <div className='container-fluid' style={{backgroundColor: '#fbf8f6', margin:'0 0',}}>
                <Works2></Works2> 
            </div> 
            
        </>
    )
}

export default HowItworks
