import React,{useState,useContext,useEffect} from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {FirebaseContext} from '../Firebase/Firebase'
import Grid from '@material-ui/core/Grid';
import { ToastContainer } from 'react-toastify';
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton'
import {successToast,errorToast} from '../redux/actions/actions';
import Seo from '../Components/Helmet/Seo';


const ForgotPassword = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
   
  }, [])

    const useStyles = makeStyles((theme) => ({
        paper: {
          marginTop: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
        },
        submit: {
          margin: theme.spacing(3, 0, 2),
        },
      }));

      // this function verify if there has an error that ocurred during the authentiication
      const verifyError = (errorCode) =>{
        switch (errorCode) {
          case 'auth/email-already-in-use':
            return 'L\'adresse e-mail est déjà utilisée par un autre compte'
          case "auth/invalid-email":
            return 'L\'adresse e-mail est invalide'
          case "auth/weak-password":
            return 'Le mot de passe doit comporter au moins 6 caractères'
          case "auth/network-request-failed":
            return 'Le délai d’attente est dépassé'
          case "auth/timeout":
            return 'L\'opération a expiré.'
          case "auth/cancelled-popup-request":
            return 'Cette opération a été annulée en raison de l\'ouverture d\'une autre fenêtre pop-up conflictuelle.'
          case "auth/popup-blocked":
            return 'Impossible d\'établir une connexion avec la popup. Elle a peut-être été bloquée par le navigateur.'
          case "auth/popup-closed-by-user":
            return 'Le popup a été fermée par l\'utilisateur avant de finaliser l\'opération.'
          case "auth/user-not-found":
            return 'Il n\'y a pas d\'enregistrement d\'utilisateur correspondant à cet identifiant.'
          case "auth/wrong-password":
            return 'Le mot de passe n\'est pas valide.'
          case "auth/account-exists-with-different-credential":
            return 'Un compte existe déjà avec la même adresse e-mail mais des informations d\'identification différentes. Connectez-vous à l\'aide d\'un fournisseur associé à cette adresse e-mail.'
        
        
          default: return null
        }
      }

      const [email, setEmail] = useState('')
      const {auth} = useContext(FirebaseContext)

      const handleSubmit = (e) =>{
        e.preventDefault()
        auth.sendPasswordResetEmail(email)
        .then(() =>{ 
            successToast(`Consultez votre adresse mail ${email} pour changer votre mot de passe `)
            setTimeout(() =>{
                props.history.push('/login')
            },5000)
      })
        .catch((error)=>{
            const errorMsg = verifyError(error.code)
            if(errorMsg === null && error.message){
             errorToast(error.message)
            }
            else{
             errorToast(errorMsg)
            }
            setEmail('')
        })
      }

        // Material-ui css
    const classes = useStyles();

    return (
      <>
        <Seo
        title="Mot de passe oublié"
        description="Récupérez l'accès à votre compte Kamamini en réinitialisant votre mot de passe."
        name="Kamamini"
        type="website"
        />  
        <div style={{backgroundImage:"url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fshape%2F09.png?alt=media&token=e0bf1212-2701-4148-b9ef-662219a204e3"+")"
        ,paddingBottom:'70px',paddingTop:'5px'}} >




          <Container component="main" maxWidth="xs">
          <CssBaseline /> 
            <ToastContainer />
            <div className={classes.paper}>
            <Box mb={3}>
                  <Typography component="h1" variant="h5" >
                  Mot de passe oublié ?
                  </Typography>
                </Box>
                <Typography component="h3" variant="h6"  >
                  Veuillez entrer votre adresse e-mail pour réinitialiser votre mot de passe
                  </Typography>
          
          <form className={classes.form} noValidate onSubmit={handleSubmit} >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Addresse E-mail"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value) }
            />

              <KamaminiButton
              type="submit"
              fullWidth
              CustomClasses={classes.submit}
            >
              Réinitialiser
            </KamaminiButton>

            <Grid container>
              
              <Grid item>
              Déjà inscrit ? 
                <Link to="/login" variant="body2"className='link'>
                  {" connectez-vous"}
                </Link>
              </Grid>
            </Grid>


            </form>
            </div>

          </Container >
        </div>
      </>
    )
}

export default ForgotPassword
