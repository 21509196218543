import React,{useState} from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CardActionArea } from '@mui/material';
import {makeStyles,Button} from '@material-ui/core';
import {useSelector} from "react-redux"
import { Link,useHistory } from 'react-router-dom'
// import stop from '../../../assets/img/stopKamamini.svg';
import KamaminiButton from '../../Buttons/KamaminiColorButton/KamaminiButton'
import {displayProjectEndTime} from '../../../redux/actions/actions' 
import TextDialog from '../../Dialog/TextDialog'
// import Sand from '../../../assets/img/sand-clock.png'

const useStyles = makeStyles((theme) => (
    {
    
      kamaminiColor:{
        borderColor: '#02b663',
        color:'#02b663',
        fontFamily: `"Poppins",sans-serif`,
        fontWeight:700,
        marginRight: theme.spacing(1),
      },
    }
    ));

const SmallRewardCard = ({title,img,price,quantity,projectSlug,getDonorsPerPerk,endData}) => {

    const classes = useStyles();
    const userInfo = useSelector(state => state.auth.userInfo)
    
    const [open, setOpen] = useState(false);
    const [endOpen, setEndOpen] = useState(false);
    let history = useHistory();

 
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClickEndOpen = () => {
        setEndOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleEndClose = () => {
        setEndOpen(false);
    };

    const handleClick = () => {
    if (userInfo) {
        
         if (displayProjectEndTime(endData) === `Terminé`)
        {
            handleClickEndOpen()
             
        }
        else if (parseInt(quantity)>0 || quantity === 'illimited') {
            history.push(`/${projectSlug}/checkout?title=${encodeURIComponent(title)}`);
        }
    } else {
        handleClickOpen()
    }
    }

  return (
    <div className='px-3 py-3'>

    <TextDialog  
    open={endOpen}
    handleClose={handleEndClose}
    contentText="Le délai de participation à ce projet est maintenant terminé. Nous remercions tous les contributeurs pour leur soutien et nous espérons que vous continuerez à soutenir d'autres projets sur Kamamini"
    image={"url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fsand-clock.png?alt=media&token=1c748bef-8d5f-4a78-8754-4dfd23afb559"+")"}
    />

    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
        <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2FstopKamamini.svg?alt=media&token=a3a6ba1e-98f7-4277-a23b-dbf1820bfb0c" className='mt-3' style={{height: "60px"}} alt="Acces interdit à cete page" />
        <DialogTitle  id="alert-dialog-title" className='d-flex justify-content-center'  >
            {"Vous n'avez pas accès a cette page"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Vous devez être connecté pour accéder à cette page. 
                <br/>
            </DialogContentText>
            <div className="d-flex justify-content-center mt-3" >
                <Link to='/login'>
                    <KamaminiButton onClick={handleClose} text="VOUS CONNECTER" />
                </Link>
            </div>
        </DialogContent>
    </Dialog>

    <Card sx={{ width: 275,maxHeight: 430 }}  >
        <CardActionArea onClick={handleClick} component="div" >

            {
                img && <CardMedia
                sx={{ height: 150 }}
                image={img}
                title={title}
            />
            }
        
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                {title}
                </Typography>

                <Typography gutterBottom variant="h5" component="div" style={{color:'#02b663'}} >
                    {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(price)}
                </Typography>
                <ul className="rewards-list pt-15 ">
                    <li>
                        <i className="far fa-user-circle"></i> 
                        {getDonorsPerPerk(title) > 1 ? `${getDonorsPerPerk(title)} Contributeurs` : `${getDonorsPerPerk(title)} Contributeur`}
                    </li>
                    {
                        parseInt(quantity) > 1 ? 
                        <li>
                            <i className="fas fa-trophy"></i>
                            {quantity} récompenses restantes
                        </li> 
                        : parseInt(quantity) === 1 ? 
                        <li>
                            <i className="fas fa-trophy"></i>
                            {quantity} récompense restante
                        </li>
                        : parseInt(quantity) === 0 ?
                        <li>
                            <i className="fas fa-trophy"></i>
                            Aucune récompense disponible
                        </li>
                        
                        : quantity === 'illimited' && 
                        <li>
                            <i className="fas fa-trophy"></i> 
                            illimité
                        </li> 
                    }
                </ul>

            </CardContent>
        <CardActions>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} minHeight={100}>
                    <Grid item xs display="flex" justifyContent="center" alignItems="center">
                        <Button 
                        variant="outlined" 
                        className={classes.kamaminiColor} 
                        >
                        Sélectionner
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </CardActions>

        </CardActionArea>
    </Card>
</div>

  )
}

export default SmallRewardCard