import React,{useEffect} from 'react'
import {
    Container,
    Grid,
    makeStyles,
    Box,
    CssBaseline
  } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ReactPlayer from "react-player/youtube";
import { ToastContainer } from 'react-toastify';
import ReactHtmlParser from "react-html-parser";
import {useDispatch} from 'react-redux'
import AccordionComponent from '../../../../Components/Accordion/Accordion'
import Button from "../../components/CustomButtons/Button.js";
import AdminReward from '../../../../Components/Cards/RewardCard/AdminReward'
import KamaminiButton from '../../../../Components/Buttons/KamaminiColorButton/KamaminiButton'
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import {projectsCancelConfirmation} from '../../../../redux/actions/projectActions'

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
    },
  };
  
  const useStyles = makeStyles(styles);


const Step2 = (props) => {

    const dispatch= useDispatch();
    const classes = useStyles();
  
    useEffect(() => {
      window.scrollTo(0, 0)
     
    }, [])

    const {
        prevStep,
        singleProject

       } =props
  
  return (
    <>

   <Box mb={5}></Box>
   <Container>
  
         <CssBaseline/>
    <ToastContainer />
         <Grid  item xs={12} sm={12} md={12} lg={12} >
                 <div  className="d-flex justify-content-start">
                   <KamaminiButton text='Précédent' IconLeft={ArrowLeftIcon} onClick={() => prevStep()} />
                 </div>
              </Grid>
         <Grid container direction="column" justifycontent="center" alignItems="center" >
          
            <h2>Projet {singleProject.title} </h2>
                    
        </Grid>
        <br/>
        <br/> 
        <Grid container spacing={2}>

       

                 <Grid item xs={8} sm={8} md={8} lg={8} >
                  <h4>Titre du projet : &nbsp; {singleProject.title}  </h4>
                  <hr/>
                  <h4>ID du projet : &nbsp; {singleProject.id}  </h4>
                  <hr/>
                  <h4>Slogan du projet : &nbsp; {singleProject.tagline}  </h4>
                  <hr/>
                  <h4>Lieu du projet : &nbsp; {singleProject.location}  </h4>
                  <hr/>
                  <h4>Image mise en avant   &nbsp;  </h4>
                  <br/>
                  <img  className="img-thumbnail"  src={singleProject.featuredImage} alt={singleProject.title} />
                  <hr/>
                  <h4>Categorie du projet : &nbsp; {singleProject.category}  </h4>
                  <hr/>
                  <h4>Durée de la campagne : &nbsp; {singleProject.duration} jours </h4>
                  <hr/>
                  <h4>Objectif de financement : &nbsp; {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(singleProject.moneyRequest) }  </h4>
                  <hr/>
                  <h4>Vidéo de Présentation du projet  </h4>
                  <br/>

                  {
                  singleProject.youtubeVideos.length > 0 ?
                  singleProject.youtubeVideos.map((video,index) => {
                    return(
                      <Grid item xs={8} sm={8} md={8} lg={8} key={index} className='mb-5' >
                         <ReactPlayer   controls url={video}/> 
                         <br/>
                      </Grid>
                    )
                    
                   }) :
                   <h4>Ce projet n'a pas de vidéo </h4>
                }
                <hr/>

                <h4>Image de Présentation du projet  </h4>
                  <br/>
                
                  <Grid container spacing={2}>

                    {
                      singleProject.images.length ?
                      singleProject.images.map((imageUrl,index) => {
                        return (
                          <Grid container item xs={12} sm={6} md={4} lg={3} key={index}   >
                            <img className="img-fluid small rounded mx-auto d-block" src={imageUrl} alt="Kamamini project's" />
                          </Grid>
                        )
                      })
                      :  <h4>Ce projet n'a pas d'image de présentation </h4>
                    }

                  </Grid>

                  <hr/>


                  <h4>Description du projet  </h4>
                  <br/>

                 
                  <div style={{overflowY: 'scroll'}} className="kamamini_editor_container p-3" >
                    {ReactHtmlParser(singleProject.projectText)} 
                  </div>

                  <hr/>

                  <h4>Foires aux questions (FAQ) : </h4>
                  <br/>
                  {
                    singleProject.faq.map((faq) =>{
                      return(
                        <AccordionComponent key={faq.id} question={faq.question} response={faq.response} />
                      )
                    })
                  }

                  <hr/>

                  <h4>Récompense : </h4> 
                  <br/>

                  <Grid container spacing={2}>
                  {
                     singleProject.perk.length ?
                    singleProject.perk.map((perk) =>{
                      return(
                        <Grid container key={perk.id} item xs={12} sm={8} md={6} lg={6} >
                          <AdminReward 
                            title={perk.title} 
                            img={perk.rewardImage} 
                            price={perk.price} 
                            description={perk.description}
                            quantity={perk.quantity}
                          />
                        </Grid>
                        
                      )
                    })
                    : <h4>Ce projet n'a pas de récompense </h4>
                  }
                </Grid>

            </Grid>

            <GridItem xs={4} sm={4} md={4} lg={4}>
                <Card profile>
                    <CardAvatar profile>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        {
                            singleProject.authorImageUrl? 
                            <img src={singleProject.authorImageUrl} alt={singleProject.authorDisplayName} />
                            : <h3>{singleProject.authorInitials}</h3>
                        }
                        
                    </a>
                    </CardAvatar>
                    <CardBody profile>
                    <h4 className={classes.cardCategory}>{singleProject.authorDisplayName} </h4>
                    <h6 className={classes.cardTitle}>{singleProject.authorEmail} </h6>

                    <p className={classes.description}>
                        {singleProject.authorId}
                    </p>
                    <p className={classes.description}>
                      {singleProject.authorInitials}
                    </p>
                   
                    </CardBody>
                </Card>
            </GridItem>

        </Grid>

             <br/>
             <hr/>

             <Grid  item xs={12} sm={12} md={12} lg={12} >

              <h3>Annuler l'approbation</h3>
                <div  className="d-flex justify-content-start mb-3">
                <Button color="warning" onClick={()=> dispatch(projectsCancelConfirmation(singleProject.id))} >Annuler</Button>
                  
                </div>
            </Grid>

           
         <Box mb={5}></Box>
   </Container>
   </>
)
}

export default Step2
