import React from 'react'
import {Helmet}  from 'react-helmet-async'
import PropTypes from "prop-types";
export default function Seo  ({title, description, name, type}) {
  return (
    <Helmet>
      { /* Standard metadata tags */ }
      <title>Kamamini | {title}</title>
      <meta name='description' content={description} />

      { /* Facebook tags */ }
      {type && <meta key="og:type" property="og:type" content={type} />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      { /* Twitter tags */ }
      {name && <meta name="twitter:creator" content={name} />}
      {type && <meta name="twitter:card" content={type} />}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>

  )
}
Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
};

