import React from 'react';
import ReactDOM from 'react-dom';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { Provider } from "react-redux"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseContext, auth, db, storage, firebaseApp } from './Firebase/Firebase'
import store from "./redux/store"



//toastify
import 'react-toastify/dist/ReactToastify.css';

// Fonde template CSS file
import "./assets/css/bootstrap.min.css"
import "./assets/css/owl.carousel.min.css"
import "./assets/css/animate.css"
import "./assets/css/magnific-popup.css"
import "./assets/css/all.min.css"
import "./assets/css/flaticon.css"
import "./assets/css/font.css"
import "./assets/css/themify-icons.css"
import "./assets/css/metisMenu.css"
import "./assets/css/nice-select.css"
import "./assets/css/slick.css"
import "./assets/css/main.css"

// React image gallery
// SCSS
import "react-image-gallery/styles/scss/image-gallery.scss";

// CSS
import "react-image-gallery/styles/css/image-gallery.css";


if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

ReactDOM.render(
  <Provider store={store} >
    <FirebaseContext.Provider value={{ auth, db, storage, firebaseApp }}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </FirebaseContext.Provider>
  </Provider>

  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
