// core components/views for Admin layout
import DashboardPage from "../views/Dashboard/Dashboard.js";
import CreateBlog from "../views/CreateBlog/CreateBlog.js";
import EditBlog from "../views/EditBlog/EditBlog.js";
import UserProfile from "../views/UserProfile/UserProfile.js";
import TableList from "../views/TableList/TableList.js";
import Typography from "../views/Typography/Typography.js";
import NotificationsPage from "../views/Notifications/Notifications.js";
import AcceptedProjects from "../views/AcceptedProjects/AcceptedProjects.js";
import MakeProjectsFeatured from "../views/MakeProjectsFeatured/MakeProjectsFeatured.js";
import MakeBlogsFeatured from "../views/MakeBlogsFeatured/MakeBlogsFeatured.js";
import Validation from "../views/ProjetsValidation/Validation.js";
import RejectedProjects from "../views/RejectedProjects/RejectedProjects.js";
import AdminUsers from "../views/AdminUsers/AdminUsers.js";
import PendingComments from "../views/PendingComments/PendingComments.js";
import CompletedProjects from "../views/CompletedProjects/CompletedProjects.js";
import InProgressProjects from "../views/InProgressProjects/InProgressProjects.js";
import CategorieFolder from "../views/CategorieFolder/CategorieFolder.js";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Notifications from "@material-ui/icons/Notifications";
import RuleIcon from '@mui/icons-material/Rule';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CancelIcon from '@material-ui/icons/Cancel';
import SyncIcon from '@mui/icons-material/Sync';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CategoryIcon from '@mui/icons-material/Category';

// admin  routes
export const adminRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      rtlName: "لوحة القيادة",
      icon: Dashboard,
      component: DashboardPage,
      layout: "/admin",
    },
    {
      path: "/create-blog",
      name: "Ecrire un blog",
      rtlName: "ملف تعريفي للمستخدم",
      icon: Edit,
      component: CreateBlog,
      layout: "/admin",
    },
    {
      path: "/edit-blog",
      name: "Modifier mes blogs",
      rtlName: "ملف تعريفي للمستخدم",
      icon: SyncIcon,
      component: EditBlog,
      layout: "/admin",
    },
    {
      path: "/projects-validation",
      name: "Valider les projets",
      rtlName: "ملف تعريفي للمستخدم",
      icon: RuleIcon,
      component: Validation,
      layout: "/admin",
    },
    {
      path: "/accepted-projects",
      name: "Projets approuvé",
      rtlName: "ملف تعريفي للمستخدم",
      icon: VerifiedUserIcon,
      component: AcceptedProjects,
      layout: "/admin",
    },
    {
      path: "/rejected-projects",
      name: "Projets rejeté",
      rtlName: "ملف تعريفي للمستخدم",
      icon: CancelIcon,
      component: RejectedProjects,
      layout: "/admin",
    },
    {
      path: "/in-progress-projects",
      name: "Projets en cours",
      rtlName: "ملف تعريفي للمستخدم",
      icon: CurrencyExchangeIcon,
      component: InProgressProjects,
      layout: "/admin",
    },
    {
      path: "/completed-projects",
      name: "Projets terminé",
      rtlName: "ملف تعريفي للمستخدم",
      icon: TrendingDownIcon,
      component: CompletedProjects,
      layout: "/admin",
    },
    {
      path: "/featured-projects",
      name: "Projets mis en avant",
      rtlName: "ملف تعريفي للمستخدم",
      icon: AddToQueueIcon,
      component: MakeProjectsFeatured,
      layout: "/admin",
    },
    {
      path: "/featured-blogs",
      name: "Blogs mis en avant",
      rtlName: "ملف تعريفي للمستخدم",
      icon: AccountBalanceIcon,
      component: MakeBlogsFeatured,
      layout: "/admin",
    },
    {
      path: "/admin-users",
      name: "Administrateur",
      rtlName: "ملف تعريفي للمستخدم",
      icon: AdminPanelSettingsIcon,
      component: AdminUsers,
      layout: "/admin",
    },
    {
      path: "/admin-coments",
      name: "Commentaires",
      rtlName: "ملف تعريفي للمستخدم",
      icon: QuestionAnswerIcon,
      component: PendingComments,
      layout: "/admin",
    },
    {
      path: "/user",
      name: "User Profile",
      rtlName: "ملف تعريفي للمستخدم",
      icon: Person,
      component: UserProfile,
      layout: "/admin",
    },
   
    {
      path: "/table",
      name: "Table List",
      rtlName: "قائمة الجدول",
      icon: "content_paste",
      component: TableList,
      layout: "/admin",
    },
    {
      path: "/typography",
      name: "Typography",
      rtlName: "طباعة",
      icon: LibraryBooks,
      component: Typography,
      layout: "/admin",
    },
    
    
    {
      path: "/notifications",
      name: "Notifications",
      rtlName: "إخطارات",
      icon: Notifications,
      component: NotificationsPage,
      layout: "/admin",
    },


    {
      path: "/categorie",
      name: "Categorie",
      rtlName: "",
      icon: CategoryIcon,
      component: CategorieFolder,
      layout: "/admin",
    },
  
   
  ]


  // staff routes
  export const staffRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      rtlName: "لوحة القيادة",
      icon: Dashboard,
      component: DashboardPage,
      layout: "/admin",
    },
    {
      path: "/create-blog",
      name: "Ecrire un blog",
      rtlName: "ملف تعريفي للمستخدم",
      icon: Edit,
      component: CreateBlog,
      layout: "/admin",
    },
    {
      path: "/edit-blog",
      name: "Modifier mes blogs",
      rtlName: "ملف تعريفي للمستخدم",
      icon: SyncIcon,
      component: EditBlog,
      layout: "/admin",
    },
    {
      path: "/projects-validation",
      name: "Valider les projets",
      rtlName: "ملف تعريفي للمستخدم",
      icon: RuleIcon,
      component: Validation,
      layout: "/admin",
    },
    {
      path: "/accepted-projects",
      name: "Projets approuvé",
      rtlName: "ملف تعريفي للمستخدم",
      icon: VerifiedUserIcon,
      component: AcceptedProjects,
      layout: "/admin",
    },
    {
      path: "/rejected-projects",
      name: "Projets rejeté",
      rtlName: "ملف تعريفي للمستخدم",
      icon: CancelIcon,
      component: RejectedProjects,
      layout: "/admin",
    },
    {
      path: "/in-progress-projects",
      name: "Projets en cours",
      rtlName: "ملف تعريفي للمستخدم",
      icon: CurrencyExchangeIcon,
      component: InProgressProjects,
      layout: "/admin",
    },
    {
      path: "/completed-projects",
      name: "Projets terminé",
      rtlName: "ملف تعريفي للمستخدم",
      icon: TrendingDownIcon,
      component: CompletedProjects,
      layout: "/admin",
    },
    {
      path: "/featured-projects",
      name: "Projets mis en avant",
      rtlName: "ملف تعريفي للمستخدم",
      icon: AddToQueueIcon,
      component: MakeProjectsFeatured,
      layout: "/admin",
    },
    {
      path: "/featured-blogs",
      name: "Blogs mis en avant",
      rtlName: "ملف تعريفي للمستخدم",
      icon: AccountBalanceIcon,
      component: MakeBlogsFeatured,
      layout: "/admin",
    },
    {
      path: "/admin-coments",
      name: "Commentaires",
      rtlName: "ملف تعريفي للمستخدم",
      icon: QuestionAnswerIcon,
      component: PendingComments,
      layout: "/admin",
    },
   
   
  ]


  
  // editor routes

  export const editorRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      rtlName: "لوحة القيادة",
      icon: Dashboard,
      component: DashboardPage,
      layout: "/admin",
    },
    {
      path: "/create-blog",
      name: "Ecrire un blog",
      rtlName: "ملف تعريفي للمستخدم",
      icon: Edit,
      component: CreateBlog,
      layout: "/admin",
    },
    {
      path: "/edit-blog",
      name: "Modifier mes blogs",
      rtlName: "ملف تعريفي للمستخدم",
      icon: SyncIcon,
      component: EditBlog,
      layout: "/admin",
    },
   
    // {
    //   path: "/accepted-projects",
    //   name: "Projets approuvé",
    //   rtlName: "ملف تعريفي للمستخدم",
    //   icon: VerifiedUserIcon,
    //   component: AcceptedProjects,
    //   layout: "/admin",
    // },
   
  ]

  const routes = [...adminRoutes, ...staffRoutes, ...editorRoutes]

  export default  routes

  


