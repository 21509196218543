import { Button, Grid } from '@material-ui/core';
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';


// const useStyles = makeStyles((theme) => ({
//   button: {},
// }));

export function FileHeader({ file, onDelete }) {
  // const classes = useStyles();

 
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>{file.name}</Grid>
      <Grid item className="py-2" >
        <Button className="bg-danger text-white" size="small" onClick={() => onDelete(file)}>
         <DeleteIcon fontSize="small"  /> Supprimer
        </Button>
      </Grid>
    </Grid>
  );
}