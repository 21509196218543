 import React from 'react'
 import True from '../Components/Trust/True.js'

function Trust() {
    return (
        <div>
            
     
            <section class="" style={{ backgroundImage: "url("+"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fevents%2F01.jpg?alt=media&token=a2a75543-883d-419e-ac9a-93eaf999918a"+")"
                                                        ,  backgroundRepeat: "no-repeat", backgroundSize: 'cover', height:'50vh'}}>
            <div class="right-border-shape">
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3">
                        <div class="page-title-wrapper text-center">
                            <h1 class="" style={{color: '#FFFFFF'}} data-wow-delay='.1s'>Honnêteté et clarté dans la présentation de votre<br/> projet</h1>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className='container'>
                <div className='container'>
            <div className='text-center justify-content-center text-align-center my-4 py-4 px-5'>
            Au cœur de la plateforme et de l'écosystème de Kamamini, il y a la confiance. La communauté vivante et équilibrée 
            que nous souhaitons cultiver ne saurait exister sans transparence, ingrédient indispensable de cette confiance. 
            C'est pourquoi nous exigeons de nos créateurs un certain degré d'honnêteté, d'ouverture et de sincérité quant à la présentation de leurs idées et au 
            déroulement de leur campagne.
            </div>
            <div className=' text-center justify-content-center text-align-center px-5'>
            Les créateurs trouveront ci-dessous quelques directives complémentaires, dont le but est d'encourager 
            l'honnêteté et la clarté dans la présentation de chaque projet :
            </div>
            </div>
            </div>
        </section>

        <section className='d-flex justify-content-center mt-5 mb-5'>
             <div className='mr-3'><True /></div>
             <div className="ml-3"><True /></div>

        </section>


        </div>
    )
}

export default Trust
