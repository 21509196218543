import React, { useState } from "react";
import { displayProjectEndTime } from '../../redux/actions/actions';
import { Grid, Container } from '@material-ui/core';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SingleProjectCard1 from '../Cards/SingleProjectCard1/SingleProjectCard1'
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { Pagination, PaginationItem } from '@mui/material';
import FilterBox from './FilterBox'
import ResponsiveFilteredBox from './ResponsiveFilteredBox'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    border: '#2E3B55 solid 2px',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '40ch',
      '&:focus': {
        width: '60ch',
      },
    },
  },
}));


const ProjectList = ({ projects, paginationInfo }) => {
  const [filter, setFilter] = useState({
    category: "",
    location: "",
    status: "",
    funding: 0,
    name: "",
  });

  const handleCategoryChange = (event) => {
    setFilter({ ...filter, category: event.target.value });
  };

  const handleLocationChange = (event) => {
    setFilter({ ...filter, location: event.target.value });
  };

  const handleStatusChange = (event) => {
    setFilter({ ...filter, status: event.target.value });
  };

  const handleFundingChange = (event) => {
    setFilter({ ...filter, funding: event.target.value });
  };
  const handleNameChange = (event) => {
    setFilter({ ...filter, name: event.target.value });
  };
  const getUnique = (items, value) => {
    if (items) {
      return [...new Set(items.map(item => item[value]))];
    }
  };
  function valuetext(value) {
    return `${value}%`;
  }

  let categoryOption = getUnique(projects, "category");
  let locationOption = getUnique(projects, "location");

  const filteredProjects = projects.filter((project) => {
    project.funding = project.moneyRaised * 100 / parseInt(project.moneyRequest)
    project.status = displayProjectEndTime(project.projectsEndDate) !== `Terminé` ? "En cours" : "Terminé"

    return (
      (filter.name === "" || project.title.toLowerCase().includes(filter.name.toLowerCase())) &&
      (filter.category === "" || project.category === filter.category) &&
      (filter.location === "" || project.location === filter.location) &&
      (filter.status === "" || project.status === filter.status) &&
      project.funding >= filter.funding
    );
  });

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Définis la taille de la page selon tes besoins

  // Gère la pagination localement
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedProjects = filteredProjects.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Container >
        <Grid container spacing={2} className="py-5" >
          <Box item component={Grid} display={{ xs: "none", sm: "none", lg: "block" }} xs={12} sm={12} md={4} lg={4} >
            <Container>
              <FilterBox
                handleCategoryChange={handleCategoryChange}
                filter={filter}
                handleLocationChange={handleLocationChange}
                handleStatusChange={handleStatusChange}
                handleFundingChange={handleFundingChange}
                valuetext={valuetext}
                categoryOption={categoryOption}
                locationOption={locationOption}

              />
            </Container>
          </Box>

          <Grid item xs={12} sm={12} md={8} lg={8} className="py-3" >
            <Box sx={{ display: 'flex', justifyContent: 'center', pb: 5 }}>
              <Search >
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Rechercher des projets"
                  inputProps={{ 'aria-label': 'search' }}
                  id="name"
                  type="search"
                  value={filter.name}
                  onChange={handleNameChange}
                />
              </Search>
            </Box>
            <Container>
              <Box display={{ xs: "block", sm: "block", lg: "none" }}>
                <ResponsiveFilteredBox
                  handleCategoryChange={handleCategoryChange}
                  filter={filter}
                  handleLocationChange={handleLocationChange}
                  handleStatusChange={handleStatusChange}
                  handleFundingChange={handleFundingChange}
                  valuetext={valuetext}
                  categoryOption={categoryOption}
                  locationOption={locationOption}
                />
              </Box>
            </Container>

            <Grid container spacing={2}>
              {displayedProjects.length > 0 ?
                displayedProjects.map((project) => (
                  <Grid key={project.id} container item xs={12} sm={6} md={6} lg={6} >
                    <SingleProjectCard1 project={project} />
                  </Grid>
                ))

                :
                <Grid container item xs={12} sm={12} md={12} lg={12} >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Container>
                      <CardMedia
                        component="img"
                        image="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fproject%2Fno-results.png?alt=media&token=4c3f3f2c-2a42-4fb8-85d7-928ec506087c"
                        alt="no results found Kamamini"
                        sx={{ maxWidth: '100%', height: 'auto' }}
                      />
                      <Typography variant="h5" align="center" sx={{ mt: 1 }}>
                        Aucun résultat trouvé
                      </Typography>
                    </Container>

                  </Box>
                </Grid>

              }
              <Grid container item xs={12} sm={12} md={12} lg={12} >
                {filteredProjects.length > 0 &&
                  <Pagination
                    count={Math.ceil(filteredProjects.length / pageSize)}
                    page={currentPage}
                    classes={{
                      "& .MuiPaginationItem-root": {
                        color: "#fff",
                        backgroundColor: '#02a056'
                      }
                    }}
                    // color="primary"

                    size="large"
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        sx={
                          item.page === currentPage
                            ? {
                              backgroundColor: '#02a056 !important',
                              color: '#fff'
                            }
                            : {}
                        }
                        component="button"
                        {...item}
                      />
                    )}
                  />
                }
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>







    </>
  );
};


export default ProjectList;
