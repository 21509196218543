import React,{useState} from 'react'
import {Container, Grid, CssBaseline, LinearProgress,Box, Button} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import ReactPlayer from "react-player/youtube";
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import KamaminiButton from '../../../../Components/Buttons/KamaminiColorButton/KamaminiButton' 
import {createBlog} from '../../../../redux/actions/blogActions'
import TextEditor from '../../../../Components/TextEditor/TextEditor'
import {errorToast,resizeFile} from '../../../../redux/actions/actions'

const CreateBlog = (props) => {

    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.auth.userInfo)
    const [imageName, setImageName] = useState('Choisissez un fichier')
    const [imageUrl, setImageUrl] = useState(null)
    const [progress, setProgress] = useState(0); //progress bar cloudinary
    const [textEditorInput, setTextEditorInput] = useState('<p>taper  les informations du blog ici </p>')
    const [title, setTitle] = useState('')
    const [blogCategory, setBlogCategory] = useState('')
    const [youtubeUrl, setYoutubeUrl] = useState([])
    const [saveInput, setSaveInput] = useState('')


    const handleSaveInput = (e) => {
      setSaveInput(e.target.value)
    }
  
    const addUrl =() =>{
      if(saveInput !== '' && saveInput.includes("youtube.com") && youtubeUrl.indexOf(saveInput) === -1){
        setYoutubeUrl(prev => [...prev, saveInput])
        setSaveInput('')
      }
  }
  const deleteUrl = (url) =>{
    setYoutubeUrl((curr) => curr.filter((currUrl) => currUrl !== url ))
  }

// Send file on Cloudinary(additionnal stockage space for the app)
    const uploadFile = (file,onProgress) =>{
        const key = "coyptbxs";
        const url = 'https://api.cloudinary.com/v1_1/kamamini/image/upload';
      
        return new Promise ((res, rej) => {
          const xhr = new XMLHttpRequest();
          xhr.open('POST', url);
      
          xhr.onload = () => {
            const resp = JSON.parse(xhr.responseText);
            res(resp.secure_url);
            setImageUrl(resp.secure_url)
          };
          xhr.onerror = (evt) => rej(evt);
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentage = (event.loaded / event.total) * 100;
              onProgress(Math.round(percentage));
            }
          };
      
          const formData = new FormData();
          formData.append('file', file);
          formData.append('upload_preset', key);
      
          xhr.send(formData);
        });
      
      }
      const handleChangeImage = async(e)=>{
        const file = e.target.files[0];
        if(file && file['type'].split('/')[0] === 'image'){
          if (file.size <= (5000 * 1024)) {
          try {
            setImageName(file.name)
            const image = await resizeFile(file);
            uploadFile(image,setProgress)
          } catch (error) {
            errorToast("Votre image n'a pas pu être sauvegardée, essayez de nouveau")
          }
        }
        else {
          errorToast("La taille de l'image ne doit pas être supérieur à 5 Mo")
        }
      
        }
      
      }
      

      const handleSubmit = (e) => {
          e.preventDefault()
          if(title.match(/^\s*$/)){
            errorToast('Veuillez entrer le titre du projet')
          }
          else if(!imageUrl ){
            errorToast('Veuillez choisir une image de présentation')
          }
          else if(blogCategory.match(/^\s*$/)){
            errorToast('Veuillez sélectionner la catégorie du projet')
          }
          else if (textEditorInput.match(/^\s*$/)){
            errorToast('Veuillez ajouter une description à votre blog')
          }
         else {
          const blogObject = {
            title:title,
            urlImage: imageUrl,
            category: blogCategory,
            blogText:textEditorInput,
            youtubeUrl:youtubeUrl
            }

            dispatch(createBlog(blogObject, userInfo))
            setImageUrl(null)
            setTitle('')
            setImageName('Choisissez un fichier')
            setBlogCategory('')
            setTextEditorInput('<p>taper  votre message </p>')
            setYoutubeUrl([])
         }

      }


    return (
        <form onSubmit={handleSubmit} >
            <ToastContainer />
            <Container>
            <CssBaseline/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10} >
                    <label htmlFor="title">Titre du Blog<span className='star'>*</span> </label>
                    <div className="input-group ">
                        <input type="text"  required value={title} className="form-control" id="title" onChange={(e)=>  setTitle(e.target.value)} aria-describedby="basic-addon3" />
                    </div>
                    <p className="note mb-3">Mettez le titre du Blog ici</p>
               </Grid>
               <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8} >
                    <h5 htmlFor="tagline">Image mise en avant <span className='star'>*</span> </h5>
                       {
                         
                         progress > 0 && progress < 100 && <LinearProgress variant="determinate" value={progress}/>
                       }
                        

                        <br></br>
                        <div className="input-group mb-3">
                            <div className="custom-file">
                                <input type="file" accept="image/x-png,image/gif,image/jpeg" required className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" onChange={(e)=>handleChangeImage(e)} lang="fr" />
                                <label className="custom-file-label " htmlFor="inputGroupFile01" data-browse="Parcourir">{imageName}</label>
                            </div>
                        </div>
                        <p className="note mb-3">Importer une image  qui représente votre blog</p>
                        
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={4} > 
                    {
                        imageUrl && 
                        <div className="projects projects-02 white-bg mb-30 wow fadeInUp2 animated" data-wow-delay='.1s'>
                        <div className="projects__thumb pos-rel">
                            <img className="img-thumbnail" src={imageUrl} alt={imageName}/>
                        </div>
                        </div>
                    }
                    
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={10} lg={10} >
                    <label htmlFor="title">Categorie du Blog<span className='star'>*</span> </label>
                    <div className="input-group ">
                        <input type="text"  required value={blogCategory} className="form-control" id="title" onChange={(e)=>  setBlogCategory(e.target.value)} aria-describedby="basic-addon3" />
                    </div>
                    <p className="note mb-3">indiquez la categorie de votre Blog</p>
                    </Grid>


                    
                </Grid>
               <Grid item xs={12} sm={12} md={10} lg={10} >
                    <TextEditor  data={textEditorInput} setData={setTextEditorInput}/>
               </Grid>
              </Grid>

              <br/>
              

              <Grid item xs={12} sm={12} md={10} lg={10} >
                <h5>VIDEO</h5>
                <label htmlFor="url">URL de la vidéo  </label>
                
                <p className="note mb-3">Saisissez une ou plusieurs URL YouTube qui apparaîtra dans la page du blog. </p>
                
                <div className="input-group ">
                    <input  type="url" placeholder='http://' value={saveInput} onChange={handleSaveInput }  className="form-control" id="url" aria-describedby="basic-addon3" />
                    <KamaminiButton onClick={addUrl} text='Ajouter la video'  />
                </div> <br/>

                
                {
                  youtubeUrl.length > 0 &&
                  youtubeUrl.map((video,index) => {
                    return(
                      <Grid item xs={12} sm={12} md={10} lg={10} key={index} className='mb-5' >
                         <ReactPlayer   controls url={video}/>  
                         <Box mb={3} ></Box>
                         <Button className="bg-danger text-white" size="small" onClick={() => deleteUrl(video)}>
                          <DeleteIcon fontSize="small"  /> Supprimer
                        </Button>
                      </Grid>
                    )
                   })
                }
                </Grid>

                <Box mb={3} ></Box>
              <KamaminiButton text='Envoyer' type='submit' /> 
              <Box mb={5} ></Box>
            </Container>
            
        </form>
    )
      
}

export default CreateBlog
