import React from 'react'
import {useDispatch} from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { makeStyles } from "@material-ui/core/styles";
import KamaminiButton from '../../Buttons/KamaminiColorButton/KamaminiButton'
import Button from "../../Admin/components/CustomButtons/Button.js";
import {featuredProject,cancelFeaturedProject} from '../../../redux/actions/projectActions'


const useStyles = makeStyles((theme) => ({
  
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
}));


const  SingleProjectCard5 = ({project,}) => {
      const classes = useStyles();
      const dispatch= useDispatch();


      const handleAdd = (id) => {
        dispatch(featuredProject(id))
      }
      const handleCancel = (id) => {
        dispatch(cancelFeaturedProject(id))
      }

    return (
        <div className="projects projects__03 pos-rel mb-30 wow fadeInUp2 animated" data-wow-delay='.1s'>
        <div className="projects__thumb pos-rel">
            <img src={project.featuredImage} alt={project.title} />
        </div>
        <div className="projects__content white-bg">
            <h4><span > {project.title}  </span></h4>
            <div className="skill mb-30">
                {
                    project.featured ? 
                    <Button color="danger" onClick={() =>handleCancel(project.id)} >Annuler</Button>
                    :
                    <KamaminiButton text='Mettre en avant' onClick={() =>handleAdd(project.id)} />
                }
               
               
            </div>
            <div className="projects__content--manager">
                <ul className="project-manager d-flex">
                    <li className="d-flex" >
                        {
                            project.authorImageUrl? <Avatar   alt={project.authorDisplayName} src={project.authorImageUrl} />:<Avatar className={`${classes.orange} `}>{project.authorInitials} </Avatar>
                        }
                       
                        <h5  className="px-3" >{project.authorDisplayName}</h5> 
                    </li>

                </ul>
            </div>
        </div>
    </div>
    )
}

export default SingleProjectCard5
