import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileUser, cancelGetProfileUser } from '../redux/actions/authActions';
import {
  CssBaseline,
} from '@material-ui/core';
import { Pagination, PaginationItem } from '@mui/material';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom'
import { fetchProfileProjects } from '../redux/actions/projectActions';
import '../assets/css/profile.css';
import SingleProjectCard2 from '../Components/Cards/SingleProjectCard2/SingleProjectCard2'
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import Seo from '../Components/Helmet/Seo';
import Errorcase from '../Components/ErrorCase/ErrorCase'
import KamaminiButton from '../Components/Buttons/KamaminiColorButton/KamaminiButton';


const Profile = (props) => {

  const slug = props.match.params.slug;
  const profileInfo = useSelector(state => state.auth.profileInfo)

  const profileProjects = useSelector(state => state.projects.profileProjects)
  const profileError = useSelector(state => state.auth.profileInfoError)
  // console.log(profileError)
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;


  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedProjects = profileProjects && profileProjects.slice(startIndex, endIndex);
  const dispatch = useDispatch()

  // console.log(profileInfo);
  // console.log(profileProjects);

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getProfileUser(slug))
    dispatch(fetchProfileProjects(slug))

    return () => {
      dispatch(cancelGetProfileUser())
    }

  }, [dispatch, slug])

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };


  if (profileError === "Le profil n'existe pas") {
    return (
      <>
        <div className='container text-center py-4'>
          <NoAccountsIcon
            //color="success"
            style={{ color: '#02b663' }}
            sx={{ fontSize: 220 }}
          />
          <div className="wrapperu">
            <h1>Profil non trouvé</h1>
            <p className="messageu">
              Oops! Il semble que le profil que vous essayez de trouver n'existe pas. L'URL que vous suivez ne correspond à aucun utilisateur. Veuillez vérifier l'adresse et réessayer. Si vous pensez qu'il s'agit d'une erreur, n'hésitez pas à contacter le support.         </p>
            <Link to='/' className="pb-5 ">
              <KamaminiButton text="Page d'accueil" />
            </Link>
          </div>
        </div>

      </>
    )
  }
  else if (profileError && profileError !== "Le profil n'existe pas") {
    return (
      <Errorcase />
    )
  }
  else if (!profileInfo || !profileProjects) {
    return (
      <div id="preloader">
        <div className="preloader">
          <CircularProgress style={{ color: 'green' }} />
        </div>
      </div>
    )
  }

  else if (profileInfo && profileProjects) {
    return (
      <>
        <Seo
          title={`Profil de ${profileInfo.displayName}`}
          description={`Découvrez le profil de Profil de ${profileInfo.displayName} sur Kamamini. Consultez ses projets, ses contributions et bien plus encore`}
          name="Kamamini"
          type="website"
        />

        <div className="container-fluid rounded" style={{ background: '#fff', padding: "0", margin: '0' }}>
          <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fproject%2Fagadez-cover.jpg?alt=media&token=370cad75-1673-4f2e-980b-1c14f2c1c861' className='w-100  img-fluid' style={{ height: '300px' }} alt='new' />
          <div style={{ borderBottom: '0.5rem solid #1A1E2D' }} ></div>
          <p className='mb-5 py-2'></p>
          <div className='container '>
            <div className='row py-5 '>
              <div className='col-md-4 col-sm-12'>
                <div className="card   border-info-custom" style={{ borderRadius: '50 px 50px' }}>
                  <div className='card w-50 mx-5 h-100 '>
                    {
                      profileInfo.photoURL &&
                      <img src={profileInfo.photoURL} className="card-img-top w-100 mx-5 rounded-3" style={{ marginTop: '-50px' }} alt={profileInfo.displayName} />
                    }

                  </div>
                  <h4 className='text-center mx-5 py-3'>{profileInfo.displayName}</h4>
                  <div className="card-body">
                    {
                      profileInfo.bio && <> <h5 style={{ color: '#02b663' }} >Biographie: </h5> <h5 className='text-dark'>{profileInfo.bio}</h5> </>
                    }

                    <h5 style={{ color: '#02b663' }} className='py-2'>Nombre de projet: <span className='text-dark'>{profileProjects?.length}</span></h5>
                    <h5 style={{ color: '#02b663' }} className='py-2'>Email: <span className='text-dark'>{profileInfo?.email}</span></h5>
                    {
                      profileInfo?.number &&
                      <h5 style={{ color: '#02b663' }} className='py-2'>Phone: <span className='text-dark'>{profileInfo?.number && profileInfo?.number}</span></h5>
                    }

                    {
                      profileInfo?.city &&
                      <h5 style={{ color: '#02b663' }} className='py-2'>Ville: <span className='text-dark'>{profileInfo?.city && profileInfo?.city}</span></h5>
                    }


                  </div>
                  <div className='card-footer'>
                    <div className="profile-work text-center">
                      <h3 style={{ color: '#1A1E2D' }} className='py-2'>Réseaux sociaux</h3>
                      <ul className="fot-list link-list d-flex justify-content-between mx-5">
                        {
                          profileInfo.websiteLink &&
                          <>
                            <li><a href={profileInfo.websiteLink} rel="noopener noreferrer" target='_blank' > <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/800px-Google_Chrome_icon_%28February_2022%29.svg.png' alt='internet' className='img-fluid rounded-circle ' style={{ width: '1.5rem' }} /></a></li>
                          </>
                        }
                        {
                          profileInfo.facebookLink &&
                          <>
                            <li><a href={profileInfo.facebookLink} rel="noopener noreferrer" target='_blank' >
                              <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/fichier%20pour%20emails%2Fimag.png?alt=media&token=0d6d2032-0daf-4433-92bd-5fa285b6ae0a' className='img-fluid rounded-circle ' style={{ width: '1.5rem' }} alt='facebook' />
                            </a></li>
                          </>
                        }

                        {
                          profileInfo.twitterLink &&
                          <>
                            <li><a href={profileInfo.twitterLink} rel="noopener noreferrer" target='_blank' >
                              <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/fichier%20pour%20emails%2Ftw.png?alt=media&token=7ceb45af-e0ac-49cd-94f3-ed594ac6cc3a' className='img-fluid rounded-circle ' style={{ width: '1.5rem' }} alt='twitter' />
                            </a></li>
                          </>
                        }
                        <br />

                        {
                          profileInfo.linkedinLink &&
                          <>
                            <li><a href={profileInfo.linkedinLink} rel="noopener noreferrer" target='_blank' >
                              <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/fichier%20pour%20emails%2Flinkedin%20(1).png?alt=media&token=18c94a56-1f20-4c93-b266-19202739c0db' className='img-fluid rounded-circle ' style={{ width: '1.5rem' }} alt='linkedin' />
                            </a></li>
                          </>
                        }
                        <br />


                        {
                          profileInfo.instagramLink &&
                          <>
                            <li><a href={profileInfo.instagramLink} rel="noopener noreferrer" target='_blank' >
                              <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/fichier%20pour%20emails%2Finsta1.jpg?alt=media&token=2e5d9d93-2715-46ae-b218-20fc1c117be2' className='img-fluid rounded-circle ' style={{ width: '1.5rem' }} alt='instagram' />
                            </a></li>
                          </>
                        }
                        <br />

                        {
                          !profileInfo.websiteLink && !profileInfo.facebookLink && !profileInfo.twitterLink && !profileInfo.linkedinLink && !profileInfo.instagramLink &&
                          <>

                          </>
                        }
                      </ul>

                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-8 col-sm-12'>
                <div className='row border-info-custom'>
                  <div className='row d-flex justify-content-around  h-100 py-2 mx-5'>
                    {
                      displayedProjects && displayedProjects?.length ? (
                        displayedProjects.map(project => {
                          return (
                            <div className='' key={project.id}>
                              <SingleProjectCard2 project={project} />
                            </div>
                          )
                        })

                      ) : (
                        <div className='col-12'>
                          <img src='https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fproject%2FNoPoject.png?alt=media&token=9c2015bb-03f6-4ea8-b58f-ee4163b91b86' alt='no project' className='img-fluid' />
                        </div>
                      )
                    }
                    {

                      profileProjects?.length > 1 && (
                        <div className='row d-flex justify-content-center mt-4 text-center pt-5'>
                          <Pagination
                            count={Math.ceil(profileProjects.length / pageSize)}
                            page={currentPage}
                            classes={{
                              "& .MuiPaginationItem-root": {
                                color: "#fff",
                                backgroundColor: '#02a056'
                              }
                            }}
                            // color="primary"

                            size="large"
                            onChange={handlePageChange}
                            renderItem={(item) => (
                              <PaginationItem
                                sx={
                                  item.page === currentPage
                                    ? {
                                      backgroundColor: '#1A1E2D !important',
                                      color: '#fff'
                                    }
                                    : {}
                                }
                                component="button"
                                {...item}
                              />
                            )}
                          />

                        </div>
                      )
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    )

  }



  // else {
  //   return (
  //     <div id="preloader">
  //       <div className="preloader">
  //         <CircularProgress style={{ color: 'green' }} />
  //       </div>
  //     </div>
  //   )
  // }

}

export default Profile