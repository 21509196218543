import React from "react";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player/youtube";
import Loader from "../Loader/Loader";

class Lector extends React.Component {
  constructor() {
    super();
    this.state = {
      playing: true,
      showIndex: false,
      showBullets: false,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: false,
      showGalleryFullscreenButton: false,
      showPlayButton: false,
      showGalleryPlayButton: true,
      showNav: false,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'bottom',
      showVideo: {},
      useWindowKeyDown: true,
      items: [],
    };
  }

  getYoutubeImages = (url) => {
    let video, results;
    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];
    let thumbnail = 'https://img.youtube.com/vi/' + video + '/mqdefault.jpg';
    let original = 'https://img.youtube.com/vi/' + video + '/mqdefault.jpg';
    // let original = 'https://img.youtube.com/vi/' + video + '/maxresdefault.jpg';
    this.setState((prev) => ({
      items: [...prev.items,
      {
        original,
        thumbnail,
        embedUrl: url,
        renderItem: this._renderVideo.bind(this),
        thumbnailHeight: 60,
        originalHeight: 400,
        originalWidth: 800
      }]
    }))

    // setItems(prev => [...prev,
    //   {
    //     original,
    //     thumbnail,
    //     embedUrl: url ,
    //    }])
  }
  addImageToLector = (imgUrl) => {
    this.setState((prev) => ({
      items: [...prev.items,
      {
        original: imgUrl,
        thumbnail: imgUrl,
        thumbnailHeight: 60,
        originalHeight: 400,
        originalWidth: 800
      }]
    }))
    // setItems(prev => [...prev,
    //   {
    //     original: imgUrl,
    //     thumbnail:imgUrl
    //    }])
  }


  componentDidMount() {
    this.props.videos.map(url => this.getYoutubeImages(url))
    this.props.images.map(url => this.addImageToLector(url))
  }

  onLectorPause = () => {
    this.setState({
      playing: !this.state.playing
    })
  }

  images = [
    {
      thumbnail:
        "https://img.youtube.com/vi/zRji5tXGtYQ/default.jpg",
      original:
        "https://img.youtube.com/vi/zRji5tXGtYQ/hqdefault.jpg",
      embedUrl: "https://www.youtube.com/embed/zRji5tXGtYQ",
      renderItem: this._renderVideo.bind(this)
    },
    {
      thumbnail:
        "https://img.youtube.com/vi/u7C7O31lbWs/default.jpg",
      original:
        "https://img.youtube.com/vi/u7C7O31lbWs/hqdefault.jpg",
      embedUrl: "https://www.youtube.com/watch?v=u7C7O31lbWs",
      renderItem: this._renderVideo.bind(this)
    },
    {
      thumbnail:
        "https://images.pexels.com/photos/3311574/pexels-photo-3311574.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      original:
        "https://images.pexels.com/photos/3311574/pexels-photo-3311574.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      embedUrl: "https://www.youtube.com/watch?v=UMaG3ugl1bw",
      renderItem: this._renderVideo.bind(this)
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/"
    },
    {
      original: "https://img.youtube.com/vi/zRji5tXGtYQ/hqdefault.jpg",
      thumbnail: "https://img.youtube.com/vi/zRji5tXGtYQ/default.jpg"
    }
  ];
  _onImageClick(event) {
    console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }
  _onSlide(index) {
    this._resetVideo();
    // this.onLectorPause();
    console.debug('slid to index', index);
  }
  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    this.setState({ [state]: event.target.value });
  }

  _handleCheckboxChange(state, event) {
    this.setState({ [state]: event.target.checked });
  }

  _handleThumbnailPositionChange(event) {
    this.setState({ thumbnailPosition: event.target.value });
  }

  _resetVideo() {
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }


  _renderVideo(item) {
    return (
      <div>
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>
            {/* <iframe
                 height="430px"
                  width="100%"
                  src={item.embedUrl}
                  frameBorder='0'
                  allowFullScreen
                >
                </iframe> */}
            <div className="player-wrapper">
              <ReactPlayer className="react-player" width='100%' controls url={item.embedUrl} />
            </div>
          </div>
        ) : (

          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)} style={{ cursor: 'pointer' }}>
            <div className="project-video pos-rel">
              <div className="play-button"> </div>
              <img className="image-gallery-image" alt="kamamini" src={item.original} />
              <div className="video-area mr-15">
                <i className="fas fa-play popup-video"></i>
              </div>
              {item.description && (
                <span
                  className="image-gallery-description"
                  style={{ right: "0", left: "initial" }}
                >
                  {item.description}
                </span>
              )}
            </div>
          </a>

        )
        }
      </div>
    );
  }

  render() {
    //  let videos = this.props.items.filter(item => item.embedUrl)
    //  videos.map(video => video.renderItem = this._renderVideo.bind(this))


    return (
      <>

        {
          this.state.items.length ?
            <ImageGallery

              ref={i => this._imageGallery = i}
              items={this.state.items}
              lazyLoad={false}
              onClick={this._onImageClick.bind(this)}
              onImageLoad={this._onImageLoad}
              onSlide={this._onSlide.bind(this)}
              onPause={this._onPause.bind(this)}
              onScreenChange={this._onScreenChange.bind(this)}
              onPlay={this._onPlay.bind(this)}
              infinite={this.state.infinite}
              showBullets={this.state.showBullets}
              showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
              showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
              showThumbnails={this.state.showThumbnails}
              showIndex={this.state.showIndex}
              showNav={this.state.showNav}
              isRTL={this.state.isRTL}
              thumbnailPosition={this.state.thumbnailPosition}
              slideDuration={parseInt(this.state.slideDuration)}
              slideInterval={parseInt(this.state.slideInterval)}
              slideOnThumbnailOver={this.state.slideOnThumbnailOver}
              additionalClass="app-image-gallery"
              useWindowKeyDown={this.state.useWindowKeyDown}
            />
            :


            <Loader />
        }



      </>
    )


  }
}
export default Lector;
