const initState = {

  projects: null,
  projectLoading: false,
  projectError: '',

  userProjects: null,
  userProjectsLoading: false,
  userProjectsError: null,

  profileProjects: null,
  profileProjectLoading: false,
  profileProjectError: null,

  singleProject: null,
  singleProjectLoading: false,
  singleProjectError: null,
  noSingleProject: false,

  getAcceptedProjects: null,
  getAcceptedProjectsLoading: false,
  getAcceptedProjectsError: null,




  getFeaturedProjects: null,
  getFeaturedProjectsLoading: false,
  getFeaturedProjectsError: null,

  getCompletedProjects: null,
  getCompletedProjectsLoading: false,
  getCompletedProjectsError: '',

  getInProgressProjects: null,
  getInProgressProjectsLoading: false,
  getInProgressProjectsError: '',

  getPendingProjects: null,
  getPendingProjectsLoading: false,
  getPendingProjectsError: '',

  getRejectedProjects: null,
  getRejectedProjectsLoading: false,
  getRejectedProjectsError: '',

  getAllProjects: null,
  getAllProjectsLoading: false,
  getAllProjectsError: '',


  userDonationProjects: null,
  userDonationProjectsLoading: false,
  userDonationProjectsError: '',

  lengthOfProject: null,
  lengthOfProjectLoading: false,
  lengthOfProjectError: '',

  updateRejectedProjectsError: null,

  createProjectError: null,
  updateAcceptedProjectError: null,


}


const projectReducer = (state = initState, action) => {
  switch (action.type) {

    case "CREATE_PROJECT":
      return { ...state };
    case "CREATE_PROJECT_ERROR":
      return { ...state, createProjectError: action.error };
    case "UPDATE_PROJECT":
      return { ...state };
    case "UPDATE_PROJECT_ERROR":
      return { ...state, updateAcceptedProjectError: action.error };

    case "UPDATE_REJECTED_PROJECT":
      return { ...state };
    case "UPDATE_REJECTED_PROJECT_ERROR":
      return { ...state, updateRejectedProjectsError: action.error };

    case "ADD_DONATION_PERK":
      return { ...state };
    case "ADD_DONATION_PERK_ERROR":
      return { ...state };
    case "ADD_DONATION":
      return { ...state };
    case "ADD_DONATION_ERROR":
      return { ...state };


    case 'FETCH_USER_PROJECTS':
      return { ...state, userProjectsLoading: true }
    case 'FETCH_USER_PROJECTS_SUCCESS':
      return { ...state, userProjectsLoading: false, userProjects: action.projects }
    case 'FETCH_USER_PROJECTS_ERROR':
      return { ...state, userProjectsLoading: false, userProjects: null, userProjectsError: action.error }

    case 'FETCH_PROJECTS':
      return { ...state, projectLoading: true }
    case 'FETCH_PROJECTS_SUCCESS':
      return { ...state, projectLoading: false, projects: action.projects }
    case 'FETCH_PROJECTS_ERROR':
      return { ...state, projectLoading: false, projects: null, projectError: action.error }


    case 'FETCH_PROFILE_PROJECTS':
      return { ...state, profileProjectLoading: true }
    case 'FETCH_PROFILE_PROJECTS_SUCCESS':
      return { ...state, profileProjectLoading: false, profileProjects: action.projects }
    case 'FETCH_PROFILE_PROJECTS_ERROR':
      return { ...state, profileProjectLoading: false, profileProjects: null, profileProjectError: action.error }


    case 'FETCH_SINGLE_PROJECT':
      return { ...state, singleProjectLoading: true }
    case 'FETCH_SINGLE_PROJECT_SUCCESS':
      return { ...state, singleProjectLoading: false, singleProject: action.singleProject }
    case 'FETCH_SINGLE_PROJECT_ERROR':
      return { ...state, singleProjectLoading: false, singleProject: null, singleProjectError: action.error }
    case 'NO_SINGLE_PROJECT':
      return { ...state, noSingleProject: true }
    case 'CANCEL_PROJECT_INFO':
      return { ...state, singleProject: null, singleProjectError: null }
    case 'CANCEL_NO_PROJECT_INFO':
      return { ...state, noSingleProject: false }

    case 'GET_ACCEPTED_PROJECTS':
      return { ...state, getAcceptedProjectsLoading: true }
    case 'GET_ACCEPTED_PROJECTS_SUCCESS':
      return { ...state, getAcceptedProjectsLoading: false, getAcceptedProjects: action.allProjects }
    case 'NO_ACCEPTED_PROJECTS':
      return { ...state, getAcceptedProjectsLoading: false, getAcceptedProjects: [] }
    case 'GET_ACCEPTED_PROJECTS_ERROR':
      return { ...state, getAcceptedProjectsLoading: false, getAcceptedProjects: null, getAcceptedProjectsError: action.error }


    case 'GET_FEATURED_PROJECTS':
      return { ...state, getFeaturedProjectsLoading: true }
    case 'GET_FEATURED_PROJECTS_SUCCESS':
      return { ...state, getFeaturedProjectsLoading: false, getFeaturedProjects: action.allProjects }
    case 'NO_FEATURED_PROJECTS':
      return { ...state, getFeaturedProjectsLoading: false, getFeaturedProjects: null }
    case 'GET_FEATURED_PROJECTS_ERROR':
      return { ...state, getFeaturedProjectsLoading: false, getFeaturedProjects: null, getFeaturedProjectsError: action.error }

    case 'GET_COMPLETED_PROJECTS':
      return { ...state, getCompletedProjectsLoading: true }
    case 'GET_COMPLETED_PROJECTS_SUCCESS':
      return { ...state, getCompletedProjectsLoading: false, getCompletedProjects: action.allProjects }
    case 'NO_COMPLETED_PROJECTS':
      return { ...state, getCompletedProjectsLoading: false, getCompletedProjects: [] }
    case 'GET_COMPLETED_PROJECTS_ERROR':
      return { ...state, getCompletedProjectsLoading: false, getCompletedProjects: null, getCompletedProjectsError: action.error }

    case 'GET_IN_PROGRESS_PROJECTS':
      return { ...state, getInProgressProjectsLoading: true }
    case 'GET_IN_PROGRESS_PROJECTS_SUCCESS':
      return { ...state, getInProgressProjectsLoading: false, getInProgressProjects: action.allProjects }
    case 'NO_IN_PROGRESS_PROJECTS':
      return { ...state, getInProgressProjectsLoading: false, getInProgressProjects: [] }
    case 'GET_IN_PROGRESS_PROJECTS_ERROR':
      return { ...state, getInProgressProjectsLoading: false, getInProgressProjects: null, getInProgressProjectsError: action.error }


    case 'GET_PENDING_PROJECTS':
      return { ...state, getPendingProjectsLoading: true }
    case 'GET_PENDING_PROJECTS_SUCCESS':
      return { ...state, getPendingProjectsLoading: false, getPendingProjects: action.pendingProjects }
    case 'NO_PENDING_PROJECTS':
      return { ...state, getPendingProjectsLoading: false, getPendingProjects: [] }
    case 'GET_PENDING_PROJECTS_ERROR':
      return { ...state, getPendingProjectsLoading: false, getPendingProjects: null, getPendingProjectsError: action.error }

    case 'GET_REJECTED_PROJECTS':
      return { ...state, getRejectedProjectsLoading: true }
    case 'GET_REJECTED_PROJECTS_SUCCESS':
      return { ...state, getRejectedProjectsLoading: false, getRejectedProjects: action.allProjects }
    case 'NO_REJECTED_PROJECTS':
      return { ...state, getRejectedProjectsLoading: false, getRejectedProjects: [] }
    case 'GET_REJECTED_PROJECTS_ERROR':
      return { ...state, getRejectedProjectsLoading: false, getRejectedProjects: null, getRejectedProjectsError: action.error }

    case 'GET_ALLPROJECTS':
      return { ...state, getAllProjectsLoading: true }
    case 'GET_ALLPROJECTS_SUCCESS':
      return { ...state, getAllProjectsLoading: false, getAllProjects: action.allProjects }
    case 'GET_ALLPROJECTS_ERROR':
      return { ...state, getAllProjectsLoading: false, getAllProjects: null, getAllProjectsError: action.error }

    case 'UPDATE_PROJECT_CONFIRMATION_TO_TRUE':
      return { ...state }
    case 'UPDATE_PROJECT_CONFIRMATION_TO_FALSE':
      return { ...state }
    case 'UPDATE_PROJECT_FEATURED_TO_TRUE':
      return { ...state }
    case 'UPDATE_PROJECT_FEATURED_TO_FALSE':
      return { ...state }

    case 'ADD_MORE_TIME_TO_PROJECT':
      return { ...state }

    case 'FETCH_USER_DONATION_PROJECTS':
      return { ...state, userDonationProjectsLoading: true }
    case 'FETCH_USER_DONATION_PROJECTS_SUCCESS':
      return { ...state, userDonationProjectsLoading: false, userDonationProjects: action.userDonationProjects }
    case 'FETCH_USER_DONATION_PROJECTS_ERROR':
      return { ...state, userDonationProjectsLoading: false, userDonationProjects: null, userDonationProjectsError: action.error }

    case 'GET_LENGTH_OF_PROJECT':
      return { ...state, lengthOfProjectLoading: true }
    case 'GET_LENGTH_OF_PROJECT_SUCCESS':
      return { ...state, lengthOfProjectLoading: false, lengthOfProject: action.lengthOfProject }
    case 'GET_LENGTH_OF_PROJECT_ERROR':
      return { ...state, lengthOfProjectLoading: false, lengthOfProject: null, lengthOfProjectError: action.error }
    case 'CANCEL_LENGTH_OF_PROJECT':
      return { ...state, lengthOfProject: null }


    default: return state
  }

}

export default projectReducer
