import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux"
import KamaminiButton from '../../Buttons/KamaminiColorButton/KamaminiButton'
import { displayProjectEndTime } from '../../../redux/actions/actions'
import TextDialog from '../../Dialog/TextDialog'


const RewardCard = ({ title, img, price, description, quantity, projectSlug, getDonorsPerPerk, endData }) => {

    const userInfo = useSelector(state => state.auth.userInfo)
    const [open, setOpen] = useState(false);
    const [isReadMore, setIsReadMore] = useState(true);
    const [endOpen, setEndOpen] = useState(false);
    let history = useHistory();


    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickEndOpen = () => {
        setEndOpen(true);
    };
    const handleClick = () => {
        if (userInfo) {
            if (displayProjectEndTime(endData) === `Terminé`) {
                handleClickEndOpen()

            }
            else if (parseInt(quantity) > 0 || quantity === 'illimited') {
                history.push(`/${projectSlug}/checkout?title=${encodeURIComponent(title)}`);
            }
        } else {
            handleClickOpen()
        }
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleEndClose = () => {
        setEndOpen(false);
    };

    return (
        <div className="image-content-right">
            <div className="widget  mb-30">
                <div className="widget-rewards">
                    <div className="reward__block" >
                        <div className="reaward__thumb text-center mb-25">
                            {
                                img &&
                                <img onClick={handleClick} src={img} alt="kamamini rewards" />
                            }

                        </div>
                        <div className="widget-rewardss">

                            {
                                <h4 onClick={handleClick} > {title}</h4>
                            }

                            <h5><span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(price)}</span></h5>
                            <p className="mb-20">
                                {isReadMore ? description.slice(0, 150) : description}
                                {
                                    description.length > 150 &&
                                    <span onClick={toggleReadMore} className="link">  <br />
                                        {isReadMore ? "Lire la suite..." : "Lire moins"}
                                    </span>
                                }

                            </p>

                            {/* <h5>30 Dec 2020</h5>
                        <p>Estimated Delivery</p> */}
                            <ul className="rewards-list pt-15 mb-25">
                                <li>
                                    <i className="far fa-user-circle"></i>
                                    {getDonorsPerPerk(title) > 1 ? `${getDonorsPerPerk(title)} Contributeurs` : `${getDonorsPerPerk(title)} Contributeur`}
                                </li>
                                {
                                    parseInt(quantity) > 1 ?
                                        <li>
                                            <i className="fas fa-trophy"></i>
                                            {quantity} récompenses restantes
                                        </li>
                                        : parseInt(quantity) === 1 ?
                                            <li>
                                                <i className="fas fa-trophy"></i>
                                                {quantity} récompense restante
                                            </li>
                                            : parseInt(quantity) === 0 ?
                                                <li>
                                                    <i className="fas fa-trophy"></i>
                                                    Aucune récompense disponible
                                                </li>

                                                : quantity === 'illimited' &&
                                                <li>
                                                    <i className="fas fa-trophy"></i>
                                                    illimité
                                                </li>
                                }
                            </ul>
                            {
                                <span onClick={handleClick} className="theme_btn theme_btn_bg" data-animation="fadeInLeft" data-delay=".5s">
                                    Sélectionner <i className="fas fa-arrow-right"></i>
                                </span>
                            }


                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <img src={"https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2FstopKamamini.svg?alt=media&token=a3a6ba1e-98f7-4277-a23b-dbf1820bfb0c"} className='mt-3' style={{ height: "60px" }} alt="Acces interdit à cete page" />
                                <DialogTitle id="alert-dialog-title" className='d-flex justify-content-center'  >
                                    {"Vous n'avez pas accès a cette page"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Vous devez être connecté pour accéder à cette page.
                                        <br />
                                    </DialogContentText>
                                    <div className="d-flex justify-content-center mt-3" >
                                        <Link to='/login'>
                                            <KamaminiButton onClick={handleClose} text="VOUS CONNECTER" />
                                        </Link>
                                    </div>
                                </DialogContent>
                            </Dialog>

                            <TextDialog
                                open={endOpen}
                                handleClose={handleEndClose}
                                contentText="Le délai de participation à ce projet est maintenant terminé. Nous remercions tous les contributeurs pour leur soutien et nous espérons que vous continuerez à soutenir d'autres projets sur Kamamini"
                                image={"url(" + "https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Fsand-clock.png?alt=media&token=1c748bef-8d5f-4a78-8754-4dfd23afb559" + ")"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RewardCard
