import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Container, CssBaseline, Box, CircularProgress } from '@material-ui/core'
// import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
// import GridContainer from "../../components/Grid/GridContainer.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
import { allBlogs } from '../../../../redux/actions/blogActions'
// import styles from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import BlogsCard2 from '../../../Cards/BlogsCard2/BlogsCard2'


// const useStyles = makeStyles(styles);

const MakeBlogsFeatured = () => {

    const dispatch = useDispatch();
    // const classes = useStyles();
    const getAllBlogs = useSelector(state => state.blogs.getAllBlogs);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        dispatch(allBlogs())

    }, [dispatch])

    const pendingBlogs = getAllBlogs && getAllBlogs.filter(blog => blog.featured === false)
    const pendingFeaturedBlogs = getAllBlogs && getAllBlogs.filter(blog => blog.featured === true)



    if (pendingBlogs && pendingFeaturedBlogs) {
        return (
            <>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomTabs
                        title="Blogs"
                        headerColor="warning"
                        tabs={[
                            {
                                tabName: "Tout les blogs",
                                // tabIcon: BugReport,
                                tabContent: (
                                    <>
                                        <CssBaseline />

                                        <Box mb={5}></Box>
                                        <Grid container spacing={2}>
                                            {
                                                pendingBlogs.map(blog => {
                                                    return (
                                                        <Grid container item xs={12} sm={6} md={4} lg={4} key={blog.id} >
                                                            <BlogsCard2 blog={blog} />
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>

                                    </>

                                ),
                            },
                            {
                                tabName: "Mis en avant",
                                // tabIcon: Code,
                                tabContent: (
                                    <>
                                        <CssBaseline />
                                        <Container>
                                            <Box mb={5}></Box>
                                            <Grid container spacing={2}>
                                                {
                                                    pendingFeaturedBlogs.map(blog => {
                                                        return (
                                                            <Grid container item xs={12} sm={6} md={6} lg={4} key={blog.id} >
                                                                <BlogsCard2 blog={blog} />
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Container>

                                    </>
                                ),
                            },

                        ]}
                    />
                </GridItem>
            </>
        )
    }
    else {
        return (
            <div id="preloader">
                <div className="preloader">
                    <CircularProgress style={{ color: 'green' }} />
                </div>
            </div>
        )
    }
}

export default MakeBlogsFeatured
