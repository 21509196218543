
import React,{useEffect,useState,useRef} from 'react'
import {NavLink,Link,useLocation} from "react-router-dom"
// import {Modal, Box,Typography} from '@mui/material';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {useSelector,useDispatch} from "react-redux"
// import { Button } from "@material-ui/core";
import MetisMenu from '@metismenu/react';
import 'metismenujs/dist/metismenujs.css';
import { useWindowScroll } from "react-use";
import KamaminiButton from '../../Buttons/KamaminiColorButton/KamaminiButton'
// import stop from '../../../assets/img/stopKamamini.svg';
// import Icon1 from '../../../assets/img/logo/bar-icon-01.png'
// import LogoOne from '../../../assets/img/logo/header_logo_one.png'
import UserMenu from './UserMenu'
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const useStyles = makeStyles((theme) => ({
   
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
  }));

const NavBar2 = ({sticky}) => {

    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.auth.userInfo)

    // console.log(userInfo);
    const classes = useStyles();
    const location = useLocation();
    const { y: pageYOffset } = useWindowScroll();

    const first = useRef(null)
    const secound = useRef(null)
    const stickyActive = useRef(null);

    useEffect(() => {
        
        if (sticky) {
            // wait until stickyActive is different from null before adding the sticky class 
        if(stickyActive.current !== null){
            if(pageYOffset > 250 && stickyActive.current){
                stickyActive.current.classList.add('sticky')
            }
            else{
                stickyActive.current.classList.remove('sticky')
            }
        }
        }
        
    }, [pageYOffset,stickyActive,sticky])


        

    const isCurrentURL = () => {
        const urls =
            [
                "/admin/dashboard",
                "/admin/user",
                "/admin/create-blog",
                "/admin/table",
                "/admin/typography",
                "/admin/icons",
                "/admin/maps",
                "/admin/notifications",
                "/admin/projects-validation",
                "/admin/accepted-projects",
                "/admin/rejected-projects",
                "/admin/featured-projects",
                "/admin/featured-blogs",
                "/admin/admin-users",
                "/admin/admin-coments",
                "/admin/edit-blog",
                "/admin/completed-projects",
                "/admin/in-progress-projects"
            
            ]
        
        // return location.pathname.toLowerCase() === url.toLowerCase();
        return urls.includes(location.pathname.toLowerCase())
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

        //Modal imran



    const openNavbar = () => {
       first.current.classList.toggle('show')
       secound.current.classList.add('active')
       document.body.classList.add('on-side');
    }

    const closeNavbar = () => {
        first.current.classList.remove('show')
        secound.current.classList.remove('active')
        document.body.classList.remove('on-side');
    }

   

        let userInital = userInfo ?  <Avatar className={`${classes.orange} ${classes.large}`}>{userInfo.initials.toUpperCase()} </Avatar> :<Avatar className={classes.large}> <CircularProgress style={{color:'green'}} /> </Avatar> 
       
        return (
        <>
          { isCurrentURL() ?
            null :
        
    <header id="top-menu">
       
        <div className="main-header-area main-head-02" ref={stickyActive} >
            <div className="container custom-container-03">
                <div className="row align-items-center justify-content-between">
                    <div className="col-xl-2 col-lg-2 col-md-6 col-6">
                        <NavLink to="/">
                        <div className="logo">
                            <div className="logo-img" ><img src="/kamamini_2.png" alt="logo kamamini" /></div>
                        </div>
                        </NavLink>
                        
                    </div>
                    <div className="col-xl- col-lg-5  d-none d-lg-block text-lg-center text-xl-right">
                        <div className="main-menu main-menu-02 d-none d-lg-block">
                            <nav>
                                <ul>
                                    {/* <li><a className="active" href="#">Home <i className="fas fa-chevron-down"></i></a>
                                        <ul className="submenu">
                                            <li><a href="index.html">Home Style 1</a></li>
                                            <li><a href="index-2.html">Home Style 2</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="about.html">About</a></li>
                                    <li><a href="#">Events <i className="fas fa-chevron-down"></i></a>
                                        <ul className="submenu">
                                            <li><a href="events.html">Events Grid</a></li>
                                            <li><a href="events-details.html">Events Details</a></li>
                                        </ul>
                                    </li> */}
                                    <li><NavLink onClick={closeNavbar} to="/" exact>Accueil</NavLink></li>
                                    <li><a href="#">Découvrir <i className="fas fa-chevron-down"></i></a>
                                        <ul className="submenu">
                                            <li><NavLink to="/faq">Faq</NavLink></li>
                                            {/* <li><NavLink to="/team">L'equipe</NavLink></li> */}
                                            <li><NavLink to="/all-projects">Tous les projets</NavLink></li>
                                            <li><NavLink to="/all-blogs">Tous les blogs</NavLink></li>
                                        </ul>
                                    </li>
                                    <li><Link to='/about'>A propos <i className="fas fa-chevron-down"></i></Link>
                                        <ul className="submenu">
                                            <li><NavLink to="/about">à propos</NavLink></li>
                                            <li><NavLink to="/blogs/qu’est-ce-que-le-financement-participatif-">Qu'est ce que le financement participatif</NavLink></li>
                                            <li><NavLink to="/blogs/pourquoi-choisir-kamamini">Pourquoi choisir kamamini</NavLink></li>
                                            
                                           
                                        </ul>
                                    </li>
                                    {/* <li><a href="#">Pages <i className="fas fa-chevron-down"></i></a>
                                        <ul className="submenu">
                                            <li><a href="history.html">History</a></li>
                                            <li><a href="career.html">Career</a></li>
                                            <li><a href="team.html">Team</a></li>
                                            <li><a href="faq.html">FAQ</a></li>
                                        </ul>
                                    </li> */}
                                    <li>
                                        <Link to="/contact" >
                                        Contact
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>


                   
                         <div className="col-xl-3 col-lg-3  d-none d-lg-block text-lg-center text-xl-right">
                            {
                                userInfo ?

                                <NavLink to="/start-a-campaign">
                                
                                <div className="main-menu main-menu-02 d-none d-lg-block">
                                  
                                   {/* <button style={{backgroundColor: "green", color: "white", border: "none" }}>Commencer une campagne</button> */}
                                   <KamaminiButton text='Commencer une campagne' />
                                </div>
                                
                                </NavLink>
                                
                                :
                                //Modals
                                <div className="main-menu main-menu-02 d-none d-lg-block">
                                    <KamaminiButton onClick={handleClickOpen} text='Commencer une campagne' />

                                    <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2FstopKamamini.svg?alt=media&token=a3a6ba1e-98f7-4277-a23b-dbf1820bfb0c" className='mt-3' style={{height: "60px"}} alt="Acces interdit à cete page" />
                                                                    
                                    <DialogTitle  id="alert-dialog-title" className='d-flex justify-content-center'  >
                                    {"Vous n'avez pas accès a cette page"}
                                    </DialogTitle>
                                    <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                       
                                        Vous devez être connecté pour accéder à cette page. 
                                        <br/>
                                        
                                    </DialogContentText>
                                    <div className="d-flex justify-content-center mt-3" >
                                    <Link to='/login'>
                                        <KamaminiButton onClick={handleClose} text="VOUS CONNECTER" />
                                    </Link>
                                    </div>
                                    
                                    </DialogContent>
                                    
                            
                                </Dialog>
                                  
                                </div>


                            }
                          

                        </div>

                            {
                                userInfo ?  
                                <div className="col-xl-2 col-lg-2  d-none d-lg-block text-lg-center ">
                                <div className="main-menu main-menu-02 d-none d-lg-block">
                                   {/* {
                                       userInfo.photoURL ? <Avatar  className={classes.large} alt={userInfo.displayName} src={userInfo.photoURL} /> : userInital
                                   } */}
                                   <UserMenu/>
                                </div>
                            </div>
                               
                                :

                                 (<div className="col-xl- col-lg-2  d-none d-lg-block text-lg-center ">
                                <div className="main-menu main-menu-02 d-none d-lg-block">
                                <NavLink to="/signup" className='text-decoration-line-through font-weight-bold text-dark'>
                                  S'inscrire
                                   </NavLink>
                                   <span className='font-weight-bold pl-2'>/</span>
                                   <NavLink to="/login" className="font-weight-bold ml-2 text-dark">
                                   Se connecter
                                   </NavLink>
                                    
                                </div>
                            </div>)
                            }
                           
                            
                    <div className="d-xl-none d-lg-none  text-right d-flex align-items-center justify-content-end">
                       
                        <div className="hamburger-menu"  onClick={(()=> openNavbar())} >
                            <Link to="#">
                                <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fbar-icon-01.png?alt=media&token=44f7c911-60f2-443a-a753-7874d71ead0d" alt="kamamini icon-bar" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
}

    <aside className="slide-bar" ref={first}>
        <div className="close-mobile-menu" onClick={()=> closeNavbar()} >
            <Link to="#"><i className="fas fa-times"></i></Link>
        </div>

         {/* offset-sidebar start  */}
        <div className="offset-sidebar">
            <div className="offset-widget offset-logo mb-30">
                <a href="index.html">
                    <img src="https://firebasestorage.googleapis.com/v0/b/kamamini-react.appspot.com/o/Kamamini-react%2Fsrc%2Fassets%2Fimg%2Flogo%2Fheader_logo_one.png?alt=media&token=a06eecf9-9959-4b30-ac87-c57bd45a52ac" alt="logo" />
                </a>
            </div>
            <div className="offset-widget mb-40">
                <div className="info-widget">
                    <h4 className="offset-title mb-20">About Us</h4>
                    <p className="mb-30">
                        But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain
                        was born and will give you a complete account of the system and expound the actual teachings of
                        the great explore
                    </p>
                    <a className="theme_btn theme_btn_bg" href="contact.html">Contact Us</a>
                </div>
            </div>
            <div className="offset-widget mb-30 pr-10">
                <div className="info-widget info-widget2">
                    <h4 className="offset-title mb-20">Contact Info</h4>
                    <p> <i className="fal fa-address-book"></i> 23/A, Miranda City Likaoli Prikano, Dope</p>
                    <p> <i className="fal fa-phone"></i> +0989 7876 9865 9 </p>
                    <p> <i className="fal fa-envelope-open"></i> info@example.com </p>
                </div>
            </div>
        </div>
         {/* offset-sidebar end  */}

         {/* side-mobile-menu start  */}
        <nav className="side-mobile-menu">
            <MetisMenu toggle={false}>
                <li><NavLink onClick={closeNavbar} to="/" exact>Accueil</NavLink></li>
                <li>
                <Link to="#" className="has-arrow">Découvrir</Link>
                <ul>
                <li><NavLink onClick={closeNavbar} to="/faq">Faq</NavLink></li>
                {/* <li><NavLink onClick={closeNavbar} to="/team">L'equipe</NavLink></li> */}
                <li><NavLink onClick={closeNavbar} to="/all-projects">Tous les projets</NavLink></li>
                <li><NavLink onClick={closeNavbar} to="/all-blogs">Tous les blogs</NavLink></li>
                </ul>
                </li>
                <li>
                <Link to="/about" className="has-arrow">à propos</Link>
                <ul>
                <li><NavLink onClick={closeNavbar} to="/about">à propos</NavLink></li>
                <li><NavLink onClick={closeNavbar} to="/blogs/qu’est-ce-que-le-financement-participatif-">Qu'est ce que le financement participatif</NavLink></li>
                <li><NavLink onClick={closeNavbar} to="/blogs/pourquoi-choisir-kamamini">Pourquoi choisir kamamini</NavLink></li>
                
                </ul>
                </li>

                <li>
                <Link to="#" className="has-arrow">Conexion</Link>
                <ul>
                    <li><NavLink onClick={closeNavbar} to="/login" >Se connecter</NavLink></li>
                    <li><NavLink onClick={closeNavbar} to="/signup" >S'inscrire</NavLink></li>
                </ul>
                </li>
                <li><NavLink onClick={closeNavbar} to="/contact" >Contact</NavLink></li>

                <li>
                    {
                        userInfo? <NavLink onClick={closeNavbar} to="/start-a-campaign" >Commencer une campagne</NavLink> :   <NavLink  to="#" onClick={handleClickOpen} >Commencer une campagne</NavLink>
                    }
                   
                </li>


               
                
                          


            </MetisMenu>
        </nav>
         {/* side-mobile-menu end  */}
    </aside>
    <div className="body-overlay" ref={secound} onClick={()=> closeNavbar()} ></div>
        </>
    )
}

export default NavBar2