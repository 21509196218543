import React from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
     Kamamini
    </div>
  );
}
