import React from 'react'

const DisplayReward = ({title,img,price,description,quantity}) => {
    return (
    <div className="image-content-right">
                <div className="widget  mb-30">
                <div className="widget-rewards">
                        
                        <div className="reward__block" >
                        {
                            img &&  
                            <div className="reaward__thumb text-center mb-25">
                                <img src={img} alt={title} />
                            </div>
                        }

                        <div className="widget-rewardss">
                        <h4>{title}</h4>
                        <h5><span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(price) } </span></h5>
                        <p className="mb-20">{description} </p>
                        {/* <h5>30 Dec 2020</h5>
                        <p>Estimated Delivery</p> */}

                        {
                            parseInt(quantity) > 1 ? 
                                <ul className="rewards-list pt-15 mb-25">
                                    <li><i className="fas fa-trophy"></i> {quantity} récompenses </li>
                                </ul>
                              
                            : parseInt(quantity) === 1 ? 
                                <ul className="rewards-list pt-15 mb-25">
                                    <li><i className="fas fa-trophy"></i> {quantity} récompense </li>
                                </ul>
                            : quantity === 'illimited' &&
                                <ul className="rewards-list pt-15 mb-25">
                                    <li><i className="fas fa-trophy"></i> récompense illimité </li>
                                </ul>                            
                        }
                       
                        </div>
                    </div>
                </div>
            </div>
               
            </div>
    )
}

export default DisplayReward
