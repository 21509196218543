import React, { useEffect } from 'react'
import { Prompt } from 'react-router'
import {
  Container,
  Grid,
  makeStyles,
  Box,
  CssBaseline
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { ToastContainer } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';
import KamaminiButton from '../Buttons/KamaminiColorButton/KamaminiButton'
import {errorToast} from '../../redux/actions/actions';


const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
  }
));



const StepFour = (props) => {

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // let future = moment().add(7, 'days')
  // console.log(future._d);


  const {
    nextStep,
    prevStep,
    CustumStepper,
    moneyRequest,
    setMoneyRequest
  } = props

  const Back = (e) => {
    e.preventDefault();
    prevStep()
  }
  // const Continue = (e) => {
  //   e.preventDefault();
  //   nextStep()
  // }
    // check if minimum money request is less than 100
    const checkMinimummoneyRequest = (moneyRequest) => {
        if(moneyRequest.toString().match(/^\s*$/) || parseInt(moneyRequest) < 50000){
          return true;
        }
      return false;
    }

  const handleSubmit = (e) => {
    e.preventDefault()
    if(checkMinimummoneyRequest(moneyRequest)){
      errorToast('La somme minimum de collecte est de 50 000 FCFA')
    }
    else {
      nextStep()
    }
  }


  return (
    <>
    <Prompt
    // when={shouldBlockNavigation}
        message='Etes-vous sûr de vouloir quitter cette page ? Les données que vous avez entrées ne seront pas sauvegardées.'
      />
      <ToastContainer />
      <CustumStepper />
      <Box mb={5}></Box>
      <Container>
        <CssBaseline />
        <form className={classes.form} onSubmit={handleSubmit} >
          <Grid container spacing={2}>
            <Grid item xs={8} sm={8} md={8} lg={8} >
              <div className="kamamini-input" >

                <h5 htmlFor="duration">Objectif de financement <span className='star'>*</span> </h5>
                <p className="note mb-3">Combien d'argent souhaitez-vous collecter pour cette campagne ?  Veuillez à tenir compte de nos frais de commission.
                <br/> NB : Le montant minimum de collecte est 50 000 F CFA </p>

                <div className="input-group input-group-lg">
                  {/* <input type="number" min={50000} value={projectData.moneyRequest} onChange={handleChange} required className="form-control" id="moneyRequest" aria-describedby="basic-addon3"  /> */}
                  <CurrencyFormat className="form-control" value={moneyRequest} displayType={'input'} thousandSeparator={'  '} suffix={'  F CFA'} allowNegative={false} 
                  onValueChange={(values) => { setMoneyRequest(values.value) }}
                  />

                </div>
              </div>
            </Grid>
          </Grid>

          <br />
          <br />
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <div className="d-flex justify-content-between">
              <KamaminiButton text='Précédent' IconLeft={ArrowLeftIcon} onClick={Back} />
              <KamaminiButton text={'Terminer'} IconRight={ArrowRightIcon} type='Submit' />
            </div>
          </Grid>

        </form>

        <Box mb={5}></Box>
      </Container>
    </>
  )
}

export default StepFour
